import useMetrics from '../../Reporting/useMetrics'
import PerformanceSnippet from './PerformanceSnippet'
import Section from './Section'
import { Maybe } from '@/types'

const calcVariation = (current?: number, prev?: number) => {
  if (!current || !prev) {
    return 0
  }

  const valueDiff = current - prev
  const percentDiff = (valueDiff / prev) * 100.0

  return Math.abs(percentDiff)
}

const whenIsNumber = (
  value: Maybe<number>,
  fn: (value: number) => number,
  defaultValue: Maybe<number> = null
) => (value === null ? defaultValue : fn(value))

const whenIsNumberX2 = (
  value1: Maybe<number>,
  value2: Maybe<number>,
  fn: (value1: number, value2: number) => number,
  defaultValue: Maybe<number> = null
) => (value1 === null || value2 === null ? defaultValue : fn(value1, value2))

const Performance = ({
  aggregatedRequestMetrics,
  prevAggregatedRequestMetrics,
}: ReturnType<typeof useMetrics>) => (
  <Section className="space-y-4">
    <Section.Title>Performance</Section.Title>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-3 w-full">
      <PerformanceSnippet
        positiveDirection="down"
        name="Urgent Requests"
        value={whenIsNumber(
          aggregatedRequestMetrics?.urgent?.percent ?? null,
          (value: number) => value * 100
        )}
        variation={whenIsNumberX2(
          aggregatedRequestMetrics?.urgent?.percent ?? null,
          prevAggregatedRequestMetrics?.urgent?.percent ?? null,
          (current, prev) => calcVariation(current, prev)
        )}
        href="/reporting/performance?metric=urgency"
      />

      <PerformanceSnippet
        positiveDirection="up"
        name="Accuracy"
        value={whenIsNumber(
          aggregatedRequestMetrics?.accurate?.percent ?? null,
          (value) => value * 100
        )}
        variation={whenIsNumberX2(
          aggregatedRequestMetrics?.accurate?.percent ?? null,
          prevAggregatedRequestMetrics?.accurate?.percent ?? null,
          (current, prev) => calcVariation(current, prev)
        )}
        href="/reporting/performance?metric=accuracy"
      />

      {/* <PerformanceSnippet name="On Time" value={86.7} variation={5.6} /> */}

      <PerformanceSnippet
        positiveDirection="up"
        name="Internal Fulfillment"
        value={whenIsNumber(
          aggregatedRequestMetrics?.internalFulfillment?.percent ?? null,
          (value) => value * 100
        )}
        variation={whenIsNumberX2(
          aggregatedRequestMetrics?.internalFulfillment?.percent ?? null,
          prevAggregatedRequestMetrics?.internalFulfillment?.percent ?? null,
          (current, prev) => calcVariation(current, prev)
        )}
        href="/reporting/performance?metric=internal fulfillment"
      />
    </div>
  </Section>
)

export default Performance
