import { GlobeAltIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

const SentToNetworkBadge = ({ className }: { className?: string }) => (
  <div
    className={classNames(
      'px-2 py-1 flex flex-row items-center gap-x-1 text-sm font-medium text-gray-900 bg-gray-200 rounded-full',
      className
    )}
  >
    <GlobeAltIcon className="w-5 h-5 inline-flex shrink-0 text-gray-800" />
    <span>Sent to the Gearflow Network</span>
  </div>
)

export default SentToNetworkBadge
