import classNames from 'classnames'
import { ReactNode } from 'react'

const Title = ({ children }) => <h3 className="text-2xl font-medium">{children}</h3>

const Section = ({ children, className }: { children: ReactNode; className?: string }) => (
  <section
    className={classNames(
      'rounded-xl border border-gray-200 bg-gray-50 shadow-base p-4 lg:p-6 xl:p-8',
      className
    )}
  >
    {children}
  </section>
)

Section.Title = Title

export default Section
