import VendorLogo from '@/buyers/components/Vendors/VendorLogo'
import { Maybe } from '@/types'
import { XIcon } from '@heroicons/react/solid'

const SelectedVendorCard = ({
  name,
  address,
  logoUrl,
  className,
  onRemoveClick,
}: {
  name: string
  address: Maybe<{ city: string; state: string }>
  logoUrl: Maybe<string>
  className?: string
  onRemoveClick?: () => void
}) => (
  <div className={className}>
    <div className="flex gap-x-4 max-w-ful items-center">
      <VendorLogo url={logoUrl} size="sm" />

      <div className="truncate flex-grow">
        <h6 className="text-lg font-medium leading-7 truncate overflow-ellipsis">{name}</h6>
        {address && (
          <p className="leading-6">
            {address.city}, {address.state}
          </p>
        )}
      </div>

      {onRemoveClick && (
        <button
          type="button"
          onClick={onRemoveClick}
          className="w-8 h-8 flex items-center justify-center flex-shrink-0 justify-self-end"
        >
          <XIcon className="h-6 w-6 inline-block" />
        </button>
      )}
    </div>
  </div>
)

export default SelectedVendorCard
