import {
  OrganizationUserMetricsQueryResult,
  SortByInput,
  SortOrder,
  useOrganizationUserMetricsQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Reporting from '@/buyers/modules/Reporting'
import RedAlert from '@/gf/components/RedAlert'
import DurationInput, {
  defaultDurationDates,
  defaultDurationId,
} from '@/gf/components/Reports/DurationInput'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Comparison from '@/gf/components/next/Comparison'
import Sort from '@/gf/modules/Sort'
import Time from '@/gf/modules/Time'
import { orderBy } from 'lodash'
import { useMemo, useState } from 'react'
import useReportingFormQueryParams from './useReportingFormQueryParams'

const getComparison = ({
  value,
  comparisonValue,
  downIsGood,
  formatValue,
}: {
  value?: number
  comparisonValue?: number
  downIsGood?: boolean
  formatValue?: (value: number) => string
}) =>
  value !== undefined && comparisonValue !== undefined && comparisonValue !== value ? (
    <Comparison
      className="ml-3"
      value={value}
      comparisonValue={comparisonValue}
      formatValue={formatValue}
      downIsGood={downIsGood}
    />
  ) : null

const getAggregateCell = ({
  value,
  comparisonValue,
  downIsGood,
  formatValue,
}: {
  value?: number
  comparisonValue?: number
  downIsGood?: boolean
  formatValue?: (value: number) => string
}) => (
  <>
    {formatValue && value !== undefined ? formatValue(value) : value}
    {getComparison({ value, comparisonValue, downIsGood, formatValue })}
  </>
)

const getOrganizationUserMetricAggregates = (
  organizationUserMetrics: NonNullable<
    OrganizationUserMetricsQueryResult['data']
  >['organizationUserMetrics']
) => ({
  requestsCreated: Reporting.aggregateMetricValues(
    organizationUserMetrics,
    ({ requestsCreated }) => requestsCreated ?? null,
    Reporting.getStandardAggregate
  ),
  responseDurationMedian: Reporting.aggregateMetricValues(
    organizationUserMetrics,
    ({ responseDurationMedian }) => responseDurationMedian ?? null,
    Reporting.getStandardAggregate
  ),
  quotesApproved: Reporting.aggregateMetricValues(
    organizationUserMetrics,
    ({ quotesApproved }) => quotesApproved ?? null,
    Reporting.getStandardAggregate
  ),
  messagesCreated: Reporting.aggregateMetricValues(
    organizationUserMetrics,
    ({ messagesCreated }) => messagesCreated ?? null,
    Reporting.getStandardAggregate
  ),
  ordersMarkedReceived: Reporting.aggregateMetricValues(
    organizationUserMetrics,
    ({ ordersMarkedReceived }) => ordersMarkedReceived ?? null,
    Reporting.getStandardAggregate
  ),
})

const TeamReport = () => {
  const { organization } = useSession()
  const gqlClient = useGqlClient()
  const { form, updateForm } = useReportingFormQueryParams({
    defaultDurationId,
    defaultDurationDates,
  })
  const [sortBy, setSortBy] = useState<SortByInput>({
    field: 'requestsCreated',
    order: SortOrder.Desc,
  })

  const { data, error } = useOrganizationUserMetricsQuery({
    client: gqlClient,
    variables: {
      orgId: organization.id,
      filter: JSON.stringify([[]]),
      fromDate: form.durationStart.toISODate(),
      toDate: form.durationEnd.toISODate(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const prevDurationEnd = form.durationStart.minus({ day: 1 })
  const prevDurationStart = prevDurationEnd.minus(form.durationEnd.diff(form.durationStart))
  const { data: prevData, error: prevError } = useOrganizationUserMetricsQuery({
    client: gqlClient,
    variables: {
      orgId: organization.id,
      filter: JSON.stringify([[]]),
      fromDate: prevDurationStart.toISODate(),
      toDate: prevDurationEnd.toISODate(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const metrics = useMemo(() => {
    if (error) return { error }
    if (!data) return { data: undefined }
    const orderedOrganizationUserMetrics = orderBy(
      data.organizationUserMetrics,
      [Sort.sortFieldToLodash(sortBy.field)],
      [Sort.sortOrderToLodash(sortBy.order)]
    )
    const aggregatedOrganizationUserMetrics = getOrganizationUserMetricAggregates(
      data.organizationUserMetrics
    )
    return { data: { orderedOrganizationUserMetrics, aggregatedOrganizationUserMetrics } }
  }, [data, error, sortBy])

  const prevMetrics = useMemo(() => {
    if (prevError) return { error: prevError }
    if (!prevData) return { data: undefined }
    const aggregatedOrganizationUserMetrics = getOrganizationUserMetricAggregates(
      prevData.organizationUserMetrics
    )
    return {
      data: {
        organizationUserMetrics: prevData.organizationUserMetrics,
        aggregatedOrganizationUserMetrics,
      },
    }
  }, [prevData, prevError])

  return (
    <div className="flex flex-col gap-y-8">
      <DurationInput
        start={form.durationStart}
        end={form.durationEnd}
        durationId={form.durationId}
        onChange={({ start, end, durationId }) =>
          updateForm({ durationStart: start, durationEnd: end, durationId })
        }
        showDates
      />

      {metrics.error || prevError ? (
        <RedAlert className="border border-red-200" title="Error, please contact support." />
      ) : (
        <div className="py-3 flex flex-col gap-y-3 bg-white border border-gray-300 rounded-lg shadow-sm">
          <div className="px-4 overflow-x-scroll overflow-y-scroll">
            <ReportingTable
              data={metrics.data?.orderedOrganizationUserMetrics}
              sortBy={{ sortBy, setSortBy }}
              getRowKey={(row) => row.user.id}
              columns={[
                { header: 'Person', getValue: (row) => row.user.displayName },
                {
                  header: 'Requests Created',
                  sortByField: 'requestsCreated',
                  getValue: (row) => row.requestsCreated,
                  getComparison: (row) =>
                    getComparison({
                      value: row.requestsCreated,
                      comparisonValue: prevMetrics.data?.organizationUserMetrics.find(
                        ({ user }) => user.id === row.user.id
                      )?.requestsCreated,
                    }),
                },
                {
                  header: 'Receive Quote',
                  sortByField: 'responseDurationMedian',
                  getValue: (row) =>
                    row.responseDurationMedian
                      ? Time.secondsToString(row.responseDurationMedian)
                      : 'None',
                  getComparison: (row) =>
                    row.responseDurationMedian
                      ? getComparison({
                          value: row.responseDurationMedian,
                          comparisonValue:
                            prevMetrics.data?.organizationUserMetrics.find(
                              ({ user }) => user.id === row.user.id
                            )?.responseDurationMedian ?? undefined,
                          formatValue: Time.secondsToString,
                          downIsGood: true,
                        })
                      : undefined,
                },
                {
                  header: 'Quotes Approved',
                  sortByField: 'quotesApproved',
                  getValue: (row) => row.quotesApproved,
                  getComparison: (row) =>
                    getComparison({
                      value: row.quotesApproved,
                      comparisonValue: prevMetrics.data?.organizationUserMetrics.find(
                        ({ user }) => user.id === row.user.id
                      )?.quotesApproved,
                    }),
                },
                {
                  header: 'Messages Sent',
                  sortByField: 'messagesCreated',
                  getValue: (row) => row.messagesCreated,
                  getComparison: (row) =>
                    getComparison({
                      value: row.messagesCreated,
                      comparisonValue: prevMetrics.data?.organizationUserMetrics.find(
                        ({ user }) => user.id === row.user.id
                      )?.messagesCreated,
                    }),
                },
                {
                  header: 'Marked Received',
                  sortByField: 'ordersMarkedReceived',
                  getValue: (row) => row.ordersMarkedReceived,
                  getComparison: (row) =>
                    getComparison({
                      value: row.ordersMarkedReceived,
                      comparisonValue: prevMetrics.data?.organizationUserMetrics.find(
                        ({ user }) => user.id === row.user.id
                      )?.ordersMarkedReceived,
                    }),
                },
              ]}
              aggregateRow={{
                label: organization.name,
                columns: [
                  getAggregateCell({
                    value: metrics.data?.aggregatedOrganizationUserMetrics.requestsCreated?.sum,
                    comparisonValue:
                      prevMetrics.data?.aggregatedOrganizationUserMetrics.requestsCreated?.sum,
                  }),
                  getAggregateCell({
                    value:
                      metrics.data?.aggregatedOrganizationUserMetrics.responseDurationMedian?.mean,
                    comparisonValue:
                      prevMetrics.data?.aggregatedOrganizationUserMetrics.responseDurationMedian
                        ?.mean,
                    formatValue: Time.secondsToString,
                    downIsGood: true,
                  }),
                  getAggregateCell({
                    value: metrics.data?.aggregatedOrganizationUserMetrics.quotesApproved?.sum,
                    comparisonValue:
                      prevMetrics.data?.aggregatedOrganizationUserMetrics.quotesApproved?.sum,
                  }),
                  getAggregateCell({
                    value: metrics.data?.aggregatedOrganizationUserMetrics.messagesCreated?.sum,
                    comparisonValue:
                      prevMetrics.data?.aggregatedOrganizationUserMetrics.messagesCreated?.sum,
                  }),
                  getAggregateCell({
                    value:
                      metrics.data?.aggregatedOrganizationUserMetrics.ordersMarkedReceived?.sum,
                    comparisonValue:
                      prevMetrics.data?.aggregatedOrganizationUserMetrics.ordersMarkedReceived?.sum,
                  }),
                ],
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default TeamReport
