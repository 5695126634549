import { useState, useEffect } from 'react'

const Circle = ({ progress }) => (
  <svg className="inset-0 w-full h-full animate-spin" viewBox="0 0 100 100">
    <circle
      className="text-gray-200"
      cx="50"
      cy="50"
      r="45"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
    />
    <circle
      className="text-blue-500"
      cx="50"
      cy="50"
      r="45"
      fill="none"
      stroke="currentColor"
      strokeWidth="8"
      strokeLinecap="round"
      style={{
        strokeDasharray: '283',
        strokeDashoffset: 283 - (progress / 100) * 283,
        transform: 'rotate(-90deg)',
        transformOrigin: 'center',
      }}
    />
  </svg>
)

const AnimatedFeedback = ({ done, show }) => {
  const [progress, setProgress] = useState(0)
  const [showText, setShowText] = useState(false)
  const [fadeOut, setFadeOut] = useState(false)
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval)
          setTimeout(() => setShowText(true), 65)
          return 100
        }
        return prev + 2
      })
    }, 12)

    return () => clearInterval(progressInterval)
  }, [])

  useEffect(() => {
    let fadeTimer
    let hideTimer

    if (showText) {
      fadeTimer = setTimeout(() => {
        setFadeOut(true)
        hideTimer = setTimeout(() => setHidden(true), 500)
      }, 2900)
    }

    return () => {
      if (fadeTimer) clearTimeout(fadeTimer)
      if (hideTimer) {
        clearTimeout(hideTimer)
        done()
      }
    }
  }, [showText])

  if (!show || hidden) return null

  return (
    <div className="flex flex-col items-center justify-center">
      {!showText ? (
        <div className="relative w-5 h-5 transition-opacity duration-500">
          <Circle progress={progress} />
        </div>
      ) : (
        <div
          className={`
          transition-opacity duration-500 ease-out
          ${!fadeOut ? 'animate-fade-slide-in' : 'opacity-0'}
        `}
        >
          <p className="text-sm text-gray-500">thank you!</p>
        </div>
      )}
    </div>
  )
}

export default AnimatedFeedback
