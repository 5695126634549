import Checkbox from '@/gf/components/next/forms/Checkbox'
import RadioInput from '@/gf/components/next/forms/RadioInput'
import { ExclamationIcon } from '@heroicons/react/solid'
import { Priority } from '../types'
import { DateTime } from 'luxon'

type Value = { priority?: Priority; machineDown: boolean; neededByDate?: DateTime }

const PrioritySelector = ({
  value,
  onChange,
  disabled,
}: {
  value: Value
  onChange: (value: Value) => void
  disabled: boolean
}) => (
  <>
    <label className="py-2 flex items-center gap-x-2" htmlFor="high-priority">
      <RadioInput
        name="urgency"
        checked={value.priority === Priority.HIGH}
        onChange={(e) =>
          e.target.checked &&
          onChange({
            priority: Priority.HIGH,
            machineDown: false,
            neededByDate: DateTime.now().plus({ day: 1 }),
          })
        }
        disabled={disabled}
        id="high-priority"
      />
      High Priority - needed within 24 hrs
    </label>

    {value.priority === Priority.HIGH && (
      <label className="py-2 flex items-center gap-x-2">
        <Checkbox
          checked={value.machineDown}
          onChange={(e) => onChange({ ...value, machineDown: e.target.checked })}
        />
        <ExclamationIcon className="text-red-600 h-4 inline-block" /> Machine Down
      </label>
    )}

    <label className="py-2 flex items-center gap-x-2" htmlFor="medium-priority">
      <RadioInput
        name="urgency"
        checked={value.priority === Priority.MEDIUM}
        onChange={(e) =>
          e.target.checked &&
          onChange({
            priority: Priority.MEDIUM,
            machineDown: false,
            neededByDate: DateTime.now().plus({ days: 3 }),
          })
        }
        disabled={disabled}
        id="medium-priority"
      />
      Medium Priority - needed within 3 days
    </label>

    <label className="py-2 flex items-center gap-x-2" htmlFor="low-priority">
      <RadioInput
        name="urgency"
        checked={value.priority === Priority.LOW}
        onChange={(e) =>
          e.target.checked &&
          onChange({
            priority: Priority.LOW,
            machineDown: false,
            neededByDate: DateTime.now().plus({ weeks: 1 }),
          })
        }
        disabled={disabled}
        id="low-priority"
      />
      Low Priority - needed in 1 week or more
    </label>
  </>
)

export default PrioritySelector
