import { DeliveryMethod } from '@/dealers/_gen/gql'

import RadioInput from '@/gf/components/inputs/Radio'

const DeliveryMethodInput = ({
  value,
  onChange,
  willCallDescription = <>Customer can pick up the order at your location.</>,
  shipDescription = <>Ship the order to the customer at their location.</>,
}: {
  value: DeliveryMethod
  onChange: (newValue: DeliveryMethod) => void
  willCallDescription?: React.ReactNode
  shipDescription?: React.ReactNode
}) => (
  <div className="space-y-2">
    {[DeliveryMethod.Pickup, DeliveryMethod.Shipping].map((deliveryMethod) => (
      <label className="flex gap-x-2 items-center text-sm" key={deliveryMethod}>
        <RadioInput
          checked={value === deliveryMethod}
          value={deliveryMethod}
          onChange={() => onChange(deliveryMethod)}
        />

        <span className="font-medium">
          {deliveryMethod === DeliveryMethod.Pickup && <>Will Call</>}
          {deliveryMethod === DeliveryMethod.Shipping && <>Ship</>}
        </span>

        <span className="text-gray-500">
          {deliveryMethod === DeliveryMethod.Pickup && willCallDescription}
          {deliveryMethod === DeliveryMethod.Shipping && shipDescription}
        </span>
      </label>
    ))}
  </div>
)

export default DeliveryMethodInput
