import { isNaN } from 'lodash'
import { round } from 'mathjs'
import pluralize from 'pluralize'
import { ReactNode } from 'react'

import Time from '@/gf/modules/Time'

import { Column } from '@/gf/components/Reports/ReportingTable'
import Tooltip, { TooltipUnderlineText } from '@/gf/components/Tooltip'
import { OutlierBadgeValues, getReportingTableValue } from './ReportingTableValue'
import { Unit, defaultFormatValue } from './Scorecards'

const requestLifespanTitle = 'Request Fulfillment Time'
const requestLifespanTooltip =
  'The total time from a Request being created, to the Request being marked as fulfilled.'
const RequestLifespanTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={requestLifespanTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {requestLifespanTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const approveTimeTitle = 'Review Request'
const approveTimeTooltip =
  'The time from a Request being created, to the Request being sent to a Vendor.'
const ApproveTimeTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={approveTimeTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {approveTimeTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const timeToQuoteTitle = 'Receive Quote'
const timeToQuoteTooltip =
  'The time from a Vendor receiving the Request, to the Vendor sending a Quote with Price and Availability.'
const TimeToQuoteTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={timeToQuoteTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {timeToQuoteTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const reviewQuotesTimeTitle = 'Review Quote'
const reviewQuotesTimeTooltip =
  'The time from receiving the first Quote with Price and Availability, to sending a Purchase Order to a Vendor.'
const ReviewQuotesTimeTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={reviewQuotesTimeTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {reviewQuotesTimeTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const orderFulfillmentTimeTitle = 'Order Fulfillment'
const orderFulfillmentTimeTooltip =
  'The time from sending a Purchase Order to a Vendor, to the Order being marked as fulfilled.'
const OrderFulfillmentTimeTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={orderFulfillmentTimeTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {orderFulfillmentTimeTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const costSavingsTitle = 'Parts Savings'
const costSavingsTooltip =
  'The cost difference between the most expensive quote on your part request, and the quote that you took.'
const CostSavingsTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={costSavingsTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {costSavingsTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

const timeSavingsTitle = 'Lead Time Saved'
const timeSavingsTooltip =
  'The lead time difference between the longest lead time quote on your part request, and the quote that you took.'
const TimeSavingsTooltipTitle = ({ prefix }: { prefix?: string }) => (
  <Tooltip className="inline-flex shrink" text={timeSavingsTooltip} size="medium" useFlip>
    <TooltipUnderlineText>
      {prefix}
      {timeSavingsTitle}
    </TooltipUnderlineText>
  </Tooltip>
)

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getLifespanDurationMedianTimeColumn = <
  T extends { lifespanDuration: { median: number } | null },
  U extends { lifespanDuration: { median: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: {
    key: 'Request Fulfillment Time',
    children: 'Time',
  },
  sortByField: 'lifespanDuration.median',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.lifespanDuration?.median ?? null,
      Time.secondsToString,
      {
        aggregate,
        getValues: (a) => a?.lifespanDuration.median ?? null,
        downIsGood: true,
      }
    ),
})

const getLifespanDurationRequestsCountColumn = <
  T extends { lifespanDuration: { count: number } | null },
  U extends { lifespanDuration: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'lifespanDuration.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.lifespanDuration?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getRequestApprovalDurationMedianTimeColumn = <
  T extends { requestApprovalDuration: { median: number } | null },
  U extends { requestApprovalDuration: { median: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: {
    key: 'Request Approval Time',
    children: 'Time',
  },
  sortByField: 'requestApprovalDuration.median',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.requestApprovalDuration?.median ?? null,
      Time.secondsToString,
      {
        aggregate,
        getValues: (a) => a?.requestApprovalDuration.median ?? null,
        downIsGood: true,
      }
    ),
})

const getRequestApprovalDurationRequestsCountColumn = <
  T extends { requestApprovalDuration: { count: number } | null },
  U extends { requestApprovalDuration: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'requestApprovalDuration.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.requestApprovalDuration?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getResponseDurationMedianTimeColumn = <
  T extends { responseDuration: { median: number } | null },
  U extends { responseDuration: { median: OutlierBadgeValues | null } },
>({
  aggregate,
  header = 'Time',
}: {
  aggregate: U | undefined
  header?: ReactNode
}): Column<T> => ({
  header: {
    key: 'Request Response Time',
    children: header,
  },
  sortByField: 'responseDuration.median',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.responseDuration?.median ?? null,
      Time.secondsToString,
      {
        aggregate,
        getValues: (a) => a?.responseDuration.median ?? null,
        downIsGood: true,
      }
    ),
})

const getResponseDurationRequestsCountColumn = <
  T extends { responseDuration: { count: number } | null },
  U extends { responseDuration: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'responseDuration.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.responseDuration?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getQuoteApprovalDurationMedianTimeColumn = <
  T extends { quoteApprovalDuration: { median: number } | null },
  U extends { quoteApprovalDuration: { median: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: {
    key: 'Quote Approval Time',
    children: 'Time',
  },
  sortByField: 'quoteApprovalDuration.median',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.quoteApprovalDuration?.median ?? null,
      Time.secondsToString,
      {
        aggregate,
        getValues: (a) => a?.quoteApprovalDuration.median ?? null,
        downIsGood: true,
      }
    ),
})

const getQuoteApprovalDurationRequestsCountColumn = <
  T extends { quoteApprovalDuration: { count: number } | null },
  U extends { quoteApprovalDuration: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'quoteApprovalDuration.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.quoteApprovalDuration?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getOrderFulfillmentDurationMedianTimeColumn = <
  T extends { orderFulfillmentDuration: { median: number } | null },
  U extends { orderFulfillmentDuration: { median: OutlierBadgeValues | null } },
>({
  aggregate,
  header = 'Time',
}: {
  aggregate: U | undefined
  header?: ReactNode
}): Column<T> => ({
  header: {
    key: 'Order Fulfillment Time',
    children: header,
  },
  sortByField: 'orderFulfillmentDuration.median',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.orderFulfillmentDuration?.median ?? null,
      Time.secondsToString,
      {
        aggregate,
        getValues: (a) => a?.orderFulfillmentDuration.median ?? null,
        downIsGood: true,
      }
    ),
})

const getOrderFulfillmentDurationRequestsCountColumn = <
  T extends { orderFulfillmentDuration: { count: number } | null },
  U extends { orderFulfillmentDuration: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'orderFulfillmentDuration.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.orderFulfillmentDuration?.count ?? null,
      (value) => value,
      undefined
    ),
})

// TODO: there's a bit of overlap here with defining the sortBy and setSortBy
const getTimeSavingsColumn = <
  T extends { timeSavings: { sum: number } | null },
  U extends { timeSavings: { sum: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: {
    key: 'Time Savings',
    children: 'Savings',
  },
  sortByField: 'timeSavings.sum',
  getValue: (row) =>
    getReportingTableValue<T, U>(row, (r) => r.timeSavings?.sum ?? null, Time.secondsToString, {
      aggregate,
      getValues: (a) => a?.timeSavings.sum ?? null,
    }),
})

const getTimeSavingsRequestsCountColumn = <
  T extends { timeSavings: { count: number } | null },
  U extends { timeSavings: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'timeSavings.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.timeSavings?.count ?? null,
      (value) => value,
      undefined
    ),
})

const getAccuracyPercentColumn = <
  T extends { accurate: { percent: number } | null },
  U extends { accurate: { percent: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: 'Accuracy',
  sortByField: 'accurate.percent',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.accurate?.percent ?? null,
      (value) => defaultFormatValue(value, 1, Unit.Percent),
      {
        aggregate,
        getValues: (a) => a?.accurate.percent ?? null,
      }
    ),
})

const getAccuracyRequestsCountColumn = <
  T extends { accurate: { count: number } | null },
  U extends { accurate: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'accurate.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.accurate?.count ?? null,
      (value) => value,
      undefined
    ),
})

const getInternalFulfillmentPercentColumn = <
  T extends { internalFulfillment: { percent: number } | null },
  U extends { internalFulfillment: { percent: OutlierBadgeValues | null } },
>({
  aggregate,
}: {
  aggregate: U | undefined
}): Column<T> => ({
  header: 'Internal',
  sortByField: 'internalFulfillment.percent',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.internalFulfillment?.percent ?? null,
      (value) => defaultFormatValue(value, 1, Unit.Percent),
      {
        aggregate,
        getValues: (a) => a?.internalFulfillment.percent ?? null,
      }
    ),
})

const getInternalFulfillmentRequestsCountColumn = <
  T extends { internalFulfillment: { count: number } | null },
  U extends { internalFulfillment: { count: OutlierBadgeValues | null } },
>(): Column<T> => ({
  header: 'Requests',
  sortByField: 'internalFulfillment.count',
  getValue: (row) =>
    getReportingTableValue<T, U>(
      row,
      (r) => r.internalFulfillment?.count ?? null,
      (value) => value,
      undefined
    ),
})

const getImpactColumnValue =
  <T,>(getValue: (row: T) => number | undefined): Column<T>['getValue'] =>
  (row) => {
    const value = getValue(row)
    if (typeof value === 'undefined') return null
    if (isNaN(value))
      return (
        <Tooltip text="Need other entries to compare this entry to." size="small" useFlip>
          <TooltipUnderlineText>N/A</TooltipUnderlineText>
        </Tooltip>
      )
    const roundedValue = round(value)
    return roundedValue > 0 ? `+${roundedValue}` : roundedValue
  }

const getImpactColumn = <T,>({
  domain,
  domainPlural = pluralize(domain, 2),
  domainPossessive = `${domain}'s`,
  metricName = 'metrics here',
  getValue,
  sortByField,
}: {
  domain: string
  domainPlural?: string
  domainPossessive?: string
  metricName?: string
  getValue: (row: T) => number | undefined
  sortByField: string
}): Column<T> => ({
  header: {
    key: 'Impact Score',
    children: (
      <Tooltip
        text={[
          `A score from -5 to +5 representing the impact of this ${domain} relative to your other ${domainPlural}.`,
          `This score is a combination of the ${metricName}, scaled by the ${domainPossessive} number of Requests.`,
        ]}
        size="medium"
        useFlip
      >
        <TooltipUnderlineText>Impact Score</TooltipUnderlineText>
      </Tooltip>
    ),
  },
  getValue: getImpactColumnValue<T>(getValue),
  sortByField,
})

export default {
  getLifespanDurationMedianTimeColumn,
  getLifespanDurationRequestsCountColumn,
  getRequestApprovalDurationMedianTimeColumn,
  getRequestApprovalDurationRequestsCountColumn,
  getResponseDurationMedianTimeColumn,
  getResponseDurationRequestsCountColumn,
  getQuoteApprovalDurationMedianTimeColumn,
  getQuoteApprovalDurationRequestsCountColumn,
  getOrderFulfillmentDurationMedianTimeColumn,
  getOrderFulfillmentDurationRequestsCountColumn,
  getTimeSavingsColumn,
  getTimeSavingsRequestsCountColumn,
  getAccuracyPercentColumn,
  getAccuracyRequestsCountColumn,
  getInternalFulfillmentPercentColumn,
  getInternalFulfillmentRequestsCountColumn,
  getImpactColumnValue,
  getImpactColumn,
  requestLifespanTitle,
  requestLifespanTooltip,
  RequestLifespanTooltipTitle,
  approveTimeTitle,
  approveTimeTooltip,
  ApproveTimeTooltipTitle,
  timeToQuoteTitle,
  timeToQuoteTooltip,
  TimeToQuoteTooltipTitle,
  reviewQuotesTimeTitle,
  reviewQuotesTimeTooltip,
  ReviewQuotesTimeTooltipTitle,
  orderFulfillmentTimeTitle,
  orderFulfillmentTimeTooltip,
  OrderFulfillmentTimeTooltipTitle,
  costSavingsTitle,
  costSavingsTooltip,
  CostSavingsTooltipTitle,
  timeSavingsTitle,
  timeSavingsTooltip,
  TimeSavingsTooltipTitle,
}
