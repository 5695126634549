import { CheckIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import Engine from './svgs/Engine'

import { AccountMachine } from '../../types'

interface MachineProps {
  id: string
  children: React.ReactNode
}

const LinkedMachine = ({ id, children }: MachineProps) => (
  <Link
    to={`/machines/${id}`}
    className="block h-full hover:bg-slate-50 hover:shadow rounded-lg border hover:border-gearflow transform duration-200"
  >
    {children}
  </Link>
)

const UnlinkedMachine = ({ id: _id, children }: MachineProps) => (
  <div className="block h-full rounded-lg border">{children}</div>
)

const MachinesList = ({
  accountMachines,
  className,
  showOwned,
  linkToMachine,
}: {
  accountMachines: AccountMachine[]
  showOwned: boolean
  linkToMachine: boolean
  className?: string
}) => {
  const MachineC = linkToMachine ? LinkedMachine : UnlinkedMachine

  return (
    <ul className={classNames('flex flex-col gap-4', className)}>
      {accountMachines.map((accountMachine) => (
        <li key={accountMachine.name}>
          <MachineC id={accountMachine.id}>
            <span className="block px-3 py-1.5">
              <span className="flex justify-between">
                <span className="flex flex-col">
                  <span className="flex flex-col">
                    <span className="text-slate-500 font-semibold text-sm">Unit #</span>
                    <span className="font-medium">{accountMachine.name}</span>
                  </span>
                  <span className="text-sm text-gray-500 mt-2">
                    Serial {accountMachine.serialNumber}
                  </span>
                </span>

                <span className="flex flex-col">
                  <span className="text-slate-500 font-semibold text-sm">Model</span>
                  <span className="font-medium">
                    {accountMachine.make} {accountMachine.model} / {accountMachine.year}
                  </span>
                </span>
              </span>
            </span>

            {showOwned && (
              <span className="border-t flex justify-between items-center px-3 py-1.5 text-sm">
                <span className="text-slate-700">
                  <Engine className="text-gearflow h-5 inline-block mr-1 -mt-1" />
                  {!accountMachine.engineMake &&
                  !accountMachine.engineModel &&
                  !accountMachine.engineSerialNumber ? (
                    'Engine details not available'
                  ) : (
                    <>
                      {accountMachine.engineMake} {accountMachine.engineModel}{' '}
                      {accountMachine.engineSerialNumber &&
                        `- Serial ${accountMachine.engineSerialNumber}`}
                    </>
                  )}
                </span>

                {showOwned && accountMachine.owned && (
                  <span className="text-gray-500 flex-shrink-0">
                    <CheckIcon className="w-5 h-5 text-green-500 inline-block" /> Owned
                  </span>
                )}
              </span>
            )}
          </MachineC>
        </li>
      ))}
    </ul>
  )
}

export default MachinesList
