import { RfqPriority, Urgency } from '../_gen/gql'
import RequestForQuote from '@/gf/modules/RequestForQuote'
import { Priority } from '../pages/CreateRequest/types'

const urgencyToDisplay = (urgency: Urgency) =>
  `${urgency.charAt(0)}${urgency.slice(1).toLowerCase()}`

const apiPriorityToCreateRfqPriority = (priority: RfqPriority) =>
  priority === RfqPriority.High
    ? Priority.HIGH
    : priority === RfqPriority.Medium
      ? Priority.MEDIUM
      : Priority.LOW

export default {
  ...RequestForQuote,
  urgencyToDisplay,
  apiPriorityToCreateRfqPriority,
}
