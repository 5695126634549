import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'
import { cn } from '@/gf/modules/utils'
import { File } from 'lucide-react'
import { useState } from 'react'

const Upload = ({
  onStarted,
  onComplete,
}: {
  onStarted: () => void
  onComplete: (url: string) => void
}) => {
  const [focus, setFocus] = useState(false)

  return (
    <div className="space-y-4">
      <div className="text-xl">Upload Order</div>

      <div>
        <UploadButton
          allowedFileTypes={['.jpg', '.jpeg', '.png', '.pdf']}
          onUploadStarted={onStarted}
          onUploadComplete={(files) => onComplete(files[0].url)}
        >
          <div
            className={cn(
              'p-4 flex gap-4 items-center rounded-lg border-2 border-gray-200 border-dashed bg-gray-50',
              focus && 'border-gearflow border-solid'
            )}
            onDragLeave={() => setFocus(false)}
            onDragOver={() => setFocus(true)}
            onDrop={() => setFocus(false)}
          >
            <Action.S className="flex gap-2 items-center whitespace-nowrap">
              <File className="w-5 h-5" /> Select File
            </Action.S>

            <span className="text-gray-500 font-medium">or drag & drop file here</span>
          </div>
        </UploadButton>
      </div>
    </div>
  )
}

export default Upload
