import A from '@/gf/components/A'
import Modal from '@/gf/components/Modal'
import Tabs from '@/gf/components/Tabs'
import Phone from '@/gf/modules/Phone'
import { PhoneIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { ModalSize } from '../../../../types'
import { useVendorStoreInfoQuery } from '../../../_gen/gql'
import useGqlClient from '../../../hooks/useGqlClient'

const tabs = [{ name: 'About' }, { name: 'Returns & Warranty' }, { name: 'Shipping' }]

const StoreInfoModal = ({
  open,
  onClose,
  vendorId,
}: {
  vendorId: string
  open: boolean
  onClose: () => void
}) => {
  const { data } = useVendorStoreInfoQuery({ client: useGqlClient(), variables: { id: vendorId } })
  const vendor = data?.vendor
  const dealerLocation = vendor?.dealerLocation
  const store = data?.vendor?.store

  const [tab, setTab] = useState(tabs[0].name)
  return (
    <Modal open={open} onClose={onClose} title="" cancelText="Close" size={ModalSize.LG}>
      <div className="flex items-center gap-x-4">
        {dealerLocation?.logoUrl && (
          <img
            src={dealerLocation.logoUrl}
            alt="store logo"
            className="w-36 h-16 object-contain flex-shrink-0"
          />
        )}
        <div>
          <h3 className="text-lg text-slate-700">{store?.name}</h3>
          {vendor?.store?.phoneNumber && (
            <A.T
              href={`tel:${vendor?.store?.phoneNumber}`}
              className="text-sm inline-flex items-center gap-x-1"
            >
              <PhoneIcon className="text-gearflow h-4 inline-block" />{' '}
              {Phone.format(vendor?.store?.phoneNumber)}
            </A.T>
          )}
        </div>
      </div>

      <div className="space-y-4">
        <Tabs tabs={tabs} currentTabName={tab} onTabSelect={setTab} />

        {tab === 'About' &&
          (!!store?.description || vendor?.brands || dealerLocation?.offerings ? (
            <div className="flex flex-col">
              {(vendor?.brands ?? []).length > 0 && (
                <>
                  <h4 className="font-medium">Brands</h4>
                  <p className="text-sm prose capitalize">
                    {vendor?.brands.map((b) => b.name).join(', ')}
                  </p>
                </>
              )}

              {!!dealerLocation?.offerings && (
                <>
                  <h4 className="font-medium mt-2">Offerings</h4>
                  <p className="text-sm prose capitalize">{dealerLocation?.offerings}</p>
                </>
              )}
              <p
                className="prose mt-2"
                dangerouslySetInnerHTML={{ __html: store?.description || '' }}
              />
            </div>
          ) : (
            <p className="prose italic text-gray-400">No information provided.</p>
          ))}
        {tab === 'Returns & Warranty' &&
          ((store?.returnPolicy || '').length > 0 ? (
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: store?.returnPolicy || '' }}
            />
          ) : (
            <p className="prose italic text-gray-400">No information provided.</p>
          ))}
        {tab === 'Shipping' &&
          ((store?.shippingInfo || '').length > 0 ? (
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: store?.shippingInfo || '' }}
            />
          ) : (
            <p className="prose italic text-gray-400">No information provided.</p>
          ))}
      </div>
    </Modal>
  )
}
export default StoreInfoModal
