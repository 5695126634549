import { PhoneIcon } from '@heroicons/react/outline'
import classNames from 'classnames'

import { RfqCreateQuoteQuery } from '@/buyers/_gen/gql'
import Phone from '@/gf/modules/Phone'

import A from '@/gf/components/A'
import Card from '@/gf/components/Card'

type Vendor = RfqCreateQuoteQuery['rfqs'][number]['vendorLinks'][number]['vendor']

const VendorCard = ({ vendor, vertical }: { vendor: Vendor; vertical: boolean }) => (
  <Card>
    <Card.Section>
      <div
        className={classNames(
          'flex',
          vertical ? 'flex-col items-left gap-y-1' : 'flex-row items-center gap-x-4'
        )}
      >
        {vendor.dealerLocation?.logoUrl && (
          <img
            src={vendor.dealerLocation.logoUrl}
            alt="store logo"
            className="w-36 h-16 object-contain flex-shrink-0"
          />
        )}

        <h3 className="text-lg text-slate-700">{vendor.store?.name}</h3>
      </div>
    </Card.Section>

    <Card.Section>
      <div className="flex flex-col items-start gap-y-1">
        {vendor.contacts.map((contact) => (
          <div className="flex flex-row flex-wrap justify-start gap-x-2" key={contact.id}>
            <div key={contact.id} className="text-sm text-gray-700">
              {contact.name}
            </div>

            {contact.phoneNumber ? (
              <A.T
                href={`tel:${contact.phoneNumber}`}
                className="text-sm inline-flex justify-start items-center gap-x-1"
              >
                <PhoneIcon className="text-gearflow h-4 inline-block" />{' '}
                {Phone.format(contact.phoneNumber)}
              </A.T>
            ) : (
              <span className="text-sm text-gray-500 italic">(No phone number)</span>
            )}
          </div>
        ))}
      </div>
    </Card.Section>
  </Card>
)

export default VendorCard
