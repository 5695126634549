// Formats large amounts of money that have commas separating the numbers, but NO DECIMALS
import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { isNaN } from 'mathjs'

import TextInput from './TextInput'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value?: number
  setValue?: (newValue: number | undefined) => void
}

const formatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 })

const NoDecimalMoneyInput = forwardRef<HTMLInputElement, Props>(
  ({ value, setValue, className, ...props }, ref) => {
    const formattedValue = typeof value === 'number' ? formatter.format(value) : undefined
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (setValue) {
        const newValue = parseFloat(event.target.value.replaceAll(',', ''))
        if (isNaN(newValue)) setValue(undefined)
        else setValue(newValue)
      }
    }
    return (
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-900 text-sm">$</span>
        </div>
        <TextInput
          {...props}
          className={twMerge('pl-7', className)}
          ref={ref}
          value={formattedValue}
          onChange={onChange}
        />
      </div>
    )
  }
)

export default NoDecimalMoneyInput
