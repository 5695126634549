import useForm from './Business/useForm'

import Link from '@/gf/components/Link'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import AddressInput from './AddressInput'
import LogoInput from './Business/LogoInput'

type Form = ReturnType<typeof useForm>

const Business = ({
  form: { fields, errors, headquartersEditable, updateFields },
}: {
  form: Form
}) => (
  <div className="space-y-6 max-w-md w-full mx-auto">
    <h2 className="text-2xl font-medium">
      Begin by adding your business name, logo, and first shop location
    </h2>

    <Field label="Business Name" error={errors.name}>
      <TextInput value={fields.name} setValue={(name) => updateFields({ name })} />
    </Field>

    <Field label="Business Logo (optional)" error={errors.logoUrl}>
      <span className="py-2 text-sm text-gray-500">
        Adding your logo helps your vendors quickly identify you.
      </span>
      <LogoInput url={fields.logoUrl} setUrl={(logoUrl) => updateFields({ logoUrl })} />
    </Field>

    <Field
      label="Shop Address"
      error={errors.headquartersAddress}
      desc={
        !headquartersEditable && (
          <>
            Edit in{' '}
            <Link.T to="/locations" target="_blank">
              Locations
            </Link.T>
          </>
        )
      }
    >
      <AddressInput
        value={fields.headquartersAddress}
        onChange={(headquartersAddress) => updateFields({ headquartersAddress })}
        disabled={!headquartersEditable}
      />
    </Field>
  </div>
)

export default Business
