import { Route, Routes } from 'react-router-dom'
import { SsoLinkType } from '../_gen/gql'
import NotFound from './NotFound'
import Base from './OAuth/Base'

const OAuth = () => (
  <Routes>
    <Route path="microsoft" element={<Base linkType={SsoLinkType.Microsoft} />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default OAuth
