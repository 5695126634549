import Messages from '@/gf/components/RocketLayout/Messages'
import ClaimAccountBanner from './LimitedFrame/ClaimAccountBanner'
import Content from './LimitedFrame/Content'

const LimitedFrame = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col min-h-screen border-t-[0.5rem] border-orange-500 text-gray-700 bg-gray-100">
    <ClaimAccountBanner />
    <Content className="flex flex-col grow">{children}</Content>
    <Messages />
  </div>
)

export default LimitedFrame
