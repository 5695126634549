import { useState } from 'react'
import findIndex from 'lodash/findIndex'
import { DateTime } from 'luxon'

import type { Money, Address } from '@/types'

export type Part = {
  id: string
  mpn: string
  rfqPartMpn: string
  rfqPartId: string | null
  name: string
  description: string
  quantity: number | null
  unitPrice: Money | null
  inStock: boolean
  availableAt: DateTime | null
}

type Form = {
  parts: Part[]
  quoteNumber: string
  shippingCost: Money | null
  taxCost: Money | null
  pickupAddress: Address
}

type Errors = { parts: Record<string, string | null>[] }

const useForm = (initForm: Form) => {
  const [form, setForm] = useState(initForm)
  const [errors, setErrors] = useState<Errors>({ parts: [] })

  const updateErrors = (gqlError: { details: { parts?: Record<string, string[]>[] } }) => {
    const parts =
      gqlError.details.parts?.map((p) => ({
        mpn: p.mpn ? p.mpn.join(' / ') : null,
        name: p.name ? p.name.join(' / ') : null,
        quantity: p.quantity ? p.quantity.join(' / ') : null,
        unitPrice: p.unit_price ? p.unit_price.join(' / ') : null,
        availableAt: p.available_at ? p.available_at.join(' / ') : null,
      })) || []

    setErrors({ parts })
  }

  const updatePart = (id: string, updates: Partial<Part>) => {
    const index = findIndex(form.parts, (p) => p.id === id)
    const parts = Object.assign([], form.parts, { [index]: { ...form.parts[index], ...updates } })
    setForm({ ...form, parts })
  }

  const updateForm = (updates: Partial<Form>) => setForm({ ...form, ...updates })

  const addPart = (part: Part) => setForm({ ...form, parts: [...form.parts, part] })

  const removePart = (id: string) =>
    setForm({ ...form, parts: form.parts.filter((p) => p.id !== id) })

  return { form, errors, updateForm, updateErrors, addPart, removePart, updatePart }
}

export default useForm
