import { RequestForQuoteStep, RequestQuery, StoreOrderStep } from '@/buyers/_gen/gql'
import Link from '@/gf/components/Link'
import SentToNetworkBadge from '@/gf/components/SentToNetworkBadge'
import partition from 'lodash/partition'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useContext } from '../../RFQ'
import Order from './Order'
import Section from './Section'

type RequestForQuote = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

const Orders = () => {
  const navigate = useNavigate()
  const { rfq } = useContext()
  const [storeOrderId, _] = useQueryParam('storeOrderId', StringParam)

  useEffect(() => {
    if (storeOrderId) navigate(`/orders/${storeOrderId}`, { replace: true })
  }, [])

  const vendorsWithoutQuote = rfq.requestForQuoteVendors.filter(
    (rfqVendor) => !rfq.storeOrders.find((so) => so.store.id === rfqVendor.vendor.store?.id)
  )

  const isExternalOrder = rfq.storeOrders.some((so) => so.externalOrder)

  const onStoreOrderClicked = (storeOrder: RequestForQuote['storeOrders'][number]) =>
    navigate(`/orders/${storeOrder.id}`)

  const canAddVendor =
    [
      RequestForQuoteStep.Submitted,
      RequestForQuoteStep.Quoted,
      RequestForQuoteStep.PoSent,
      RequestForQuoteStep.Fulfilling,
    ].includes(rfq.step) &&
    !rfq.closedAt &&
    !isExternalOrder &&
    !rfq.createdByTutorial

  const [orders, quotes] = partition(
    rfq.storeOrders,
    (so) => ![StoreOrderStep.Quoted, StoreOrderStep.Canceled].includes(so.step)
  )

  return (
    <>
      {orders.length > 0 && (
        <Section title="Orders">
          <div className="mt-4">
            <div className="flex flex-col gap-y-8">
              {orders.map((storeOrder) => (
                <Order
                  storeOrder={storeOrder}
                  onClick={() => onStoreOrderClicked(storeOrder)}
                  key={storeOrder.id}
                />
              ))}
            </div>
          </div>
        </Section>
      )}

      <Section
        title={
          <div className="flex flex-row items-center gap-x-4">
            Quotes
            {rfq.broadcast && !rfq.needsApproval && vendorsWithoutQuote.length === 0 && (
              <SentToNetworkBadge />
            )}
          </div>
        }
        actions={canAddVendor && <Link.T to={`/rfqs/${rfq.id}/add-vendors`}>Add Vendors</Link.T>}
      >
        {quotes.length > 0 ? (
          <div className="mt-4">
            <div className="flex flex-col gap-y-8">
              {quotes.map((storeOrder) => (
                <Order
                  storeOrder={storeOrder}
                  onClick={() => onStoreOrderClicked(storeOrder)}
                  key={storeOrder.id}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="text-gray-500 italic">
            {!rfq.closedAt && !rfq.canceledAt ? 'Coming soon' : 'None'}
          </div>
        )}
      </Section>
    </>
  )
}

export default Orders
