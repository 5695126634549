import { ReactNode } from 'react'
import OutlierBadge from './OutlierBadge'

type GetValue<U extends object> = (row: U) => number | null
type GetValueDisplay = (value: number) => ReactNode
export type OutlierBadgeValues = { mean: number; std: number }
type OutlierBadgeT<V extends object> = {
  aggregate: V | undefined
  getValues: (aggregate: V | undefined) => OutlierBadgeValues | null
  downIsGood?: boolean
}

const ReportingTableValue = <U extends object, V extends object>({
  row,
  getValue,
  getValueDisplay,
  outlierBadge,
}: {
  row: U
  getValue: GetValue<U>
  getValueDisplay: GetValueDisplay
  outlierBadge?: OutlierBadgeT<V>
}) => {
  const value = getValue(row)
  const outlierValues = outlierBadge && outlierBadge.getValues(outlierBadge.aggregate)
  return value === null ? (
    <>None</>
  ) : (
    <div className="flex items-center gap-x-1">
      {outlierBadge && outlierValues && (
        <OutlierBadge
          className="z-30"
          value={value}
          meanValue={outlierValues.mean}
          stdValue={outlierValues.std}
          downIsGood={outlierBadge.downIsGood}
          getValueDisplay={getValueDisplay}
        />
      )}
      {getValueDisplay(value)}
    </div>
  )
}

export const getReportingTableValue = <U extends object, V extends object>(
  row: U,
  getValue: GetValue<U>,
  getValueDisplay: GetValueDisplay,
  outlierBadge?: OutlierBadgeT<V>
) => (
  <ReportingTableValue
    row={row}
    getValue={getValue}
    getValueDisplay={getValueDisplay}
    outlierBadge={outlierBadge}
  />
)
