import { Org } from './context'

const UserDisplay = ({ user }: { user: Org['users'][number] }) => {
  return user.displayName !== user.email ? (
    <>
      {user.displayName} ({user.email})
    </>
  ) : (
    user.displayName
  )
}

export default UserDisplay
