import { useSetOrganizationLogoMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import UploadButton from '@/gf/components/UploadButton'
import useMsgs from '@/gf/hooks/useMsgs'
import { Maybe } from '@/types'
import { UploadIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'

const UpdateOrgLogoModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { organization } = useSession()
  const reloadSession = useReloadSession()
  const [_msgs, msgsMgr] = useMsgs()
  const [logoUrl, setLogoUrl] = useState<Maybe<string>>(null)
  const client = useGqlClient()
  const [setOrganizationLogo] = useSetOrganizationLogoMutation({ client })

  useEffect(() => {
    if (open) {
      setLogoUrl(null)
    }
  }, [open])

  const onSubmit = async () => {
    try {
      if (!logoUrl) {
        return
      }

      await setOrganizationLogo({ variables: { id: organization.id, logoUrl } })

      reloadSession()

      onClose()
    } catch (err) {
      msgsMgr.add((err as Error)?.message ?? 'Something went wrong')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()} className="px-6 py-4 relative space-y-4">
        <h2 className="text-lg leading-7 pb-6">Change Company Logo</h2>
        <CloseModalButton onClick={onClose} className="absolute -top-2 right-3" />

        {logoUrl ? (
          <img src={logoUrl} alt="" className="w-full h-48 object-contain" />
        ) : (
          <UploadButton
            allowedFileTypes={['image/*', '*.png', '*.jpg', '*.jpeg']}
            onUploadComplete={(file) => Promise.resolve(setLogoUrl(file[0].url))}
            onError={(e) => console.error(e)}
          >
            <button
              type="button"
              className="border border-dashed border-gray-200 bg-gray-50 rounded-lg p-5 w-full"
            >
              <UploadIcon className="h-4 inline-block" /> Click to select a file
            </button>
          </UploadButton>
        )}

        <div className="flex justify-end">
          <Action.P onClick={onSubmit} disabled={!logoUrl}>
            Save
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default UpdateOrgLogoModal
