import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useOrgMachineDetailsQuery } from '@/buyers/_gen/gql'

interface Params {
  id: string
}

const useOrgMachineDetails = (params: Params) =>
  useOrgMachineDetailsQuery({
    variables: { id: params.id },
    fetchPolicy: 'cache-and-network',
    client: useGqlClient(),
  })

export type OrgMachineDetails = NonNullable<ReturnType<typeof useOrgMachineDetails>>

export default useOrgMachineDetails
