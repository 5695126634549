import { useState } from 'react'
import { RefreshIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import Time from '@/gf/modules/Time'

const RefetchButton = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const [refetching, setRefetching] = useState(false)
  const [lastUpdate, setLastUpdate] = useState(DateTime.now())
  return (
    <div className="flex row items-center gap-x-2 invisible xl:visible">
      <span className="text-sm text-gray-900">
        Last update: {Time.formatRelativeDay(lastUpdate)},{' '}
        {lastUpdate.toLocaleString(DateTime.TIME_SIMPLE)}
      </span>
      <button
        type="button"
        onClick={() => {
          setRefetching(true)
          refetch().finally(() => {
            setRefetching(false)
            setLastUpdate(DateTime.now())
          })
        }}
      >
        <RefreshIcon
          className={classNames(
            'flex shrink-0 w-6 h-6 text-blue-500',
            refetching && 'animate-spin reverse'
          )}
          style={{ animationDirection: 'reverse' }}
        />
      </button>
    </div>
  )
}

export default RefetchButton
