import { useState } from 'react'
import { CreateRequestState } from '../CreateRequest/types'
import { Address, Maybe, Point } from '@/types'
import { US_CENTER } from '@/gf/modules/Map'
import useToggle from '@/gf/hooks/useToggle'

type AddVendorsForm = {
  locationId: Maybe<string>
  shippingAddress: Maybe<Address>
  nearbyReferencePoint: Point
  broadcast: boolean
  dealerLocationIds: string[]
  vendors: CreateRequestState['vendors']
}

const useAddVendorsForm = () => {
  const [value, setValue] = useState<AddVendorsForm>({
    dealerLocationIds: [],
    vendors: [],
    broadcast: false,
    locationId: null,
    shippingAddress: null,
    nearbyReferencePoint: US_CENTER, // TODO: change this
  })
  const [loading, loadingToggle] = useToggle(false)

  const onChange = (changes: Partial<AddVendorsForm>) => {
    setValue((prev) => ({ ...prev, ...changes }))
  }

  return { value, onChange, loading, loadingOn: loadingToggle.on, loadingOff: loadingToggle.off }
}

export default useAddVendorsForm
