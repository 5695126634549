import { CheckIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

interface Step {
  id: string
  name: string
}

// Declaring all of the specific styles (ex: text-gearflow) rather than just the colors (ex: gearflow), because I was
// having trouble with Tailwind loading the styles using the latter.
type Theme = {
  textPrimary: string
  bgPrimary: string
  textDark: string
  textMedDark: string
  hoverBgDark: string
  groupHoverBgDark: string
  bgLight: string
  borderPrimary: string
  borderTopPrimary: string
  borderLight: string
  ringPrimary: string
  focusRingPrimary: string
}

const ProgressNav = ({
  steps,
  stepIndex,
  setStepIndex,
  navLinksDisabled,
  theme,
}: {
  steps: Step[]
  stepIndex: number
  setStepIndex: (index: number) => void
  navLinksDisabled: boolean
  theme: Theme
}) => (
  <nav aria-label="Progress">
    <ol className="divide-y divide-gray-300 rounded-md border bg-white shadow-sm md:flex md:divide-y-0">
      {steps.map((step, stepIdx) => (
        <li key={step.name} className="relative md:flex md:flex-1">
          {stepIdx < stepIndex ? (
            // Completed
            <button
              className={classNames('group flex w-full items-center', {
                'cursor-default': navLinksDisabled,
              })}
              type="button"
              onClick={navLinksDisabled ? undefined : () => setStepIndex(stepIdx)}
            >
              <span className="flex items-center px-4 lg:px-6 pr-6 py-4 text-sm font-medium">
                <span
                  className={classNames(
                    `flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${theme.bgPrimary}`,
                    { [theme.groupHoverBgDark]: !navLinksDisabled }
                  )}
                >
                  <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <span className="ml-2 lg:ml-4 text-sm font-medium text-gray-900">{step.name}</span>
              </span>
            </button>
          ) : stepIdx === stepIndex ? (
            // Current
            <div
              className="flex items-center px-4 lg:px-6 pr-6 py-4 text-sm font-medium"
              aria-current="step"
            >
              <span
                className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${theme.borderPrimary}`}
              >
                <span className={theme.textPrimary}>{step.id}</span>
              </span>
              <span className={`ml-2 lg:ml-4 text-sm font-medium ${theme.textPrimary}`}>
                {step.name}
              </span>
            </div>
          ) : (
            // Upcoming
            <div className="group flex items-center">
              <span className="flex items-center px-4 lg:px-6 pr-6 py-4 text-sm font-medium">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                  <span className="text-gray-500">{step.id}</span>
                </span>
                <span className="ml-2 lg:ml-4 text-sm font-medium text-gray-500">{step.name}</span>
              </span>
            </div>
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </li>
      ))}
    </ol>
  </nav>
)

export default ProgressNav
