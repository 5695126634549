import { useEffect } from 'react'

import useConfig from '@/gf/hooks/useConfig'

const Orgs = () => {
  const { adminUrl } = useConfig()

  useEffect(() => {
    window.location.href = `${adminUrl}/customers?tab=1`
  }, [])

  return null
}

export default Orgs
