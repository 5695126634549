import { useNewRfqQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/gf/components/ui/dialog'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import Reminder from './NewRfq/Reminder'
import Notifications from './Notifications'

const NewRfq = ({ rfqId }: { rfqId: string }) => {
  const { organization } = useSession()
  const [open, setOpen] = useQueryParam('new', withDefault(BooleanParam, false))

  const { data } = useNewRfqQuery({
    variables: { orgId: organization.id, filter: JSON.stringify(['id_eq', rfqId]) },
    client: useGqlClient(),
  })

  const close = () => setOpen(undefined, 'replaceIn')
  const rfq = data?.rfqs[0]

  return (
    <Dialog open={open} onOpenChange={close}>
      <DialogContent className="sm:max-w-2xl block divide-y p-0">
        <DialogHeader className="p-6">
          <div className="flex items-center gap-3">
            <div className="bg-green-100 rounded-full p-1">
              <CheckCircleIcon className="w-10 h-10 text-green-500" />
            </div>

            <DialogTitle>Request Created</DialogTitle>
          </div>

          <DialogDescription className="text-base">
            {rfq &&
              (rfq.needsApproval ? (
                <>Your team will be notified to approve this request.</>
              ) : (
                <>You will be notified as soon as you have quotes ready for review.</>
              ))}
          </DialogDescription>
        </DialogHeader>

        {rfq && !rfq.needsApproval && <Reminder rfq={rfq} />}

        <div className="p-6">
          <Notifications title="Notifications" />
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default NewRfq
