import { StoreOrderStep } from '@/buyers/_gen/gql'
import classNames from 'classnames'

const badgeByStep = {
  [StoreOrderStep.Quoted]: {
    title: <>Quoted</>,
    className: 'bg-blue-100 border-blue-200 text-blue-700',
  },
  [StoreOrderStep.PoSent]: {
    title: <>PO Sent</>,
    className: 'bg-green-100 border-green-200 text-green-700',
  },
  [StoreOrderStep.Fulfilling]: {
    title: <>Fulfilling</>,
    className: 'bg-yellow-100 border-yellow-200 text-yellow-600',
  },
  [StoreOrderStep.Fulfilled]: {
    title: <>Fulfilled</>,
    className: 'bg-orange-100 border-orange-200 text-orange-500',
  },
  [StoreOrderStep.Canceled]: {
    title: <>Canceled</>,
    className: 'bg-red-100 border-red-200 text-red-700',
  },
}

const StepBadge = ({ step }: { step: StoreOrderStep }) => {
  const badge = badgeByStep[step]
  const className = classNames('rounded border font-medium text-sm px-2 py-1', badge.className)
  return <div className={className}>{badge.title}</div>
}

export default StepBadge
