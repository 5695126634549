import Action from '@/gf/components/Action'
import AddressC from '@/gf/components/Address'
import AddressInputC from '@/gf/components/AddressInput'
import BuyerAutocomplete from '@/gf/components/BuyerAutocomplete'
import Checkbox from '@/gf/components/Checkbox'
import Field from '@/gf/components/Field'
import Modal from '@/gf/components/Modal'
import PhoneInput from '@/gf/components/PhoneInput'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import AddressM from '@/gf/modules/Address'
import label from '@/gf/modules/Branch'
import GQL from '@/gf/modules/GraphQL'
import { SEARCH_BUYERS } from '@/gf/queries/users'
import SupplierAutocomplete from '@/suppliers/components/SupplierAutocomplete'
import { InternalRefetchQueriesInclude } from '@apollo/client'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import omit from 'lodash/omit'
import { useEffect, useState } from 'react'
import { Address, ModalSize, Point, SupplierUser } from '../../types'
import {
  AllBranchesDocument,
  Branch,
  CreateShippingLocationMutationVariables,
  useCreateShippingLocationMutation,
  useGetAddressLocationLazyQuery,
  useGetLocationAddressLazyQuery,
} from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import OneLineAddress from './OneLineAddress'
import Map from './ShippingLocationModalV2/Map'

const LocationModal = ({
  open,
  onClose,
  onComplete,
  initialAddress: _initialAddress,
  showPersist,
  branches,
  buyers,
  refetchQueries = [],
  noSuccessNotification = false,
}: {
  open: boolean
  showPersist: boolean
  onClose: () => void
  onComplete: (
    shippingLocationId: string,
    variables: CreateShippingLocationMutationVariables
  ) => void
  initialAddress: Address
  branches: Pick<Branch, 'id' | 'name' | 'code'>[]
  buyers: SupplierUser[]
  refetchQueries?: InternalRefetchQueriesInclude
  noSuccessNotification?: boolean
}) => {
  const client = useGqlClient()
  const [createShippingLocationMutation] = useCreateShippingLocationMutation({
    refetchQueries,
    client,
  })
  const [_msgs, msgsMgr] = useMsgs()
  const [selectedBuyerId, setSelectedBuyerId] = useState<string>()
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [persistLocation, setPersistLocation] = useState<boolean>(true)
  const [defaultLocation, setDefaultLocation] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [address, setAddress] = useState<Address | null>(null)
  const [selectedBranchId, setSelectedBranchId] = useState<string>()
  const [allBranches, setAllBranches] = useState<Branch[]>([])
  const [point, setPoint] = useState<Point | null>(null)
  const [addressType, setAddressType] = useState<'auto' | 'manual'>('auto')
  const [spinnerLive, spinner] = useToggle()
  const [allBuyers, setAllBuyers] = useState<SupplierUser[]>([])
  const branchOptions = branches?.map((b) => ({ value: b.id, label: label(b) })) || []
  const buyerOptions = buyers?.map((u) => ({ value: u.id, label: `${u.name} ${u.email}` })) || []

  const [getAddressLocation] = useGetAddressLocationLazyQuery({ client })
  const [getLocationAddress] = useGetLocationAddressLazyQuery({ client })

  const resetModal = () => {
    setName('')
    setCode('')
    setPhoneNumber('')
    setDefaultLocation(false)
    setPersistLocation(true)
    setSelectedBranchId(undefined)
    setSelectedBuyerId(undefined)
    setAddress(null)
    setPoint(null)
    setAddressType('auto')
  }

  useEffect(() => {
    resetModal()
  }, [open])

  const onAddressSelected = async (selectedAddress: Address | null) => {
    setAddress(selectedAddress)
    let newPoint: Point | null =
      selectedAddress?.latitude && selectedAddress.longitude
        ? { lat: selectedAddress.latitude, lng: selectedAddress.longitude }
        : null
    if (selectedAddress && !newPoint) {
      const r = await getAddressLocation({
        variables: {
          address: {
            deliverable: false,
            rdi: 'unknown',
            ...omit(GQL.cleanGraphQlInput(selectedAddress), ['latitude', 'longitude']),
            point: selectedAddress.point ?? null,
          },
        },
      })
      newPoint = r.data?.addressLocation ? GQL.cleanGraphQlInput(r.data?.addressLocation) : null
    }

    setPoint(newPoint)

    if (addressType === 'auto') {
      setName('')
      setPhoneNumber('')
      setCode('')
    }
  }

  const onPointSelected = async (selectedPoint: Point | null) => {
    setPoint(selectedPoint)

    if (selectedPoint && addressType === 'auto') {
      const r = await getLocationAddress({
        variables: { point: GQL.cleanGraphQlInput(selectedPoint) },
      })
      const newAddress = r.data?.locationAddress
        ? {
            ...GQL.cleanGraphQlInput(r.data?.locationAddress),
            firstName: null,
            lastName: null,
            companyName: null,
            postalCode: null,
          }
        : null

      setAddress(newAddress)

      setName('')
      setPhoneNumber('')
      setCode('')
    }
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (buyers.length > 0 && !selectedBuyerId) {
      msgsMgr.add('Select a gearflow user', 'negative')
      return
    }

    if (branches.length > 0 && !selectedBranchId) {
      msgsMgr.add('Select a billing company', 'negative')
      return
    }

    if (!address) {
      msgsMgr.add('Invalid address', 'negative')
      return
    }

    spinner.on()

    const variables: CreateShippingLocationMutationVariables = {
      name,
      code,
      selectedBuyerId: selectedBuyerId ?? null,
      defaultLocation,
      persistLocation,
      phoneNumber,
      address: {
        lineOne: address.lineOne,
        lineTwo: address.lineTwo,
        city: address.city,
        state: address.state,
        country: address.country,
        postalCode: address.postalCode,
        firstName: null,
        lastName: null,
        companyName: null,
        point: point ? GQL.cleanGraphQlInput(point) : null,
        deliverable: null,
        rdi: null,
      },
      branchId: selectedBranchId ?? null,
    }

    try {
      const { data, errors } = await createShippingLocationMutation({ variables })
      if (!noSuccessNotification) msgsMgr.add('Location added.', 'positive')

      if (data) {
        onComplete(data.createShippingLocation.id, variables)
        resetModal()
        onClose()
      }

      if (errors) {
        throw new Error(errors.map((err) => err.message).join(', '))
      }
    } catch (err) {
      const _err = err as Error
      msgsMgr.add(
        !_err?.message || _err?.message.includes('DOCTYPE')
          ? 'Sorry, something went wrong trying to create location'
          : _err?.message,
        'negative'
      )
    } finally {
      spinner.off()
    }
  }

  const onAddressNotFound = (addressInput) => {
    setAddressType('manual')
    setAddress({ ...AddressM.init(), lineOne: addressInput })
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="New Location"
      size={ModalSize.XL}
      showFooter={false}
    >
      <p className="-mt-1.5 mb-3">
        Add a new location by searching an address below or selecting a point on the map.
      </p>
      {(buyers.length > 0 || branches.length > 0) && (
        <div className="flex flex-col gap-3 py-6">
          {buyers.length !== 0 && (
            <SupplierAutocomplete
              defaultValue={null}
              defaultOptions={buyerOptions}
              allData={allBuyers}
              query={SEARCH_BUYERS}
              selected={[]}
              reactSelectOptions={{ isMulti: false, placeholder: 'Select user...' }}
              onChange={({ value }) => {
                setSelectedBuyerId(value)
              }}
              transformation={(response) => {
                const theBuyers = response.data.searchBuyers.map((u) => ({
                  value: u.id,
                  label: `${u.name} ${u.email}`,
                }))

                setAllBuyers(theBuyers)

                return theBuyers
              }}
            />
          )}
          {branches.length !== 0 && (
            <Field label="Billing company">
              <BuyerAutocomplete
                defaultValue={null}
                defaultOptions={branchOptions}
                allData={allBranches}
                query={AllBranchesDocument}
                selected={[]}
                isMulti={false}
                placeholder="Select billing company..."
                onChange={({ value }: any) => {
                  setSelectedBranchId(value)
                }}
                transformation={(response) => {
                  const theBranches = response.data.allBranches.map((b) => ({
                    value: b.id,
                    label: label(b),
                  }))

                  setAllBranches(theBranches)

                  return theBranches
                }}
              />
            </Field>
          )}
        </div>
      )}

      <div
        className={classNames(
          'relative -mx-4 sm:-mx-6 -mb-4',
          addressType === 'manual' && 'flex border-t'
        )}
      >
        <Map
          onSaveLocationClicked={onPointSelected}
          pickLocationEnabled={!address && !point}
          location={point ?? undefined}
          className={addressType === 'manual' ? 'order-2' : undefined}
        />

        <div
          className={classNames(
            'bg-white transform duration-300 w-72 flex-shrink-0',
            address || point ? 'p-2 shadow-lg' : 'shadow',
            addressType === 'auto' ? 'absolute top-2 left-2 rounded-xl' : 'order-1'
          )}
        >
          {point && !address ? (
            <p className="px-2 py-2 border rounded-lg font-medium text-sm flex justify-between text-slate-800">
              Dropped Pin{' '}
              <Action.T onClick={() => onPointSelected(null)}>
                <XIcon className="text-slate-500 h-5 inline-block" />
              </Action.T>
            </p>
          ) : addressType === 'auto' ? (
            <OneLineAddress
              value={address}
              onAddressSelected={onAddressSelected}
              onAddressRemoved={() => onAddressSelected(null)}
              onAddressNotFound={onAddressNotFound}
            />
          ) : null}

          {(address || point) && (
            <form onSubmit={onSubmit}>
              <div
                className={classNames('flex flex-col gap-y-2', addressType === 'auto' && 'mt-3')}
              >
                {address &&
                  (addressType === 'auto' ? (
                    <AddressC
                      address={address}
                      hideCompany
                      hideName
                      className="text-slate-900 font-medium text-sm [&>p:nth-child(1)]:text-base"
                    />
                  ) : (
                    <div className="flex flex-col gap-y-2">
                      <AddressInputC
                        address={address}
                        hideCompany
                        hideFirstLastName
                        onChange={onAddressSelected}
                      />
                    </div>
                  ))}

                {point && (
                  <p className="font-medium text-sm text-slate-900">
                    <LocationMarkerIcon className="h-4 text-gearflow inline-block -mt-1" />{' '}
                    {point.lat.toFixed(6)}, {point.lng.toFixed(6)}
                  </p>
                )}

                {showPersist && (
                  <Checkbox
                    label="Save this location for later"
                    checked={persistLocation}
                    value="Save this location for later"
                    onChange={() => setPersistLocation(!persistLocation)}
                  />
                )}

                <PhoneInput
                  label="Phone number"
                  required
                  value={phoneNumber}
                  onChange={(newPhoneNumber) => setPhoneNumber(newPhoneNumber)}
                />

                {persistLocation && (
                  <>
                    <TextField
                      label="Location name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      noErrorText
                    />
                    <TextField
                      label="Code (optional)"
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                      noErrorText
                    />
                    <Checkbox
                      label="Use as your default location"
                      checked={defaultLocation}
                      value="Use as your default location"
                      onChange={() => setDefaultLocation(!defaultLocation)}
                      id="default-location"
                    />
                  </>
                )}

                <Action.P type="submit" disabled={spinnerLive}>
                  Save
                </Action.P>
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default LocationModal
