import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import uniq from 'lodash/uniq'
import React from 'react'

import allFilterTypes from '../data/filterTypes'
import FilterM from '../modules/Filter'

import Button from './ButtonOld'
import FilterC from './Filters/Filter'

import { Filter, FilterField, FiltersApi } from '../../types'

const Filters = ({
  filters,
  stagedFilters,
  fields,
  filtersApi,
  onApply,
  className,
}: {
  filters: Filter[]
  stagedFilters: Filter[]
  fields: FilterField[]
  filtersApi: FiltersApi
  onApply: () => void
  className?: string
}) => {
  const buildFilter = () => {
    const field = fields[0]
    return FilterM.build({ fieldId: field.id, typeId: field.filterTypeIds[0] })
  }

  React.useEffect(() => {
    if (stagedFilters.length === 0) filtersApi.add(buildFilter())
  }, [])

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    onApply()
    e.preventDefault()
  }

  const changed = !isEqual(stagedFilters, filters)

  const filterTypes = React.useMemo(() => {
    const filterTypeIds = uniq(fields.flatMap((f) => f.filterTypeIds))
    return allFilterTypes.filter((ft) => filterTypeIds.includes(ft.id))
  }, [fields])

  return (
    <div className={classNames('p-3 sm:px-6 sm:py-3 bg-gray-50', className)}>
      <form onSubmit={onSubmit}>
        <div className="grid gap-2">
          {stagedFilters.map((filter) => (
            <FilterC
              key={filter.id}
              filter={filter}
              fields={fields}
              filterTypes={filterTypes}
              filtersApi={filtersApi}
            />
          ))}

          <div className="flex justify-end gap-2">
            <Button
              onClick={() => filtersApi.add(buildFilter())}
              title="➕ Filter"
              type="button"
              primary={false}
            />

            {changed && <Button title="Apply" type="submit" />}
          </div>
        </div>
      </form>
    </div>
  )
}

export default Filters
