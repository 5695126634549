import { DateTime } from 'luxon'

type Props = {
  value: DateTime | null
  onChange: (dateTime: DateTime | null) => void
  min?: DateTime
  max?: DateTime
} & Pick<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'required' | 'id'
>

const format = 'yyyy-MM-dd'

const Date = ({ value, onChange, min, max, ...props }: Props) => (
  <input
    value={value ? value.toFormat(format) : ''}
    onChange={({ target }) =>
      onChange(target.value === '' ? null : DateTime.fromFormat(target.value, format))
    }
    min={min && min.toFormat(format)}
    max={max && max.toFormat(format)}
    type="date"
    className="text-sm shadow-sm border border-gray-300 rounded-md text-gray-700"
    {...props}
  />
)

export default Date
