import { gql } from '@apollo/client'

const FETCH_ALL_STORES = gql`
  query AllStores {
    allStores {
      id
      name
      alias
    }
  }
`

export default FETCH_ALL_STORES
