import { useMutation, gql } from '@apollo/client'

interface Data {
  deletePartRequests: boolean
}

interface Form {
  rfqId: string
  partRequestIds: string[]
}

const mutation = gql`
  mutation DeletePartRequests($rfqId: ID!, $partRequestIds: [ID]!) {
    deletePartRequests(rfqId: $rfqId, partRequestIds: $partRequestIds)
  }
`

const useDeletePartRequests = () => {
  const [mutate] = useMutation<Data, Form>(mutation)
  return (variables: Form) => mutate({ variables })
}

export default useDeletePartRequests
