import type { Address } from '../../types'
import { AddressInput } from '../_gen/gql'

import GraphQL from '@/gf/modules/GraphQL'
import AddressM from '@/gf/modules/Address'

const toGraphQlAddressInput = (address: Address): AddressInput => ({
  firstName: address.firstName ?? null,
  lastName: address.lastName ?? null,
  companyName: address.companyName ?? null,
  lineOne: address.lineOne ?? null,
  lineTwo: address.lineTwo ?? null,
  city: address.city ?? null,
  state: address.state ?? null,
  country: address.country ?? null,
  deliverable: address.deliverable ?? null,
  postalCode: address.postalCode ?? null,
  rdi: address.rdi ?? null,
  point: address.point ? GraphQL.cleanGraphQlInput(address.point) : null,
})

const Address = { ...AddressM, toGraphQlAddressInput }

export default Address
