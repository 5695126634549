import { gql, useQuery, QueryHookOptions } from '@apollo/client'

import AccountMachine from '../modules/AccountMachine'

import { AccountMachinePayload, Pagination } from '../../types'

interface Data {
  accountMachineSearch: {
    machines: AccountMachinePayload[]
    pagination: Pagination
  }
}

interface Vars {
  page: number
  filters: string[][]
}

const query = gql`
  query AccountMachineSearch($page: Int, $filters: [[String]]) {
    accountMachineSearch(page: $page, filters: $filters) {
      pagination {
        totalPages
        totalResults
      }
      machines {
        id
        name
        serialNumber
        description
        owned
        engineMake
        engineModel
        engineSerialNumber
        machine {
          make
          model
          year
        }
      }
    }
  }
`

const useAccountMachineSearch = (options?: QueryHookOptions<Data, Vars>) => {
  const result = useQuery<Data, Vars>(query, { ...options, fetchPolicy: 'no-cache' })

  const data = result.data && {
    ...result.data,
    accountMachineSearch: {
      ...result.data.accountMachineSearch,
      machines: result.data.accountMachineSearch.machines.map(AccountMachine.fromPayload),
    },
  }

  return { ...result, data }
}

export default useAccountMachineSearch
