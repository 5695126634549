import { LocationMarkerIcon } from '@heroicons/react/solid'
import placeholderImage from './map-placeholder.png'

const LocationPlaceholder = ({ text }: { text: string }) => (
  <figure className="rounded block overflow-hidden relative h-80">
    <img
      src={placeholderImage}
      alt="Map placeholder"
      className="object-cover w-full h-full opacity-40"
    />
    <div className="absolute inset-0 flex items-center justify-center flex-col text-center bg-slate-900 bg-opacity-10">
      <LocationMarkerIcon className="text-gearflow h-10 md:h-14 block mx-auto" />
      <p className="text-zinc-600 text-base md:text-lg max-w-prose px-4">{text}</p>
    </div>
  </figure>
)

export default LocationPlaceholder
