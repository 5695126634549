import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Demo from './LearningCarousel/Demo'
import VendorVideo from './LearningCarousel/VendorVideo'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { Container } from '../Home'

const NavButton = ({ type }: { type: 'prev' | 'next' }) => (
  <span
    className={classNames(
      'absolute top-1/2 z-[2] w-12 h-12 rounded-full flex items-center justify-center bg-white text-gray-900 border shadow-lg cursor-pointer',
      type === 'prev' ? 'learning-arrow-left left-0' : 'learning-arrow-right right-0'
    )}
  >
    {type === 'next' ? (
      <ArrowRightIcon className="w-5 h-5 inline-block" />
    ) : (
      <ArrowLeftIcon className="w-5 h-5 inline-block" />
    )}
  </span>
)

const LearningCarousel = () => (
  <Container className="relative">
    <NavButton type="prev" />
    <NavButton type="next" />

    <Swiper
      modules={[Navigation, A11y]}
      spaceBetween={20}
      slidesPerView="auto"
      className="z-[1] overflow-visible"
      navigation={{
        enabled: true,
        nextEl: '.learning-arrow-right',
        disabledClass: 'hidden',
        prevEl: '.learning-arrow-left',
      }}
    >
      <SwiperSlide className="w-4/5">
        <Demo />
      </SwiperSlide>
      <SwiperSlide className="w-4/5">
        <VendorVideo />
      </SwiperSlide>
    </Swiper>
  </Container>
)

export default LearningCarousel
