import classNames from 'classnames'

const EnabledBadge = ({ enabled }: { enabled: boolean }) => (
  <span
    className={classNames(
      'flex-shrink-0 rounded-full text-xs px-2 py-1 leading-3 inline-flex items-center gap-x-1 justify-center leading-3',
      enabled ? 'text-emerald-500 bg-emerald-50' : 'bg-slate-100 text-slate-700'
    )}
  >
    <span
      className={classNames(
        'h-1.5 w-1.5 rounded-full inline-block',
        enabled ? 'bg-emerald-500' : 'bg-slate-700'
      )}
    />
    {enabled ? 'Active In Fleet' : 'Machine Disabled'}
  </span>
)

export default EnabledBadge
