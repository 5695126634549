import type { AdminUserForm } from '@/buyers/hooks/useAddUserForm'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Field from '@/gf/components/Field'
import TextInput from '@/gf/components/inputs/Text'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'

const Details = ({
  fields,
  errors,
  updateFields,
  validate,
  cancel,
  navigateToStepIndex,
}: AdminUserForm & { cancel: () => void; navigateToStepIndex: (index: number) => void }) => {
  const next = () => validate('details').then(() => navigateToStepIndex(1))

  return (
    <Card>
      <Card.Section>
        <div className="space-y-4 max-w-sm">
          <Field label="Name" errors={errors.name}>
            <TextInput value={fields.name} setValue={(name) => updateFields({ name })} />
          </Field>

          <Field label="Email" errors={errors.email}>
            <TextInput
              value={fields.email}
              setValue={(email) => updateFields({ email })}
              disabled={!!fields.inviteId}
            />
          </Field>

          <Field label="Phone number" errors={errors.phoneNumber}>
            <PhoneInput
              value={fields.phoneNumber}
              onChange={(phoneNumber) => updateFields({ phoneNumber })}
            />
          </Field>

          <Field label="Title (optional)" errors={errors.title}>
            <TextInput value={fields.title} setValue={(title) => updateFields({ title })} />
          </Field>
        </div>
      </Card.Section>

      <Card.Section>
        <div className="flex gap-6 justify-end">
          <Action.T onClick={cancel}>
            <span className="text-sm">Cancel</span>
          </Action.T>

          <Action.P onClick={next}>Next</Action.P>
        </div>
      </Card.Section>
    </Card>
  )
}

export default Details
