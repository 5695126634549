import classNames from 'classnames'

type Props = Exclude<JSX.IntrinsicElements['button'], 'type' | 'className'> & { selected: boolean }

const ScopeAction = ({ selected, ...props }: Props) => {
  const className = classNames(
    'px-4 py-2 ring-none outline-none',
    selected ? 'bg-blue-500 text-white' : 'text-gray-900 bg-white hover:bg-gray-50'
  )

  return <button className={className} type="button" {...props} />
}

export default ScopeAction
