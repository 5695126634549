import { useUpdateVendorContactPreferredSettingMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Link from '@/gf/components/Link'
import { PlusIcon } from '@heroicons/react/solid'
import { useCallback } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import AddContactModal from '../../components/AddContactModal'
import useVendorContacts from '../../hooks/useVendorContacts'
import { useContext } from '../Vendor'
import ContactList from './Contacts/ContactList'
import ContactsListGhost from './Contacts/ContactsListGhost'
import ContactsZeroState from './Contacts/ContactsZeroState'

const Contacts = () => {
  const { vendor } = useContext()
  const { loading, contacts, refetch } = useVendorContacts(vendor.id)
  const [setPreferredSetting] = useUpdateVendorContactPreferredSettingMutation({
    client: useGqlClient(),
  })
  const navigate = useNavigate()

  const onPreferredSettingChanged = useCallback(
    (contactId, preferred) =>
      setPreferredSetting({ variables: { vendorId: vendor.id, contactId, preferred } }).then(() =>
        refetch()
      ),
    [vendor.id]
  )

  return (
    <>
      <Routes>
        <Route
          path="new"
          element={
            <AddContactModal
              vendorId={vendor.id}
              open
              onClose={() => navigate('./', { replace: true })}
              onAfterSave={(_) => refetch()}
            />
          }
        />
      </Routes>

      <div className="p-4">
        {(loading || contacts.length > 0) && (
          <div className="flex justify-between items-center">
            <h3 className="flex items-center font-black text-xl text-slate-600">Contacts</h3>
            <Link.P to="./new">
              <PlusIcon className="inline h-4 align-middle -mt-1" /> Add Contact
            </Link.P>
          </div>
        )}

        {loading ? (
          <ContactsListGhost />
        ) : contacts.length === 0 ? (
          <ContactsZeroState />
        ) : (
          <ContactList contacts={contacts} onPreferredChanged={onPreferredSettingChanged} />
        )}
      </div>
    </>
  )
}

export default Contacts
