import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { Point } from '@/types'
import { PartialRequestUpdate, SourcingType, VendorSelectionType } from './types'
import NoAccess from '@/buyers/components/NoAccess'
import PartHubProPitch from '@/buyers/components/PartHubProPitch'
import MyVendors from './SelectVendorsStep/MyVendors'
import Sourcing from './SelectVendorsStep/Sourcing'

const SelectVendorsStep = ({
  reset,
  request,
  updateRequest,
  onSubmit,
  onBackClicked,
  sourcingTypeEnabled = true,
  vendorSelectionType,
  gearflowNetworkSectionEnabled = true,
}: {
  reset?: () => void
  onSubmit: () => void
  onBackClicked: () => void
  sourcingTypeEnabled?: boolean
  vendorSelectionType: VendorSelectionType
  gearflowNetworkSectionEnabled?: boolean
} & PartialRequestUpdate) => {
  const navigate = useNavigate()

  return (
    <div>
      <Routes>
        <Route
          path=""
          element={<Navigate to={sourcingTypeEnabled ? 'sourcing' : 'select'} replace />}
        />
        <Route
          path="sourcing"
          element={
            <Sourcing
              reset={reset}
              request={request}
              updateRequest={updateRequest}
              onSubmit={onSubmit}
              onBackClicked={onBackClicked}
              vendorSelectionType={vendorSelectionType}
              sourcingTypeEnabled={sourcingTypeEnabled}
              gearflowNetworkSectionEnabled={gearflowNetworkSectionEnabled}
            />
          }
        />
        <Route
          path="*"
          element={
            <div className="space-y-4">
              {request.sourcing === SourcingType.VENDORS &&
                (vendorSelectionType !== 'disabled' ? (
                  <MyVendors
                    dealerLocationIds={request.dealerLocationIds}
                    locationId={request.locationId ?? null}
                    machineOrgId={request.machineOrgId ?? null}
                    nearbyReferencePoint={request.nearbyReferencePoint as Point}
                    vendors={request.vendors}
                    reset={reset}
                    onBackClicked={() =>
                      sourcingTypeEnabled ? navigate('sourcing') : onBackClicked()
                    }
                    onSubmit={() => onSubmit()}
                    vendorSelectionType={vendorSelectionType}
                    onDealerLocationsChanged={(selectedDealerLocationIds) =>
                      updateRequest({ dealerLocationIds: selectedDealerLocationIds })
                    }
                    onLocationChanged={(point, selectedLocationId) =>
                      updateRequest({ nearbyReferencePoint: point, locationId: selectedLocationId })
                    }
                    onContactAndDeliveryChanged={(values) => updateRequest(values)}
                    tutorial={request.tutorial}
                  />
                ) : (
                  <>
                    <div className="w-106">
                      <NoAccess />
                    </div>

                    <PartHubProPitch />
                  </>
                ))}
            </div>
          }
        />
      </Routes>
    </div>
  )
}

export default SelectVendorsStep
