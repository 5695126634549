import Select from '@/gf/components/next/Select'
import { useMemo } from 'react'

const ExternalVendors = ({
  value,
  onChange,
  vendors,
  loading,
}: {
  value: string | null
  onChange: (newValue: string) => void
  vendors: { id: string; name: string }[] | undefined
  loading: boolean
}) => {
  const options = vendors?.map((v) => ({ value: v.id, label: v.name })) ?? []
  const selectedOption = useMemo(() => options.find((o) => o.value === value), [options, value])

  return (
    <Select
      isLoading={loading}
      options={options}
      value={selectedOption}
      onChange={(o) => (o ? onChange(o.value) : undefined)}
      placeholder="Select a vendor..."
      className="text-sm w-full max-w-xs"
    />
  )
}

export default ExternalVendors
