import classNames from 'classnames'
import { CogIcon } from '@heroicons/react/outline'
import Dropdown from '@/gf/components/Dropdown'

import type { Request } from '../Requests'

const VendorLine = ({ name, contactNames }) => (
  <div>
    <p className="truncate max-w-72">{name}</p>

    {contactNames.length > 0 && (
      <p className="text-xs text-slate-400 italic mt-1 truncate max-w-72">
        {contactNames.join(', ')}
      </p>
    )}
  </div>
)

const Vendor = ({ name, contactNames, otherVendorsCount, className = '' }) => (
  <div className={classNames('flex gap-2 text-left', className)}>
    <VendorLine name={name} contactNames={contactNames} />

    {otherVendorsCount > 0 && (
      <div className="flex-shrink-0">
        <span className="font-medium text-xs bg-slate-100 text-slate-500 rounded-full px-2 py-1">
          +{otherVendorsCount}
        </span>
      </div>
    )}
  </div>
)

const gfn = {
  key: 'gfn',
  name: (
    <span className="flex gap-1 items-center">
      <CogIcon className="shrink-0 w-5 h-5 text-gearflow" />
      <span>Gearflow Network</span>
    </span>
  ),
  contactNames: [],
}

const Vendors = ({ rfq }: { rfq: Request }) => {
  const vendors = rfq.vendorLinks.map((vl) => ({
    key: vl.id,
    name: <>{vl.vendor.name}</>,
    contactNames: vl.vendorContacts.reduce<string[]>(
      (acc, c) => (c.name !== null ? [...acc, c.name] : acc),
      []
    ),
  }))

  const [firstVendor, ...otherVendors] =
    rfq.broadcast && !rfq.resumeDealer ? [...vendors, gfn] : vendors

  if (!firstVendor) return <span className="text-slate-400 italic px-6">None</span>

  return otherVendors.length === 0 ? (
    <div className="flex gap-x-1">
      <Vendor
        className="px-6 py-4"
        name={firstVendor.name}
        contactNames={firstVendor.contactNames}
        otherVendorsCount={otherVendors.length}
      />
    </div>
  ) : (
    <Dropdown
      title={
        <Vendor
          name={firstVendor.name}
          contactNames={firstVendor.contactNames}
          otherVendorsCount={otherVendors.length}
        />
      }
      stopEventPropagation
    >
      <div className="flex flex-col gap-y-2">
        {otherVendors.map((v) => (
          <VendorLine key={v.key} name={v.name} contactNames={v.contactNames} />
        ))}
      </div>
    </Dropdown>
  )
}

export default Vendors
