import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Address from '@/gf/components/Address'
import LabeledText from '@/gf/components/LabeledText'
import SidebarProfileView from '@/gf/components/Layout/SidebarProfileView'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { LocationMarkerIcon, PencilIcon, PhoneIcon } from '@heroicons/react/outline'
import { useContext } from '../Vendor'
import AddressFormModal from './AddressFormModal'
import PreferredSettingForm from './PreferredSettingForm'

const VendorView = ({ onEditClick }: { onEditClick: () => void }) => {
  const [addressModalOpen, { on: openAddressModal, off: closeAddressModal }] = useToggle()
  const { vendor, org, refetchVendor } = useContext()

  return (
    <SidebarProfileView
      title={vendor.name}
      imageUrl={vendor.store?.logo?.url || vendor.dealerLocation?.logoUrl}
      detail={
        vendor.store?.phoneNumber ? (
          <>
            <PhoneIcon className="h-3 inline-block mr-1" />
            <A.T href={`tel:${vendor.store?.phoneNumber}`} className="text-slate-600">
              {Phone.format(vendor.store?.phoneNumber)}
            </A.T>
          </>
        ) : null
      }
      onEditClick={onEditClick}
      className="w-full md:w-64"
    >
      <AddressFormModal
        open={addressModalOpen}
        onClose={closeAddressModal}
        onAfterSave={refetchVendor}
      />
      <LabeledText label="Account Number" value={vendor.accountNumbers.join(', ')} />

      <LabeledText
        label="Brands"
        value={vendor.brands.length === 0 ? null : vendor.brands.map((b) => b.name).join(', ')}
      />

      {org?.orgApps.some((oa) => oa.appId === 'fleetio') && (
        <LabeledText label="Fleetio Vendor ID" value={vendor.fleetioId} />
      )}

      <div className="flex flex-col">
        <label className="text-slate-600 text-sm font-medium">Address</label>

        {vendor.address ? (
          <div
            onClick={openAddressModal}
            className="relative hover:bg-slate-50 -mx-2 px-2 pt-1 cursor-pointer hover:underline"
          >
            <Address
              className="text-slate-700 text-sm"
              address={vendor.address}
              hideCompany
              hideName
            />

            <PencilIcon className=" absolute top-1 right-1 h-5 w-5 text-zinc-400" />
          </div>
        ) : (
          <div className="text-sm text-left hover:bg-slate-50 flex -mx-2 px-2">
            <Action.T onClick={openAddressModal}>
              <span className="inline-flex gap-x-1 items-center pt-1.5 pb-2 underline">
                <LocationMarkerIcon className="inline-block text-gearflow h-4" />
                <span>Click to add the address</span>
              </span>
            </Action.T>
          </div>
        )}
      </div>

      {vendor && <PreferredSettingForm vendorId={vendor.id} preferredFor={vendor.preferredFor} />}
    </SidebarProfileView>
  )
}

export default VendorView
