import { Maybe } from '@/types'
import Variation, { formatTime } from '../Variation'
import Time from '@/gf/modules/Time'
import { Link } from 'react-router-dom'
import { NO_DATA_VALUE } from '@/buyers/pages/Reporting/Scorecards'
import classNames from 'classnames'

const Content = ({
  value,
  variation,
  hoverEffect = false,
}: {
  value: Maybe<number>
  variation: Maybe<number>
  hoverEffect?: boolean
}) => (
  <span
    className={classNames(
      'block space-y-3 rounded-lg transition duration-300 p-2',
      hoverEffect && 'hover:bg-gray-100'
    )}
  >
    <span className="text-4xl leading-[2.625rem] font-medium">
      {value === null ? NO_DATA_VALUE : Time.secondsToString(value)}
    </span>

    {variation && (
      <div className="flex items-center gap-x-2">
        <Variation value={variation} toformat={formatTime} className="" positiveDirection="down" />
        <span className="font-medium">Last 30 days</span>
      </div>
    )}
  </span>
)

const FulfillmentValue = ({
  value,
  variation,
  link = true,
}: {
  value: Maybe<number>
  variation: Maybe<number>
  link?: boolean
}) =>
  link ? (
    <Link to="/reporting/time?metric=request fulfillment time" className="block">
      <Content value={value} variation={variation} hoverEffect />
    </Link>
  ) : (
    <Content value={value} variation={variation} />
  )

export default FulfillmentValue
