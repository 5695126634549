import {
  MicrosoftContact,
  MicrosoftContactsQueryVariables,
  useMicrosoftContactsQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { useState } from 'react'
import MicrosoftContactsButton from './MicrosoftContacts/Button'
import Selector from './MicrosoftContacts/Selector'

export type Contact = Pick<MicrosoftContact, 'id'> & {
  name: string
  emailAddress: string
  phone: string
}

const MicrosoftContacts = ({
  onSelect,
  redirectParams,
}: {
  onSelect: (contact: Contact) => void
  redirectParams?: () => URLSearchParams
}) => {
  const {
    user: { id: userId },
  } = useSession()

  const [vars, setVars] = useState<Omit<MicrosoftContactsQueryVariables, 'userId'>>({
    previous: null,
    current: null,
    next: null,
    search: '',
  })

  const { data, previousData, loading } = useMicrosoftContactsQuery({
    variables: { userId, ...vars },
    client: useGqlClient(),
  })

  const microsoftLink = data ? data.user.microsoftLink : previousData?.user.microsoftLink

  if (microsoftLink === undefined) return null

  if (microsoftLink === null || microsoftLink.contactsLink === null)
    return (
      <div>
        <MicrosoftContactsButton redirectParams={redirectParams} />
      </div>
    )

  return (
    <Selector
      paginatedContacts={microsoftLink.contactsLink.paginatedContacts}
      search={vars.search}
      loading={loading}
      onSelect={onSelect}
      setVars={setVars}
    />
  )
}

export default MicrosoftContacts
