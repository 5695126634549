import { useRfqDetailsQuery } from '@/buyers/_gen/gql'
import RFQCommentForm from '@/buyers/components/RFQCommentForm'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Address from '@/gf/components/Address'
import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import LinkButton from '@/gf/components/LinkButtonOld'
import RfqEvents from '@/gf/components/RfqEvents'
import { useTimelineEventsForRfq } from '@/gf/hooks/useTimelineEvents'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import StoreOrderEventContext from '@/gf/modules/StoreOrderEvent'
import Time from '@/gf/modules/Time'
import UserContext from '@/gf/modules/User'
import nth from 'lodash/nth'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useContext } from '../RFQ'
import Notifications from './Details/Notifications'
import EditWorkOrderModal from './EditWorkOrderModal'

const Details = ({ refetch }: { refetch: () => Promise<unknown> }) => {
  const {
    user,
    organization: { id: orgId },
  } = useSession()

  const { rfq } = useContext()
  const navigate = useNavigate()
  const eventsSearch = useTimelineEventsForRfq(rfq.id).data?.eventsSearch
  const [workOrderOpen, workOrderToggle] = useToggle()
  const gqlClient = useGqlClient()

  const { data: rfqNext, refetchRequest } = (() => {
    const { refetch: refetchNext, data } = useRfqDetailsQuery({
      variables: { orgId, filter: JSON.stringify(['id_eq', rfq.id]) },
      client: gqlClient,
    })

    const request = data && nth(data.rfqs, 0)
    return { data: request, refetchRequest: refetchNext }
  })()

  if (!rfqNext) return null

  const shippingAddress = rfq.shippingAddress ?? rfq.user?.shippingAddress

  return (
    <Layout>
      <EditWorkOrderModal
        refetch={refetchRequest}
        onClose={workOrderToggle.off}
        open={workOrderOpen}
        requestId={rfq.id}
        woNumber={rfqNext.workOrderNumber}
      />

      <Routes>
        <Route path="notifications" element={<Notifications />} />
      </Routes>

      <Layout.Section type="ipad" className="xl:col-span-4">
        <Card title="Timeline">
          <Card.Section>
            <Card.SubSection>
              <RFQCommentForm
                rfqId={rfq.id}
                refetch={refetch}
                isAdmin={UserContext.isAdmin(user)}
                openMessagesTab={() => navigate('messages')}
              />
            </Card.SubSection>
            <Card.SubSection>
              <RfqEvents
                events={eventsSearch?.rfqEvents}
                storeOrderEvents={eventsSearch?.storeOrderEvents}
                storeOrderEventMapper={StoreOrderEventContext.getBuyerEventDisplayData}
                showStoreNames
              />
            </Card.SubSection>
          </Card.Section>
        </Card>
      </Layout.Section>
      <Layout.Section type="ipad" className="xl:col-span-2">
        {rfq.user && (
          <Card title="Info.">
            <Card.Section>
              <Desc.List>
                <Desc.Row title="Created by">{rfq.user.displayName}</Desc.Row>
                <Desc.Row title="Created on">{Time.formatDateTime(rfq.insertedAt)}</Desc.Row>
                <Desc.Row title="Contact">
                  <div>
                    <p>{rfq.fullName}</p>
                    {rfq.emailAddress && <p className="break-words">{rfq.emailAddress}</p>}
                    <p>{Phone.format(rfq.phoneNumber)}</p>
                  </div>
                </Desc.Row>
                <Desc.Row title="Shipping address">
                  {shippingAddress ? (
                    <Address address={shippingAddress} />
                  ) : (
                    <p className="text-gray-400 italic">(none)</p>
                  )}
                </Desc.Row>
                <Desc.Row title="Work order number">
                  {rfqNext.workOrderNumber}{' '}
                  <LinkButton onClick={workOrderToggle.on}>Edit</LinkButton>
                </Desc.Row>
              </Desc.List>
            </Card.Section>
          </Card>
        )}

        <Card title="Notifications" headerButton={<Link.T to="notifications">Edit</Link.T>}>
          <Card.Section>
            {rfq.buyerNotificationUsers.length === 0 ? (
              <p className="text-sm text-gray-700">
                None. Click &apos;Edit&apos; to subscribe/unsubscribe users to/from notifications on
                this RFQ.
              </p>
            ) : (
              rfq.buyerNotificationUsers.map((user) => (
                <p key={user.id} className="text-sm text-gray-700">
                  {user.displayName}
                </p>
              ))
            )}
          </Card.Section>
        </Card>
      </Layout.Section>
    </Layout>
  )
}

export default Details
