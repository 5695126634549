import classNames from 'classnames'

import { StoreOrderStep as Step, RequestQuery } from '@/buyers/_gen/gql'

type RequestForQuote = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

type StoreOrder = RequestForQuote['storeOrders'][number]
type Props = { color: string; textColor: string; title: string }

const propsByStep: Record<Step, Props> = {
  [Step.Quoted]: {
    color: 'bg-orange-200',
    textColor: 'text-orange-900',
    title: 'Open Quote',
  },
  [Step.PoSent]: {
    color: 'bg-gray-200',
    textColor: 'text-gray-900',
    title: 'PO Sent',
  },
  [Step.Fulfilling]: {
    color: 'bg-green-200',
    textColor: 'text-green-900',
    title: 'Fulfilling',
  },
  [Step.Fulfilled]: {
    color: 'bg-green-600',
    textColor: 'text-white',
    title: 'Fulfilled',
  },
  [Step.Canceled]: {
    color: 'bg-red-400',
    textColor: 'text-white',
    title: 'Canceled',
  },
}

const OrderProgressLabel = ({
  storeOrder,
  className,
}: {
  storeOrder: StoreOrder
  className?: string
}) => {
  const { color, textColor, title } = propsByStep[storeOrder.step]

  return (
    <div
      className={classNames(
        'text-sm rounded-md py-1 px-3 group-hover:shadow transform duration-200 group-hover:scale-110',
        color,
        textColor,
        className
      )}
    >
      {title}
    </div>
  )
}

export default OrderProgressLabel
