import { PointTooltip } from '@nivo/line'
import { DateTime } from 'luxon'
import pluralize from 'pluralize'

const NivoPointTooltip: PointTooltip = ({ point }) => {
  const pluralNumber = typeof point.data.y === 'number' ? point.data.y : 0

  const serieId = point.serieId.toString()

  const label = serieId.includes('urgency')
    ? `Urgent ${pluralize('Requests', pluralNumber)}`
    : serieId.includes('accuracy')
      ? `Inaccurate ${pluralize('Requests', pluralNumber)}`
      : serieId.includes('internal fulfillment')
        ? `Internal ${pluralize('Fulfillments', pluralNumber)}`
        : undefined

  return (
    <div
      key={point.id}
      className="p-2 flex flex-col gap-y-1 bg-white border-gray-300 border rounded shadow-md"
    >
      {serieId.includes('Parts budget') && (
        <span className="text-xs text-gray-900 leading-none">Budget</span>
      )}

      <span className="text-xs text-gray-900">
        {point.serieId.toString().includes('Daily average')
          ? 'Daily average'
          : typeof point.data.x === 'object'
            ? DateTime.fromJSDate(point.data.x).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
            : point.data.xFormatted}
      </span>

      <span className="text-sm text-gray-900 font-medium">
        {point.data.yFormatted}
        {label && ` ${label}`}
      </span>
    </div>
  )
}

export default NivoPointTooltip
