import Box from '@/buyers/components/Box'

const NotFound = () => (
  <div className="py-8">
    <Box className="text-base space-y-2">
      <div className="text-lg">Sorry, page does not exist.</div>
      <div>For help, contact support@gearflow.com</div>
    </Box>
  </div>
)

export default NotFound
