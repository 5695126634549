import Select from '@/gf/components/next/Select'
import SlimBadge from '@/gf/components/SlimBadge'
import useGoogleMapsScriptLoader from '@/gf/hooks/useGoogleMapsScriptLoader'
import { GREEN_PIN, ORANGE_PIN, US_CENTER } from '@/gf/modules/Map'
import { Address, Maybe, Point } from '@/types'
import { XIcon } from '@heroicons/react/solid'
import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api'
import classNames from 'classnames'
import { uniqueId } from 'lodash'
import pick from 'lodash/pick'
import pluralize from 'pluralize'
import { Fragment, useEffect, useState } from 'react'
import {
  LocationsDealersQuery,
  MyLocationsQuery,
  useFleetBrandsQuery,
  useGetAddressLocationLazyQuery,
  useLocationsDealersLazyQuery,
  useMyLocationsQuery,
} from '../_gen/gql'
import OneLineAddress from '../components/OneLineAddress'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import AddressM from '../modules/Address'

type LocationDealer = {
  id: string
  point: Point
  dealers: LocationsDealersQuery['dealerLocations']['entries']
  coverage: { id: string; name: string; count: number }[]
}

const viewTypes = [
  { label: 'My Locations', value: 'my_locations' },
  { label: 'Address', value: 'address' },
]

const typeFilters = [
  { label: 'All Vendors', value: 'all' },
  { label: 'Active on Gf', value: 'active' },
  { label: 'My Vendors', value: 'saved' },
]

const VendorsMap = () => {
  const { orgId } = useSession()
  const { isLoaded } = useGoogleMapsScriptLoader()
  const client = useGqlClient()
  const [selectedAddress, setSelectedAddress] = useState<Maybe<Address>>(null)
  const { data: locationsData } = useMyLocationsQuery({ variables: { orgId }, client })
  const [fetchLocationsDealers] = useLocationsDealersLazyQuery({ client })
  const { data: fleetBrandsData } = useFleetBrandsQuery({ client })
  const [getAddressLocation] = useGetAddressLocationLazyQuery({ client })

  const [locationsDealers, setLocationsDealers] = useState<LocationDealer[]>([])
  const [selectedlocation, setSelectedLocation] = useState<Maybe<LocationDealer>>()
  const [selectedDealer, setSelectedDealer] =
    useState<Maybe<LocationsDealersQuery['dealerLocations']['entries'][number]>>()
  const [viewType, setViewType] = useState<(typeof viewTypes)[number]>(viewTypes[0])
  const [typeFilter, setTypeFilter] = useState<(typeof typeFilters)[number]>(typeFilters[0])

  const defaultLocation =
    locationsData?.org?.locations.find((l) => l.defaultLocation) ?? locationsData?.org?.locations[0]

  const fetchDealers = async (
    point: Point,
    brands: { id: string; name: string }[],
    dealerTypeFilter: string
  ) => {
    const { data } = await fetchLocationsDealers({
      variables: {
        orgId,
        brandIds: brands.map((b) => b.id),
        nearPoint: {
          location: pick(point, ['lat', 'lng']) as Point,
          distance: 100,
        },
        active: dealerTypeFilter === 'active' ? true : null,
        saved: dealerTypeFilter === 'saved' ? true : null,
      },
    })

    const dealers = data?.dealerLocations.entries ?? []

    const brandsSummary = dealers
      .flatMap((dl) => dl.brands)
      .reduce(
        (acc, b) => ({
          ...acc,
          [b.id]: acc[b.id]
            ? { ...acc[b.id], count: (acc[b.id].count as number) + 1 }
            : { ...b, count: 1 },
        }),
        {}
      )

    return {
      id: uniqueId(),
      point,
      dealers,
      coverage: brands
        .map((b) => brandsSummary[b.id] ?? { ...b, count: 0 })
        .sort((a, b) => b.count - a.count),
    }
  }

  const fetchLocationsData = async (
    locations: NonNullable<MyLocationsQuery['org']>['locations'],
    brands: { id: string; name: string }[],
    dealerTypeFilter: string
  ) => {
    const data = await Promise.all(
      locations
        .filter((l) => !!l.address.point)
        .map((l) => fetchDealers(l.address.point as Point, brands, dealerTypeFilter))
    )

    setLocationsDealers(data)
  }

  const fetchAddressData = async (
    address: Address,
    brands: { id: string; name: string }[],
    dealerTypeFilter: string
  ) => {
    const { data: locationData } = await getAddressLocation({
      variables: { address: AddressM.toGraphQlAddressInput(address) },
    })

    const data = await fetchDealers(
      locationData?.addressLocation as Point,
      brands,
      dealerTypeFilter
    )

    setLocationsDealers([data])
  }

  useEffect(() => {
    setLocationsDealers([])
    const brands = fleetBrandsData?.fleetBrands ?? []
    switch (viewType.value) {
      case 'address':
        if (selectedAddress) {
          fetchAddressData(selectedAddress, brands, typeFilter.value)
        }

        break
      default:
        fetchLocationsData(locationsData?.org?.locations ?? [], brands, typeFilter.value)
    }
  }, [typeFilter, viewType, selectedAddress, locationsData, fleetBrandsData])

  if (!isLoaded) {
    return null
  }

  return (
    <div className="bg-white h-screen flex flex-col">
      <div className="p-4 flex gap-4 z-50">
        <Select options={typeFilters} value={typeFilter} onChange={(o) => o && setTypeFilter(o)} />

        <Select
          options={viewTypes}
          value={viewType}
          onChange={(o) => o && setViewType(o)}
          className="w-56"
        />

        {viewType.value === 'address' &&
          (selectedAddress ? (
            <p className="flex items-center">
              {selectedAddress.lineOne}, {selectedAddress.city} - {selectedAddress.state}{' '}
              {selectedAddress.postalCode}{' '}
              <button
                type="button"
                onClick={() => setSelectedAddress(null)}
                className="p-1.5 hover:bg-gray-50 rounded-full"
              >
                <XIcon className="w-5 h-5 inline-block" />
              </button>
            </p>
          ) : (
            <OneLineAddress
              value={selectedAddress}
              onAddressSelected={setSelectedAddress}
              onAddressNotFound={() => undefined}
              onAddressRemoved={() => setSelectedAddress(null)}
            />
          ))}
      </div>
      <div className="self-stretch flex-grow">
        <GoogleMap
          mapContainerClassName={classNames('w-full h-full')}
          center={defaultLocation?.address.point ?? US_CENTER}
          zoom={4}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {selectedlocation && (
            <InfoWindowF
              key={`${selectedlocation.id}-hh`}
              position={selectedlocation.point}
              onCloseClick={() => setSelectedLocation(null)}
            >
              <div className="max-w-96">
                <h4 className="font-medium pb-2">Coverage</h4>
                <ul>
                  {selectedlocation.coverage.map((b) => (
                    <li key={b.id} className={b.count > 0 ? 'text-green-500' : 'text-yellow-600'}>
                      {b.name} -{' '}
                      {b.count > 0 ? `${b.count} ${pluralize('dealer', b.count)}` : 'No dealers'}
                    </li>
                  ))}
                </ul>
              </div>
            </InfoWindowF>
          )}

          {selectedDealer && selectedDealer.point && (
            <InfoWindowF
              key={`${selectedDealer.id}-info`}
              position={selectedDealer.point as Point}
              onCloseClick={() => setSelectedDealer(null)}
            >
              <div className="max-w-96">
                <h4 className="font-medium pb-2">{selectedDealer.name}</h4>
                <div className="flex gap-x-2">
                  {selectedDealer.vendor ? (
                    <SlimBadge title="My Vendor" color="bg-orange-500" textColor="text-white" />
                  ) : null}

                  {selectedDealer.numOfQuotes && selectedDealer.numOfQuotes > 0 ? (
                    <SlimBadge title="Active on GF" color="bg-blue-600" textColor="text-white" />
                  ) : null}
                </div>
              </div>
            </InfoWindowF>
          )}

          {locationsDealers.map((l) => (
            <Fragment key={`${l.id}-ff`}>
              <MarkerF
                position={l.point}
                icon={ORANGE_PIN}
                onClick={() => setSelectedLocation(l)}
                zIndex={50}
              />

              {l.dealers.map((d) =>
                d.point ? (
                  <MarkerF
                    key={d.id}
                    position={d.point}
                    icon={GREEN_PIN}
                    onClick={() => setSelectedDealer(d)}
                  />
                ) : null
              )}
            </Fragment>
          ))}
        </GoogleMap>
      </div>
    </div>
  )
}

export default VendorsMap
