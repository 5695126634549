import Box from '@/gf/components/Box'
import FullScreenLayout from '../components/FullScreenLayout'
import Link from '@/gf/components/Link'
import Action from '@/gf/components/Action'
import pluralize from 'pluralize'
import useSession from '../hooks/useSession'
import {
  AccountBuilderDocument,
  OnboardingStep,
  useAccountBuilderQuery,
  useCompleteStepMutation,
  useRestartStepMutation,
} from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import { CheckIcon } from '@heroicons/react/solid'
import useToggle from '@/gf/hooks/useToggle'
import useMsgs from '@/gf/hooks/useMsgs'
import classNames from 'classnames'

const SetupCard = ({
  title,
  text,
  href,
  name,
  count,
  complete,
  onMarkComplete,
  onRestart,
  loading,
}: {
  title: string
  text: string
  href: string
  name: string
  count: number
  complete: boolean
  onMarkComplete: () => void
  onRestart: () => void
  loading: boolean
}) => (
  <Box
    className={classNames(
      'shadow-base rounded-xl p-6 flex flex-col sm:h-60',
      complete && 'bg-gray-50'
    )}
  >
    <hgroup className="space-y-1 flex-grow">
      <h3 className="text-xl font-medium">{title}</h3>
      <p className="text-base">{text}</p>
    </hgroup>
    <p className="font-medium text-sm pt-4 pb-3">
      {count} {pluralize(name, count)} added
    </p>
    <div className="flex justify-between items-center">
      {complete ? (
        <>
          <Action.S onClick={onRestart} className="font-medium" performing={loading}>
            Undo Complete
          </Action.S>
          <span className="text-gray-400 font-medium text-sm inline-flex items-center gap-x-2">
            <CheckIcon className="h-5 w-5 inline-block" /> Complete
          </span>
        </>
      ) : (
        <>
          <Link.P to={href} className="font-medium" color="blue">
            Continue
          </Link.P>

          <Action.T
            onClick={onMarkComplete}
            className="font-medium no-underline text-sm"
            disabled={loading}
          >
            Mark Complete
          </Action.T>
        </>
      )}
    </div>
  </Box>
)

const AccountBuilder = () => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [loading, loadingToggle] = useToggle()
  const [_, msgs] = useMsgs()
  const [restartMutation] = useRestartStepMutation({
    client,
    refetchQueries: [AccountBuilderDocument],
  })
  const [completeMutation] = useCompleteStepMutation({
    client,
    refetchQueries: [AccountBuilderDocument],
  })
  const { data } = useAccountBuilderQuery({ client, variables: { orgId } })

  if (!data || !data.org) {
    return null
  }

  const onMarkComplete = (step: OnboardingStep) => async () => {
    try {
      loadingToggle.on()
      await completeMutation({ variables: { orgId, step } })
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      loadingToggle.off()
    }
  }

  const onRestart = (step: OnboardingStep) => async () => {
    try {
      loadingToggle.on()
      await restartMutation({ variables: { orgId, step } })
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      loadingToggle.off()
    }
  }

  return (
    <FullScreenLayout topRight={<Link.S to="/">Close</Link.S>}>
      <div className="sm:w-165 mx-auto space-y-8 px-6">
        <h2 className="text-3xl font-medium">Account Builder</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <SetupCard
            title="Add Locations"
            text="This will automate your request flow and location based notifications."
            href="/locations"
            name="location"
            count={data.org.stats.shippingLocationCount}
            complete={!!data.org.onboardingCheckmarks.locationsCompletedAt}
            onMarkComplete={onMarkComplete(OnboardingStep.Locations)}
            onRestart={onRestart(OnboardingStep.Locations)}
            loading={loading}
          />

          <SetupCard
            title="Add Fleet Brands"
            text="This will streamline adding your vendors."
            href="/brands"
            name="brand"
            count={data.org.stats.brandCount}
            complete={!!data.org.onboardingCheckmarks.brandsCompletedAt}
            onMarkComplete={onMarkComplete(OnboardingStep.Brands)}
            onRestart={onRestart(OnboardingStep.Brands)}
            loading={loading}
          />

          <SetupCard
            title="Add Vendors"
            text="Adding vendors will automate your request flow."
            href="/vendors/new?selectLocation=1"
            name="vendor"
            count={data.org.stats.vendorCount}
            complete={!!data.org.onboardingCheckmarks.vendorsCompletedAt}
            onMarkComplete={onMarkComplete(OnboardingStep.Vendors)}
            onRestart={onRestart(OnboardingStep.Vendors)}
            loading={loading}
          />

          <SetupCard
            title="Invite Your Team"
            text="Gearflow works best when your parts purchasing managers and field technicians are all onboard."
            href="/settings/users"
            name="user"
            count={data.org.stats.userCount}
            complete={!!data.org.onboardingCheckmarks.usersCompletedAt}
            onMarkComplete={onMarkComplete(OnboardingStep.Team)}
            onRestart={onRestart(OnboardingStep.Team)}
            loading={loading}
          />
        </div>
      </div>
    </FullScreenLayout>
  )
}

export default AccountBuilder
