import { UserLocationsDocument as Query, useUserLocationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'
import { Maybe } from '@/types'
import { useMemo } from 'react'
import useSession from '../hooks/useSession'

export { Query }

const UserLocationsSelector = ({
  value,
  onChange,
  className,
}: {
  value: Maybe<string>
  onChange: (newValue: string) => void
  className?: string
}) => {
  const { orgId } = useSession()

  const { data, loading } = useUserLocationsQuery({
    variables: { orgId, search: null },
    client: useGqlClient(),
  })

  const locations = data?.org?.locations ?? []

  const options = useMemo(
    () =>
      locations
        .filter((l) => l.address?.point?.lat && l.address?.point?.lng)
        .sort((a, _b) => (a.defaultLocation ? -1 : 0)) // default location first
        .map((l) => ({
          label: `${l.name} ${l.defaultLocation ? `(default location)` : ''}`,
          value: l.id,
        })),
    [locations]
  )

  const selectedOption = useMemo(() => options.find((o) => o.value === value), [value, options])

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={(o) => o && onChange(o.value)}
      isLoading={loading}
      className={className}
      placeholder="Select location"
    />
  )
}

export default UserLocationsSelector
