import Ghost from '@/gf/components/Ghost'

const Loading = () => (
  <>
    {[1, 2, 3, 4, 5, 6].map((n) => (
      <span
        key={n}
        className="border rounded-xl shadow-base flex items-center justify-center h-[7.5rem]"
      >
        <Ghost className="block h-12 w-2/3 rounded-2xl" />
      </span>
    ))}
  </>
)

export default Loading
