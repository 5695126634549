import { useMemo } from 'react'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import useConfig from '@/gf/hooks/useConfig'

const SupplierAutocomplete = ({
  name,
  label,
  query,
  defaultValue,
  defaultOptions,
  allData,
  selected,
  onChange,
  transformation,
  reactSelectOptions = {},
  variables = {},
}: {
  name?: string
  label?: string
  query: any
  defaultValue?: any
  defaultOptions: any[]
  allData: any[]
  selected: any[]
  onChange: (value) => void
  transformation: (response) => void
  reactSelectOptions?: any
  variables?: any
}) => {
  const config = useConfig()

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: `${config.suppliersUrl}/gql`,
        cache: new InMemoryCache(),
        credentials: 'include',
        defaultOptions: { query: { fetchPolicy: 'no-cache' } },
      }),
    [config.suppliersUrl]
  )

  const runQuery = async (search) => {
    const response = await client.query({ query, variables: { ...variables, value: search } })

    if (!response.loading && search !== '') {
      return transformation(response)
    }

    return []
  }

  const getOptions = (search) => runQuery(search)
  const debouncedOptions = debounce(getOptions, 300, { leading: true })

  const selectedIds = selected.map((selection) => selection.value)
  const value = allData.find((o) => o.id === selectedIds)
  const defaultedValue = value || defaultValue

  return (
    <div className="w-full text-sm mb-1">
      {label && (
        <label className="mb-1 block text-sm text-gray-700" htmlFor={name}>
          {label}
        </label>
      )}
      <AsyncSelect
        onChange={onChange}
        defaultValue={defaultedValue}
        defaultOptions={defaultOptions}
        loadOptions={debouncedOptions}
        {...reactSelectOptions}
      />
    </div>
  )
}

export default SupplierAutocomplete
