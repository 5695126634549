import { useState } from 'react'
import pick from 'lodash/pick'
import nth from 'lodash/nth'

import { useUpdateBusinessDetailsMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

import type { Address } from '@/types'
import type { Org } from '../../Setup'

type Fields = {
  logoUrl: string | null
  name: string
  headquartersAddress: Address | null
}

type Errors = {
  logoUrl?: string[]
  name?: string[]
  headquartersAddress?: string[]
}

type MutationError = {
  graphQLErrors: { details?: Errors }[]
}

const useBusinessForm = (org: Org) => {
  const [update, { loading: saving }] = useUpdateBusinessDetailsMutation({ client: useGqlClient() })
  const location = nth(org.locations, 0)

  const headquartersEditable = !location

  const [fields, setFields] = useState<Fields>({
    logoUrl: org.logoUrl,
    name: org.name,
    headquartersAddress: location
      ? {
          ...location.address,
          firstName: null,
          lastName: null,
          companyName: null,
        }
      : null,
  })

  const [errors, setErrors] = useState<Errors>({})

  const updateFields = (updates: Partial<Fields>) => setFields((prev) => ({ ...prev, ...updates }))

  const save = (onOk?: () => void) => {
    setErrors({})

    const headquartersAddress =
      fields.headquartersAddress &&
      pick(fields.headquartersAddress, [
        'lineOne',
        'lineTwo',
        'city',
        'state',
        'postalCode',
        'country',
      ])

    update({
      variables: {
        orgId: org.id,
        ...pick(fields, ['logoUrl', 'name']),
        headquartersAddress,
        annualPartsSpend: null,
        headquartersPhoneNumber: null,
        machineCount: null,
        officeLocationCount: null,
        vendorCount: null,
      },
    })
      .then(() => {
        if (onOk) onOk()
      })
      .catch((err: MutationError) => {
        const gqlError = err.graphQLErrors[0]

        if (gqlError.details) setErrors(gqlError.details)
      })
  }

  return { fields, errors, saving, headquartersEditable, updateFields, save, submitDisabled: false }
}

export default useBusinessForm
