import { useState } from 'react'
import useGqlClient from '../../hooks/useGqlClient'
import { PreferredSetting, useSetVendorPreferredSettingMutation } from '../../_gen/gql'
import Tooltip from '@/gf/components/Tooltip'
import RadioInput from '@/gf/components/inputs/Radio'

const PreferredSettingForm = ({
  vendorId,
  preferredFor,
}: {
  vendorId: string
  preferredFor: PreferredSetting
}) => {
  const [setPreferredFor] = useSetVendorPreferredSettingMutation({ client: useGqlClient() })
  const [value, setValue] = useState(preferredFor)

  const onChange = async (newValue) => {
    setValue(newValue)

    await setPreferredFor({ variables: { vendorId, preferredFor: newValue } })
  }

  return (
    <form className="border-t pt-4 flex flex-col gap-y-2 mt-2" id="preference-settings">
      <div>
        <h5 className="font-medium text-slate-800 mb-1">Preferred Settings</h5>
        <p className="text-sm text-slate-500 leading-4">
          Automatically send parts requests to this vendor based on the make of the machine.
        </p>
      </div>

      <div className="flex gap-2 flex-col">
        <div className="flex gap-x-4 gap-y-2 flex-col">
          <label className="flex gap-x-2 items-center text-sm">
            <RadioInput
              checked={value === PreferredSetting.None}
              value={PreferredSetting.None}
              onChange={() => onChange(PreferredSetting.None)}
              id="preferredFor"
            />
            Not Preferred
          </label>

          <label className="flex gap-x-2 items-center text-sm">
            <RadioInput
              checked={value === PreferredSetting.Brands}
              value={PreferredSetting.Brands}
              onChange={() => onChange(PreferredSetting.Brands)}
              id="preferredFor"
            />
            Vendor Brands{' '}
            <Tooltip
              size="small"
              text="Automatically send part requests to this vendor for equipment brands they work with."
            />
          </label>

          <label className="flex gap-x-2 items-center text-sm">
            <RadioInput
              checked={value === PreferredSetting.All}
              value={PreferredSetting.All}
              onChange={() => onChange(PreferredSetting.All)}
              id="preferredFor"
            />
            All Equipment Brands{' '}
            <Tooltip
              size="small"
              text="Automatically send part requests to this vendor regardless of the equipment brand."
            />
          </label>
        </div>
      </div>
    </form>
  )
}

export default PreferredSettingForm
