import { ArrowCircleLeftIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import useConfig from '@/gf/hooks/useConfig'

import Id from '@/gf/modules/Id'

import A from '@/gf/components/A'
import { ReactNode } from 'react'

const BrokerWarning = ({ className, children }: { className?: string; children: ReactNode }) => (
  <div
    className={classNames(
      'px-4 py-3 flex flex-row gap-x-2 items-center rounded-md bg-yellow-50 border border-yellow-300 text-yellow-800 font-medium',
      className
    )}
  >
    <ArrowCircleLeftIcon className="w-5 h-5 inline-flex shrink-0 text-yellow-600" />
    {children}
  </div>
)

const Request = ({
  fulfillingRfqId,
  className,
}: {
  fulfillingRfqId: string
  className?: string
}) => {
  const config = useConfig()
  return (
    <BrokerWarning className={className}>
      <span>
        Fulfilling{' '}
        <A.T href={`${config.suppliersUrl}/rfqs/${fulfillingRfqId}`} target="_blank">
          Request {Id.shorten(fulfillingRfqId)}
        </A.T>
      </span>
    </BrokerWarning>
  )
}
BrokerWarning.Request = Request

export default BrokerWarning
