import { useContactAndDeliveryInformationQuery } from '@/buyers/_gen/gql'
import LocationField from '@/buyers/components/LocationField'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import Field from '@/gf/components/next/forms/Field'
import FieldError from '@/gf/components/next/forms/FieldError'
import PhoneInput from '@/gf/components/next/forms/PhoneInput'
import TextInput from '@/gf/components/next/forms/TextInput'
import Select from '@/gf/components/next/Select'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe, Point } from '@/types'
import { PlusIcon, XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { nth, uniq } from 'lodash'
import isBoolean from 'lodash/isBoolean'
import StartOverButton from '../../StartOverButton'
import StickyBottomBar from '../../StickyBottomBar'
import { CreateRequestState } from '../../types'
import DeliveryMethodSelector from './DeliveryMethodSelector'
import SelectedVendorCard from './SelectedVendorCard'
import useContactAndDeliveryMethodForm, { FormValues } from './useContactAndDeliveryMethodForm'

export type ContactAndDeliveryInformationSubmit = {
  locationId: string
  vendors: CreateRequestState['vendors']
}

const ContactAndDeliveryInformation = ({
  dealerLocationIds,
  locationId,
  nearbyReferencePoint,
  vendors,
  reset,
  onBackClicked,
  onSubmit,
  locationSelectorEnabled,
  orgMachineId,
  disabledContactIds,
  tutorial = false,
}: {
  dealerLocationIds: string[]
  locationId: Maybe<string>
  nearbyReferencePoint: Point
  vendors: CreateRequestState['vendors']
  reset?: () => void
  onBackClicked: () => void
  onSubmit: (values: ContactAndDeliveryInformationSubmit) => void
  locationSelectorEnabled: boolean
  orgMachineId: string | null
  disabledContactIds: string[]
  tutorial?: boolean
}) => {
  const { orgId } = useSession()

  const { org } =
    useContactAndDeliveryInformationQuery({ variables: { orgId }, client: useGqlClient() }).data ||
    {}

  const showFleetioId = org?.orgApps.some((oa) => oa.appId === 'fleetio') ?? false
  const showHcssId = org?.orgApps.some((oa) => oa.appId === 'hcss') ?? false
  const showAssetWorks = org?.orgApps.some((oa) => oa.appId === 'assetworks') ?? false
  const showExternalVendor = showHcssId || showAssetWorks

  const form = useContactAndDeliveryMethodForm(
    dealerLocationIds,
    locationId,
    nearbyReferencePoint,
    vendors,
    locationSelectorEnabled,
    orgMachineId,
    showFleetioId,
    showExternalVendor,
    disabledContactIds,
    tutorial
  )

  const formDeliveryMethods = uniq(
    form.values.dealerLocations.map(({ deliveryMethod }) => deliveryMethod)
  )

  const onFormSubmit = (values: FormValues) =>
    onSubmit({
      locationId: values.locationId,
      vendors: values.dealerLocations.map((dl) => ({
        vendorId: dl.vendorId,
        contactIds: dl.contactIds,
        deliveryMethod: dl.deliveryMethod,
        accountNumber: dl.accountNumber,
        fleetioId: dl.fleetioId,
      })),
    })

  return (
    <Box className="shadow-base p-8 space-y-6">
      <p className="text-lg">
        Confirm who you want to send the request to and how you want to receive your parts.
      </p>

      {locationSelectorEnabled && (
        <LocationField
          label="Drop-off Location"
          locationId={form.selectedLocation?.id || null}
          onLocationIdChange={form.onLocationIdChange}
          errors={form.errors}
        />
      )}

      <div className="max-w-4xl">
        {form.loading ? (
          <p>loading...</p>
        ) : (
          <div className="space-y-3">
            {form.dealerLocations.map((dl, i) => {
              const dlValue = form.values.dealerLocations?.[i]
              return (
                <Box key={dl.id} className="p-6 w-full flex flex-col sm:flex-row gap-y-6">
                  <div className="pr-0 xl:pr-6 w-full sm:w-1/2">
                    <SelectedVendorCard
                      name={dl.name}
                      logoUrl={dl.logoUrl}
                      address={
                        dl.cityState
                          ? {
                              city: dl.cityState.split(', ')[0],
                              state: dl.cityState.split(', ')[1],
                            }
                          : dl.address
                            ? { city: dl.address.city, state: dl.address.state }
                            : null
                      }
                    />
                  </div>

                  <div className="flex w-full sm:justify-center sm:w-1/2 gap-4 lg:gap-6">
                    {!dl.vendor ? (
                      <Field
                        label="Account Number"
                        error={form.errors.dealerLocations?.[i]?.accountNumber}
                        htmlFor="account-number"
                      >
                        <TextInput
                          value={form.values.dealerLocations?.[i]?.accountNumber ?? ''}
                          onChange={(e) => form.onAccountNumberChanged(i, e.target.value ?? '')}
                          id="account-number"
                        />
                      </Field>
                    ) : dl.vendor.accountNumbers.length > 1 ? (
                      <Field
                        label="Account Number"
                        error={form.errors.dealerLocations?.[i]?.accountNumber}
                      >
                        <Select
                          options={dl.vendor.accountNumbers.map((value) => ({
                            value,
                            label: value,
                          }))}
                          value={
                            form.values.dealerLocations?.[i]?.accountNumber
                              ? {
                                  value: form.values.dealerLocations?.[i]?.accountNumber,
                                  label: form.values.dealerLocations?.[i]?.accountNumber,
                                }
                              : null
                          }
                          onChange={(o) => o && form.onAccountNumberChanged(i, o.value)}
                          isSearchable={false}
                          isClearable={false}
                        />
                      </Field>
                    ) : null}

                    <div
                      className={classNames(
                        'flex gap-6 ',
                        nth(form.values.dealerLocations, i)?.contacts.some((c) => c.isNew) &&
                          'flex-col'
                      )}
                    >
                      <div className="text-sm flex-grow">
                        <h6 className="leading-5 mb-2">Send request to:</h6>

                        {dlValue.contacts.map((c, contactIndex) =>
                          c.isNew ? (
                            <div
                              key={c.id}
                              className={classNames(
                                'flex flex-col gap-y-3',
                                contactIndex > 0 && 'mt-4'
                              )}
                            >
                              <TextInput
                                placeholder="Enter name"
                                value={dlValue.contacts[contactIndex].contactName}
                                onChange={(e) =>
                                  form.onNewContactChanged(i, c.id, {
                                    ...c,
                                    contactName: e.target.value,
                                  })
                                }
                              />
                              <FieldError
                                error={
                                  form.errors.dealerLocations?.[i]?.contacts?.[contactIndex]
                                    ?.contactName
                                }
                              />
                              <PhoneInput
                                value={
                                  form.values.dealerLocations?.[i]?.contacts[contactIndex]
                                    .contactPhone
                                }
                                onChange={(contactPhone) =>
                                  form.onNewContactChanged(i, c.id, {
                                    ...c,
                                    contactPhone,
                                  })
                                }
                                placeholder="Phone number"
                              />
                              <FieldError
                                error={
                                  form.errors.dealerLocations?.[i]?.contacts?.[contactIndex]
                                    ?.contactPhone
                                }
                              />
                              <TextInput
                                value={dlValue.contacts[contactIndex].contactEmail}
                                onChange={(e) =>
                                  form.onNewContactChanged(i, c.id, {
                                    ...c,
                                    contactEmail: e.target.value,
                                  })
                                }
                                placeholder="Email address"
                              />
                              <FieldError
                                error={
                                  form.errors.dealerLocations?.[i]?.contacts?.[contactIndex]
                                    ?.contactEmail
                                }
                              />
                            </div>
                          ) : (
                            <label
                              key={c.id}
                              className={classNames(
                                'py-2 flex items-center gap-x-2',
                                disabledContactIds.includes(c.id) && 'text-gray-400'
                              )}
                            >
                              <Checkbox
                                checked={disabledContactIds.includes(c.id) || c.selected}
                                onChange={(e) => {
                                  form.onContactChecked(i, e.target.checked, contactIndex)
                                  GE.whenCreateRequestPath(() => GE.selectsVendorContact(c.id))
                                }}
                                disabled={disabledContactIds.includes(c.id)}
                                className="disabled:opacity-20"
                              />{' '}
                              {dl.vendor?.contacts
                                .filter((vc) => vc.preferred)
                                .find((vc) => vc.id === c.id) ? (
                                <span className="font-medium">
                                  {c.contactName ?? c.contactEmail}{' '}
                                  <span className="font-normal hidden xl:block">(preferred)</span>
                                </span>
                              ) : (
                                (c.contactName ?? c.contactEmail)
                              )}
                            </label>
                          )
                        )}

                        {typeof form.errors.dealerLocations?.[i]?.contacts === 'string' && (
                          <FieldError error={form.errors.dealerLocations?.[i].contacts as string} />
                        )}

                        <div className="flex flex-row items-center gap-x-4">
                          {dlValue.contacts.length > 1 &&
                            nth(dlValue.contacts, dlValue.contacts.length - 1)?.isNew && (
                              <Action.T
                                className="text-sm font-medium py-2 no-underline"
                                onClick={() => form.onRemoveContact(i)}
                              >
                                <XIcon className="text-blue-500 h-3 inline-block mr-1 -mt-0.5" />
                                Remove Contact
                              </Action.T>
                            )}

                          {!tutorial && (
                            <Action.T
                              className="text-sm font-medium py-2 no-underline"
                              onClick={() => {
                                form.onAddContact(i)
                                GE.whenCreateRequestPath(() =>
                                  GE.clicksButtonOnFlow(
                                    'add-vendor-contact',
                                    GE.UserFlow.CreateRequest
                                  )
                                )
                              }}
                            >
                              <PlusIcon className="text-blue-500 h-3 inline-block mr-1 -mt-0.5" />
                              Add Contact
                            </Action.T>
                          )}
                        </div>
                      </div>

                      {showFleetioId && !dl.vendor && (
                        <div className="text-sm">
                          <Field
                            label="Fleetio Vendor ID"
                            error={form.errors.dealerLocations?.[i]?.fleetioId}
                            htmlFor="fleetio-vendor-id"
                          >
                            <TextInput
                              value={form.values.dealerLocations?.[i]?.fleetioId ?? ''}
                              onChange={(e) => form.onFleetioIdChanged(i, e.target.valueAsNumber)}
                              type="number"
                              id="fleetio-vendor-id"
                              required
                            />
                          </Field>
                        </div>
                      )}

                      <div className="text-sm">
                        <h6 className="leading-5 mb-2">Get parts via:</h6>

                        <DeliveryMethodSelector
                          dealerLocationId={dl.id}
                          value={form.values.dealerLocations?.[i]?.deliveryMethod}
                          onChange={(deliveryMethod) => {
                            form.onDeliveryMethodChanged(i, deliveryMethod)
                            GE.whenCreateRequestPath(() => GE.selectsDeliveryMethod(deliveryMethod))
                          }}
                          showWillCallDistanceWarning={dl.distance ? dl.distance > 50 : false}
                          showAddressNotDeliverableWarning={
                            isBoolean(form.selectedLocation?.address?.deliverable)
                              ? !form.selectedLocation?.address?.deliverable
                              : false
                          }
                          tutorial={tutorial}
                          selectionAction={
                            form.dealerLocations.length > 1 &&
                            i === 0 &&
                            dlValue.deliveryMethod &&
                            (formDeliveryMethods.length !== 1 ||
                              formDeliveryMethods[0] !== dlValue.deliveryMethod) ? (
                              <div className="relative self-start">
                                <div className="absolute -top-2 left-2 p-0.5 bg-gray-200 shadow rounded-md flex flex-row items-center">
                                  <div className="z-0 absolute -left-1 w-4 h-4 bg-gray-200 rotate-45" />
                                  <div className="z-10">
                                    <Action.S
                                      className="whitespace-nowrap	"
                                      size="sm"
                                      onClick={() => {
                                        form.dealerLocations.forEach((_, index) => {
                                          if (dlValue.deliveryMethod)
                                            form.onDeliveryMethodChanged(
                                              index,
                                              dlValue.deliveryMethod
                                            )
                                        })
                                      }}
                                    >
                                      Use for all
                                    </Action.S>
                                  </div>
                                </div>
                              </div>
                            ) : undefined
                          }
                        />

                        <FieldError error={form.errors.dealerLocations?.[i]?.deliveryMethod} />
                      </div>
                    </div>
                  </div>
                </Box>
              )
            })}
          </div>
        )}
      </div>
      <StickyBottomBar>
        {reset && <StartOverButton reset={reset} />}

        <div className="flex-grow" />
        <Action.S onClick={onBackClicked}>Previous</Action.S>
        <Action.P color="blue" onClick={() => form.handleSubmit(onFormSubmit)}>
          Next
        </Action.P>
      </StickyBottomBar>
    </Box>
  )
}

export default ContactAndDeliveryInformation
