import classNames from 'classnames'
import { DateTime } from 'luxon'

import Time from '@/gf/modules/Time'
import Tooltip from '@/gf/components/Tooltip'

const TimeOpenPill = ({
  createdAt,
  closedAt,
  className,
  tooltipLeft,
}: {
  createdAt: DateTime
  closedAt?: DateTime | null
  className?: string
  tooltipLeft?: boolean
}) => (
  <Tooltip
    text={
      closedAt
        ? `Request was closed after ${Time.formatDiff(createdAt, closedAt)}`
        : `Request was created ${Time.formatDiff(createdAt)} ago`
    }
    left={tooltipLeft}
  >
    <div
      className={classNames(
        'flex justify-center items-center gap-1 text-base whitespace-nowrap cursor-default',
        className
      )}
    >
      <span>{Time.formatDiff(createdAt, closedAt)}</span>
    </div>
  </Tooltip>
)

export default TimeOpenPill
