import ProfileImage from '@/buyers/components/ProfileImage'
import Card from '@/gf/components/Card'
import ChangePassword from '../../components/ChangePassword'
import UpdateForm from './Profile/UpdateForm'

const Profile = () => (
  <div className="space-y-4 max-w-lg">
    <Card title="Personal Information">
      <Card.Section>
        <div className="flex gap-6 items-start">
          <UpdateForm />

          <div className="space-y-4 text-center">
            <ProfileImage size="lg" showEditAlways />
            <div>Profile Picture</div>
          </div>
        </div>
      </Card.Section>
    </Card>

    <ChangePassword />
  </div>
)

export default Profile
