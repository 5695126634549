import { useSetOrganizationNameMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { useState } from 'react'

const UpdateOrgDetailsModal = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) => {
  const { organization } = useSession()
  const reloadSession = useReloadSession()
  const [_msgs, msgsMgr] = useMsgs()
  const [name, setName] = useState<string>(organization.name)
  const client = useGqlClient()
  const [setOrganizationName] = useSetOrganizationNameMutation({ client })

  const onSubmit = async () => {
    try {
      await setOrganizationName({ variables: { id: organization.id, name } })

      reloadSession()
      onSuccess()

      onClose()
    } catch (err) {
      msgsMgr.add((err as Error)?.message ?? 'Something went wrong')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()} className="px-6 py-4 relative space-y-4">
        <h2 className="text-lg leading-7">Update Company</h2>
        <CloseModalButton onClick={onClose} className="absolute -top-2 right-3" />

        <Field label="Company Name">
          <TextInput value={name} onChange={(e) => setName(e.target.value)} required />
        </Field>

        <div className="flex justify-end">
          <Action.P onClick={onSubmit}>Save</Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default UpdateOrgDetailsModal
