import { useMemo, useState } from 'react'
import { useSearchBrandsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'

const BrandsSelector = ({
  value,
  onChange,
  placeholder,
  className,
}: {
  value: string[]
  onChange: (newValue: string[]) => void
  className?: string
  placeholder?: string
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const { data, loading } = useSearchBrandsQuery({
    client: useGqlClient(),
    variables: { searchTerm, ids: null },
  })

  const { data: selectedBrandsData, loading: loadingSelected } = useSearchBrandsQuery({
    client: useGqlClient(),
    variables: { searchTerm: null, ids: value },
    skip: value.length === 0,
  })

  const options = useMemo(
    () => (data?.brands ?? []).map((b) => ({ label: b.name, value: b.id })),
    [data?.brands]
  )

  const selectedOptions = useMemo(
    () => (selectedBrandsData?.brands ?? []).map((b) => ({ label: b.name, value: b.id })),
    [selectedBrandsData]
  )

  return (
    <Select
      isMulti
      isLoading={loading || loadingSelected}
      options={options}
      defaultValue={selectedOptions}
      backspaceRemovesValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      tabSelectsValue={false}
      onInputChange={(input) => setSearchTerm(input)}
      onChange={(opts) => onChange(opts.map((o) => o.value))}
      menuPortalTarget={document.body}
      className={className}
      placeholder={placeholder}
    />
  )
}

export default BrandsSelector
