import { LocationMarkerIcon } from '@heroicons/react/outline'

import { RequestQuery } from '@/buyers/_gen/gql'

type RequestForQuote = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

type StoreOrder = RequestForQuote['storeOrders'][number]
type Address = StoreOrder['store']['address']

const VendorDetails = ({
  name,
  address,
  distance,
  contactNames,
  children,
}: {
  name: string | null
  address: Address
  distance: number | null
  contactNames: string[]
  children?: React.ReactNode
}) => (
  <div className="flex flex-col w-full">
    <h5 className="font-bold text-gray-800">
      {name} <span className="text-sm font-medium">· {contactNames.join(', ')}</span>
    </h5>
    {address && (
      <p className="text-sm text-gray-500 leading-4 mt-1">
        {distance !== null && (
          <span className=" text-slate-900 font-semibold border-r pr-2 mr-2">
            <LocationMarkerIcon className="h-4 inline-block text-gearflow mb-1" />{' '}
            {distance.toFixed(0)} mi
          </span>
        )}

        {[
          address.lineOne,
          address.lineTwo,
          [address.city, address.state, address.postalCode].join(' '),
        ]
          .filter((s) => s && s.length > 0)
          .join(', ')}
      </p>
    )}
    {children}
  </div>
)

export default VendorDetails
