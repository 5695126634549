import useSession from '@/buyers/hooks/useSession'
import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import LoadingCard from '@/gf/components/LoadingCard'
import { useNavigate } from 'react-router-dom'
import { useFetchQualificationQuery } from '../../../_gen/gql'
import {
  CompleteApproveNetTerms,
  PendingApproveNetTerms,
} from '../../../components/ApproveNetTerms'
import useGqlClient from '../../../hooks/useGqlClient'

const Payment = () => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const navigate = useNavigate()
  const finish = () => navigate('/settings/billing')

  const qualificationQuery = useFetchQualificationQuery({
    variables: { orgId },
    client,
    fetchPolicy: 'no-cache',
  })

  const qualification = qualificationQuery?.data?.org?.qualification
  const qualificationUrl = qualification ? qualification.url : null
  const qualificationStatus = qualification ? qualification.status : null

  return qualificationQuery?.loading ? (
    <LoadingCard />
  ) : (
    <>
      {qualificationUrl && qualificationStatus === 'pending' ? (
        <Card>
          <Card.Section title="Bank Information">
            <div className="space-y-4">
              <div className="text-gray-700 prose">Why do we need bank account information?</div>
            </div>
            <div className="py-4">
              Invoices must be paid, either manually or automatically, via ACH from the bank account
              on file. This is a requirement by Balance to prevent late payments and fees.
            </div>
            <div className="py-2">
              For more information on Bank Account requirements, see section 3 in Balance&#39;s{' '}
              <A.T href="https://www.getbalance.com/legal/end-user-terms">End User Agreement</A.T>
            </div>
          </Card.Section>
          <Card.Section title="">
            <iframe
              title="bank information"
              className="w-full"
              src={qualificationUrl}
              height="770px"
              frameBorder="0"
              scrolling="no"
            />
          </Card.Section>
        </Card>
      ) : (
        <Card>
          <Card.Section title="">
            <div className="transform bg-white w-full border border-gray-200 flex items-center justify-center rounded-md transition-all ease-out duration-700">
              <div className="w-full relative px-2 py-2">
                <div>
                  <>
                    {qualification?.pending === false && qualification?.status === 'approved' ? (
                      <CompleteApproveNetTerms />
                    ) : (
                      <PendingApproveNetTerms />
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="flex gap-6 justify-end pt-4">
              <Action.P onClick={finish}>Finish</Action.P>
            </div>
          </Card.Section>
        </Card>
      )}
    </>
  )
}

export default Payment
