import classNames from 'classnames'
import { useEffect, useState } from 'react'
import useOrgMachineImages from '../../../hooks/useOrgMachineImages'

interface Props {
  orgMachineId: string
}

const Images: React.FC<Props> = ({ orgMachineId }) => {
  const { images, loading } = useOrgMachineImages({ id: orgMachineId })
  const [selectedImage, setSelectedImage] = useState('')

  useEffect(() => {
    if (images.length > 0 && !selectedImage) {
      setSelectedImage(images[0].url)
    }
  }, [selectedImage, images])

  if (loading || images.length === 0) {
    return null
  }

  return (
    <div>
      <h3 className="flex items-center font-black text-xl text-slate-600">Pictures</h3>
      <p className="text-sm text-slate-500">
        These are pictures that have been uploaded as part of part requests
      </p>

      {selectedImage && (
        <figure className="h-72 border border-slate-200 rounded-lg flex items-center justify-center mt-2">
          <img src={selectedImage} alt="Selected" className="h-full w-full object-contain" />
        </figure>
      )}

      <div className="flex mt-4">
        <div className="inline-flex flex-wrap gap-x-4 gap-y-4 p-1">
          {images.map(({ url }, i) => (
            <button
              key={url}
              type="button"
              className={classNames(
                'block flex-shrink-0 w-16 h-16 border hover:border-slate-300 hover:border-2 shadow overflow-hidden rounded transform hover:scale-110 duration-200',
                {
                  'border-gearflow scale-110 border-2': url === selectedImage,
                }
              )}
              onClick={() => setSelectedImage(url)}
            >
              <img
                src={url}
                alt={`Part request ${i + 1}`}
                className="h-full w-full inline-block object-cover"
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Images
