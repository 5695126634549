import { SsoLinkType, useRedeemOAuthCodeMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Spinner from '@/gf/components/Spinner'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'

const BaseOAuth = ({ linkType }: { linkType: SsoLinkType }) => {
  const {
    user: { id: userId },
  } = useSession()

  const navigate = useNavigate()
  const [query, setQuery] = useQueryParams({ code: StringParam, state: StringParam })
  const [redeemCode] = useRedeemOAuthCodeMutation({ client: useGqlClient() })
  const [code, setCode] = useState<string>()

  useEffect(() => {
    if (query.code) {
      setCode(query.code)
      setQuery({ code: undefined })
    } else {
      navigate('/', { replace: true })
    }
  }, [])

  const process = async () => {
    if (!code || !query.state) return
    await redeemCode({ variables: { userId, code, linkType } })
    navigate(query.state, { replace: true })
  }

  useEffect(() => {
    process()
  }, [code])

  return (
    <div className="p-8 flex items-center">
      <Spinner />
    </div>
  )
}

export default BaseOAuth
