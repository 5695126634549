import { MicrosoftContactsQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import DelayedSearchInput from '@/gf/components/inputs/DelayedSearch'
import Field from '@/gf/components/next/forms/Field'
import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { Contact } from '../MicrosoftContacts'
import Pager from './Pager'

type PaginatedContacts = NonNullable<
  NonNullable<MicrosoftContactsQuery['user']['microsoftLink']>['contactsLink']
>['paginatedContacts']

const MicrosoftContactsSelector = ({
  paginatedContacts,
  search,
  loading,
  onSelect,
  setVars,
}: {
  paginatedContacts: PaginatedContacts
  search: string | null
  loading: boolean
  onSelect: (contact: Contact) => void
  setVars: (vars: {
    previous: string | null
    current: string | null
    next: string | null
    search: string | null
  }) => void
}) => {
  const contacts =
    paginatedContacts.contacts
      .map((c) => ({
        id: c.id,
        name: c.displayName,
        emailAddress: c.emailAddresses[0].address,
        phone: c.businessPhones[0] || c.mobilePhone || c.homePhones[0],
      }))
      .filter((c): c is Contact => c.emailAddress !== null) || []

  return (
    <Field label="Microsoft Contact">
      <div className="space-y-2 text-sm">
        <div>
          <DelayedSearchInput
            value={search}
            setValue={(newSearch) =>
              setVars({
                previous: null,
                current: null,
                next: null,
                search: newSearch,
              })
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') e.preventDefault()
            }}
          />
        </div>

        <div className="divide-y h-56 overflow-auto border-1 rounded-md px-4 py-2">
          {contacts.map((contact) => (
            <div className="flex gap-2 justify-between items-center py-2" key={contact.id}>
              <span>
                {contact.name} ({contact.emailAddress})
              </span>

              <Action.T onClick={() => onSelect(contact)}>select</Action.T>
            </div>
          ))}

          {contacts.length === 0 && (
            <div className="italic text-gray-500 py-2">No contacts to display.</div>
          )}
        </div>

        <div className="flex items-center gap-1 justify-end">
          {loading && (
            <span className="animate-spin-slow border-t-white inline-block rounded-full border-2 border-gray-400 h-4 w-4" />
          )}

          <Pager
            onClick={() => setVars({ previous: null, current: null, next: null, search })}
            disabled={loading || !!search || !paginatedContacts.current}
            Icon={ChevronDoubleLeftIcon}
          />

          <Pager
            onClick={() =>
              setVars({
                previous: paginatedContacts.previous,
                current: paginatedContacts.current,
                next: null,
                search,
              })
            }
            disabled={loading || !!search || paginatedContacts.previous === null}
            Icon={ChevronLeftIcon}
          />

          <Pager
            onClick={() =>
              setVars({
                previous: null,
                current: paginatedContacts.current,
                next: paginatedContacts.next,
                search,
              })
            }
            disabled={loading || !!search || paginatedContacts.next === null}
            Icon={ChevronRightIcon}
          />
        </div>
      </div>
    </Field>
  )
}

export default MicrosoftContactsSelector
