import { LocationQuery, useStoreOrdersSearchLiteQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Link from '@/gf/components/Link'
import { LocationMarkerIcon, PlusIcon } from '@heroicons/react/outline'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { nth } from 'lodash'
import qs from 'query-string'
import IconZeroState from '../../components/IconZeroState'
import OrderCard from '../../components/OrderCard/OrderCard'
import RFQCard from '../../components/RFQCard/RFQCardNext'

const Activity = ({
  shippingLocationAddressId,
  rfqs = [],
  requestsLoading,
}: {
  shippingLocationAddressId: string
  rfqs: LocationQuery['searchRequests']['requests'] | undefined
  requestsLoading: boolean
}) => {
  const { orgId } = useSession()

  const { data: storeOrderData, loading: storeOrderLoading } = useStoreOrdersSearchLiteQuery({
    variables: {
      orgId,
      page: 1,
      filters: [['equals', 'shipping_location_address_id', shippingLocationAddressId]],
    },
    client: useGqlClient(),
  })

  const storeOrders = storeOrderData?.org?.paginatedStoreOrders.storeOrders || []
  const lastStoreOrder = storeOrders && (nth(storeOrders, 0) || null)

  const lastRfq = rfqs && (nth(rfqs, 0) || null)

  const noActivity = !lastStoreOrder && !lastRfq && !storeOrderLoading && !requestsLoading

  const rfqsPath = `/rfqs?${qs.stringify({ search: shippingLocationAddressId })}`

  const ordersPath = `/orders?search=${shippingLocationAddressId}`

  return noActivity ? (
    <IconZeroState
      title="No activity for this location"
      subtitle="Get started by requesting a quote for parts."
      icon={LocationMarkerIcon}
    >
      <Link.P
        to={`/rfqs/create?source.path=${encodeURIComponent(
          document.location.pathname
        )}&source.button=create-request`}
      >
        <PlusIcon className="inline-block h-4" /> Create Request
      </Link.P>
    </IconZeroState>
  ) : (
    <>
      <OrderCard loading={storeOrderLoading} storeOrder={lastStoreOrder} className="mt-6" />
      <RFQCard loading={requestsLoading} requestForQuote={lastRfq} className="mt-6" />

      <section className="border-t border-slate-100 mt-6  pt-4">
        <h3 className="text-xl text-slate-600 flex items-center">Location History</h3>
        <p className="text-slate-500 mt-1 prose leading-5">
          <InformationCircleIcon className="inline-block h-4 text-gearflow opacity-75" /> You can
          view a complete history of all <strong>Orders</strong> and <strong>Requests</strong>{' '}
          created for this location using the links below.
        </p>
        <div className="flex gap-x-4 mt-4 mb-2">
          <Link.T to={rfqsPath} title="See all requests for this location">
            All Requests
          </Link.T>

          <Link.T to={ordersPath} title="See all orders for this location">
            All Orders
          </Link.T>
        </div>
      </section>
    </>
  )
}

export default Activity
