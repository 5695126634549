import Action from '@/gf/components/Action'
import EnabledBadge from '@/gf/components/MachineOrg/EnabledBadge'
import OwnedBadge from '@/gf/components/MachineOrg/OwnedBadge'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'

interface Props {
  machineDetails: OrgMachineDetails
  onEditClick: () => void
}

const OrgMachineView: React.FC<Props> = ({ machineDetails, onEditClick }) => {
  const orgMachine = machineDetails.data?.orgMachine

  return (
    <div className="p-6 flex items-start sm:items-center justify-between gap-x-6 gap-y-4 flex-col sm:flex-row">
      <div className="flex items-center gap-x-2">
        {orgMachine?.machine?.logoUrl && (
          <img
            src={orgMachine.machine.logoUrl}
            alt=""
            className="block w-20 h-20 rounded-full overflow-hidden object-cover flex-shrink-0"
          />
        )}

        <hgroup className="min-w-0 truncate">
          <h2 className="text-xl font-medium text-ellipsis">
            {orgMachine?.machine.make} {orgMachine?.machine.model}
          </h2>
          <p className="text-sm font-medium text-gray-500">Unit #: {orgMachine?.name}</p>
          <div className="flex gap-2 items-start mt-3">
            <OwnedBadge owned={orgMachine?.owned || false} />
            <EnabledBadge enabled={orgMachine?.enabled || false} />
          </div>
        </hgroup>
      </div>

      <div className="text-sm text-gray-600">
        <p>Year: {orgMachine?.machine.year}</p>
        <p>Make: {orgMachine?.machine.make}</p>
        <p>Model: {orgMachine?.machine.model}</p>
        <p>Serial Number: {orgMachine?.serialNumber}</p>
        {orgMachine?.description && (
          <p className="max-w-96">Description: {orgMachine?.description}</p>
        )}
      </div>

      <div>
        <Action.T onClick={onEditClick}>Update</Action.T>
      </div>
    </div>
  )
}

export default OrgMachineView
