import { gql, InternalRefetchQueriesInclude, useMutation } from '@apollo/client'
import { RequestForQuotePayload } from '../../types'

export const UPDATE_RFQ_BUYER_NOTIFICATION_USERS = gql`
  mutation UpdateRfqBuyerNotificationUsers($rfqId: String!, $userIds: [String]!) {
    updateRfqBuyerNotificationUsers(rfqId: $rfqId, userIds: $userIds) {
      id
    }
  }
`

interface UpdateRfqBuyerNotificationUsersVars {
  rfqId: string
  userIds: string[]
}
interface UpdateRfqBuyerNotificationUsersData {
  updateRfqBuyerNotificationUsers: RequestForQuotePayload
}
const useUpdateRfqBuyerNotificationUsers = (refetchQueries?: InternalRefetchQueriesInclude) =>
  useMutation<UpdateRfqBuyerNotificationUsersData, UpdateRfqBuyerNotificationUsersVars>(
    UPDATE_RFQ_BUYER_NOTIFICATION_USERS,
    {
      refetchQueries,
    }
  )

useUpdateRfqBuyerNotificationUsers.Mutation = UPDATE_RFQ_BUYER_NOTIFICATION_USERS
export default useUpdateRfqBuyerNotificationUsers
