import {
  CogIcon,
  OfficeBuildingIcon,
  ShoppingCartIcon,
  UserGroupIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'

import { ConversationRowPartyType } from '../../types'

const ConversationRowIcon = ({ type }: { type: ConversationRowPartyType }) => (
  <div
    className={classNames('p-1 rounded-full', {
      'bg-orange-100 text-orange-900': type === 'admin',
      'bg-blue-100 text-blue-900': type === 'supplier',
      'bg-green-100 text-green-900': type === 'buyer' || type === 'internal_org',
    })}
  >
    {type === 'admin' ? (
      <CogIcon className="w-5 h-5" />
    ) : type === 'supplier' ? (
      <OfficeBuildingIcon className="w-5 h-5" />
    ) : type === 'buyer' ? (
      <ShoppingCartIcon className="w-5 h-5" />
    ) : type === 'internal_org' ? (
      <UserGroupIcon className="w-5 h-5" />
    ) : null}
  </div>
)

const User = ({ type, name }: { type: ConversationRowPartyType; name: string | null }) => (
  <div
    className={classNames(
      'rounded-full w-7 h-7 min-w-7 flex justify-center items-center text-base',
      {
        'bg-orange-100 text-orange-900': type === 'admin',
        'bg-blue-100 text-blue-900': type === 'supplier',
        'bg-green-100 text-green-900': type === 'buyer' || type === 'internal_org',
      }
    )}
  >
    {name ? name[0] : '?'}
  </div>
)

ConversationRowIcon.User = User
export default ConversationRowIcon
