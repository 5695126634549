import {
  SortByInput,
  SortOrder,
  StoreOrderState,
  StoreOrderStep,
  useStoreOrdersQuery,
  useStoreOrdersSearchCountQuery,
} from '@/buyers/_gen/gql'
import Frame from '@/buyers/components/Frame'
import StoreOrderStepBadge from '@/buyers/components/StoreOrderStepBadge'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import FilterDropdown from '@/gf/components/FilterDropdown'
import FilterTabs from '@/gf/components/FilterTabs'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import SearchInput from '@/gf/components/SearchInput'
import SortByHeaderButton from '@/gf/components/SortByHeaderButton'
import Spinner from '@/gf/components/Spinner'
import { TableV2 as Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import usePage from '@/gf/hooks/usePage'
import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import Money from '@/gf/modules/Money'
import Equals from '@/gf/modules/filters/Equals'
import Is from '@/gf/modules/filters/Is'
import True from '@/gf/modules/filters/True'
import { Maybe } from '@/types'
import { DateTime } from 'luxon'
import { BooleanParam, JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import useSession from '../hooks/useSession'

type StoreOrderStepStrings = keyof typeof StoreOrderStep

const ToggleButton = ({
  label,
  value,
  onToggle,
}: {
  label: string
  value: Maybe<boolean>
  onToggle: (newValue: boolean) => void
}) =>
  value ? (
    <Action.P size="sm" onClick={() => onToggle(false)}>
      {label}
    </Action.P>
  ) : (
    <Action.S size="sm" onClick={() => onToggle(true)}>
      {label}
    </Action.S>
  )

const ACTION_NEEDED_TEXT = 'Action Needed'
const now = DateTime.now()

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Orders', href: '/orders' }],
}

const useQueryParamsOpts = {
  search: withDefault(StringParam, ''),
  needsAttention: withDefault(BooleanParam, false),
  markReceived: withDefault(BooleanParam, false),
  tab: StringParam,
  step: StringParam,
  status: StringParam,
  sortBy: withDefault<SortByInput, SortByInput>(JsonParam, {
    field: 'inserted_at',
    order: SortOrder.Desc,
  }),
  'delivery-method': withDefault(StringParam, null),
}

const cols = 9

const StoreOrders = () => {
  const { orgId } = useSession()
  const [page, setPage] = usePage()
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)

  const actionNeededFilters = [True.build({ fieldId: 'action_needed' })]

  // Org filter added by default in the backend
  const filters = FormatFilters.andFilters(
    FilterSet.toApiFilters([
      ...(query.step
        ? [Is.build({ fieldId: 'step', selectedOption: query.step.toLowerCase() })]
        : []),
      ...(query['delivery-method']
        ? [Equals.build({ fieldId: 'delivery_method', text: query['delivery-method'] })]
        : []),
      ...(query.status
        ? [Equals.build({ fieldId: 'state', text: query.status.toLowerCase() })]
        : []),
      ...(query.tab === ACTION_NEEDED_TEXT ? actionNeededFilters : []),
      ...(query.markReceived ? [True.build({ fieldId: 'mark_as_received' })] : []),
      ...(query.needsAttention ? [True.build({ fieldId: 'needs_attention' })] : []),
    ])
  )

  const client = useGqlClient()

  const queryResult = useStoreOrdersQuery({
    variables: { orgId, page, filters, search: query.search, sortBy: query.sortBy },
    client,
  })

  const actionNeededCount = useStoreOrdersSearchCountQuery({
    variables: {
      orgId,
      filters: FormatFilters.andFilters(FilterSet.toApiFilters(actionNeededFilters)),
    },
    client,
  }).data?.org?.storeOrderCount

  const tabs = [
    { name: 'All', filters: [] },
    {
      name: ACTION_NEEDED_TEXT,
      filters: actionNeededFilters,
      notification: actionNeededCount,
    },
  ]

  const setTab = (tabName: string) => updateQuery({ tab: tabName })
  const onSearchChanged = (search: string) => {
    updateQuery({ search })
    setPage(1)
  }
  const onStepChanged = (step: Maybe<string>) => {
    updateQuery({ step })
    setPage(1)
  }

  const onStatusChanged = (status: Maybe<string>) => {
    updateQuery({ status })
    setPage(1)
  }

  const setSortBy = (newSortBy: SortByInput) => updateQuery({ sortBy: newSortBy })

  const storeOrders = queryResult.data?.org?.paginatedStoreOrders.storeOrders
  const pagination = queryResult.data?.org?.paginatedStoreOrders.pagination

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title="Orders"
        className="overflow-auto"
        actionsNext={[
          <Link.S key="log-order-action" to="/orders/create-external">
            Log Order
          </Link.S>,
        ]}
      >
        <div className="flex flex-col overflow-auto space-y-4 mt-4">
          <div>
            <FilterTabs
              tabs={tabs}
              currentTabName={query.tab || undefined}
              onTabSelect={(filterTab) => {
                setTab(filterTab.name)
                setPage(1)
              }}
            />

            <div className="p-4 lg:p-6 bg-gray-50 col-span-6 flex flex-col gap-y-4">
              <SearchInput
                value={query.search}
                onChange={onSearchChanged}
                placeholder="Search orders..."
              />

              <div className="flex gap-x-2 items-center">
                <label className="text-sm text-gray-500">Filter by:</label>
                <FilterDropdown
                  title="Request Status"
                  value={(query.step ?? null) as StoreOrderStepStrings | null}
                  onChange={onStepChanged}
                  steps={[
                    { label: 'All', value: null },
                    { label: 'Quoted', value: StoreOrderStep.Quoted.toString() },
                    { label: 'PO Sent', value: StoreOrderStep.PoSent.toString() },
                    { label: 'Fulfilling', value: StoreOrderStep.Fulfilling.toString() },
                    { label: 'Fulfilled', value: StoreOrderStep.Fulfilled.toString() },
                  ]}
                />

                <FilterDropdown
                  title="Order Status"
                  value={(query.status ?? null) as StoreOrderStepStrings | null}
                  onChange={onStatusChanged}
                  steps={[
                    { label: 'All', value: null },
                    { label: 'Canceled', value: StoreOrderState.Cancelled.toString() },
                    { label: 'Quote Denied', value: StoreOrderState.QuoteDenied.toString() },
                    { label: 'Open Quote', value: StoreOrderState.OpenQuote.toString() },
                    { label: 'PO Sent', value: StoreOrderState.Received.toString() },
                    { label: 'Processing', value: StoreOrderState.Processing.toString() },
                    { label: 'Ready for Pickup', value: StoreOrderState.ReadyForPickup.toString() },
                    {
                      label: 'Partially Shipped',
                      value: StoreOrderState.PartiallyShipped.toString(),
                    },
                    { label: 'Shipped', value: StoreOrderState.Shipped.toString() },
                    { label: 'Fulfilled', value: StoreOrderState.BuyerReceived.toString() },
                    { label: 'Refunded', value: StoreOrderState.Refunded.toString() },
                  ]}
                />

                <ToggleButton
                  label="Needs Attention"
                  value={query.needsAttention}
                  onToggle={(needsAttention) =>
                    updateQuery({ needsAttention: needsAttention === true ? true : undefined })
                  }
                />

                <ToggleButton
                  label="Mark as Received"
                  value={query.markReceived}
                  onToggle={(markReceived) =>
                    updateQuery({ markReceived: markReceived === true ? true : undefined })
                  }
                />

                <FilterDropdown
                  title="Delivery method"
                  value={query['delivery-method']}
                  onChange={(value) => updateQuery({ 'delivery-method': value || undefined })}
                  steps={[
                    { label: 'All', value: null },
                    { label: 'Will Call', value: '2' },
                    { label: 'Delivery', value: '1' },
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="shadow-sm border rounded-md flex flex-col overflow-auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <SortByHeaderButton
                      field="inserted_at"
                      display="Created"
                      sortBy={query.sortBy}
                      setSortBy={setSortBy}
                    />
                  </Th>
                  <Th>Order ID</Th>
                  <Th>
                    <SortByHeaderButton
                      field="purchase_order"
                      display="Purchase Order"
                      sortBy={query.sortBy}
                      setSortBy={setSortBy}
                    />
                  </Th>
                  <Th>Request ID</Th>
                  <Th>
                    <SortByHeaderButton
                      field="work_order"
                      display="Work Order"
                      sortBy={query.sortBy}
                      setSortBy={setSortBy}
                    />
                  </Th>
                  <Th>Status</Th>
                  <Th>Vendor</Th>
                  <Th>Cost</Th>
                </Tr>
              </Thead>
              <Tbody>
                {storeOrders?.map((storeOrder) => (
                  <Tr key={storeOrder.id}>
                    <Td>
                      <span className="block">
                        {storeOrder.insertedAt.year < now.year
                          ? storeOrder.insertedAt.toLocal().toFormat('D - h:mm a')
                          : storeOrder.insertedAt.toLocal().toFormat('M/d - h:mm a')}
                      </span>
                      {(storeOrder.order.requestForQuote?.creator ||
                        storeOrder.order.requestForQuote?.user) && (
                        <span className="block text-gray-400 italic text-xs">
                          {
                            (
                              storeOrder.order.requestForQuote?.creator ??
                              storeOrder.order.requestForQuote.user
                            )?.displayName
                          }
                        </span>
                      )}
                    </Td>
                    <Td>
                      <Link.T to={`/orders/${storeOrder.id}`}>{storeOrder.shortId}</Link.T>
                      <span className="block text-gray-400 italic text-xs">
                        {storeOrder.order.user?.displayName}
                      </span>
                    </Td>
                    <Td>{storeOrder.purchaseOrder}</Td>
                    <Td>
                      {storeOrder.order.requestForQuote && (
                        <Link.T to={`/rfqs/${storeOrder.order.requestForQuote.id}`}>
                          {storeOrder.order.requestForQuote.id.split('-')[0]}
                        </Link.T>
                      )}
                    </Td>
                    <Td>{storeOrder.order.requestForQuote?.workOrderNumber}</Td>
                    <Td>
                      <StoreOrderStepBadge step={storeOrder.step} />
                    </Td>
                    <Td>{storeOrder.store.name}</Td>
                    <Td>{Money.format(storeOrder.total)}</Td>
                  </Tr>
                ))}

                {!storeOrders ? (
                  <Tr>
                    <Td colSpan={cols}>
                      <Spinner />
                    </Td>
                  </Tr>
                ) : (
                  storeOrders.length === 0 && (
                    <Tr>
                      <Td colSpan={cols}>No orders found.</Td>
                    </Tr>
                  )
                )}
              </Tbody>
            </Table>
          </div>

          <PaginationC pagination={pagination} page={page} updatePage={setPage} />
        </div>
      </Page>
    </Frame>
  )
}

export default StoreOrders
