import useConfig from '@/gf/hooks/useConfig'
import { microsoftAuthUrl } from '@/gf/modules/Microsoft'

type Props = Pick<Parameters<typeof microsoftAuthUrl>[0], 'scopes'> & {
  redirectTo?: string
}

const useMicrosoftAuthUrl = ({ redirectTo, scopes }: Props) => {
  const config = useConfig()
  const { location } = window

  return microsoftAuthUrl({
    redirectUri: `${config.buyersUrl}/oauth/microsoft`,
    scopes,
    state: redirectTo === undefined ? location.pathname + location.search : redirectTo,
  })
}

export default useMicrosoftAuthUrl
