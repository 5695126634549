import UserLocationsSelector from '@/buyers/components/UserLocationsSelector'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import { useState } from 'react'

const UpdateLocationModal = ({
  open,
  onClose,
  locationId,
  onChange,
}: {
  open: boolean
  onClose: () => void
  locationId: string
  onChange: (locationId: string) => void
}) => {
  const [selected, setSelected] = useState<string>(locationId)

  const onConfirm = () => {
    onChange(selected)
    onClose()
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-6 py-4 relative space-y-4">
        <h2 className="text-lg leading-7">Update Location</h2>
        <CloseModalButton onClick={onClose} className="absolute -top-2 right-3" />

        <UserLocationsSelector value={selected} onChange={setSelected} />

        <div className="flex justify-end">
          <Action.P onClick={onConfirm}>Confirm</Action.P>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateLocationModal
