import { ApolloError } from '@apollo/client'
import { ExclamationIcon } from '@heroicons/react/outline'
import { FormEventHandler } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useNotificationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useNotificationsForm from '@/buyers/pages/Settings/Notifications/useForm'
import useMsgs from '@/gf/hooks/useMsgs'
import Phone from '@/gf/modules/Phone'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Desc from '@/gf/components/Desc'
import Link from '@/gf/components/Link'
import Spinner from '@/gf/components/Spinner'
import { TableWrapper } from '@/gf/components/Table'
import Locations from './Notifications/Locations'

import StuckRfqNotification from './Notifications/StuckRfqNotification'
import NotificationsTable from './Notifications/Table'

const Notifications = () => {
  const {
    user: { id: userId },
    orgId,
    organization,
  } = useSession()

  const userResult = useNotificationsQuery({
    variables: { id: userId, orgId },
    client: useGqlClient(),
  })

  const [_msgs, msgsMgr] = useMsgs()
  const user = userResult.data?.user
  const form = useNotificationsForm({ user })

  if (!user || !form.eventNotificationSettings) return <Spinner />

  const onFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    if (!user) return

    form.save(
      () => {
        msgsMgr.add('Updated notification settings', 'positive')
        userResult.refetch()
      },
      (err: ApolloError) => {
        msgsMgr.add(err.message, 'negative')
      }
    )
  }

  return (
    <form className="space-y-4" onSubmit={onFormSubmit}>
      <Card
        title="Notifications"
        primaryFooterAction={{
          id: 1,
          element: (
            <Action.P type="submit" performing={form.saving}>
              Save
            </Action.P>
          ),
        }}
      >
        <Card.Section>
          <Desc.List>
            <Desc.Row title="Email">
              <div className="flex gap-4 items-center">
                <span>{user.email}</span>
                <Link.T to="/settings/profile" className="text-sm">
                  Edit
                </Link.T>
              </div>
            </Desc.Row>
            <Desc.Row title="Phone number">
              <div className="flex gap-4 items-center">
                <span>
                  {user.phoneNumber ? (
                    Phone.format(user.phoneNumber)
                  ) : (
                    <span className="text-gray-500 italic text-sm">Not added.</span>
                  )}
                </span>
                <Link.T to="/settings/profile" className="text-sm">
                  Edit
                </Link.T>
                {!user.phoneNumber && user.smsEnabled && (
                  <RouterLink
                    className="px-2 py-1 flex items-center gap-1 rounded-md text-sm text-yellow-700 bg-yellow-50"
                    to="/settings/profile"
                  >
                    <ExclamationIcon className="w-6 h-6" />
                    <span>SMS notifications require a phone number</span>
                  </RouterLink>
                )}
              </div>
            </Desc.Row>
          </Desc.List>
        </Card.Section>

        {user.userRole?.name === 'admin' && organization.requestApproval && (
          <Card.Section>
            <StuckRfqNotification initialValue={user.notifyRfqStuckInbound} />
          </Card.Section>
        )}

        <Card.Section>
          <Locations user={user} onChange={userResult.refetch} />
        </Card.Section>
        <Card.Section innerClassName="flex flex-col gap-y-4">
          <div className="text-sm font-semibold">Notification Activities</div>
          <p className="prose">
            Choose what kinds of activities you&apos;d like to receive notifications for and whether
            you want to receive email notifications, SMS text notifications, or both. You can also
            choose your preference to get notifications for all Requests and Orders, or only your
            Requests and Orders.
          </p>
          {/* TODO: create a mobile-friendly version of this table (like the Create RFQ Parts List) */}
          <TableWrapper>
            <NotificationsTable form={form} />
          </TableWrapper>
        </Card.Section>
      </Card>
    </form>
  )
}

export default Notifications
