import { CheckCircleIcon } from '@heroicons/react/outline'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useCheckedOutQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useConfig from '@/gf/hooks/useConfig'
import { trackPurchase } from '@/gf/modules/Analytics'

import Frame from '@/buyers/components/Frame'
import A from '@/gf/components/A'
import Link from '@/gf/components/Link'

const RetailCheckoutComplete = () => {
  const [query, _] = useSearchParams()
  const orderId = query.get('order_id') as string
  const config = useConfig()

  const order = useCheckedOutQuery({ variables: { id: orderId }, client: useGqlClient() }).data
    ?.order

  useEffect(() => {
    if (order) trackPurchase(order)
  }, [!order])

  if (!order) return null

  return (
    <Frame>
      <div className="space-y-4 p-8">
        <div className="flex gap-4">
          <CheckCircleIcon className="w-10 h-10 text-gearflow shrink-0" />

          <h2 className="text-3xl tracking-tight font-bold text-gray-700">
            Order received &amp; sent for approval
          </h2>
        </div>

        <p className="text-xl prose">
          To make sure we got your order right, you will not be charged until the supplier approves
          your order. An email has been sent to{' '}
          <span className="font-bold">{order.user?.email}</span>. Please reach out if you have any
          questions.
        </p>

        <p className="text-xl prose">Thanks for choosing Gearflow ❤️</p>

        <ul className="space-y-2 text-xl">
          <li>
            <Link.T to="/orders">View my orders</Link.T>
          </li>

          <li>
            <Link.T to="/rfqs/create">Request a quote</Link.T>
          </li>

          <li>
            <A.T href={`${config.gfBaseUrl}/`}>Continue shopping</A.T>
          </li>
        </ul>
      </div>
    </Frame>
  )
}

export default RetailCheckoutComplete
