import { useSavedLocationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Box from '@/gf/components/Box'
import TextTag from '@/gf/components/next/TextTag'
import { ExclamationIcon } from '@heroicons/react/outline'
import { Org } from '../Setup'

const VendorsSummary = ({ org }: { org: Org }) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const location = org.locations[0] ?? null

  const { data } = useSavedLocationsQuery({ variables: { orgId }, client })
  const savedLocations = data?.dealerLocations.entries ?? []

  const brandsWithLocations = savedLocations.flatMap((dl) => dl.brands.map((dlb) => dlb.id))
  const missingBrands = org.brands.filter((b) => !brandsWithLocations.includes(b.id))

  return (
    <div className="flex gap-x-6 justify-center">
      <div className="flex-grow basis-1/5 max-w-[15.75rem]" />
      <div className="max-w-md flex-shrink-0 space-y-6">
        <hgroup className="space-y-2">
          <h2 className="text-2xl font-medium">Here’s what we’ve captured for {location.name}</h2>
          <p className="text-lg">
            You will be able to add more vendors to this shop, as well as more locations after
            setup.
          </p>
        </hgroup>

        <Box className="p-6 space-y-4">
          <div>
            <h3 className="text-2xl font-medium">{location.name}</h3>
            {missingBrands.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-2">
                {missingBrands.map((b) => (
                  <TextTag key={b.id} color="red">
                    <ExclamationIcon className="h-4 w-4 inline-block" /> Missing vendor for {b.name}
                  </TextTag>
                ))}
              </div>
            )}
          </div>
          <ul>
            {savedLocations.map((l) => (
              <li
                key={l.id}
                className="flex gap-x-4 py-6 border-b last:border-0 last:pb-4 first:pt-4"
              >
                <span className="block w-[3.125rem] h-[3.125rem] border rounded-full overflow-hidden flex-shrink-0">
                  {l.logoUrl && (
                    <img src={l.logoUrl} alt="" className="w-full h-full object-contain" />
                  )}
                </span>

                <span className="block">
                  <span className="block text-lg font-medium leading-8">{l.name}</span>

                  <span className="flex">
                    {l.address && (
                      <span className="block min-w-48">
                        {l.address.city}, {l.address.state}
                      </span>
                    )}
                    <span className="text-sm">{l.distance && `${l.distance.toFixed(0)} mi`}</span>
                  </span>

                  <span className="flex gap-2 mt-2 flex-wrap">
                    <TextTag color="green">Your Vendor</TextTag>
                    {l.brands.map((b) => (
                      <TextTag key={b.id}>{b.name}</TextTag>
                    ))}
                  </span>
                </span>
              </li>
            ))}
          </ul>
        </Box>
      </div>
      <div className="space-y-6 basis-1/5 max-w-[15.75rem] pt-10" />
    </div>
  )
}

export default VendorsSummary
