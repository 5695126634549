import { GraphQLError } from 'graphql'

import useMsgs from '@/gf/hooks/useMsgs'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useToggle from '@/gf/hooks/useToggle'
import { useReactivateUserMutation } from '@/buyers/_gen/gql'

import ModalForm from '@/gf/components/ModalForm'

import type { User } from '../Users'

const ReactivateModal = ({
  user,
  open,
  onClose,
  onReactivated,
}: {
  user: User | null
  open: boolean
  onClose: () => void
  onReactivated: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [deactivateUser] = useReactivateUserMutation({ client: useGqlClient() })

  const onSubmit = () => {
    if (!user) return

    spinner.on()
    deactivateUser({ variables: { userId: user.id } })
      .then(() => {
        msgr.add('User activated.', 'positive')
        onReactivated()
      })
      .catch((err: GraphQLError) => {
        if (err.message) msgr.add(err.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(spinner.off)
  }

  return (
    <ModalForm
      title="Reactivate User"
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      submitButtonText="Yes"
      submitButtonShowSpinner={spinnerLive}
      submitButtonDisabled={spinnerLive}
    >
      <div className="space-y-2">
        <p className="prose">
          Are you sure you want to reactivate <span className="font-bold">{user?.email}</span>?
        </p>
        <p className="prose">They will be able to access the organization.</p>
      </div>
    </ModalForm>
  )
}

export default ReactivateModal
