import React from 'react'
import { UserIcon, PencilAltIcon } from '@heroicons/react/solid'

import useUppy from '@/gf/hooks/useUppy'
import useGqlClient from '../hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useReloadSession from '../hooks/useReloadSession'
import useSession from '../hooks/useSession'
import useToggle from '@/gf/hooks/useToggle'

import { useUpdateProfileImageMutation } from '../_gen/gql'

import ModalForm from '@/gf/components/ModalForm'
import UppyFileInput from '@/gf/components/UppyFileInput'

interface File {
  id: string
  name: string
  extension: string
  type?: string
  data: string
}

const sizes = { md: '20', lg: '32' }
type Size = 'md' | 'lg'

const allowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png']

const imageBaseClassName = (size: Size) =>
  `h-${sizes[size]} w-${sizes[size]} rounded-full object-cover`

const ProfileImage = ({
  size = 'md',
  showEditAlways = false,
}: {
  size?: Size
  showEditAlways?: boolean
}) => {
  const [_, msgr] = useMsgs()
  const { user } = useSession()
  const reloadSession = useReloadSession()
  const [spinnerLive, spinnerToggle] = useToggle()
  const [uploadFile, setUploadFile] = React.useState<File>()
  const [updating, setUpdating] = React.useState(false)
  const [update] = useUpdateProfileImageMutation({ client: useGqlClient() })

  const uppy = useUppy({
    autoProceed: false,
    onFilesAdded: ([file]) => {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          const fileWithData: File = {
            id: file.id,
            name: file.name,
            extension: file.extension,
            type: file.type,
            data: e.target.result,
          }
          setUploadFile(fileWithData)
        }
      }
      reader.readAsDataURL(file.data)
    },
    allowedFileTypes,
    onComplete: ([file]) => {
      update({ variables: { url: file.url } }).then(() => {
        reloadSession()
        msgr.add('Profile image updated.', 'positive')
        setUpdating(false)
      })
    },
    maxNumberOfFiles: 1,
  })

  return (
    <div>
      <ModalForm
        title="Update Profile Image"
        open={updating}
        onClose={() => setUpdating(false)}
        onSubmit={(e) => {
          e.preventDefault()
          spinnerToggle.on()
          uppy.upload()
        }}
        submitButtonShowSpinner={spinnerLive}
      >
        <div className="mt-2 flex flex-col space-y-4">
          <UppyFileInput
            uppy={uppy}
            onFileInputError={(error) => msgr.add(error, 'negative')}
            allowedFileTypes={allowedFileTypes}
          />
          {uploadFile && (
            <div className="flex flex-col space-y-2 items-center">
              <p className="text-base text-gray-700">Preview</p>
              <img
                className={imageBaseClassName(size)}
                src={uploadFile.data}
                alt={uploadFile.name}
              />
            </div>
          )}
        </div>
      </ModalForm>
      <div className="relative group">
        {user.profileImageUrl ? (
          <img src={user.profileImageUrl} alt="Profile" className={imageBaseClassName(size)} />
        ) : (
          <UserIcon className={`h-${sizes[size]} w-${sizes[size]}`} />
        )}

        <button
          className={`bg-white drop-shadow-md p-2 absolute -top-2 -right-2 rounded-full ${
            !showEditAlways && user.profileImageUrl && `hidden group-hover:block`
          }`}
          type="button"
          onClick={() => setUpdating(true)}
        >
          <PencilAltIcon className="block w-5 h-5 text-gearflow hover:text-orange-700" />
        </button>
      </div>
    </div>
  )
}

export default ProfileImage
