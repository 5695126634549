import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import findIndex from 'lodash/findIndex'
import { v4 as uuid } from 'uuid'

import type { Money, Address } from '@/types'

import MoneyM from '@/gf/modules/Money'
import { useCreateManualOrderMutation, DeliveryMethod } from '@/buyers/_gen/gql'
import useMsgs from '@/gf/hooks/useMsgs'
import useGqlClient from '@/buyers/hooks/useGqlClient'

type Part = {
  id: string
  mpn: string
  rfqPartMpn: string
  rfqPartId: string | null
  name: string
  quantity: number | null
  unitPrice: Money | null
}

type Fields = {
  vendorId: string | null
  parts?: Part[]
  shippingCost: Money | null
  taxCost: Money | null
  quoteNumber: string
  purchaseOrder: string | null
  deliveryMethod: DeliveryMethod
  pickupAddress?: Address
}

type Errors = {
  vendorId?: string | null
  parts: {
    mpn?: string
    name?: string
    quantity?: string
    unitPrice?: string
  }[]
}

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const buildPart = (attrs?: Partial<Part>) => ({
  id: uuid(),
  mpn: '',
  rfqPartMpn: '',
  rfqPartId: null,
  name: '',
  quantity: 1,
  unitPrice: zeroPrice,
  ...(attrs || {}),
})

const useForm = ({ rfqId }: { rfqId: string }) => {
  const navigate = useNavigate()

  const [fields, setFields] = useState<Fields>({
    quoteNumber: '',
    shippingCost: zeroPrice,
    taxCost: zeroPrice,
    vendorId: null,
    purchaseOrder: null,
    deliveryMethod: DeliveryMethod.Pickup,
  })

  const [errors, setErrors] = useState<Errors>({ parts: [] })
  const [submitting, setSubmitting] = useState(false)
  const [createManualOrder] = useCreateManualOrderMutation({ client: useGqlClient() })
  const [_, msgr] = useMsgs()

  const updatePart = (id: string, updates: Partial<Part>) => {
    if (!fields.parts) return

    const index = findIndex(fields.parts, (p) => p.id === id)

    const parts = Object.assign([], fields.parts, {
      [index]: { ...fields.parts[index], ...updates },
    })

    setFields({ ...fields, parts })
  }

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  const addPart = () => {
    if (!fields.parts) return
    setFields({ ...fields, parts: [...fields.parts, buildPart()] })
  }

  const removePart = (id: string) => {
    if (!fields.parts) return
    setFields({ ...fields, parts: fields.parts.filter((p) => p.id !== id) })
  }

  const submit = () => {
    if (!fields.parts) return
    setSubmitting(true)

    const pickupAddress =
      fields.deliveryMethod === DeliveryMethod.Pickup && fields.pickupAddress
        ? {
            lineOne: fields.pickupAddress.lineOne,
            lineTwo: fields.pickupAddress.lineTwo,
            city: fields.pickupAddress.city,
            state: fields.pickupAddress.state,
            postalCode: fields.pickupAddress.postalCode,
            country: fields.pickupAddress.country,
          }
        : null

    createManualOrder({
      variables: {
        rfqId,
        parts: fields.parts.map((p) => ({
          mpn: p.mpn,
          name: p.name,
          quantity: p.quantity,
          unitPrice: p.unitPrice,
          rfqPartId: p.rfqPartId,
        })),
        vendorId: fields.vendorId,
        deliveryMethod: fields.deliveryMethod,
        purchaseOrder: fields.purchaseOrder,
        quoteNumber: fields.quoteNumber,
        shippingCost: fields.shippingCost,
        taxCost: fields.taxCost,
        pickupAddress,
      },
    })
      .then(() => {
        msgr.add('Order created.', 'positive')
        navigate(`/rfqs/${rfqId}/request`)
      })
      .catch((err) => {
        const gqlError:
          | {
              message: string
              details: {
                vendor_id?: string[]
                parts?: {
                  mpn?: string[]
                  name?: string[]
                  quantity?: string[]
                  unit_price?: string[]
                }[]
              }
            }
          | undefined = err.graphQLErrors[0]

        if (gqlError) {
          const partsErrors =
            gqlError.details.parts?.map((p) => ({
              mpn: p.mpn && p.mpn.join(' / '),
              name: p.name && p.name.join(' / '),
              quantity: p.quantity && p.quantity.join(' / '),
              unitPrice: p.unit_price && p.unit_price.join(' / '),
            })) || []

          setErrors({
            vendorId: gqlError.details.vendor_id?.join(' / '),
            parts: partsErrors,
          })
          msgr.add(gqlError.message, 'negative')
        } else msgr.addUnknownError()
      })
      .finally(() => setSubmitting(false))
  }

  return {
    fields,
    submitting,
    errors,
    updateFields,
    buildPart,
    addPart,
    removePart,
    updatePart,
    submit,
  }
}

export default useForm
