import { TrashIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import type { Org } from '../Integrations'

import { useDeleteWebhookMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import Time from '@/gf/modules/Time'

import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Link from '@/gf/components/Link'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import AddWebhook from './AddWebhook'

const Webhooks = ({
  org,
  onAdded,
  onDeleted,
}: {
  org?: Org
  onAdded: () => Promise<unknown>
  onDeleted: () => Promise<unknown>
}) => {
  const [_, msgr] = useMsgs()
  const client = useGqlClient()
  const [deleteWebhookMutation] = useDeleteWebhookMutation({ client })
  const [deletingId, setDeletingId] = useState<string | null>(null)

  if (!org) return null

  const deleteWebhook = (id: string) => {
    setDeletingId(id)

    deleteWebhookMutation({ variables: { id } })
      .then(() => {
        onDeleted().then(() => msgr.add('Webhook deleted.', 'positive'))
      })
      .catch(() => {
        msgr.addUnknownError()
      })
      .finally(() => setDeletingId(null))
  }

  return (
    <>
      <Routes>
        <Route path="add-webhook" element={<AddWebhook orgId={org.id} onAdded={onAdded} />} />
      </Routes>

      <Card title="Webhooks">
        <Card.Section>
          <div className="space-y-4">
            <Link.S to="add-webhook">Add Webhook</Link.S>

            <Table>
              <Thead>
                <tr>
                  <Th>URL</Th>
                  <Th>Added</Th>
                  <Th />
                </tr>
              </Thead>
              <Tbody>
                {org.webhooks.map((webhook) => (
                  <Tr key={webhook.id}>
                    <Td>{webhook.url}</Td>
                    <Td>{Time.formatDateTime(webhook.addedAt)}</Td>
                    <Td>
                      <Action.S
                        onClick={() => deleteWebhook(webhook.id)}
                        performing={deletingId === webhook.id}
                        size="sm"
                        className="text-gray-400 hover:text-red-700"
                      >
                        {deletingId !== webhook.id && <TrashIcon className="w-5 h-5" />}
                      </Action.S>
                    </Td>
                  </Tr>
                ))}

                {org.webhooks.length === 0 && (
                  <Tr>
                    <Td colSpan={3}>
                      <div className="text-gray-500 italic text-center">No webhooks added.</div>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </div>
        </Card.Section>
      </Card>
    </>
  )
}

export default Webhooks
