import { ModalSize, Point } from '../../types'
import useToggle from '../hooks/useToggle'
import Action from './Action'
import Modal from './Modal'
import PointC from './Point'
import SingleMarkerMap from './SingleMarkerMap'

const PointSelection = ({
  point = null,
  onChange,
  className,
}: {
  point?: Point | null
  onChange: (newPoint: Point) => void
  className?: string
}) => {
  const [modalOpen, mapModal] = useToggle()
  return (
    <>
      {point ? (
        <PointC point={point} className={className} onClick={mapModal.on} />
      ) : (
        <Action.T type="button" className={className} onClick={mapModal.on}>
          Click to drop a location pin
        </Action.T>
      )}

      <Modal
        title="Select a location"
        size={ModalSize.XL}
        open={modalOpen}
        onClose={mapModal.off}
        layer="2"
        footerAction={
          <Action.P type="button" onClick={mapModal.off}>
            Select
          </Action.P>
        }
      >
        <div className="pt-2 -mx-6 -mb-4">
          <SingleMarkerMap
            lat={point?.lat ?? null}
            lng={point?.lng ?? null}
            rounded={false}
            selectable
            onChange={onChange}
            zoom={14}
          />
        </div>
      </Modal>
    </>
  )
}

export default PointSelection
