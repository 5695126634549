import { LockClosedIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import { DateTime } from 'luxon'
import useSession from '@/buyers/hooks/useSession'

import Action from '@/gf/components/Action'
import FreeProTrialModal from './FreeProTrialModal'
import TrialExpiredModal from '@/gf/components/TrialExpiredModal'

const TrialButtons = () => {
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useState(false)
  return (
    <div className="flex items-center gap-2 px-5 py-3 bg-white rounded-full text-gray-600 border-x-1 border-b-1 border-gray-200 drop-shadow-sm">
      <FreeProTrialModal
        open={isFreeProTrialModalOpen}
        onClose={() => setIsFreeProTrialModalOpen(false)}
        onClaimed={() => {
          setIsFreeProTrialModalOpen(false)
          return Promise.resolve()
        }}
      />
      <LockClosedIcon className="w-6 h-6 flex-none" />
      <div>
        <Action.T onClick={() => setIsFreeProTrialModalOpen(true)}>
          Upgrade to Parts Hub Pro
        </Action.T>{' '}
        to access this feature.
      </div>
    </div>
  )
}

const UpgradeButtons = () => {
  const [expiredTrialModalOpen, setExpiredTrialModalOpen] = useState(false)
  return (
    <div className="flex items-center gap-2 px-5 py-3 bg-white rounded-full text-gray-600 border-x-1 border-b-1 border-gray-200 drop-shadow-sm">
      <TrialExpiredModal
        open={expiredTrialModalOpen}
        onClose={() => setExpiredTrialModalOpen(false)}
      />
      <LockClosedIcon className="w-6 h-6 flex-none" />
      <div>
        <Action.T onClick={() => setExpiredTrialModalOpen(true)}>Upgrade to Parts Hub Pro</Action.T>{' '}
        to access this feature.
      </div>
    </div>
  )
}

const NoAccess = () => {
  const { organization } = useSession()
  const inTrialPeriod = organization.buyerDashboardAccess === true
  const trialExpirationDate = organization.buyerDashboardAccessExpiresAt ?? undefined
  const expiresAt = DateTime.fromISO(`${trialExpirationDate}`).endOf('day')
  const { days } = expiresAt.diff(DateTime.now().endOf('day'), ['days'])
  const totalDays = days > 0.1 ? Math.ceil(days) : Math.round(days)
  const trialExpired = totalDays <= 0
  const hasExpiredTrial = inTrialPeriod && trialExpired

  if (inTrialPeriod && hasExpiredTrial) {
    return <UpgradeButtons />
  }

  return <TrialButtons />
}

export default NoAccess
