import { FilterType } from '../../types'

const filterTypes: FilterType[] = [
  {
    id: 'contains',
    display: 'contains',
  },
  {
    id: 'not_contains',
    display: 'does not contain',
  },
  {
    id: 'equals',
    display: 'equals',
  },
  {
    id: 'not_equals',
    display: 'does not equal',
  },
  {
    id: 'blank',
    display: 'is blank',
  },
  {
    id: 'not_blank',
    display: 'is not blank',
  },
  {
    id: 'date_time_in_range',
    display: 'between',
  },
  {
    id: 'true',
    display: 'is true',
  },
  {
    id: 'false',
    display: 'is false',
  },
  {
    id: 'not_unique',
    display: 'is not unique',
  },

  {
    id: 'too_long',
    display: 'is too long',
  },
  {
    id: 'is',
    display: 'is',
  },
  {
    id: 'is_not',
    display: 'is not',
  },
]

export default filterTypes
