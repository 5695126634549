import useMetrics from '../../Reporting/useMetrics'
import FulfillmentValue from './RequestFulfillment/FulfillmentValue'
import Timeline from './RequestFulfillment/Timeline'
import Section from './Section'
import useSession from '@/buyers/hooks/useSession'
import { Maybe } from '@/types'
import { isNumber } from 'lodash'
import Ghost from '@/gf/components/Ghost'

export const timeComparison = (
  value: Maybe<number> | undefined,
  previous: Maybe<number> | undefined
) => {
  if (!isNumber(value)) {
    return typeof value === 'undefined' ? undefined : null
  }

  if (!isNumber(previous)) {
    return {
      value,
      variation: typeof previous === 'undefined' ? undefined : null,
    } as DurationDataPoint
  }

  const diff = value - previous

  return {
    value,
    variation: diff,
  } as DurationDataPoint
}

export type DurationDataPoint = {
  value: number
  variation: Maybe<number> | undefined
}

export type DurationStep = {
  data: Maybe<DurationDataPoint> | undefined
  active?: boolean
  onClick?: () => void
}

const RequestFulfillment = ({
  aggregatedRequestMetrics,
  prevAggregatedRequestMetrics,
}: ReturnType<typeof useMetrics>) => {
  const { organization } = useSession()
  const lifespanDuration = timeComparison(
    typeof aggregatedRequestMetrics === 'undefined'
      ? undefined
      : (aggregatedRequestMetrics?.lifespanDuration?.median ?? null),
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : (prevAggregatedRequestMetrics?.lifespanDuration?.median ?? null)
  )

  const orderFulfillmentDuration = timeComparison(
    typeof aggregatedRequestMetrics === 'undefined'
      ? undefined
      : (aggregatedRequestMetrics?.orderFulfillmentDuration?.median ?? null),
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : (prevAggregatedRequestMetrics?.orderFulfillmentDuration?.median ?? null)
  )

  const quoteApprovalDuration = timeComparison(
    typeof aggregatedRequestMetrics === 'undefined'
      ? undefined
      : (aggregatedRequestMetrics?.quoteApprovalDuration?.median ?? null),
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : (prevAggregatedRequestMetrics?.quoteApprovalDuration?.median ?? null)
  )

  const responseDuration = timeComparison(
    typeof aggregatedRequestMetrics === 'undefined'
      ? undefined
      : (aggregatedRequestMetrics?.responseDuration?.median ?? null),
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : (prevAggregatedRequestMetrics?.responseDuration?.median ?? null)
  )

  const requestApprovalDuration = timeComparison(
    typeof aggregatedRequestMetrics === 'undefined'
      ? undefined
      : (aggregatedRequestMetrics?.requestApprovalDuration?.median ?? null),
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : (prevAggregatedRequestMetrics?.requestApprovalDuration?.median ?? null)
  )

  return (
    <Section className="flex flex-col lg:flex-row gap-y-4 gap-x-8">
      <div className="space-y-3">
        <Section.Title>Request Fulfillment Time</Section.Title>

        {typeof lifespanDuration === 'undefined' ? (
          <Ghost className="inline-block h-8 w-56" />
        ) : (
          <FulfillmentValue
            value={lifespanDuration?.value ?? null}
            variation={lifespanDuration?.variation ?? null}
          />
        )}
      </div>

      <div className="flex-grow">
        <Timeline
          showReviewStep={organization.requestApproval}
          reviewRequest={{ data: requestApprovalDuration }}
          receiveQuote={{ data: responseDuration }}
          reviewQuote={{ data: quoteApprovalDuration }}
          orderProcessing={{ data: orderFulfillmentDuration }}
          links
        />
      </div>
    </Section>
  )
}

export default RequestFulfillment
