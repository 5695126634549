import classNames from 'classnames'
import pluralize from 'pluralize'

import { Maybe, Money } from '@/types'

import MoneyM from '@/gf/modules/Money'

import Ghost from '@/gf/components/Ghost'
import Comparison from '@/gf/components/next/Comparison'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import { TooltipUnderlineText } from '@/gf/components/Tooltip'

export const NO_DATA_VALUE = 'No data'
const DEFAULT_FIXED_VALUE = 1

type ValueT = number | Money
export enum Unit {
  Percent = 0,
}
type UnitT = Unit | string

export const defaultFormatValue = <T extends ValueT>(
  value: T,
  valueToFixed: number,
  unit?: UnitT
) =>
  `${
    typeof value === 'number'
      ? (unit === Unit.Percent ? value * 100 : value).toFixed(valueToFixed)
      : MoneyM.format(value, { maximumFractionDigits: valueToFixed })
  }${unit === Unit.Percent ? '%' : unit ? ` ${pluralize(unit)}` : ''}`

interface ScorecardDataProps<T extends ValueT> {
  name: string
  tooltip?: string
  value: Maybe<T> | undefined
  fromValue?: Maybe<T>
  toFixed?: number
  unit?: UnitT
  formatValue?: (value: T) => string
  active?: boolean
  className?: string
  downIsGood?: boolean
}

// TODO: Figure out how to make this a list of generics rather than locking the array into one generic
export const ScorecardData = <T extends ValueT>({
  name,
  tooltip,
  value,
  fromValue,
  toFixed = DEFAULT_FIXED_VALUE,
  unit,
  formatValue,
  active,
  className,
  downIsGood = false,
}: ScorecardDataProps<T>) => {
  const nameClassName = classNames(
    'text-xs lg:text-sm font-medium text-left',
    active ? 'text-blue-700' : 'text-gray-900'
  )
  const format = formatValue ?? ((v: T) => defaultFormatValue(v, toFixed, unit))
  return (
    <div className={classNames('flex flex-col items-stretch gap-y-1', className)}>
      {tooltip ? (
        <Tooltip>
          <TooltipTrigger className="inline-flex shrink self-start">
            <TooltipUnderlineText className={nameClassName}>{name}</TooltipUnderlineText>
          </TooltipTrigger>
          <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
            {tooltip}x
          </TooltipContent>
        </Tooltip>
      ) : (
        <h5 className={nameClassName}>{name}</h5>
      )}
      <div className="flex flex-row flex-wrap justify-between items-end gap-x-4">
        {typeof value === 'undefined' ? (
          <Ghost className={classNames('w-20 h-8 inline-flex', active && 'bg-blue-200')} />
        ) : (
          <span
            className={classNames(
              'block text-xl lg:text-2xl font-medium',
              active ? 'text-blue-700' : 'text-gray-900'
            )}
          >
            {value === null ? NO_DATA_VALUE : format(value)}
          </span>
        )}
        {typeof fromValue === 'undefined' || typeof value === 'undefined' ? (
          <Ghost className={classNames('w-16 h-7 inline-block', active && 'bg-blue-200')} />
        ) : fromValue !== null && value !== null ? (
          <Comparison
            value={value}
            comparisonValue={fromValue}
            downIsGood={downIsGood}
            formatValue={format}
          />
        ) : null}
      </div>
    </div>
  )
}

const Scorecards = <T extends ValueT>({
  scorecards,
  className,
}: {
  scorecards: (ScorecardDataProps<T> & { onClick: () => void })[]
  className?: string
}) => (
  <div
    className={classNames(
      'flex flex-row border border-gray-300 divide-x divide-gray-300 rounded-lg',
      className
    )}
  >
    {scorecards.map((scorecard) => (
      <div
        key={scorecard.name}
        className={classNames(
          'p-4 flex grow first:rounded-l-lg last:rounded-r-lg cursor-pointer',
          scorecard.active
            ? 'z-10 bg-blue-50 outline outline-2 -outline-offset-1 outline-blue-500'
            : 'bg-white hover:bg-gray-100 transition duration-150'
        )}
        onClick={scorecard.onClick}
      >
        <ScorecardData className="flex grow" {...scorecard} />
      </div>
    ))}
  </div>
)

export default Scorecards
