import { motion } from 'framer-motion'

const AnimateShowIn = ({ children, visible }: { children: React.ReactNode; visible: boolean }) => (
  <motion.div
    animate={visible ? 'show' : 'hidden'}
    variants={{
      show: {
        opacity: 1,
        x: 0,
        display: 'block',
        transition: {
          duration: 0.3,
          type: 'spring',
          damping: 10,
          mass: 0.4,
        },
      },
      hidden: {
        opacity: 0,
        x: '-100%',
        display: 'none',
      },
    }}
  >
    {children}
  </motion.div>
)

export default AnimateShowIn
