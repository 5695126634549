import { Routes, Route } from 'react-router-dom'

import LimitedFrame from '@/buyers/components/LimitedFrame'
import Order from '@/buyers/pages/LimitedOrder'
import ActivateAccount from '@/buyers/pages/ActivateAccount'
import NotFound from '@/buyers/pages/LimitedNotFound'

const LimitedRouter = () => (
  <LimitedFrame>
    <Routes>
      <Route path="/orders/:storeOrderId/*" element={<Order />} />
      <Route path="/activate" element={<ActivateAccount />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </LimitedFrame>
)

export default LimitedRouter
