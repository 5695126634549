import { usePendingInvitesQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Link from '@/gf/components/Link'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { ApolloError, gql, useMutation } from '@apollo/client'

const DENY_REQUEST = gql`
  mutation DenyRequest($id: String!) {
    denyRequest(id: $id) {
      id
    }
  }
`

const PendingInvitesTable = ({ showAddButton }) => {
  const { orgId } = useSession()
  const [_, msgr] = useMsgs()

  const pendingInvitesResult = usePendingInvitesQuery({
    variables: { orgId },
    client: useGqlClient(),
  })

  const pendingInvites = pendingInvitesResult.data?.org?.pendingInvites
  const [sendDenyRequest] = useMutation(DENY_REQUEST)
  const [denySpinnerLive, denySpinner] = useToggle()

  if (!pendingInvites || pendingInvites.length === 0) return null

  const denyInvite = ({ id }) => {
    denySpinner.on()

    sendDenyRequest({ variables: { id } })
      .then(() => {
        pendingInvitesResult.refetch()

        return Promise.resolve().then(() => {
          msgr.add('Request denied.', 'positive')
        })
      })
      .catch((error: ApolloError) => msgr.add(error.message, 'negative'))
      .finally(() => denySpinner.off())
  }

  return (
    <Card title="Pending Invites">
      <Card.Section>
        <div className="overflow-x-auto rounded border border-gray-300">
          <Table>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {pendingInvites.map((invite) => (
                <Tr key={invite.id}>
                  <Td>{invite.user.name}</Td>
                  <Td>{invite.user.email}</Td>
                  <Td>{invite.user.phoneNumber}</Td>
                  <Td>
                    <div className="flex gap-2">
                      {showAddButton ? (
                        <Link.P size="sm" to={`/settings/users/invites/${invite.id}/add/details`}>
                          Add User
                        </Link.P>
                      ) : (
                        <SimpleTooltip
                          placement="bottom-start"
                          text="You have reached your license limit, please contact sales."
                          className="lg:max-w-56"
                        >
                          <Action.S size="sm" disabled>
                            Add User
                          </Action.S>
                        </SimpleTooltip>
                      )}
                      <Action.S
                        size="sm"
                        performing={denySpinnerLive}
                        onClick={() => denyInvite({ id: invite.id })}
                      >
                        Deny
                      </Action.S>
                    </div>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Card.Section>
    </Card>
  )
}

export default PendingInvitesTable
