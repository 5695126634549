import { useParams, useNavigate } from 'react-router-dom'

import CloseRequestModal from '@/buyers/components/CloseRequestModal'

const CloseRequest = () => {
  const { rfqId } = useParams() as { rfqId: string }
  const navigate = useNavigate()
  const close = () => navigate(`/rfqs/${rfqId}`)

  return <CloseRequestModal rfqId={rfqId} close={close} onOk={close} />
}

export default CloseRequest
