import type { PermissionSummary, Role } from '@/buyers/_gen/gql'
import Radio from '@/gf/components/inputs/Radio'
import RolePermissions from './RolePermissions'

const sortRoleValue = (role: Pick<Role, 'name'>) =>
  role.name === 'Admin' ? 3 : role.name === 'Purchaser' ? 1 : 0

export const sortRoles = (a: Pick<Role, 'name'>, b: Pick<Role, 'name'>) =>
  sortRoleValue(b) - sortRoleValue(a)

const RolePicker = ({
  roles,
  permissionSummaries,
  roleId,
  onChange,
}: {
  roles?: Pick<Role, 'id' | 'name'>[]
  permissionSummaries: Pick<PermissionSummary, 'title' | 'roles'>[] | undefined
  roleId: string | null
  onChange: (roleId: string | null) => void
}) => {
  const currentRole = roles?.find((r) => r.id === roleId)

  return (
    <div className="flex flex-wrap gap-4 sm:gap-16 text-base">
      <div className="space-y-2">
        {roles?.sort(sortRoles)?.map((role) => (
          <div className="flex items-center gap-2" key={role.id}>
            <Radio
              id={role.id}
              value={role.id}
              checked={roleId === role.id}
              onChange={({ target }) => onChange(target.checked ? role.id : null)}
            />

            <label htmlFor={role.id} className="block">
              {role.name}
            </label>
          </div>
        ))}
      </div>

      {currentRole && (
        <RolePermissions role={currentRole} permissionSummaries={permissionSummaries} />
      )}
    </div>
  )
}

export default RolePicker
