import ModalForm from '@/gf/components/ModalForm'
import PhoneInput from '@/gf/components/PhoneInput'
import TextField from '@/gf/components/TextField'
import GraphQL from '@/gf/modules/GraphQL'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useAddVendorContactMutation } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'

type SavedContact = {
  vendorContactId: string
  vendorId: string
}

interface Props {
  vendorId: string
  open: boolean
  onClose: () => void
  onAfterSave: (savedContact: SavedContact) => void
}

interface ContactForm {
  name: string
  email: string
  phoneNumber: string
}

function stopPropagate(callback: () => void) {
  return (e: { stopPropagation: () => void; preventDefault: () => void }) => {
    e.stopPropagation()
    callback()
    e.preventDefault()
  }
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email().required('Email is required'),
  phoneNumber: yup.string().required('Phone Number is required'),
})

const AddContactModal = ({ vendorId, open, onClose, onAfterSave }: Props) => {
  const [addContact, { loading }] = useAddVendorContactMutation({ client: useGqlClient() })
  const [errors, setErrors] = useState<string[]>([])

  const form = useForm<ContactForm>({
    shouldUnregister: true,
    defaultValues: {
      name: '',
      email: '',
      phoneNumber: '',
    },

    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async ({ name, email, phoneNumber }: ContactForm) => {
    try {
      setErrors([])

      const result = await addContact({
        variables: {
          vendorId,
          name,
          email,
          phoneNumber,
        },
      })

      const vendorContactId = result.data?.addVendorContact?.id
      if (!vendorContactId) {
        return
      }

      onClose()
      onAfterSave({ vendorContactId, vendorId })
    } catch (err) {
      const validationErrors = GraphQL.parseErrorList(err)
      if (validationErrors.length > 0) {
        setErrors(validationErrors)
      } else {
        console.error(err)
      }
    }
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      onSubmit={stopPropagate(form.handleSubmit(onSubmit))}
      title="New Contact"
      submitButtonShowSpinner={loading}
      submitButtonDisabled={loading}
      submitButtonText="Save"
    >
      <div className="flex flex-col gap-y-2">
        <TextField
          autoFocus
          label="Name"
          errors={form.formState.errors.name?.message}
          {...form.register('name')}
        />
        <TextField
          label="Email"
          errors={form.formState.errors.email?.message}
          {...form.register('email')}
        />

        <Controller
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <PhoneInput
              value={field.value}
              onChange={field.onChange}
              label="Phone Number"
              errors={form.formState.errors.phoneNumber?.message}
            />
          )}
        />

        {errors.length > 0 && (
          <ul>
            {errors.map((err) => (
              <li key={err} className="text-sm text-rose-500">
                {err}
              </li>
            ))}
          </ul>
        )}
      </div>
    </ModalForm>
  )
}

export default AddContactModal
