import { PencilIcon, PlusCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { GraphQLError } from 'graphql'
import pluralize from 'pluralize'
import React from 'react'

import { RequestForQuote, RequestForQuoteStep } from '@/buyers/_gen/gql'
import { PartRequest, ShowMore } from '../../types'

import Action from '@/gf/components/Action'
import Checkbox from '@/gf/components/Checkbox'
import LinkButton from '@/gf/components/LinkButtonOld'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { UNKNOWN_ERROR } from '@/gf/providers/MsgsProvider'
import useDeletePartRequests from '../hooks/useDeletePartRequests'
import AddPartModal from './AddPartModal'
import EditPartModal from './EditPartModal'

const Description = ({ text }: { text: string }) => (
  <span className={classNames('text-sm', !text && 'text-slate-400 italic')}>
    {text || 'No description provided.'}
  </span>
)

const PartRequestsTableV2 = ({
  rfq,
  onChange,
  viewOnly = false,
  maxRows,
  showMore = ShowMore.None,
  onShowMoreChange,
}: {
  rfq: Pick<RequestForQuote, 'id' | 'step' | 'partRequests'>
  onChange?: () => void
  viewOnly?: boolean
  maxRows?: number | undefined
  showMore?: ShowMore
  onShowMoreChange?: (newShowMore: ShowMore) => void
}) => {
  const [_, msgr] = useMsgs()
  const [selection, setSelection] = React.useState<string[]>([])
  const [deleting, setDeleting] = React.useState(false)
  const check = (id: string) => setSelection([...selection, id])
  const unCheck = (id: string) => setSelection(selection.filter((sid) => sid !== id))
  const deletePartRequests = useDeletePartRequests()
  const [adding, addingToggler] = useToggle(false)
  const [editingPartRequest, setEditingPartRequest] = React.useState<PartRequest>()

  const onDelete = () => {
    if (deleting) return
    setDeleting(true)

    deletePartRequests({ rfqId: rfq.id, partRequestIds: selection })
      .then(() => {
        setSelection([])
        if (onChange) onChange()
        msgr.add('Part(s) deleted.', 'positive')
      })
      .catch((err: GraphQLError) => {
        msgr.add(err.message || UNKNOWN_ERROR, 'negative')
      })
      .finally(() => setDeleting(false))
  }

  const onAdded = () => {
    if (onChange) onChange()
    addingToggler.off()
  }

  const onEdited = () => {
    if (onChange) onChange()
    setEditingPartRequest(undefined)
  }

  const canChange = !viewOnly && rfq.step === RequestForQuoteStep.Submitted
  const canAdd = canChange
  const canEdit = canChange
  const canDelete = canChange && rfq.partRequests.length > 1

  return (
    <>
      <AddPartModal rfqId={rfq.id} open={adding} onClose={addingToggler.off} onAdded={onAdded} />
      <EditPartModal
        partRequest={editingPartRequest}
        onClose={() => setEditingPartRequest(undefined)}
        onEdited={onEdited}
      />
      <div className="space-y-2">
        {selection.length > 0 && rfq.partRequests.length !== selection.length && (
          <div className="flex gap-2 items-center text-sm">
            <div>{selection.length.toLocaleString()} selected:</div>
            <Action.S onClick={onDelete} size="sm" disabled={deleting}>
              {deleting ? <>Deleting&hellip;</> : <>Delete</>}
            </Action.S>
          </div>
        )}

        <div className="border rounded-lg">
          <table className="table-auto border-collapse w-full">
            <thead className="hidden md:table-header-group">
              <tr className="border-b text-sm">
                {canDelete && <th aria-label="Select to Delete" />}
                <th className="text-left leading-4 align-middle font-medium text-slate-600 px-2 py-2">
                  Part Number
                </th>
                <th className="text-left align-middle font-medium text-slate-600 px-2 py-2 hidden md:table-cell">
                  Description
                </th>
                <th className="text-left align-middle font-medium text-slate-600 px-2 py-2 hidden md:table-cell">
                  Quantity
                </th>
                {canEdit && <th aria-label="Click to Edit" />}
              </tr>
            </thead>
            <tbody>
              {rfq.partRequests
                .slice(0, typeof maxRows === 'undefined' ? undefined : maxRows)
                .map((pr) => (
                  <tr key={pr.id} className="border-b last:border-0">
                    {canDelete && (
                      <td className="pl-2 align-middle py-1.5">
                        <Checkbox
                          checked={selection.includes(pr.id)}
                          setChecked={(checked) => (checked ? check(pr.id) : unCheck(pr.id))}
                        />
                      </td>
                    )}

                    <td className="text-left md:font-medium px-2 py-1.5 align-middle">
                      <span className="hidden md:inline-block">{pr.mpn}</span>
                      <div className="md:hidden flex items-center">
                        <span className="flex-grow flex flex-col">
                          <span className="font-medium">
                            <span className="text-slate-600">Part #:</span> {pr.mpn}
                          </span>
                          <Description text={pr.description} />
                        </span>
                        <span>
                          {pr.quantity} {pluralize('item', pr.quantity)}
                        </span>
                      </div>
                    </td>
                    <td className="text-left px-2 py-1.5 align-middle hidden md:table-cell">
                      <Description text={pr.description} />
                    </td>

                    <td className="px-2 py-1.5 align-middle font-medium hidden md:table-cell">
                      {pr.quantity} {pluralize('item', pr.quantity)}
                    </td>

                    {canEdit && (
                      <td className="align-middle px-2 w-20 text-center">
                        <LinkButton onClick={() => setEditingPartRequest(pr)}>
                          <div className="flex items-center gap-1">
                            <PencilIcon className="h-5 w-5" />
                            <span>Edit</span>
                          </div>
                        </LinkButton>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          {rfq.partRequests.length === 0 && (
            <p className="text-sm text-gray-500 text-center italic py-6">There are no parts.</p>
          )}

          {showMore !== 'none' && (
            <div className="text-sm !text-gray-700 border-t px-2 py-2">
              <LinkButton
                onClick={
                  onShowMoreChange
                    ? () =>
                        onShowMoreChange(
                          showMore === ShowMore.Hidden ? ShowMore.Visible : ShowMore.Hidden
                        )
                    : undefined
                }
              >
                {showMore === ShowMore.Hidden ? '...Show more' : 'Show less'}
              </LinkButton>
            </div>
          )}
          {canAdd && (
            <div className="p-2 border-t">
              <LinkButton onClick={addingToggler.on}>
                <div className="flex items-center gap-1">
                  <PlusCircleIcon className="w-5 h-5" />
                  <span>Add part</span>
                </div>
              </LinkButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PartRequestsTableV2
