import { Machine, OrgMachine } from '@/buyers/_gen/gql'

type OrgMachineT = Pick<OrgMachine, 'id' | 'name'> & {
  machine: Pick<Machine, 'make' | 'model' | 'year'>
}

export const getOrgMachineName = (orgMachine: OrgMachineT) =>
  `${[orgMachine.machine.make, orgMachine.machine.model].filter((v) => !!v).join(' ')}${
    orgMachine.machine.year ? `, ${orgMachine.machine.year}` : ''
  }`
