import { useParams } from 'react-router-dom'

import Frame from '@/buyers/components/Frame'
import Content from '@/buyers/components/LimitedFrame/Content'
import LimitedOrder from './LimitedOrder'
import useSession from '../hooks/useSession'
import { useLimitedOrderQuery } from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import { OrderTutorialBanner } from '../components/TutoriaBanners'

const Order = () => {
  const client = useGqlClient()
  const { storeOrderId } = useParams<{ storeOrderId: string }>() as { storeOrderId: string }

  const {
    user: { id: userId },
    orgId,
  } = useSession()

  // TODO: Figure out a way to remove this query
  // right now we need to know if the order was created by the tutorial here in the frame level
  const queryResult = useLimitedOrderQuery({
    variables: { orgId, userId, storeOrderFilter: JSON.stringify(['id_eq', storeOrderId]) },
    client,
  })

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'Orders', href: '/orders' },
      { name: 'Order details', href: `/orders/${storeOrderId}` },
    ],
  }

  return (
    <Frame
      breadcrumbs={breadcrumbs}
      contentClassName="flex flex-col"
      topBanner={
        queryResult.data?.storeOrder?.createdByTutorial ? <OrderTutorialBanner /> : undefined
      }
    >
      <Content className="flex flex-col grow">
        {queryResult !== undefined && <LimitedOrder />}
      </Content>
    </Frame>
  )
}

export default Order
