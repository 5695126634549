import { Fragment, useMemo } from 'react'

import type { StoreOrder } from '@/buyers/pages/StoreOrder/Messages'
import type { DetailsForConversationProps, NewConversationStore } from '@/types'

import {
  OrgMachine as OrgMachineT,
  RequestForQuote as RequestForQuoteT,
  RequestQuery,
  Store as StoreT,
} from '@/buyers/_gen/gql'
import useToggle from '@/gf/hooks/useToggle'
import ConversationM from '@/gf/modules/Conversation'
import Money from '@/gf/modules/Money'
import useConversationMembers from '../hooks/useConversationMembers'

import Button from '@/gf/components/ButtonOld'
import Ghost from '@/gf/components/Ghost'
import Link from '@/gf/components/Link'
import RedAlert from '@/gf/components/RedAlert'
import ReturnsAndWarrantyInfoModal from '@/gf/components/ReturnsAndWarrantyInfoModal'
import ShippingInfoModal from '@/gf/components/ShippingInfoModal'
import StoreOrderSection from './InboxDetails/StoreOrderSection'

const ConversationMembersSection = ({
  conversationId,
  conversationRfqId,
  conversationStoreOrderId,
}: {
  conversationId: string | undefined
  conversationRfqId: string | undefined
  conversationStoreOrderId: string | undefined
}) => {
  const { data, error } = useConversationMembers({
    conversationId,
    conversationRfqId,
    conversationStoreOrderId,
  })
  return (
    <>
      <div>
        <p className="mb-0.5 text-base font-medium">Conversation Members</p>
        <p className="mb-3 text-xs text-gray-500">
          These people will receive notifications for new messages.
        </p>
        {error ? (
          <RedAlert title="Error loading Conversation Members" />
        ) : !data ? (
          <>
            <Ghost className="block w-32 h-6 bg-gray-200" />
            <Ghost className="mt-1 block w-32 h-6 bg-gray-200" />
          </>
        ) : (
          data.map(({ id, displayName }) => (
            <p key={id} className="text-sm text-gray-700">
              {displayName}
            </p>
          ))
        )}
      </div>
      <div className="border-b border-gray-300" />
    </>
  )
}

const AdminSection = () => (
  <>
    <div>
      <p className="mb-2 text-base font-medium">{ConversationM.ADMIN_CONVERSATION_NAME}</p>
      <p className="text-sm text-gray-700">
        This is a private conversation between your organization and Gearflow.
      </p>
    </div>
    <div className="border-b border-gray-300" />
  </>
)

const StoreSection = ({
  store,
}: {
  store: Pick<StoreT, 'name' | 'shippingInfo' | 'returnPolicy'> | NewConversationStore
}) => {
  const [returnsInfoModalOpen, returnsInfoModalToggler] = useToggle()
  const [shippingInfoModalOpen, shippingInfoModalToggler] = useToggle()
  return (
    <div>
      <p className="mb-2 text-base font-medium">Vendor</p>
      <ReturnsAndWarrantyInfoModal
        open={returnsInfoModalOpen}
        onClose={returnsInfoModalToggler.off}
        returnsAndWarrantyInfo={store.returnPolicy || ''}
      />
      <ShippingInfoModal
        open={shippingInfoModalOpen}
        onClose={shippingInfoModalToggler.off}
        shippingInfo={store.shippingInfo || ''}
      />
      <p className="text-sm text-gray-700">{store.name}</p>
      {(store.returnPolicy || store.shippingInfo) && (
        <div className="flex flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-3 mt-4">
          {store.returnPolicy && (
            <Button
              className="flex flex-grow justify-center"
              primary={false}
              onClick={returnsInfoModalToggler.on}
            >
              Returns and Warranty
            </Button>
          )}
          {store.shippingInfo && (
            <Button
              className="flex flex-grow justify-center"
              primary={false}
              onClick={shippingInfoModalToggler.on}
            >
              Shipping Information
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

const RequestForQuoteSection = ({
  accountMachines,
  requestForQuote,
  user,
}: {
  accountMachines: Pick<OrgMachineT, 'id' | 'name' | 'serialNumber'>[]
  requestForQuote: Pick<RequestForQuoteT, 'id' | 'shortId'>
  user: { claimed?: boolean }
}) => (
  <div className="space-y-4">
    {(!('claimed' in user) || user.claimed) && (
      <div className="space-y-1">
        <p className="text-base font-medium">Request</p>

        <div className="flex items-center gap-x-3 gap-y-2 flex-wrap text-sm whitespace-nowrap">
          <Link.T to={`/rfqs/${requestForQuote.id}`}>Request {requestForQuote.shortId}</Link.T>
        </div>
      </div>
    )}

    {accountMachines.map((orgMachine) => (
      <div className="space-y-1" key={orgMachine.id}>
        <p className="text-base font-medium">Machine</p>

        <div className="text-sm">
          <p>
            <Link.T to={`/machines/${orgMachine.id}`}>{orgMachine.name}</Link.T>
          </p>

          <p>{orgMachine.serialNumber}</p>
        </div>
      </div>
    ))}
  </div>
)

type Rfq = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

// Inbox details for RequestForQuote details page
export const RequestForQuoteInboxDetails = ({
  requestForQuote,
  conversation,
}: DetailsForConversationProps & {
  requestForQuote: {
    storeOrders: (Pick<Rfq['storeOrders'][number], 'id'> & {
      store: Pick<Rfq['storeOrders'][number]['store'], 'id'>
    })[]
    orgMachines: Pick<Rfq['orgMachines'][number], 'id' | 'name' | 'serialNumber'>[]
  }
}) => (
  <>
    {ConversationM.isInternalOrgConversation(conversation) && (
      <ConversationMembersSection
        conversationId={ConversationM.isNewConversation(conversation) ? undefined : conversation.id}
        conversationRfqId={conversation.requestForQuote?.id}
        conversationStoreOrderId={conversation.storeOrder?.id}
      />
    )}

    {conversation.admin && <AdminSection />}

    {conversation.store && <StoreSection store={conversation.store} />}

    {ConversationM.filterRfqStoreOrders(requestForQuote.storeOrders, conversation).map(
      (storeOrder) => (
        <Fragment key={storeOrder.id}>
          <StoreOrderSection storeOrderId={storeOrder.id} showStoreName={conversation.admin} />
          {requestForQuote.orgMachines.map((orgMachine) => (
            <div key={orgMachine.id}>
              <p className="mb-2 text-base font-medium">Machine</p>

              <p className="text-sm text-gray-700">
                <Link.T to={`/machines/${orgMachine.id}`}>{orgMachine.name}</Link.T>
              </p>

              <p className="text-sm text-gray-700">{orgMachine.serialNumber}</p>
            </div>
          ))}
        </Fragment>
      )
    )}
  </>
)

// Inbox details for Order details page

type StoreOrderInboxDetailsProps = DetailsForConversationProps & {
  storeOrder: Pick<StoreOrder, 'total'> & {
    accountMachines: Pick<StoreOrder['accountMachines'][number], 'id' | 'name' | 'serialNumber'>[]
    order: {
      requestForQuote?: Pick<
        Exclude<StoreOrder['order']['requestForQuote'], undefined>,
        'id' | 'shortId'
      >
    }
  }
}

const StoreOrderInboxDetails = ({
  storeOrder,
  conversation,
  user,
}: StoreOrderInboxDetailsProps) => (
  <>
    {ConversationM.isInternalOrgConversation(conversation) && (
      <ConversationMembersSection
        conversationId={ConversationM.isNewConversation(conversation) ? undefined : conversation.id}
        conversationRfqId={conversation.requestForQuote?.id}
        conversationStoreOrderId={conversation.storeOrder?.id}
      />
    )}
    {conversation.admin && <AdminSection />}
    {conversation.store && <StoreSection store={conversation.store} />}

    <div className="space-y-1">
      <p className="text-base font-medium">Order Total</p>
      <div className="text-sm text-gray-700">{Money.format(storeOrder.total)}</div>
    </div>

    {storeOrder.order.requestForQuote && (
      <RequestForQuoteSection
        accountMachines={storeOrder.accountMachines}
        requestForQuote={storeOrder.order.requestForQuote}
        user={user}
      />
    )}
  </>
)

// Inbox details for the entire Conversation
const ConversationInboxDetails = ({ conversation, user }: DetailsForConversationProps) => (
  <>
    {ConversationM.isInternalOrgConversation(conversation) && (
      <ConversationMembersSection
        conversationId={ConversationM.isNewConversation(conversation) ? undefined : conversation.id}
        conversationRfqId={conversation.requestForQuote?.id}
        conversationStoreOrderId={conversation.storeOrder?.id}
      />
    )}
    {conversation.admin && <AdminSection />}
    {conversation.requestForQuote && (
      <RequestForQuoteSection
        accountMachines={conversation.requestForQuote.orgMachines}
        requestForQuote={conversation.requestForQuote}
        user={user}
      />
    )}
    {conversation.requestForQuote?.storeOrders &&
      ConversationM.filterRfqStoreOrders(
        conversation.requestForQuote.storeOrders,
        conversation
      ).map((storeOrder) => (
        <StoreOrderSection
          key={storeOrder.id}
          storeOrderId={storeOrder.id}
          showStoreName={conversation.admin}
        />
      ))}
    {conversation.storeOrder && <StoreOrderSection storeOrderId={conversation.storeOrder.id} />}
    {conversation.store && <StoreSection store={conversation.store} />}
  </>
)

const useStoreOrder = (storeOrder: StoreOrderInboxDetailsProps['storeOrder']) =>
  useMemo(
    () => (props: Omit<StoreOrderInboxDetailsProps, 'storeOrder'>) => (
      <StoreOrderInboxDetails {...props} storeOrder={storeOrder} />
    ),
    [storeOrder]
  )

export default {
  useStoreOrder,
  Conversation: ConversationInboxDetails,
}
