import { SortByInput, useStoreRequestMetricsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Reporting, {
  SecondDegreeStandardAggregateT,
  SecondDegreeStandardBooleanAggregateT,
  StandardAggregateT,
  StandardBooleanAggregateT,
} from '@/buyers/modules/Reporting'
import Sort from '@/gf/modules/Sort'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { intersection, map, orderBy } from 'lodash'
import { DateTime } from 'luxon'
import { mean } from 'mathjs'
import useReportingFormQueryParams from './useReportingFormQueryParams'
import { SelectedValue } from './useSelectedValues'

const getLifespanDurationImpact = (
  metricValue: { lifespanDuration: StandardAggregateT | null },
  aggregateValue: { lifespanDuration: SecondDegreeStandardAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.lifespanDuration?.median,
        getAggregateValue: (aggregate) => aggregate.lifespanDuration.median,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.lifespanDuration?.count,
      getAggregateValue: (aggregate) => aggregate.lifespanDuration.count,
    }
  )

const getRequestApprovalDurationImpact = (
  metricValue: { requestApprovalDuration: StandardAggregateT | null },
  aggregateValue: { requestApprovalDuration: SecondDegreeStandardAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.requestApprovalDuration?.median,
        getAggregateValue: (aggregate) => aggregate.requestApprovalDuration.median,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.requestApprovalDuration?.count,
      getAggregateValue: (aggregate) => aggregate.requestApprovalDuration.count,
    }
  )

const getResponseDurationImpact = (
  metricValue: { responseDuration: StandardAggregateT | null },
  aggregateValue: { responseDuration: SecondDegreeStandardAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.responseDuration?.median,
        getAggregateValue: (aggregate) => aggregate.responseDuration.median,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.responseDuration?.count,
      getAggregateValue: (aggregate) => aggregate.responseDuration.count,
    }
  )

const getQuoteApprovalDurationImpact = (
  metricValue: { quoteApprovalDuration: StandardAggregateT | null },
  aggregateValue: { quoteApprovalDuration: SecondDegreeStandardAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.quoteApprovalDuration?.median,
        getAggregateValue: (aggregate) => aggregate.quoteApprovalDuration.median,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.quoteApprovalDuration?.count,
      getAggregateValue: (aggregate) => aggregate.quoteApprovalDuration.count,
    }
  )

const getOrderFulfillmentDurationImpact = (
  metricValue: { orderFulfillmentDuration: StandardAggregateT | null },
  aggregateValue: { orderFulfillmentDuration: SecondDegreeStandardAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.orderFulfillmentDuration?.median,
        getAggregateValue: (aggregate) => aggregate.orderFulfillmentDuration.median,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.orderFulfillmentDuration?.count,
      getAggregateValue: (aggregate) => aggregate.orderFulfillmentDuration.count,
    }
  )

const getUrgentImpact = (
  metricValue: { urgent: StandardBooleanAggregateT | null },
  aggregateValue: { urgent: SecondDegreeStandardBooleanAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.urgent?.percent,
        getAggregateValue: (aggregate) => aggregate.urgent.percent,
        downIsGood: true,
      },
    ],
    {
      getValue: (metric) => metric.urgent?.count,
      getAggregateValue: (aggregate) => aggregate.urgent.count,
    }
  )

const getAccuracyImpact = (
  metricValue: { accurate: StandardBooleanAggregateT | null },
  aggregateValue: { accurate: SecondDegreeStandardBooleanAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.accurate?.percent,
        getAggregateValue: (aggregate) => aggregate.accurate.percent,
      },
    ],
    {
      getValue: (metric) => metric.accurate?.count,
      getAggregateValue: (aggregate) => aggregate.accurate.count,
    }
  )

const getInternalFulfillmentImpact = (
  metricValue: { internalFulfillment: StandardBooleanAggregateT | null },
  aggregateValue: { internalFulfillment: SecondDegreeStandardBooleanAggregateT }
) =>
  Reporting.getImpact(
    metricValue,
    aggregateValue,
    [
      {
        getValue: (metric) => metric.internalFulfillment?.percent,
        getAggregateValue: (aggregate) => aggregate.internalFulfillment.percent,
      },
    ],
    {
      getValue: (metric) => metric.internalFulfillment?.count,
      getAggregateValue: (aggregate) => aggregate.internalFulfillment.count,
    }
  )

// TODO: move selected values into the form
const useMetrics = ({
  form,
  selectedValues,
  selectedDate,
  sortBy,
  requestsSortBy,
  storeFilter,
  creatorFilter,
  machineFilter,
  urgencyFilter,
  requestFilter,
  purchaserFilter,
  categoryFilter,
  getChartValue,
  // TODO: replace this with something that uses the metric
  useRequestProcessedStoreOrders = true,
}: {
  form: Pick<
    ReturnType<typeof useReportingFormQueryParams>['form'],
    'durationStart' | 'durationEnd' | 'urgentRequestsOnly' | 'tab'
  >
  selectedValues: SelectedValue[]
  selectedDate: DateTime | undefined
  sortBy: SortByInput
  requestsSortBy: SortByInput
  storeFilter: (storeMetric: ReturnType<typeof Reporting.getStoreMetrics>[string]) => unknown
  creatorFilter: (creatorMetric: ReturnType<typeof Reporting.getCreatorMetrics>[string]) => unknown
  machineFilter: (machineMetrics: ReturnType<typeof Reporting.getMachineMetrics>[string]) => unknown
  urgencyFilter: (urgencyMetric: ReturnType<typeof Reporting.getUrgencyMetrics>[string]) => unknown
  requestFilter: (requestMetric: ReturnType<typeof Reporting.getRequestMetrics>[string]) => unknown
  purchaserFilter: (
    purchaserMetric: ReturnType<typeof Reporting.getPurchaserMetrics>[string]
  ) => unknown
  categoryFilter: (
    categoryMetrics: ReturnType<typeof Reporting.getCategoryMetrics>[string]
  ) => unknown
  getChartValue: (
    requestMetric: ReturnType<typeof Reporting.getInsertedAtDateMetrics>[string]
  ) => number | undefined
  useRequestProcessedStoreOrders?: boolean
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()

  const commonStoreRequestMetricsVariables = {
    filter: JSON.stringify([form.urgentRequestsOnly ? ['machine_is_down'] : []]),
  }

  const { data, error, refetch } = useStoreRequestMetricsQuery({
    client,
    variables: {
      orgId,
      ...commonStoreRequestMetricsVariables,
      fromDate: form.durationStart.toISODate(),
      toDate: form.durationEnd.toISODate(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const prevDurationEnd = form.durationStart.minus({ day: 1 })
  const prevDurationStart = prevDurationEnd.minus(form.durationEnd.diff(form.durationStart))
  const {
    data: prevData,
    error: prevError,
    refetch: prevRefetch,
  } = useStoreRequestMetricsQuery({
    client,
    variables: {
      orgId,
      ...commonStoreRequestMetricsVariables,
      fromDate: prevDurationStart.toISODate(),
      toDate: prevDurationEnd.toISODate(),
    },
    fetchPolicy: 'cache-and-network',
  })

  const refetchAll = () => Promise.all([refetch(), prevRefetch()])

  if (!data || !prevData || error || prevError) {
    return {
      data,
      prevData,
      error,
      prevError,
      refetch: refetchAll,
    }
  }

  const requestMetrics = Reporting.getRequestMetrics(data.storeRequestMetrics)
  const aggregatedRequestMetrics = Reporting.aggregateFirstDegreeMetrics(map(requestMetrics))
  const prevRequestMetrics = Reporting.getRequestMetrics(prevData.storeRequestMetrics)
  const prevAggregatedRequestMetrics = Reporting.aggregateFirstDegreeMetrics(
    map(prevRequestMetrics)
  )

  const orderedRequestMetrics = orderBy(
    map(requestMetrics).filter(requestFilter),
    [Sort.sortFieldToLodash(requestsSortBy.field)],
    [Sort.sortOrderToLodash(requestsSortBy.order)]
  )

  const filteredOrderedRequestMetrics = orderedRequestMetrics
    .filter(
      ({ requestForQuote }) =>
        !selectedDate ||
        selectedDate.startOf('day').toLocaleString(DateTime.DATE_FULL) ===
          requestForQuote.insertedAt.startOf('day').toLocaleString(DateTime.DATE_FULL)
    )
    .filter(({ requestForQuote }) => {
      const storeOrders = useRequestProcessedStoreOrders
        ? requestForQuote.storeOrders.filter(StoreOrderM.isQuoteApproved)
        : requestForQuote.storeOrders
      const stores = (storeOrders.length > 0 ? storeOrders : requestForQuote.storeOrders).map(
        ({ store }) => store
      )
      return (
        selectedValues.length === 0 ||
        intersection(
          selectedValues.map(({ value }) => value),
          // TODO: clean up how this works (create a pattern for the tabs)
          form.tab === 'Vendor'
            ? stores.map(({ id }) => id)
            : form.tab === 'Machine'
              ? requestForQuote.orgMachines.map(({ id }) => id)
              : form.tab === 'Requester'
                ? requestForQuote.creator
                  ? [requestForQuote.creator.id]
                  : []
                : form.tab === 'Purchaser'
                  ? requestForQuote.assignedUser
                    ? [requestForQuote.assignedUser.id]
                    : []
                  : form.tab === 'Category'
                    ? !requestForQuote.orgMachines || requestForQuote.orgMachines.length === 0
                      ? ['no_machine']
                      : requestForQuote.orgMachines.flatMap((om) =>
                          om.machine.subcategories.length === 0
                            ? ['unknown']
                            : om.machine.subcategories.map(({ id }) => id)
                        )
                    : form.tab === 'Urgency'
                      ? [requestForQuote.urgency]
                      : []
        ).length > 0
      )
    })

  const insertedAtDateRequestMetrics = Reporting.getInsertedAtDateMetrics(map(requestMetrics))

  // TODO: generalize this for other checked groupings
  const selectedInsertedAtDateMetrics =
    form.tab === 'Vendor'
      ? Reporting.getGroupedInsertedAtDateMetrics(
          data.storeRequestMetrics,
          ({ store }) => store?.id
        )
      : form.tab === 'Requester'
        ? Reporting.getGroupedInsertedAtDateMetrics(
            map(requestMetrics),
            ({ requestForQuote }) => requestForQuote.creator?.id
          )
        : form.tab === 'Machine'
          ? Reporting.getGroupedInsertedAtDateMetrics(
              // TODO: create a helper function for this
              map(requestMetrics).flatMap((requestMetric) =>
                requestMetric.requestForQuote.orgMachines.map((orgMachine) => ({
                  ...requestMetric,
                  orgMachine,
                }))
              ),
              ({ orgMachine }) => orgMachine.id
            )
          : form.tab === 'Urgency'
            ? Reporting.getGroupedInsertedAtDateMetrics(
                map(requestMetrics),
                ({ requestForQuote }) => requestForQuote.urgency
              )
            : undefined

  const chartData = Reporting.getChartData(
    insertedAtDateRequestMetrics,
    form.durationStart,
    form.durationEnd,
    getChartValue
  )

  // Default null values to 0
  const chartDailyValues = chartData.map(({ y }) => y ?? 0)
  const chartDailyAverage = chartDailyValues.length > 0 ? mean(chartDailyValues) : undefined

  const selectedChartData = selectedInsertedAtDateMetrics
    ? selectedValues.map((selectedValue) => ({
        id: selectedValue.display,
        data: Reporting.getChartData(
          selectedInsertedAtDateMetrics[selectedValue.value],
          form.durationStart,
          form.durationEnd,
          getChartValue
        ),
      }))
    : undefined

  const storeMetrics = Reporting.getStoreMetrics(data.storeRequestMetrics)
  const aggregatedStoreMetrics = Reporting.aggregateSecondDegreeMetrics(map(storeMetrics))
  const storeMetricsWithImpact = map(storeMetrics, (storeMetric) => ({
    ...storeMetric,
    lifespanDurationImpact: getLifespanDurationImpact(storeMetric, aggregatedStoreMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      storeMetric,
      aggregatedStoreMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(storeMetric, aggregatedStoreMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      storeMetric,
      aggregatedStoreMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      storeMetric,
      aggregatedStoreMetrics
    ),
    urgencyImpact: getUrgentImpact(storeMetric, aggregatedStoreMetrics),
    accuracyImpact: getAccuracyImpact(storeMetric, aggregatedStoreMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(storeMetric, aggregatedStoreMetrics),
  }))
  const orderedStoreMetrics = orderBy(
    storeMetricsWithImpact.filter(storeFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  const creatorMetrics = Reporting.getCreatorMetrics(map(requestMetrics))
  const aggregatedCreatorMetrics = Reporting.aggregateSecondDegreeMetrics(map(creatorMetrics))
  const creatorMetricsWithImpact = map(creatorMetrics, (creatorMetric) => ({
    ...creatorMetric,
    lifespanDurationImpact: getLifespanDurationImpact(creatorMetric, aggregatedCreatorMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      creatorMetric,
      aggregatedCreatorMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(creatorMetric, aggregatedCreatorMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      creatorMetric,
      aggregatedCreatorMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      creatorMetric,
      aggregatedCreatorMetrics
    ),
    urgencyImpact: getUrgentImpact(creatorMetric, aggregatedCreatorMetrics),
    accuracyImpact: getAccuracyImpact(creatorMetric, aggregatedCreatorMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(
      creatorMetric,
      aggregatedCreatorMetrics
    ),
  }))
  const orderedCreatorMetrics = orderBy(
    creatorMetricsWithImpact.filter(creatorFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  const machineMetrics = Reporting.getMachineMetrics(map(requestMetrics))
  const aggregatedMachineMetrics = Reporting.aggregateSecondDegreeMetrics(map(machineMetrics))
  const machineMetricsWithImpact = map(machineMetrics, (machineMetric) => ({
    ...machineMetric,
    lifespanDurationImpact: getLifespanDurationImpact(machineMetric, aggregatedMachineMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      machineMetric,
      aggregatedMachineMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(machineMetric, aggregatedMachineMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      machineMetric,
      aggregatedMachineMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      machineMetric,
      aggregatedMachineMetrics
    ),
    urgencyImpact: getUrgentImpact(machineMetric, aggregatedMachineMetrics),
    accuracyImpact: getAccuracyImpact(machineMetric, aggregatedMachineMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(
      machineMetric,
      aggregatedMachineMetrics
    ),
  }))
  const orderedMachineMetrics = orderBy(
    machineMetricsWithImpact.filter(machineFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  const urgencyMetrics = Reporting.getUrgencyMetrics(map(requestMetrics))
  const aggregatedUrgencyMetrics = Reporting.aggregateSecondDegreeMetrics(map(urgencyMetrics))
  const urgencyMetricsWithImpact = map(urgencyMetrics, (urgencyMetric) => ({
    ...urgencyMetric,
    lifespanDurationImpact: getLifespanDurationImpact(urgencyMetric, aggregatedUrgencyMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      urgencyMetric,
      aggregatedUrgencyMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(urgencyMetric, aggregatedUrgencyMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      urgencyMetric,
      aggregatedUrgencyMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      urgencyMetric,
      aggregatedUrgencyMetrics
    ),
    urgencyImpact: getUrgentImpact(urgencyMetric, aggregatedUrgencyMetrics),
    accuracyImpact: getAccuracyImpact(urgencyMetric, aggregatedUrgencyMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(
      urgencyMetric,
      aggregatedUrgencyMetrics
    ),
  }))
  const orderedUrgencyMetrics = orderBy(
    urgencyMetricsWithImpact.filter(urgencyFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  const purchaserMetrics = Reporting.getPurchaserMetrics(map(requestMetrics))
  const aggregatedPurchaserMetrics = Reporting.aggregateSecondDegreeMetrics(map(purchaserMetrics))
  const purchaserMetricsWithImpact = map(purchaserMetrics, (purchaserMetric) => ({
    ...purchaserMetric,
    lifespanDurationImpact: getLifespanDurationImpact(purchaserMetric, aggregatedPurchaserMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      purchaserMetric,
      aggregatedPurchaserMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(purchaserMetric, aggregatedPurchaserMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      purchaserMetric,
      aggregatedPurchaserMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      purchaserMetric,
      aggregatedPurchaserMetrics
    ),
    urgencyImpact: getUrgentImpact(purchaserMetric, aggregatedPurchaserMetrics),
    accuracyImpact: getAccuracyImpact(purchaserMetric, aggregatedPurchaserMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(
      purchaserMetric,
      aggregatedPurchaserMetrics
    ),
  }))
  const orderedPurchaserMetrics = orderBy(
    purchaserMetricsWithImpact.filter(purchaserFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  const categoryMetrics = Reporting.getCategoryMetrics(map(requestMetrics))
  const aggregatedCategoryMetrics = Reporting.aggregateSecondDegreeMetrics(map(categoryMetrics))
  const categoryMetricsWithImpact = map(categoryMetrics, (categoryMetric) => ({
    ...categoryMetric,
    lifespanDurationImpact: getLifespanDurationImpact(categoryMetric, aggregatedCategoryMetrics),
    requestApprovalDurationImpact: getRequestApprovalDurationImpact(
      categoryMetric,
      aggregatedCategoryMetrics
    ),
    responseDurationImpact: getResponseDurationImpact(categoryMetric, aggregatedCategoryMetrics),
    quoteApprovalDurationImpact: getQuoteApprovalDurationImpact(
      categoryMetric,
      aggregatedCategoryMetrics
    ),
    orderFulfillmentDurationImpact: getOrderFulfillmentDurationImpact(
      categoryMetric,
      aggregatedCategoryMetrics
    ),
    urgencyImpact: getUrgentImpact(categoryMetric, aggregatedCategoryMetrics),
    accuracyImpact: getAccuracyImpact(categoryMetric, aggregatedCategoryMetrics),
    internalFulfillmentImpact: getInternalFulfillmentImpact(
      categoryMetric,
      aggregatedCategoryMetrics
    ),
  }))
  const orderedCategoryMetrics = orderBy(
    categoryMetricsWithImpact.filter(categoryFilter),
    [Sort.sortFieldToLodash(sortBy.field)],
    [Sort.sortOrderToLodash(sortBy.order)]
  )

  return {
    data,
    prevData,
    error,
    prevError,
    refetch: refetchAll,
    // Aggregates
    aggregatedRequestMetrics,
    prevAggregatedRequestMetrics,
    aggregatedStoreMetrics,
    aggregatedCreatorMetrics,
    aggregatedMachineMetrics,
    aggregatedUrgencyMetrics,
    aggregatedPurchaserMetrics,
    aggregatedCategoryMetrics,
    // Chart data
    chartData,
    chartDailyAverage,
    selectedChartData,
    // Table metrics
    orderedStoreMetrics,
    orderedCreatorMetrics,
    orderedMachineMetrics,
    orderedUrgencyMetrics,
    filteredOrderedRequestMetrics,
    orderedPurchaserMetrics,
    orderedCategoryMetrics,
  }
}

export default useMetrics
