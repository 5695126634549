import { useState } from 'react'

import AnimateShowIn from '@/gf/components/AnimateShowIn'
import VendorForm from './VendorForm'
import VendorView from './VendorView'
import { useContext } from '../Vendor'

const Sidebar = () => {
  const [editing, setEditing] = useState(false)
  const { refetchVendor } = useContext()

  const onAfterSave = () => {
    setEditing(false)
    refetchVendor()
  }

  return (
    <>
      <AnimateShowIn visible={editing}>
        <VendorForm onAfterSave={onAfterSave} onCancel={() => setEditing(false)} />
      </AnimateShowIn>

      <AnimateShowIn visible={!editing}>
        <VendorView onEditClick={() => setEditing(true)} />
      </AnimateShowIn>
    </>
  )
}

export default Sidebar
