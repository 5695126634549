import Action from '@/gf/components/Action'
import Spinner from '@/gf/components/Spinner'

const ImagesList = ({
  imageUrls,
  onRemoveClick,
  uploadInProgress = false,
}: {
  imageUrls: string[]
  onRemoveClick?: (imageUrl: string) => void
  uploadInProgress?: boolean
}) => {
  if (imageUrls.length === 0 && !uploadInProgress) return null

  return (
    <div className="flex gap-2 flex-wrap">
      {imageUrls.map((imageUrl) => (
        <div key={imageUrl} className="space-y-1">
          <img
            src={imageUrl}
            alt=""
            className="w-20 h-20 rounded-md object-cover border border-gray-100"
          />

          {onRemoveClick && (
            <Action.T
              onClick={() => onRemoveClick(imageUrl)}
              className="font-medium text-sm leading-5"
            >
              Remove
            </Action.T>
          )}
        </div>
      ))}

      {uploadInProgress && (
        <div className="w-20 h-20 rounded-md object-cover border border-gray-100 flex items-center justify-center">
          <Spinner size="sm" />
        </div>
      )}
    </div>
  )
}

export default ImagesList
