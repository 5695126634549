import Ghost from '@/gf/components/Ghost'

const LatestOrderGhost = () => (
  <div className="rounded-lg border mt-4">
    <div className="flex justify-between items-center border-b p-2">
      <Ghost className="block h-8 rounded-xl w-52" />
      <Ghost className="block h-6 rounded-xl w-32" />
    </div>
    <div className="p-2 justify-between flex items-center">
      <div className="flex items-center gap-x-2">
        <Ghost className="block rounded-lg w-16 h-16" />
        <div>
          <Ghost className="block rounded-lg w-52 h-4 mb-2" />
          <Ghost className="block rounded-lg w-24 h-4" />
        </div>
      </div>

      <div className="flex items-center">
        <Ghost className="inline-block rounded-lg w-20 h-4 mr-2" />x
        <Ghost className="inline-block rounded-lg w-20 h-4 ml-2 mr-8" />
        <Ghost className="block rounded-lg w-20 h-4" />
      </div>
    </div>
    <div className="bg-slate-50 p-2 flex justify-between rounded-b-lg">
      <Ghost className="h-4 w-40" />
      <Ghost className="h-4 w-24" />
    </div>
  </div>
)

export default LatestOrderGhost
