import classNames from 'classnames'

const StickyBottomBar = ({ children }: { children: React.ReactNode }) => (
  <div
    className={classNames(
      'fixed bottom-0  bg-white shadow-br-lg z-20 left-0 mdplus:left-56 right-0'
    )}
  >
    <div className="px-2 sm:px-4 lg:px-8 py-2 flex justify-end gap-2">{children}</div>
  </div>
)

export default StickyBottomBar
