import Ghost from '@/gf/components/Ghost'

const OrgMachineViewGhost: React.FC = () => (
  <div className="p-6 space-y-2">
    <Ghost className="h-7 w-1/3 block" />
    <Ghost className="h-5 w-1/4 block" />
    <div className="flex gap-x-2 pt-1">
      <Ghost className="h-5 w-32 block rounded-md" />
      <Ghost className="h-5 w-32 block rounded-md" />
    </div>
  </div>
)

export default OrgMachineViewGhost
