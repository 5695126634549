import { Route, Routes } from 'react-router-dom'

import type { ConvosResult } from '../LimitedOrder'

import { useOrderContext } from './context'

import Messages from '@/buyers/pages/StoreOrder/Messages'

const OrderMessages = ({ convosResult }: { convosResult: ConvosResult }) => {
  const { org, refetchStoreOrder } = useOrderContext()

  return (
    <Routes>
      <Route
        path=""
        element={
          <Messages org={org} convosResult={convosResult} refetchStoreOrder={refetchStoreOrder} />
        }
      />
      <Route
        path=":conversationId"
        element={
          <Messages org={org} convosResult={convosResult} refetchStoreOrder={refetchStoreOrder} />
        }
      />
      <Route
        path="store/:storeId"
        element={
          <Messages org={org} convosResult={convosResult} refetchStoreOrder={refetchStoreOrder} />
        }
      />
      <Route
        path="internalOrg"
        element={
          <Messages
            org={org}
            convosResult={convosResult}
            refetchStoreOrder={refetchStoreOrder}
            internalOrg
          />
        }
      />
    </Routes>
  )
}

export default OrderMessages
