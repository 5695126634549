import { useMemo, useState } from 'react'
import { Brand as GQLBrand, useUpdateBrandsMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe } from '@/types'

export type Brand = Pick<GQLBrand, 'id' | 'name' | 'logoUrl'>

type Fields = {
  brands: Brand[]
  previouslyAddedBrands: Brand[]
}

const useBrandsForm = (brands: Brand[]) => {
  const [update, { loading: saving }] = useUpdateBrandsMutation({ client: useGqlClient() })

  const [fields, setFields] = useState<Fields>({
    brands,
    previouslyAddedBrands: [],
  })

  const updateFields = (updates: Partial<Fields>) => setFields((prev) => ({ ...prev, ...updates }))

  const toggleBrand = (brand: Brand, listIndex: number, searchTerm: Maybe<string>) => {
    const exists = fields.brands.find((b) => b.id === brand.id)

    updateFields({
      brands: exists ? fields.brands.filter((b) => b.id !== brand.id) : [...fields.brands, brand],
    })

    if (exists) {
      GE.deselectsBrandOnAccountSetup(brand.id, listIndex, searchTerm)
    } else {
      GE.selectsBrandOnAccountSetup(brand.id, listIndex, searchTerm)
    }
  }

  const isBrandSelected = (brand: Brand) => !!fields.brands.find((b) => b.id === brand.id)

  const save = (onOk?: () => void) => {
    if (fields.brands.length === 0) {
      return
    }

    update({
      variables: {
        brandIds: [
          ...fields.previouslyAddedBrands.map((b) => b.id),
          ...fields.brands.map((b) => b.id),
        ],
      },
    })
      .then(() => {
        if (onOk) onOk()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const submitDisabled = useMemo(() => fields.brands.length === 0, [fields.brands])

  return { fields, saving, updateFields, save, submitDisabled, toggleBrand, isBrandSelected }
}

export default useBrandsForm
