import { useAllBranchesQuery, useShippingLocationsSearchQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Button from '@/gf/components/ButtonOld'
import Filters from '@/gf/components/Filters'
import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import Spinner from '@/gf/components/Spinner'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import Tooltip from '@/gf/components/Tooltip'
import useFilters from '@/gf/hooks/useFilters'
import usePage from '@/gf/hooks/usePage'
import Address from '@/gf/modules/Address'
import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import Time from '@/gf/modules/Time'
import { StarIcon } from '@heroicons/react/solid'
import { useLocation, useNavigate } from 'react-router-dom'
import { JsonParam, useQueryParams, withDefault } from 'use-query-params'
import { Filter, FilterField } from '../../types'
import Frame from '../components/Frame'
import LocationModal from '../components/LocationModal'
import useSession from '../hooks/useSession'
import FilterM from '../modules/Filter'

const filterFields: FilterField[] = [
  {
    id: 'name',
    display: 'Name',
    filterTypeIds: ['contains'],
  },
]

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Locations', href: '/locations' }],
}

const useQueryParamsOpts = {
  filters: withDefault<Filter[], Filter[]>(JsonParam, []),
}

const cols = 4

const ShippingLocations = () => {
  const client = useGqlClient()
  const { orgId, featureFlags } = useSession()
  const loc = useLocation()
  const navigate = useNavigate()
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)
  const [page, setPage] = usePage()
  const [stagedFilters, filtersApi] = useFilters(query.filters)
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches
  const apiFilters = (() => FormatFilters.andFilters(FilterSet.toApiFilters(query.filters)))()

  const { data, refetch } = useShippingLocationsSearchQuery({
    variables: { orgId, page, filters: apiFilters },
    client,
  })

  const shippingLocations = data?.shippingLocationsSearch.shippingLocations
  const pagination = data?.shippingLocationsSearch.pagination
  const fields = FilterM.availableFilterFields(filterFields, featureFlags)

  const updateFilters = (newFilters: Filter[]) => {
    updateQuery({ filters: newFilters })
    setPage(1)
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title="Locations"
        titleRightContent={
          <Button title="Add Location" onClick={() => navigate('/locations/add')} />
        }
      >
        <LocationModal
          buyers={[]}
          onComplete={() => refetch()}
          branches={branches || []}
          showPersist={false}
          open={loc.pathname === '/locations/add'}
          onClose={() => navigate('/locations')}
          initialAddress={Address.init()}
        />

        <Layout>
          <Layout.Section type="table">
            <Filters
              filters={query.filters}
              stagedFilters={stagedFilters}
              fields={fields}
              filtersApi={filtersApi}
              onApply={() => updateFilters(stagedFilters)}
            />
            <Table>
              <Thead>
                <Tr>
                  <Th>Date Created</Th>
                  <Th>Name</Th>
                  <Th>Code</Th>
                  <Th>Phone</Th>
                </Tr>
              </Thead>
              <Tbody>
                {shippingLocations?.map((location) => (
                  <Tr
                    key={location.id}
                    onClick={() => {
                      navigate(`/locations/${location.id}`)
                    }}
                  >
                    <Td>{Time.toString(location.insertedAt)}</Td>
                    <Td>
                      <span className="flex gap-x-1">
                        {location.name}

                        {location.defaultLocation && (
                          <Tooltip text="Default location">
                            <StarIcon className="h-5 text-amber-300 inline-block" />
                          </Tooltip>
                        )}
                      </span>
                    </Td>
                    <Td>{location.code}</Td>
                    <Td>{location.phoneNumber}</Td>
                  </Tr>
                ))}

                {!shippingLocations && (
                  <Tr>
                    <Td colSpan={cols}>
                      <Spinner />
                    </Td>
                  </Tr>
                )}

                {shippingLocations?.length === 0 && (
                  <Tr>
                    <Td colSpan={cols}>No locations found.</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Layout.Section>
          <Layout.Section type="full">
            <PaginationC pagination={pagination} page={page} updatePage={setPage} />
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
  )
}

export default ShippingLocations
