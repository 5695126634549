import { CodeGeneratorConfigType } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import TextInput from '@/gf/components/next/forms/TextInput'
import Select from '@/gf/components/next/Select'
import { PurchaseOrderGeneratorConfig } from '../ApprovalSettings'

const SAMPLE_UNIT_NUMBER = '60-03'
const SAMPLE_SERIAL_NUMBER = '4884343341234'

const sampleCode = (config: PurchaseOrderGeneratorConfig) =>
  config.parts.map((p) =>
    p.type === CodeGeneratorConfigType.UnitNumber
      ? SAMPLE_UNIT_NUMBER
      : p.type === CodeGeneratorConfigType.Sequence
        ? config.sequence
        : p.type === CodeGeneratorConfigType.SerialNumber
          ? SAMPLE_SERIAL_NUMBER
          : p.value
  )

const typeOptions = [
  { value: CodeGeneratorConfigType.Sequence, label: 'Sequential Number' },
  { value: CodeGeneratorConfigType.Text, label: 'Text' },
  { value: CodeGeneratorConfigType.UnitNumber, label: 'Unit Number' },
  { value: CodeGeneratorConfigType.SerialNumber, label: 'Serial Number' },
]

const ConfigInput = ({
  sequence,
  onSequenceChanged,
  value,
  onChange,
  canRemove,
  onRemoveClick,
}: {
  value: PurchaseOrderGeneratorConfig['parts'][number]
  onChange: (newValue: PurchaseOrderGeneratorConfig['parts'][number]) => void
  sequence: number
  onSequenceChanged: (newSequence: number) => void
  canRemove: boolean
  onRemoveClick: () => void
}) => (
  <div>
    <div className="flex gap-x-2">
      <div className="flex gap-x-3 max-w-lg">
        <Select
          options={typeOptions}
          value={typeOptions.find((v) => v.value === value.type)}
          onChange={(o) =>
            o
              ? onChange({
                  value: '',
                  type: o.value,
                })
              : undefined
          }
          className="w-52 flex-shrink-0"
        />
        {value.type === CodeGeneratorConfigType.Sequence ? (
          <TextInput
            type="number"
            value={sequence}
            onChange={(e) => onSequenceChanged(e.target.valueAsNumber)}
            step={1}
            min={1}
            required
          />
        ) : (
          <TextInput
            value={value.value ?? ''}
            placeholder={
              value.type === CodeGeneratorConfigType.UnitNumber
                ? `i.e. ${SAMPLE_UNIT_NUMBER}`
                : value.type === CodeGeneratorConfigType.SerialNumber
                  ? `i.e. ${SAMPLE_SERIAL_NUMBER}`
                  : undefined
            }
            disabled={[
              CodeGeneratorConfigType.UnitNumber,
              CodeGeneratorConfigType.SerialNumber,
            ].includes(value.type)}
            onChange={(e) => onChange({ type: value.type, value: e.target.value })}
            required
          />
        )}
      </div>
      {canRemove && <Action.T onClick={onRemoveClick}>Remove</Action.T>}
    </div>
    <p className="text-sm text-gray-500 mt-1">
      {value.type === CodeGeneratorConfigType.Sequence
        ? 'Select a starting number that incrementally adds 1 with every order'
        : value.type === CodeGeneratorConfigType.Text
          ? 'Add in static text'
          : value.type === CodeGeneratorConfigType.UnitNumber
            ? 'Dynamically add the machine unit number (when applicable)'
            : 'Dynamically add the machine serial number (when applicable)'}
    </p>
  </div>
)

const PurchaseOrderConfigInput = ({
  value,
  onChange,
}: {
  value: PurchaseOrderGeneratorConfig
  onChange: (newValue: PurchaseOrderGeneratorConfig) => void
}) => {
  const onCodePartChanged = (index, codePart: PurchaseOrderGeneratorConfig['parts'][number]) =>
    onChange({ ...value, parts: value.parts.map((p, i) => (i === index ? codePart : p)) })

  const addCodePart = () =>
    onChange({
      ...value,
      parts: [...value.parts, { type: CodeGeneratorConfigType.Text, value: '' }],
    })

  const removeCodePart = (index) =>
    onChange({ ...value, parts: value.parts.filter((_p, i) => i !== index) })
  return (
    <div className="space-y-2">
      <div className="space-y-2">
        {value.parts.map((config, index) => (
          <ConfigInput
            key={config.type.concat(index.toString())}
            sequence={value.sequence}
            onSequenceChanged={(newSequence) => onChange({ ...value, sequence: newSequence })}
            value={config}
            onChange={(codePart) => onCodePartChanged(index, codePart)}
            canRemove={value.parts.length > 1}
            onRemoveClick={() => removeCodePart(index)}
          />
        ))}
      </div>
      <Action.T onClick={() => addCodePart()}>Add Value</Action.T>
      <p className="py-4">
        PO Number Sample: <span className="font-medium">{sampleCode(value)}</span>
      </p>
    </div>
  )
}

export default PurchaseOrderConfigInput
