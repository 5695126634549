import { Maybe } from '@/types'
import Variation, { PositiveDirection, formatPercentage } from './Variation'
import Ghost from '@/gf/components/Ghost'
import isNumber from 'lodash/isNumber'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

const PerformanceSnippet = ({
  name,
  value,
  variation,
  positiveDirection,
  href,
}: {
  name: string
  value: Maybe<number>
  variation: Maybe<number>
  positiveDirection: PositiveDirection
  href?: string
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={classNames(
        'border rounded-md bg-white shadow-base p-4',
        href && 'hover:border-blue-600 cursor-pointer transition duration-300'
      )}
      onClick={() => href && navigate(href)}
    >
      <h4 className="text-lg font-medium">{name}</h4>
      <p className="flex flex-col gap-y-3">
        <span className="text-2xl font-medium">
          {isNumber(value) ? (
            formatPercentage(1)(value)
          ) : (
            <Ghost className="inline-block h-5 w-20" />
          )}
        </span>
        {isNumber(value) ? (
          variation ? (
            <Variation
              value={variation}
              toformat={formatPercentage(1)}
              positiveDirection={positiveDirection}
            />
          ) : null
        ) : (
          <Ghost className="inline-block h-4 w-12" />
        )}
      </p>
    </div>
  )
}

export default PerformanceSnippet
