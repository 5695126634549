import { useState } from 'react'
import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'
import useToggle from '@/gf/hooks/useToggle'
import { PhotographIcon } from '@heroicons/react/solid'
import ImagesList from '../ImagesList'
import { twMerge } from 'tailwind-merge'

const PartImages = ({
  value,
  desc,
  onChange,
  addDisabled,
}: {
  value: string[]
  desc?: React.ReactNode
  onChange: (newfiles) => void
  addDisabled: boolean
}) => {
  const [uploading, uploadToggle] = useToggle()
  const [focus, setFocus] = useState(false)

  return (
    <div className="space-y-2 mt-4 flex flex-col items-start">
      <div className="text-sm">
        <p>Pictures (optional)</p>
        {desc}
      </div>

      <ImagesList
        imageUrls={value}
        uploadInProgress={uploading}
        onRemoveClick={(imageUrl) => onChange(value.filter((i) => i !== imageUrl))}
      />

      <UploadButton
        allowedFileTypes={['.jpg', '.jpeg', '.png', '.gif']}
        onUploadComplete={(uploadedFiles) => {
          uploadToggle.off()

          onChange([...(value ?? []), ...uploadedFiles.map((f) => f.url)])

          return Promise.resolve()
        }}
        onUploadStarted={uploadToggle.on}
        onError={(err) => {
          uploadToggle.off()
          console.error(err)
        }}
        key={value.length} // this is necessary to update the component and properly add the new pictures
      >
        <div
          className={twMerge(
            'py-2 pl-2 pr-4 flex flex-row gap-x-2 items-center rounded-lg border-2 border-gray-200 border-dashed bg-gray-50',
            focus && 'border-gearflow border-solid'
          )}
          onDragLeave={() => setFocus(false)}
          onDragOver={() => setFocus(true)}
          onDrop={() => setFocus(false)}
        >
          <Action.S
            className="font-medium text-sm flex items-center shrink-0"
            disabled={addDisabled}
          >
            <PhotographIcon className="mr-2 h-4 inline-block" /> Browse Pictures
          </Action.S>
          <span className="text-sm text-gray-500 font-medium leading-tight">
            or drag & drop pictures here
          </span>
        </div>
      </UploadButton>
    </div>
  )
}

export default PartImages
