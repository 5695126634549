import Link from '@/gf/components/Link'

const Welcome = ({ beginPath }: { beginPath: string }) => (
  <div className="space-y-6 max-w-md mx-auto">
    <div className="space-y-4">
      <h2 className="text-2xl font-medium">
        Start building your account by adding a few details about your organization.
      </h2>

      <div className="prose">
        You’ll add your first shop location and some of the vendors that support it. It’s quick to
        get started.
      </div>
    </div>

    <div>
      <Link.P to={beginPath} className="w-full bg-blue-600 hover:bg-blue-700 text-center text-base">
        Start Building
      </Link.P>
    </div>
  </div>
)

export default Welcome
