import { useBrandsMissingVendorsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import TextTag from '@/gf/components/next/TextTag'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import { Placement } from '@floating-ui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import pluralize from 'pluralize'
import { useMemo } from 'react'

const BrandsMissingVendors = ({ placement = 'bottom' }: { placement?: Placement }) => {
  const client = useGqlClient()
  const { organization } = useSession()

  const { data } = useBrandsMissingVendorsQuery({ client, variables: { orgId: organization.id } })
  const missingBrands = useMemo(
    () => data?.org?.brandStats.filter((b) => b.vendorCount === 0).map(({ brand }) => brand) ?? [],
    [data]
  )

  const text = useMemo(
    () => `Missing vendors for ${missingBrands.length} ${pluralize('brand', missingBrands.length)}`,
    [missingBrands]
  )

  if (!data || missingBrands.length === 0) {
    return null
  }

  return (
    <Tooltip placement={placement}>
      <TooltipTrigger className="text-left">
        <TextTag color="red">
          <ExclamationIcon className="h-4 w-4 inline-block flex-shrink-0" /> {text}
        </TextTag>
      </TooltipTrigger>
      <TooltipContent>
        <div className="p-3 bg-gray-700 border border-gray-900 text-gray-100 text-sm rounded-md max-w-96 space-y-2">
          <p>{missingBrands.map((b) => b.name).join(', ')}</p>
        </div>
      </TooltipContent>
    </Tooltip>
  )
}

export default BrandsMissingVendors
