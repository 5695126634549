import type { AuthenticatedSession } from '@/buyers/contexts/SessionContext'
import Filter from '@/gf/modules/Filter'
import { FilterField } from '@/types'

const availableFilterFields = (
  fields: FilterField[],
  featureFlags: AuthenticatedSession['featureFlags']
) => Filter.availableFilterFields(fields, false, featureFlags)

export default {
  ...Filter,
  availableFilterFields,
}
