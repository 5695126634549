import { Rfq, useSetRfqFollowUpReminderMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Checkbox from '@/gf/components/Checkbox'
import Select from '@/gf/components/Select'
import DateTimeSelect from '@/gf/components/inputs/DateTime'
import { cn } from '@/gf/modules/utils'
import { Check } from 'lucide-react'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { BooleanParam, useQueryParams } from 'use-query-params'

const options = [
  { id: '30-mins', display: '30 minutes' },
  { id: '1-hour', display: '1 hour' },
  { id: '2-hours', display: '2 hours' },
  { id: 'custom', display: 'Custom' },
]

type Fields = { followUp: boolean; duration: string; remindAt: DateTime }

const determineRemindAt = (now: DateTime, fields: Fields) => {
  if (!fields.followUp) return null
  if (fields.duration === '30-mins') return now.plus({ minutes: 30 }).toUTC()
  if (fields.duration === '1-hour') return now.plus({ hours: 1 }).toUTC()
  if (fields.duration === '2-hours') return now.plus({ hours: 2 }).toUTC()
  if (fields.duration === 'custom') return fields.remindAt.toUTC()
  throw new Error(`Unexpected duration: ${fields.duration}`)
}

const Reminder = ({ rfq }: { rfq: Pick<Rfq, 'id' | 'remindAt'> }) => {
  const now = DateTime.now()
  const isAfterHours = now.hour >= 17
  const [query, updateQuery] = useQueryParams({ init: BooleanParam })
  const [saved, setSaved] = useState(false)
  const [setReminder] = useSetRfqFollowUpReminderMutation({ client: useGqlClient() })

  const [fields, setFields] = useState<Fields>({
    followUp: !!rfq.remindAt,
    duration: isAfterHours || rfq.remindAt ? 'custom' : '1-hour',
    remindAt: rfq.remindAt || now.plus({ days: 1 }).set({ hour: 9, minute: 30, second: 0 }),
  })

  const updateFields = (updates: Partial<Fields>) => {
    const newFields = { ...fields, ...updates }
    setFields(newFields)
    setSaved(true)
    setTimeout(() => setSaved(false), 2000)
    setReminder({ variables: { rfqId: rfq.id, remindAt: determineRemindAt(now, newFields) } })
  }

  useEffect(() => {
    if (query.init) {
      updateFields({ followUp: true })
      updateQuery({ init: undefined })
    }
  }, [])

  return (
    <div className="space-y-4 p-6">
      <div className="flex gap-4 justify-between items-start">
        <div className="font-medium">Reminder</div>

        <div className={cn('invisible flex gap-1 items-center', saved && 'visible')}>
          <Check className="w-5 h-5 text-green-600" />
          <span className="text-sm text-gray-500">Saved</span>
        </div>
      </div>

      <Checkbox
        checked={fields.followUp}
        setChecked={(followUp) => updateFields({ followUp })}
        label="Remind me to follow up with my vendors"
        className="text-base"
      />

      {fields.followUp && (
        <div className="space-y-2">
          <div className="prose">If I don&apos;t receive a quote, remind me to follow up in:</div>

          <div className="flex gap-2 items-center">
            <Select
              options={options}
              currentId={fields.duration}
              onChange={(duration) => updateFields({ duration })}
              className="w-auto"
            />

            {fields.duration === 'custom' && (
              <DateTimeSelect
                min={now}
                dateTime={fields.remindAt}
                updateDateTime={(remindAt) => remindAt && updateFields({ remindAt })}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Reminder
