import type { PermissionSummary, Role } from '@/buyers/_gen/gql'
import { useSetUserRoleMutation, useUpdatePrimaryLocationMutation } from '@/buyers/_gen/gql'
import EditOrgUserModal from '@/buyers/components/EditOrgUserModal'
import LocationSelector from '@/buyers/components/LocationSelector'
import RolePicker from '@/buyers/components/RolePicker'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Form from '@/gf/components/Form'
import Modal from '@/gf/components/ModalNext'
import Spinner from '@/gf/components/Spinner'
import { Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import ThreeDotsDropdown, { ActionItem } from '@/gf/components/ThreeDotsDropdown'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { useState } from 'react'
import type { User } from '../Users'
import DeactivateModal from './DeactivateModal'
import ReactivateModal from './ReactivateModal'
import RemoveModal from './RemoveModal'

const UsersTable = ({
  users,
  roles,
  permissionSummaries,
  refetchUsers,
}: {
  users?: User[]
  roles?: Pick<Role, 'id' | 'name'>[]
  permissionSummaries?: Pick<PermissionSummary, 'title' | 'roles'>[]
  refetchUsers: () => void
}) => {
  const { user: currentUser } = useSession()
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinnerLiveToggle] = useToggle()
  const client = useGqlClient()
  const [showEditModal, editModalActions] = useToggle()
  const [showDeactivateModal, deactivateModalActions] = useToggle()
  const [showReactivateModal, reactivateModalActions] = useToggle()
  const [showRemoveModal, removeModalActions] = useToggle()
  const [showPermissionsModal, permissionsModalActions] = useToggle()
  const [setUserRole] = useSetUserRoleMutation({ client })
  const [updatePrimary] = useUpdatePrimaryLocationMutation({ client })
  const [selected, setSelected] = useState<User | null>(null)
  const [newRoleId, setNewRoleId] = useState<string | null>(null)

  const updatePrimaryLocation = ({ userId, shippingLocationId }) => {
    updatePrimary({
      variables: {
        userId,
        shippingLocationId,
      },
    })
      .then(() => {
        refetchUsers()
        msgr.add('Primary location updated!', 'positive')
      })
      .catch((err: ApolloError) => {
        const message = err.graphQLErrors.reduce((acc, error) => `${acc}${error.message}`, '')
        msgr.add(message, 'negative')
      })
  }

  const assignRole = () => {
    if (!selected || !newRoleId) return

    setUserRole({
      variables: { userId: selected?.id, roleId: newRoleId },
    })
      .then(() => {
        msgr.add('Permissions updated.', 'positive')
        refetchUsers()
      })
      .catch((err: GraphQLError) => {
        if (err.message) msgr.add(err.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(() => {
        spinnerLiveToggle.off()
        setNewRoleId(null)
        permissionsModalActions.off()
      })
  }

  return (
    <>
      <div className="rounded border border-gray-300">
        <table className="divide-y divide-gray-200 bg-white min-w-full">
          <Thead>
            <Tr>
              <Th>Details</Th>
              <Th>Primary Location</Th>
              <Th>Role</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {users?.map((user) => (
              <Tr key={user.id}>
                <Td>
                  <div className="space-y-1">
                    <div className="flex gap-2 items-center">
                      <span>{user.name}</span>
                      {user.orgUser?.role === 'owner' && <span className="italic">(owner)</span>}
                    </div>

                    <div className="space-y-1 text-gray-500">
                      <div>{user.email}</div>
                      {user.phoneNumber && <div>{Phone.format(user.phoneNumber)}</div>}
                    </div>
                  </div>
                </Td>
                <Td>
                  {currentUser?.userRole?.name === 'admin' ? (
                    <LocationSelector
                      value={user?.primaryShippingLocation?.shippingLocationId || null}
                      onChange={(location) => {
                        updatePrimaryLocation({ userId: user.id, shippingLocationId: location.id })
                      }}
                      className="flex-1 w-64 xl:w-80"
                      containerClassName="flex-1 w-64 xl:w-80"
                    />
                  ) : user.primaryShippingLocation ? (
                    <span className="text-sm text-gray-900">
                      {user.primaryShippingLocation.shippingLocationName}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500 italic">None</span>
                  )}
                </Td>
                <Td>
                  <div className="capitalize">{user.userRole?.name}</div>
                </Td>
                <Td>
                  <div className="flex justify-end">
                    <ThreeDotsDropdown>
                      <ActionItem
                        onClick={() => {
                          setSelected(user)
                          editModalActions.on()
                        }}
                      >
                        Edit Details
                      </ActionItem>
                      <ActionItem
                        onClick={() => {
                          setSelected(user)
                          setNewRoleId(user.userRole?.id || null)
                          permissionsModalActions.on()
                        }}
                      >
                        Edit Role
                      </ActionItem>
                      {user.active ? (
                        <ActionItem
                          onClick={() => {
                            setSelected(user)
                            deactivateModalActions.on()
                          }}
                        >
                          Deactivate
                        </ActionItem>
                      ) : (
                        <ActionItem
                          onClick={() => {
                            setSelected(user)
                            reactivateModalActions.on()
                          }}
                        >
                          Reactivate
                        </ActionItem>
                      )}
                      <ActionItem
                        onClick={() => {
                          setSelected(user)
                          removeModalActions.on()
                        }}
                      >
                        Remove
                      </ActionItem>
                    </ThreeDotsDropdown>
                  </div>
                </Td>
              </Tr>
            ))}

            {!users && (
              <Tr>
                <Td colSpan={99}>
                  <Spinner />
                </Td>
              </Tr>
            )}
          </Tbody>
        </table>
      </div>
      <DeactivateModal
        user={selected}
        open={showDeactivateModal}
        onClose={() => {
          deactivateModalActions.off()
          setSelected(null)
        }}
        onDeactivated={() => {
          deactivateModalActions.off()
          refetchUsers()
          setSelected(null)
        }}
      />
      <ReactivateModal
        user={selected}
        open={showReactivateModal}
        onClose={() => {
          reactivateModalActions.off()
          setSelected(null)
        }}
        onReactivated={() => {
          reactivateModalActions.off()
          refetchUsers()
          setSelected(null)
        }}
      />
      <RemoveModal
        open={showRemoveModal}
        onClose={() => {
          removeModalActions.off()
          setSelected(null)
        }}
        userId={selected?.id}
        onRemove={() => {
          removeModalActions.off()
          refetchUsers()
          setSelected(null)
        }}
      >
        <p className="text-base">
          This will remove <span className="font-bold">{selected?.email}</span> from the
          organization.
        </p>
      </RemoveModal>
      <EditOrgUserModal
        open={showEditModal}
        onClose={() => {
          setSelected(null)
          editModalActions.off()
        }}
        selected={selected}
        refetch={refetchUsers}
      />

      <Modal
        open={showPermissionsModal}
        onClose={() => {
          setSelected(null)
          setNewRoleId(null)
          permissionsModalActions.off()
        }}
      >
        <CloseModalButton
          onClick={() => {
            setSelected(null)
            setNewRoleId(null)
            permissionsModalActions.off()
          }}
          className="absolute top-3 right-3"
        />

        <Form onSubmit={assignRole}>
          <div className="p-6 space-y-6">
            <div className="text-lg">Select the role to associate with this user</div>

            <RolePicker
              roles={roles}
              permissionSummaries={permissionSummaries}
              roleId={newRoleId}
              onChange={setNewRoleId}
            />
          </div>

          <div className="px-6 py-4 bg-gray-50 flex gap-2 flex-row-reverse">
            <Action.P type="submit" performing={spinnerLive} color="blue">
              Save
            </Action.P>

            <Action.S
              onClick={() => {
                setSelected(null)
                setNewRoleId(null)
                permissionsModalActions.off()
              }}
            >
              Cancel
            </Action.S>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default UsersTable
