import { PlanName, useRfqNotificationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useUpdateRfqBuyerNotificationUsers from '@/buyers/hooks/useUpdateRfqBuyerNotificationUsers'
import Action from '@/gf/components/Action'
import { Table, Tbody, Th, Thead, Tr } from '@/gf/components/Table'
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/gf/components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from '@/gf/components/ui/popover'
import { cn } from '@/gf/modules/utils'
import partition from 'lodash/partition'
import { Check } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RfqNotificationsContext } from './Notifications/context'
import NewUser from './Notifications/NewUser'
import User from './Notifications/User'
import UserDisplay from './Notifications/UserDisplay'

const Notifications = ({ title = 'Request Notifications' }: { title?: string }) => {
  const {
    user: { id: userId },
    orgId,
  } = useSession()

  const rfqId = useParams().rfqId as string
  const [newUserIds, setNewUserIds] = useState<string[]>([])
  const [userIds, setUserIds] = useState<string[]>([])
  const [view, setView] = useState<'view' | 'select' | 'add'>('view')
  const [saved, setSaved] = useState(false)
  const client = useGqlClient()
  const [updateNotificationUsers] = useUpdateRfqBuyerNotificationUsers()

  const { data } = useRfqNotificationsQuery({
    variables: { userId, orgId, filter: JSON.stringify(['id_eq', rfqId]) },
    client,
  })

  const org = data?.org
  if (org === null) return null
  const user = data?.user
  const rfq = data?.rfqs[0]

  useEffect(() => {
    if (rfq) setUserIds(rfq.buyerNotificationUsers.map((u) => u.id))
  }, [!rfq])

  useEffect(() => {
    if (saved) setTimeout(() => setSaved(false), 2000)
  }, [saved])

  const [selectedUsers, unselectedUsers] = partition(org?.users, (u) =>
    userIds.find((id) => u.id === id)
  )

  const userOptions = unselectedUsers.map((user) => ({
    user,
    value: `${user.displayName} ${user.email}`,
  }))

  const setUsers = async (newUserIds: string[]) => {
    setUserIds(newUserIds)
    await updateNotificationUsers({ variables: { rfqId, userIds: newUserIds }, update: () => {} })
    await client.reFetchObservableQueries()
    setSaved(true)
  }

  const removeUser = (userId: string) => {
    setUsers(userIds.filter((id) => id !== userId))
  }

  const addUser = (value: string) => {
    const option = userOptions.find((o) => o.value === value)
    if (!option) return
    setView('view')
    setUsers([...userIds, option.user.id])
  }

  const onNewUserAdded = async (userId: string) => {
    setNewUserIds([...newUserIds, userId])
    await setUsers([...userIds, userId])
    setView('view')
  }

  const planSubscription = data?.planSubscription

  const showAddNewUser =
    user?.can.manageUsers &&
    (org?.plan?.name === PlanName.Starter ||
      planSubscription?.plan.subscriptionType === 'php_fixed_plus' ||
      org?.buyerDashboardAccess === true)

  return (
    <RfqNotificationsContext.Provider
      value={{
        org,
        rfq,
        planSubscription,
        planPaymentMethod: data?.planPaymentMethod,
      }}
    >
      <div className="space-y-6">
        <div className="space-y-2 text-base">
          <div className="flex gap-4 justify-between items-start">
            <div className="font-medium">{title}</div>

            <div className={cn('invisible flex gap-1 items-center mr-6', saved && 'visible')}>
              <Check className="w-5 h-5 text-green-600" />
              <span className="text-sm text-gray-500">Saved</span>
            </div>
          </div>

          <div className="text-muted-foreground">
            Who should receive notifications for this request?
          </div>
        </div>

        {selectedUsers.length > 0 && (
          <Table className="min-w-min">
            <Thead>
              <Tr>
                <Th className="py-2 px-4">User</Th>
                <Th className="py-2 px-4">Role</Th>
                <Th className="py-2 px-4" />
              </Tr>
            </Thead>

            <Tbody>
              {selectedUsers.map((user) => (
                <User
                  user={user}
                  showEditRole={newUserIds.includes(user.id)}
                  removeUser={removeUser}
                  onRoleChanged={() => setSaved(true)}
                  key={user.id}
                />
              ))}
            </Tbody>
          </Table>
        )}

        {view !== 'add' ? (
          <Popover
            open={view === 'select'}
            onOpenChange={(opened) => setView(opened ? 'select' : 'view')}
          >
            <PopoverTrigger asChild>
              <Action.S role="combobox" aria-expanded={open} className="flex gap-2 items-center">
                Add user&hellip;
              </Action.S>
            </PopoverTrigger>

            <PopoverContent className="bg-white p-0 w-96">
              <Command>
                <CommandInput placeholder="Search users&hellip;" />

                <CommandList>
                  <CommandEmpty>No user found.</CommandEmpty>

                  {userOptions.map(({ user, value }) => (
                    <CommandItem key={user.id} value={value} onSelect={addUser}>
                      <UserDisplay user={user} />
                    </CommandItem>
                  ))}
                </CommandList>

                {showAddNewUser && (
                  <>
                    <CommandSeparator />

                    <Action.S onClick={() => setView('add')} className="border-0 w-full">
                      Add new user&hellip;
                    </Action.S>
                  </>
                )}
              </Command>
            </PopoverContent>
          </Popover>
        ) : (
          <NewUser onAdded={onNewUserAdded} cancel={() => setView('view')} />
        )}
      </div>
    </RfqNotificationsContext.Provider>
  )
}

export default Notifications
