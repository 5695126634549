import Action from '@/gf/components/Action'
import Modal from '@/gf/components/ModalNext'

import CloseModalButton from '@/gf/components/CloseModalButton'
import useToggle from '@/gf/hooks/useToggle'
import { ModalSize } from '@/types'

const StartOverButton = ({ reset }: { reset: () => void }) => {
  const [open, toggle] = useToggle()

  return (
    <>
      <Action.S onClick={toggle.on}>Start Over</Action.S>
      <Modal size={ModalSize.SM} open={open} onClose={toggle.off}>
        <CloseModalButton onClick={toggle.off} className="absolute top-2 right-2" />
        <h2 className="px-4 py-2 font-medium text-gray-900 text-xl flex items-center border-b border-slate-2">
          Start Over
        </h2>
        <div className="pt-6 pb-2 px-4 flex flex-col gap-y-6">
          <p className="text-base flex justify-start leading-5 text-gray-900">
            Reset the request and delete all progress.
          </p>
          <div className="flex justify-end gap-2">
            <Action.S onClick={toggle.off}>Cancel</Action.S>
            <Action.P onClick={reset}>Confirm</Action.P>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StartOverButton
