import { ChevronDownIcon } from '@heroicons/react/solid'

const DropdownAction = ({
  children,
  onClick,
}: {
  children: React.ReactNode
  onClick?: () => void
}) => (
  <span
    className="cursor-pointer text-base text-left h-full py-2 inline-flex items-center flex-shrink-0 gap-x-1"
    onClick={onClick}
  >
    {children} <ChevronDownIcon className="h-5 inline-block mt-0.5 ml-1" />
  </span>
)

export default DropdownAction
