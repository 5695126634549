import { useParams, useLocation } from 'react-router-dom'
import nth from 'lodash/nth'

import RequestForQuote from '@/gf/modules/RequestForQuote'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { useRfqCreateQuoteQuery } from '@/buyers/_gen/gql'
import useWindowWidth from '@/gf/hooks/useWindowWidth'

import Page from '@/gf/components/Page'
import Frame from '@/buyers/components/Frame'
import Layout from '@/gf/components/Layout'
import Form from './CreateQuote/Form'
import VendorCard from './CreateQuote/VendorCard'

const CreateQuote = () => {
  const {
    organization: { id: orgId },
  } = useSession()

  const location = useLocation()
  const { rfqId, vendorId } = useParams() as { rfqId: string; vendorId: string }
  const windowWidth = useWindowWidth()

  const queryResult = useRfqCreateQuoteQuery({
    variables: { orgId, filter: JSON.stringify(['id_eq', rfqId]) },
    client: useGqlClient(),
  })

  const rfq = queryResult.data && (nth(queryResult.data.rfqs, 0) || null)
  const vendorLink = rfq?.vendorLinks.find((vl) => vl.vendor.id === vendorId)

  if (!rfq || !vendorLink) return null

  const windowIsMd = windowWidth > 768

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'RFQs', href: '/rfqs' },
      { name: `RFQ ${RequestForQuote.shortenId(rfqId)}`, href: `/rfqs/${rfqId}` },
      { name: 'Enter Quote', href: location.pathname },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Enter Quote" previousPage={`/rfqs/${rfqId}/request`}>
        <Layout>
          {!windowIsMd && (
            <Layout.Section type="full">
              <VendorCard vendor={vendorLink.vendor} vertical={false} />
            </Layout.Section>
          )}

          <Layout.Section type={windowIsMd ? 'primary' : 'full'}>
            <Form rfq={rfq} vendor={vendorLink.vendor} vendorLink={vendorLink} />
          </Layout.Section>

          {windowIsMd && (
            <Layout.Section type="secondary">
              <VendorCard vendor={vendorLink.vendor} vertical />
            </Layout.Section>
          )}
        </Layout>
      </Page>
    </Frame>
  )
}

export default CreateQuote
