import { useOrderContext } from './context'

import DetailsV1 from '@/buyers/pages/StoreOrder/Details'

const Details = () => {
  const { refetchStoreOrder } = useOrderContext()
  return <DetailsV1 refetchStoreOrder={refetchStoreOrder} />
}

export default Details
