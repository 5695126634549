import { gql, useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { GraphQLError } from 'graphql'
import Promise from 'promise-polyfill'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { useEffect } from 'react'

import ModalForm from '@/gf/components/ModalForm'
import TextField from '@/gf/components/TextField'

const EDIT_USER = gql`
  mutation EditOrgUser($userId: String, $title: String, $name: String, $phoneNumber: String) {
    editOrgUser(userId: $userId, title: $title, name: $name, phoneNumber: $phoneNumber) {
      user {
        id
      }
    }
  }
`

const orgUserSchema = yup.object({
  title: yup.string().optional().nullable().label('Title'),
  name: yup.string().optional().nullable().label('Name'),
  phoneNumber: yup.string().optional().nullable().label('Phone number'),
})

type OrgUserForm = {
  title: string
  name: string
  phoneNumber: string
}

const EditOrgUserModalForm = ({ open, onClose, selected, refetch }) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [spinnerLive, spinnerToggler] = useToggle()
  const selectedOrgUser = selected || {}

  const form = useForm<OrgUserForm>({
    shouldUnregister: true,
    defaultValues: selectedOrgUser,
    resolver: yupResolver(orgUserSchema),
  })

  useEffect(() => {
    form.reset(selectedOrgUser)
  }, [selected])

  const [editUserMutation] = useMutation(EDIT_USER)

  const editOrgUser = (formData, event) => {
    event?.preventDefault()

    spinnerToggler.on()

    editUserMutation({
      variables: {
        userId: selected.id,
        title: formData.title,
        name: formData.name,
        phoneNumber: formData.phoneNumber,
      },
    })
      .then(() => {
        msgsMgr.add('User updated', 'positive')

        return refetch().then(() => {
          form.reset()

          return Promise.resolve().then(onClose)
        })
      })
      .catch((e: GraphQLError) => {
        if (e.message) {
          msgsMgr.add(e.message, 'negative')
        } else {
          msgsMgr.add('Error editing user', 'negative')
        }

        onClose()
      })
      .finally(spinnerToggler.off)
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Edit User"
      submitButtonDisabled={spinnerLive}
      submitButtonShowSpinner={spinnerLive}
      onSubmit={form.handleSubmit(editOrgUser)}
    >
      <div className="flex flex-col space-y-4">
        <TextField
          label="Phone number (optional)"
          {...form.register('phoneNumber')}
          errors={form.formState.errors.phoneNumber?.message}
        />
        <TextField
          label="Title (optional)"
          {...form.register('title')}
          errors={form.formState.errors.title?.message}
        />
        <TextField
          label="Name (optional)"
          {...form.register('name')}
          errors={form.formState.errors.name?.message}
        />
      </div>
    </ModalForm>
  )
}

export default EditOrgUserModalForm
