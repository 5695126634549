import Action from '@/gf/components/Action'
import useCurrentPosition from '@/gf/hooks/useCurrentPosition'
import useGoogleMapsScriptLoader from '@/gf/hooks/useGoogleMapsScriptLoader'
import { THICK_ORANGE_PIN } from '@/gf/modules/Map'
import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

type Point = {
  lat: number
  lng: number
}

interface Props {
  onSaveLocationClicked: (position: Point) => void
  pickLocationEnabled: boolean
  location?: Point
  className?: string
}

const Map = ({ onSaveLocationClicked, pickLocationEnabled, location, className }: Props) => {
  const currentPosition = useCurrentPosition()
  const [map, setMap] = useState<google.maps.Map>()
  const { isLoaded } = useGoogleMapsScriptLoader()
  const [selectedPoint, setSelectedPoint] = useState<Point | null>()
  const [center, setCenter] = useState<Point>()

  const _onSaveLocationClicked = (position: Point) => {
    onSaveLocationClicked(position)
    setSelectedPoint(null)
  }

  useEffect(() => {
    if (currentPosition.coords && !center) {
      setCenter({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude })
    }
  }, [currentPosition, center])

  useEffect(() => {
    if (location) {
      map?.fitBounds(new google.maps.LatLngBounds(location))
      map?.setZoom(16)
    }
  }, [location])

  if (!isLoaded) return null

  return (
    <GoogleMap
      mapContainerClassName={classNames('w-full h-[40rem] max-h-screen', className)}
      center={center}
      zoom={7}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        draggableCursor: pickLocationEnabled ? 'crosshair' : undefined,
        gestureHandling: 'greedy',
      }}
      onLoad={(m: google.maps.Map) => setMap(m)}
      onClick={(e) =>
        pickLocationEnabled &&
        e.latLng &&
        setSelectedPoint({ lat: e.latLng.lat(), lng: e.latLng.lng() })
      }
    >
      {location && (
        <MarkerF
          // animation={google.maps.Animation.DROP}
          position={location}
          title="selected location"
          icon={THICK_ORANGE_PIN}
          draggable
          onDragEnd={(e) =>
            e.latLng && _onSaveLocationClicked({ lat: e.latLng.lat(), lng: e.latLng.lng() })
          }
        />
      )}
      {selectedPoint && (
        <>
          <MarkerF
            animation={google.maps.Animation.DROP}
            position={selectedPoint}
            title="dropped pin"
            icon={THICK_ORANGE_PIN}
            draggable
            onDragEnd={(e) =>
              e.latLng && setSelectedPoint({ lat: e.latLng.lat(), lng: e.latLng.lng() })
            }
          />

          {pickLocationEnabled && (
            <InfoWindowF
              position={selectedPoint}
              options={{ pixelOffset: new google.maps.Size(0, -35, 'px') }}
              onCloseClick={() => setSelectedPoint(null)}
            >
              <div className="flex flex-col">
                <h5 className="text-base font-medium text-slate-800">Dropped Pin</h5>
                <p className="text-sm text-slate-700">
                  {selectedPoint.lat.toFixed(6)}, {selectedPoint.lng.toFixed(6)}
                </p>
                <div className="px-1 pt-3 pb-1">
                  <Action.P
                    onClick={() => _onSaveLocationClicked(selectedPoint)}
                    className="w-full"
                    size="sm"
                  >
                    Save Location
                  </Action.P>
                </div>
              </div>
            </InfoWindowF>
          )}
        </>
      )}
    </GoogleMap>
  )
}

export default Map
