import { useState } from 'react'

import { SortByInput } from '@/buyers/_gen/gql'

// Store multiple sets of SortBy values
const useSortBy = (key: string, defaultSortByValue: SortByInput) => {
  const [sortByMap, setSortByMap] = useState<Record<string, SortByInput>>({})

  const setSortBy = (sortBy: SortByInput) =>
    setSortByMap((prev) => ({
      ...prev,
      [key]: sortBy,
    }))

  return {
    sortBy: sortByMap[key] ?? defaultSortByValue,
    setSortBy,
  }
}

export default useSortBy
