import { useEffect, useState } from 'react'
import pick from 'lodash/pick'
import { Point } from '@/types'
import {
  useRfqShippingAddressCoordinatesQuery,
  RfqShippingAddressCoordinatesQuery,
  useGetAddressLocationLazyQuery,
} from '../_gen/gql'
import useGqlClient from './useGqlClient'

type Address = RfqShippingAddressCoordinatesQuery['address']

const useRfqShippingAddressCoordinates = ({
  locationId,
  addressId,
}: {
  locationId: string | null
  addressId: string | null
}) => {
  const client = useGqlClient()
  const [point, setPoint] = useState<Point | null>(null)
  const [address, setAddress] = useState<Address | null>(null)
  const [fallback, setFallback] = useState(false)

  const [getAddressLocation] = useGetAddressLocationLazyQuery({ client })

  useRfqShippingAddressCoordinatesQuery({
    client,
    skip: !locationId && !addressId,
    variables: locationId ? { locationId, addressId: null } : { addressId, locationId: null },
    onCompleted(data) {
      if (data.address?.point) {
        setPoint(pick(data.address?.point, ['lat', 'lng']) as Point)

        return
      }

      setAddress(data.address)
      setFallback(true)
    },
  })

  const findAddressLocation = async (lookupAddress: Address) => {
    if (!lookupAddress) {
      return
    }

    const result = await getAddressLocation({
      variables: {
        address: {
          lineOne: lookupAddress.lineOne,
          lineTwo: lookupAddress.lineTwo,
          city: lookupAddress.city,
          state: lookupAddress.state,
          country: lookupAddress.country,
          postalCode: lookupAddress.postalCode,
          deliverable: null,
          firstName: null,
          lastName: null,
          companyName: null,
          rdi: null,
          point: null,
        },
      },
    })

    if (!result.data?.addressLocation) {
      return
    }

    setPoint(pick(result.data?.addressLocation, ['lat', 'lng']) as Point)
  }

  useEffect(() => {
    if (fallback && address) {
      findAddressLocation(address)
    }
  }, [fallback, address])

  return point
}

export default useRfqShippingAddressCoordinates
