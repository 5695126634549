import classNames from 'classnames'

const LoadingCard = ({ className }: { className?: string }) => (
  <div className={classNames('bg-white shadow-sm border rounded-md', className)}>
    <div className="flex w-full gap-4 items-center justify-between pt-6 px-6">
      <div className="text-sm bg-slate-100 rounded-md animate-pulse w-80">&nbsp;</div>
    </div>
    <div>
      <div className="p-6 first:border-t-0 border-t-1">
        <div className="w-full pt-3 sm:pt-5 pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="text-sm bg-slate-100 rounded-md animate-pulse w-96">&nbsp;</div>
          <div className="mt-4 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
)

export const LoadingFormCard = ({ className }: { className?: string }) => (
  <div className={classNames('bg-white shadow-sm border rounded-md', className)}>
    <div>
      <div className="p-6 pb-0 grid grid-cols-2 gap-2">
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
      </div>
      <div className="p-6 pt-0 pb-0 grid grid-cols-2 gap-2">
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
      </div>
      <div className="p-6 pt-0 pb-0">
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
      </div>
      <div className="p-6 pt-0">
        <div className="w-full pb-2 rounded-md bg-center bg-no-repeat bg-cover bg-white">
          <div className="mt-2 text-base font-medium rounded-md animate-pulse bg-slate-100 vw-100">
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default LoadingCard
