import { useMemo } from 'react'
import { HeroIcon } from '../../types'

interface Props {
  icon: HeroIcon
  title: React.ReactNode
  subtitle: React.ReactNode
  children: React.ReactNode
}

const IconZeroState = ({ icon, title, subtitle, children }: Props) => {
  const IconComponent = useMemo(() => icon, [icon])
  return (
    <section className="py-10">
      <div className="flex flex-col justify-center items-center">
        <IconComponent className="h-16 text-slate-300" />
        <h4 className="font-medium text-xl text-slate-800 -mt-1">{title}</h4>
      </div>
      <p className="text-center max-w-md mx-auto text-gray-500 mt-2">{subtitle}</p>

      {children && <div className="flex justify-center mt-4">{children}</div>}
    </section>
  )
}

export default IconZeroState
