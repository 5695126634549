import { useQueryParams, StringParam, withDefault } from 'use-query-params'

const useQueryParamsOpts = { search: withDefault(StringParam, null) }

const useSearchQueryParam = () => {
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)

  const updateSearch = (newSearch: string | null | undefined) => {
    const search = newSearch && newSearch.trim()
    updateQuery({ search: search || undefined })
  }

  return { search: query.search, updateSearch }
}

export default useSearchQueryParam
