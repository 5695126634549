import { useParams, useLocation, Routes, Route } from 'react-router-dom'
import nth from 'lodash/nth'

import RequestForQuote from '@/gf/modules/RequestForQuote'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { useRfqFulfillFromInventoryQuery } from '@/buyers/_gen/gql'

import Page from '@/gf/components/Page'
import Frame from '@/buyers/components/Frame'
import CloseRequest from './FulfillFromInventory/CloseRequest'
import Form from './FulfillFromInventory/Form'

const FulfillFromInventory = () => {
  const { orgId } = useSession()
  const location = useLocation()
  const { rfqId } = useParams() as { rfqId: string }

  const queryResult = useRfqFulfillFromInventoryQuery({
    variables: { orgId, rfqFilter: JSON.stringify(['id_eq', rfqId]) },
    client: useGqlClient(),
  })

  const { rfqs, org } = queryResult.data || {}
  const rfq = nth(rfqs, 0)
  const defaultPickupLocationId = org?.locations.at(0)?.id || null
  const title = 'Fulfill from Inventory'

  const breadcrumbs = {
    copy: 'Back to Dashboard',
    crumbs: [
      { name: 'RFQs', href: '/rfqs' },
      { name: `RFQ ${RequestForQuote.shortenId(rfqId)}`, href: `/rfqs/${rfqId}` },
      { name: title, href: location.pathname },
    ],
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title={title}
        subtitle="Fulfill this request from parts you have in stock."
        previousPage={`/rfqs/${rfqId}/request`}
      >
        {rfq && <Form rfq={rfq} initPickupLocationId={defaultPickupLocationId} />}

        <Routes>
          <Route path="close-request" element={<CloseRequest />} />
        </Routes>
      </Page>
    </Frame>
  )
}

export default FulfillFromInventory
