import React from 'react'

import SessionContext from '../contexts/SessionContext'

const useSession = () => {
  const session = React.useContext(SessionContext)

  if (session === undefined) throw new Error('must be used within provider')

  return session
}

export default useSession
