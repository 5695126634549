import { gql, useQuery } from '@apollo/client'

import { Organization } from '../../types'

interface Data {
  organizations: Pick<
    Organization,
    'id' | 'name' | 'buyerDashboardAccess' | 'buyerDashboardAccessExpiresAt'
  >[]
}

const query = gql`
  query FetchOrganizations {
    organizations {
      id
      name
      buyerDashboardAccess
      buyerDashboardAccessExpiresAt
    }
  }
`

const useFetchOrganizations = () => useQuery<Data>(query)

useFetchOrganizations.Query = query
export default useFetchOrganizations
