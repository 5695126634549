import { ExclamationIcon } from '@heroicons/react/outline'

const Banner = ({ children }) => (
  <div className="bg-blue-300 font-medium text-lg text-blue-900 text-center p-3">{children}</div>
)

const RequestTutorialBanner = () => (
  <Banner>
    <ExclamationIcon className="inline-block h-5 w-5 -mt-0.5" /> This request was created by the
    tutorial and has no real value.
  </Banner>
)

const OrderTutorialBanner = () => (
  <Banner>
    <ExclamationIcon className="inline-block h-5 w-5 -mt-0.5" /> This order was created by the
    tutorial and has no real value.
  </Banner>
)

const CreateRequestTutorialBanner = () => (
  <Banner>This is a tutorial & no real parts will be ordered</Banner>
)

export { CreateRequestTutorialBanner, RequestTutorialBanner, OrderTutorialBanner }
