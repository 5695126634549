import { useState, useEffect } from 'react'
import { ApolloError } from '@apollo/client'

import { useUpdateNotificationSettingsMutation, NotificationsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

import type { UserNotificationSetting, UserNotificationSettingId } from '@/types'

type EventNotificationSettings = Record<UserNotificationSettingId, EventNotificationSetting>
export type EventNotificationSetting = Omit<UserNotificationSetting, 'key'>

type User = Pick<NotificationsQuery['user'], 'notificationSettings'>

const useNotificationsForm = ({ user }: { user?: User }) => {
  const [eventNotificationSettings, setEventNotificationSettings] = useState(
    {} as EventNotificationSettings
  )

  const [update, { loading: saving }] = useUpdateNotificationSettingsMutation({
    client: useGqlClient(),
  })

  useEffect(() => {
    if (user)
      setEventNotificationSettings(
        user.notificationSettings.reduce(
          (acc, { key, email, sms, pushNotification, scope }) => ({
            ...acc,
            [key]: { email, sms, pushNotification, scope },
          }),
          {} as EventNotificationSettings
        )
      )
  }, [!user])

  const save = (onOk?: () => void, onError?: (err: ApolloError) => void) => {
    const notificationSettings = Object.entries(eventNotificationSettings).map(([key, value]) => ({
      key: key as UserNotificationSettingId,
      email: value.email,
      sms: value.sms,
      pushNotification: value.pushNotification,
      scope: value.scope,
    }))

    update({ variables: { notificationSettings } })
      .then(() => {
        if (onOk) onOk()
      })
      .catch((err: ApolloError) => {
        if (onError) onError(err)
      })
  }

  return { eventNotificationSettings, saving, setEventNotificationSettings, save }
}

export default useNotificationsForm
