import Action from '@/gf/components/Action'
import useRequestSuggestedPart from '../pages/Reporting/Consumption/useRequestSuggestedPart'

const RequestPartButton = ({
  partNumber,
  partDescription,
  requestQty,
  orgMachineId,
}: {
  partNumber: string
  partDescription: string
  requestQty: number
  orgMachineId?: string
}) => {
  const createRequest = useRequestSuggestedPart(
    partNumber,
    partDescription,
    requestQty,
    orgMachineId ?? null
  )

  return (
    <Action.T onClick={createRequest} className="z-40 relative px-2 py-1">
      Request Part
    </Action.T>
  )
}
export default RequestPartButton
