import { useState } from 'react'
import AnimateShowIn from '@/gf/components/AnimateShowIn'
import ShippingLocationForm from '@/gf/components/ShippingLocationForm'
import { ShippingLocationsSearchQuery } from '@/buyers/_gen/gql'
import SidebarLocationView from './SidebarLocationView'

interface Props {
  shippingLocation: ShippingLocationsSearchQuery['shippingLocationsSearch']['shippingLocations'][number]
  onDataChanged: () => void
}

const Sidebar = ({ shippingLocation, onDataChanged }: Props) => {
  const [editing, setEditing] = useState<boolean>(false)
  const onAfterSave = () => {
    setEditing(false)

    onDataChanged()
  }

  return (
    <>
      <AnimateShowIn visible={editing}>
        <ShippingLocationForm
          className="w-full md:w-86"
          shippingLocation={shippingLocation}
          onAfterSave={onAfterSave}
          onCancel={() => setEditing(false)}
          allowPointSelection
        />
      </AnimateShowIn>

      <AnimateShowIn visible={!editing}>
        <SidebarLocationView
          shippingLocation={shippingLocation}
          onEditClick={() => setEditing(true)}
          onDataChanged={onDataChanged}
        />
      </AnimateShowIn>
    </>
  )
}

export default Sidebar
