import useQuoteTotals from '@/dealers/pages/Request/QuoteBuilder/useQuoteTotals'
import Summary from '@/dealers/pages/Request/Summary'
import Action from '@/gf/components/Action'
import AddAccountMachineModal from '@/gf/components/AddAccountMachineModal'
import Checkbox from '@/gf/components/Checkbox'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import Link from '@/gf/components/Link'
import Page from '@/gf/components/Page'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import QuantityInput from '@/gf/components/inputs/Quantity'
import TextInput from '@/gf/components/inputs/Text'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/gf/components/ui/alert-dialog'
import useToggle from '@/gf/hooks/useToggle'
import MoneyM from '@/gf/modules/Money'
import OrDivider from '@/retail/pages/Microsoft/OrDivider'
import { Money } from '@/types'
import { LoaderCircle, Plus } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  LogExternalOrderError,
  useAllBranchesQuery,
  useExternalOrderQuery,
  useLogExternalOrderMutation,
} from '../_gen/gql'
import Box from '../components/Box'
import Frame from '../components/Frame'
import LocationModal from '../components/LocationModal'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import Address from '../modules/Address'
import CreateVendorModal from './CreateExternalOrder/CreateVendorModal'
import ExternalVendors from './CreateExternalOrder/ExternalVendors'
import LocationSelector, { Query as LocationsQuery } from './CreateExternalOrder/LocationSelector'
import Upload from './CreateExternalOrder/Upload'
import useUpload from './CreateExternalOrder/useUpload'
import MachineSelect from './CreateRequest/MachineSelect'

type Item = {
  partNumber: string
  description: string
  unitPrice: Money | null
  quantity: number | null
}

type Fields = {
  vendorId: string | null
  locationId: string | null
  machineInvolved: boolean
  orgMachineId: string | null
  purchaseOrderNumber: string
  workOrderNumber: string
  shippingCost: Money | null
  taxCost: Money | null
  items: Item[]
}

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [
    { name: 'Orders', href: '/orders' },
    { name: 'Log Order', href: '/orders/create-external' },
  ],
}

const newItem = {
  partNumber: '',
  description: '',
  unitPrice: null,
  quantity: 1,
}

const newFields = {
  vendorId: null,
  locationId: null,
  machineInvolved: true,
  orgMachineId: null,
  purchaseOrderNumber: '',
  workOrderNumber: '',
  shippingCost: null,
  taxCost: null,
  items: [newItem],
}

const CreateExternalOrder = () => {
  const navigate = useNavigate()
  const { orgId, featureFlags, organization } = useSession()
  const client = useGqlClient()
  const { data, loading } = useExternalOrderQuery({ variables: { orgId }, client })
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches
  const [logExternalOrder] = useLogExternalOrderMutation({ client })
  const [vendorModalOpen, vendorModalToggle] = useToggle()
  const [machineModalOpen, machineModalToggle] = useToggle()
  const [locationModalOpen, locationModalToggle] = useToggle()
  const [errors, setErrors] = useState<LogExternalOrderError>()
  const [fields, setFields] = useState<Fields>(newFields)

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  const updateItemField = (index: number, updates: Partial<Item>) => {
    const items = Object.assign([], fields.items, {
      [index]: { ...fields.items[index], ...updates },
    })

    updateFields({ items })
  }

  const { uploadProcessing, uploadStarted, resetUpload, extractOrderDoc } = useUpload(
    (orderDoc) => {
      setErrors(undefined)

      const items = orderDoc.items!.map(({ properties }) => {
        const unitPrice = properties.find((p) => p.__typename === 'OrderDocItemUnitPrice')?.price
        const totalPrice = properties.find((p) => p.__typename === 'OrderDocItemTotalPrice')?.price
        const quantity = properties.find((p) => p.__typename === 'OrderDocItemQuantity')?.quantity

        return {
          partNumber: properties.find((p) => p.__typename === 'OrderDocItemPartNumber')?.text || '',
          description:
            properties.find((p) => p.__typename === 'OrderDocItemDescription')?.text || '',
          unitPrice: unitPrice || totalPrice || null,
          quantity: (unitPrice && quantity) || 1,
        }
      })

      updateFields({
        purchaseOrderNumber:
          orderDoc.properties.find((p) => p.__typename === 'OrderDocPoNumber')?.text || '',
        taxCost: orderDoc.properties.find((p) => p.__typename === 'OrderDocTax')?.price,
        shippingCost: orderDoc.properties.find((p) => p.__typename === 'OrderDocShippingCost')
          ?.price,
        items: items.length > 0 ? items : [newItem],
      })
    }
  )

  const { totals } = useQuoteTotals({
    items: fields.items.map((i) => ({
      ...i,
      unitPrice: i.unitPrice ? MoneyM.toDecimal(i.unitPrice) : null,
    })),
    taxAmount: fields.taxCost,
    shippingAmount: fields.shippingCost,
    customerDiscount: 0,
    customerFeeRate: null,
  })

  const logOrder = async () => {
    const { data } = await logExternalOrder({
      variables: {
        vendorId: fields.vendorId,
        purchaseOrderNumber: fields.purchaseOrderNumber,
        workOrderNumber: fields.workOrderNumber,
        shippingCost: fields.shippingCost,
        taxCost: fields.taxCost,
        orgMachineId: fields.machineInvolved ? fields.orgMachineId : null,
        locationId: fields.locationId,
        items: fields.items,
      },
    })

    if (data!.logExternalOrder.__typename === 'LogExternalOrderOk') {
      navigate(`/orders/${data!.logExternalOrder.storeOrderId}`)
    } else {
      setErrors(data!.logExternalOrder)
    }
  }

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Log Order">
        <CreateVendorModal
          open={vendorModalOpen}
          onClose={vendorModalToggle.off}
          onVendorAdded={(vendorId) => updateFields({ vendorId })}
        />

        <AddAccountMachineModal
          open={machineModalOpen}
          onClose={machineModalToggle.off}
          onAdd={(om) => {
            if (om) updateFields({ orgMachineId: om.id })
          }}
          accountId={organization.id}
        />

        <LocationModal
          open={locationModalOpen}
          onClose={locationModalToggle.off}
          onComplete={(locationId) => updateFields({ locationId })}
          branches={(organization.requireBillingCompany ? branches : []) ?? []}
          buyers={[]}
          initialAddress={Address.init()}
          refetchQueries={[LocationsQuery]}
          showPersist
        />

        {uploadProcessing && (
          <AlertDialog open>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="flex gap-2 items-center">
                  <LoaderCircle className="animate-spin" /> Processing&hellip;
                </AlertDialogTitle>

                <AlertDialogDescription>
                  This may take up to a minute. Order details will be provided from the information
                  in this file.
                </AlertDialogDescription>
              </AlertDialogHeader>

              <AlertDialogFooter>
                <AlertDialogCancel onClick={resetUpload}>Cancel</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}

        <Form
          onSubmit={logOrder}
          className="flex flex-wrap gap-x-6 gap-y-4 md:flex-nowrap max-w-screen-lg mt-4"
        >
          <Box className="space-y-8 text-sm">
            {featureFlags.logOrderUpload && (
              <>
                <Upload onStarted={uploadStarted} onComplete={extractOrderDoc} />
                <OrDivider />
              </>
            )}

            <div className="space-y-4">
              <div className="text-xl">Provide Order Details</div>

              <Field label="Vendor" errors={errors?.vendorId}>
                <div className="flex items-center gap-x-4">
                  <ExternalVendors
                    value={fields.vendorId}
                    onChange={(vendorId) => updateFields({ vendorId })}
                    vendors={data?.org?.externalVendors}
                    loading={loading}
                  />

                  <Action.T onClick={vendorModalToggle.on}>Add vendor</Action.T>
                </div>
              </Field>

              <Field label="Location" errors={errors?.locationId} className="">
                <div className="flex items-center gap-x-4">
                  <LocationSelector
                    value={fields.locationId}
                    onChange={(locationId) => updateFields({ locationId })}
                  />

                  <Action.T onClick={locationModalToggle.on}>Add location</Action.T>
                </div>
              </Field>

              <Field label="Machine">
                <Checkbox
                  label="No machine involved"
                  checked={!fields.machineInvolved}
                  onChange={({ target }) => updateFields({ machineInvolved: !target.checked })}
                />

                {fields.machineInvolved && (
                  <div className="flex items-center gap-x-4">
                    <div className="w-full max-w-xs">
                      <MachineSelect
                        value={fields.orgMachineId}
                        onChange={(orgMachineId) => updateFields({ orgMachineId })}
                      />
                    </div>

                    <Action.T onClick={machineModalToggle.on}>Add machine</Action.T>
                  </div>
                )}
              </Field>
            </div>

            {fields.items.map((item, index) => (
              <div className="space-y-3">
                <div className="flex justify-between">
                  <h4 className="text-lg font-medium">
                    Part {fields.items.length > 1 ? index + 1 : null}
                  </h4>

                  {index > 0 && (
                    <Action.T
                      onClick={() =>
                        updateFields({ items: fields.items.filter((_, i) => i !== index) })
                      }
                    >
                      Remove
                    </Action.T>
                  )}
                </div>

                <div className="flex gap-x-2">
                  <Field
                    label="Part Number"
                    errors={errors?.items?.at(index)?.partNumber}
                    className="w-1/3"
                  >
                    <TextInput
                      value={item.partNumber}
                      setValue={(partNumber) => updateItemField(index, { partNumber })}
                      placeholder="ie. ES3897B"
                    />
                  </Field>

                  <Field
                    label="Quantity"
                    errors={errors?.items?.at(index)?.quantity}
                    className="w-1/3"
                  >
                    <QuantityInput
                      value={item.quantity}
                      setValue={(quantity) => updateItemField(index, { quantity })}
                    />
                  </Field>

                  <Field
                    label="Unit Price"
                    errors={errors?.items?.at(index)?.unitPrice}
                    className="w-1/3"
                  >
                    <PriceV2
                      price={item.unitPrice}
                      onChange={(unitPrice) => updateItemField(index, { unitPrice })}
                    />
                  </Field>
                </div>

                <Field label="Part Description" errors={errors?.items?.at(index)?.description}>
                  <TextInput
                    value={item.description}
                    setValue={(description) => updateItemField(index, { description })}
                    placeholder="ie. Air Filter, Fuel Pump"
                  />
                </Field>
              </div>
            ))}

            <div>
              <Action.S
                onClick={() => updateFields({ items: [...fields.items, newItem] })}
                className="flex gap-1 items-center"
              >
                <Plus className="h-5 w-5" /> Add part
              </Action.S>
            </div>
          </Box>

          <div className="relative">
            <div className="w-80 flex flex-col gap-y-4 sticky top-4">
              <Box className="shadow-base p-6 space-y-4">
                <Field
                  label={`PO number${
                    !organization.generatePurchaseOrderNumber ? ' (optional)' : ''
                  }`}
                >
                  {featureFlags.poNumberGenerator && organization.generatePurchaseOrderNumber ? (
                    <div className="text-sm block">
                      <div className="flex gap-x-3">
                        <p className="italic text-gray-500 flex-grow">Auto-Generated</p>

                        <Link.T to="/settings/organization#approval-settings">Edit</Link.T>
                      </div>
                    </div>
                  ) : (
                    <TextInput
                      value={fields.purchaseOrderNumber}
                      setValue={(purchaseOrderNumber) => updateFields({ purchaseOrderNumber })}
                    />
                  )}
                </Field>

                <Field label="Work Order number (optional)">
                  <TextInput
                    value={fields.workOrderNumber}
                    setValue={(workOrderNumber) => updateFields({ workOrderNumber })}
                  />
                </Field>
              </Box>

              <Box className="shadow-base p-6 space-y-4">
                <Field label="Shipping cost (optional)" className="mt-4">
                  <PriceV2
                    price={fields.shippingCost}
                    onChange={(shippingCost) => updateFields({ shippingCost })}
                  />
                </Field>

                <Field label="Sales tax (optional)">
                  <PriceV2
                    price={fields.taxCost}
                    onChange={(taxCost) => updateFields({ taxCost })}
                  />
                </Field>

                <Summary
                  total={totals.total}
                  subtotal={totals.subtotal}
                  discount={totals.discount}
                  discountPercent={null}
                  shippingCost={fields.shippingCost}
                  taxCost={fields.taxCost ?? zeroPrice}
                  customerFee={totals.customerFee}
                  refunded={MoneyM.fromInt(0, 'USD')}
                  showShippingCost
                />
              </Box>

              <Action.P type="submit" className="w-full">
                Log Order
              </Action.P>

              <Action.S onClick={() => setFields(newFields)}>Reset Form</Action.S>
            </div>
          </div>
        </Form>
      </Page>
    </Frame>
  )
}

export default CreateExternalOrder
