import { PartStoreOrdersQuery, usePartStoreOrdersQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import ReportingTable from '@/gf/components/Reports/ReportingTable'
import Id from '@/gf/modules/Id'
import MoneyM from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import { Money } from '@/types'
import { DateTime } from 'luxon'

const formatPrice = (price?: Money) => (price ? MoneyM.format(price) : null)

const getMachineColumn = ({
  order,
}: PartStoreOrdersQuery['periodicPartRequest']['storeOrders'][number]) => {
  const orgMachine = order?.requestForQuote?.orgMachines[0]
  return (
    <>
      {orgMachine ? (
        [orgMachine.machine.year, orgMachine.machine.make, orgMachine.machine.model]
          .filter((i) => !!i)
          .join(' ')
      ) : (
        <span className="italic text-gray-500">No Machine</span>
      )}
    </>
  )
}

const getRequestColumn = (
  storeOrder: PartStoreOrdersQuery['periodicPartRequest']['storeOrders'][number]
) => {
  const rfq = storeOrder.order.requestForQuote

  const purchaseOrder = StoreOrderM.isQuoteApproved(storeOrder) ? storeOrder.purchaseOrder : null

  return rfq ? (
    purchaseOrder ? (
      <div className="flex flex-col items-start leading-snug">
        <Link.T key={purchaseOrder} className="text-sm" to={`/rfqs/${rfq.id}`} target="_blank">
          {`PO #${purchaseOrder}`}
        </Link.T>

        <span className="text-xs text-gray-500">Request {Id.shorten(rfq.id)}</span>
      </div>
    ) : (
      <Link.T className="text-sm" to={`/rfqs/${rfq.id}`} target="_blank">
        Request {Id.shorten(rfq.id)}
      </Link.T>
    )
  ) : (
    <Link.T className="text-sm" to={`/orders/${storeOrder.id}`} target="_blank">
      Order {Id.shorten(storeOrder.id)}
    </Link.T>
  )
}

const PartStoreOrders = ({ periodicPartId }: { periodicPartId: string }) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const { data } = usePartStoreOrdersQuery({
    client,
    variables: { periodicPartRequestId: periodicPartId, orgId },
  })

  return (
    <Box className="px-4 pt-6 rounded-xl space-y-3">
      <h3 className="text-lg text-gray-900 font-medium">
        {data?.periodicPartRequest.numOfOrders} {data?.periodicPartRequest.partDescription} (#
        {data?.periodicPartRequest.partNumber}) orders
      </h3>
      <ReportingTable
        data={data?.periodicPartRequest.storeOrders.sort((a, b) =>
          !a || !b ? 0 : b.insertedAt.diff(a.insertedAt, 'milliseconds').milliseconds
        )}
        getRowKey={({ id }) => id}
        columns={[
          {
            header: 'Request',
            getValue: getRequestColumn,
          },
          {
            header: 'Ordered At',
            getValue: (row) => row?.insertedAt.toLocaleString(DateTime.DATE_SHORT),
          },
          {
            header: 'Cost',
            getValue: (row) =>
              formatPrice(
                row?.lineItems.find((li) => li.product.mpn === data?.periodicPartRequest.partNumber)
                  ?.extendedPrice
              ),
          },
          {
            header: 'Machine',
            getValue: getMachineColumn,
          },
          {
            header: 'Vendor',
            getValue: (row) => row?.store.name,
          },
        ]}
      />
    </Box>
  )
}

export default PartStoreOrders
