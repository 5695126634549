import Button from '@/gf/components/ButtonOld'
import LinkButton from '@/gf/components/LinkButtonOld'
import TextArea from '@/gf/components/TextArea'
import useMsgs from '@/gf/hooks/useMsgs'
import { TIMELINE_QUERY_FROM_RFQ_ID } from '@/gf/hooks/useTimelineEvents'
import useToggle from '@/gf/hooks/useToggle'
import { ADD_TIMELINE_NOTE } from '@/gf/mutations/quotes'
import { useMutation } from '@apollo/client'
import { useState } from 'react'

type RFQCommentProps = {
  rfqId: string
  refetch: () => void
  isAdmin: boolean
  openMessagesTab: () => void
}

export default function RFQCommentForm({
  rfqId,
  refetch,
  isAdmin,
  openMessagesTab,
}: RFQCommentProps) {
  const [commentText, setCommentText] = useState('')
  const [addTimelineEvent] = useMutation(ADD_TIMELINE_NOTE)
  const [_msgs, msgsMgr] = useMsgs()
  const [spinnerLive, spinnerToggler] = useToggle()

  const addTimelineNote = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    privacy: 'public' | 'protected' | 'private',
    storeId?: string
  ) => {
    e.preventDefault()

    if (commentText.trim() === '') {
      msgsMgr.add('Please insert a comment', 'negative')
      return
    }

    spinnerToggler.on()

    const variables = storeId
      ? { privacy, note: commentText, requestForQuoteId: rfqId, storeId }
      : { privacy, note: commentText, requestForQuoteId: rfqId }

    addTimelineEvent({
      variables,
      refetchQueries: [TIMELINE_QUERY_FROM_RFQ_ID],
    })
      .then(() => {
        msgsMgr.add('Added Comment', 'positive')
        setCommentText('')
        refetch()
      })
      .catch(() => {
        const message = 'There was an error adding your comment.'
        msgsMgr.add(message, 'negative')
      })
      .finally(spinnerToggler.off)
  }

  return (
    <form className="space-y-2">
      <TextArea
        placeholder="Leave a comment..."
        title="Comment"
        required
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
      />

      <div className="flex justify-between items-start">
        <Button
          title="Post"
          disabled={!commentText || spinnerLive}
          performing={spinnerLive}
          onClick={(e) => addTimelineNote(e, isAdmin ? 'private' : 'protected')}
        />

        <div className="text-sm text-gray-500 space-x-2 space-y-0.5 text-right">
          <p>Only you and your team can see comments</p>

          <p>
            To contact a vendor or Gearflow, go to the{' '}
            <LinkButton onClick={openMessagesTab}>messages tab</LinkButton>
          </p>
        </div>
      </div>
    </form>
  )
}
