import { JsonParam, NumberParam, useQueryParams, withDefault } from 'use-query-params'

import { Filter } from '../../types'

import useSession from '../hooks/useSession'
import useConfig from '@/gf/hooks/useConfig'
import useAllEvents from '@/gf/hooks/useAllEvents'
import useGqlClient from '../hooks/useGqlClient'

import FilterSet from '@/gf/modules/FilterSet'
import FormatFilters from '@/gf/modules/FormatFilters'
import StoreOrderEvent from '@/gf/modules/StoreOrderEvent'
import RfqEvent from '@/gf/modules/RfqEvent'

import Layout from '@/gf/components/Layout'
import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import NotificationsTable from '@/gf/components/NotificationsTable'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Notifications', href: '/notifications' }],
}

const useQueryParamsOpts = {
  page: withDefault(NumberParam, 1),
  filters: withDefault<Filter[], Filter[]>(JsonParam, []),
}

const Notifications = () => {
  const { featureFlags, organization } = useSession()
  const gqlClient = useGqlClient()
  const config = useConfig()
  const { buyersUrl } = config
  const [query, updateQuery] = useQueryParams(useQueryParamsOpts)
  const setPage = (newPage: number) => updateQuery({ page: newPage })
  // Reset to page 1 when the filters are updated
  const setFilters = (newFilters: Filter[]) => updateQuery({ page: 1, filters: newFilters })

  // Org filter added by default in the backend
  const filters = FormatFilters.andFilters(FilterSet.toApiFilters(query.filters))

  const { loading, error, data } = useAllEvents({ page: query.page, filters })

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title="Notifications"
        subtitle={
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              These are all the events across your Account &apos;{organization.name}&apos;
            </p>
          </div>
        }
      >
        <Layout>
          {error ? (
            <Layout.Section type="full">
              <p className="text-sm">Failed to fetch notifications. Please contact support.</p>
            </Layout.Section>
          ) : (
            <NotificationsTable
              page={query.page}
              filters={query.filters}
              updatePage={setPage}
              updateFilters={setFilters}
              events={data?.events ?? []}
              pagination={data?.pagination ?? { totalPages: 1, totalResults: 0 }}
              loading={loading}
              featureFlags={featureFlags}
              dashboardUrl={buyersUrl}
              getStoreOrderEventDisplayData={(storeOrderEvent) =>
                StoreOrderEvent.getBuyerEventDisplayData(storeOrderEvent)
              }
              getRequestForQuoteEventDisplayData={(requestForQuoteEvent) =>
                RfqEvent.getEventDisplayData(true, config)(requestForQuoteEvent)
              }
              gqlClient={gqlClient}
            />
          )}
        </Layout>
      </Page>
    </Frame>
  )
}

export default Notifications
