import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'

const Actions = ({
  onAdd,
  cancel,
  submitting,
  navigateToStepIndex,
}: {
  onAdd: () => void
  cancel: () => void
  submitting: boolean
  navigateToStepIndex: (index: number) => void
}) => (
  <Card.Section>
    <div className="flex gap-6 justify-end">
      <Action.T onClick={cancel}>
        <span className="text-sm">Cancel</span>
      </Action.T>

      <div className="flex gap-2">
        <Action.S onClick={() => navigateToStepIndex(1)}>Previous</Action.S>

        <Action.P onClick={onAdd} disabled={submitting} performing={submitting}>
          Add User
        </Action.P>
      </div>
    </div>
  </Card.Section>
)

export default Actions
