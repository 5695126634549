import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Tooltip from '@/gf/components/Tooltip'
import Phone from '@/gf/modules/Phone'
import {
  AtSymbolIcon,
  PhoneIcon,
  StarIcon as SolidStarIcon,
  UserIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'
import { VendorContact } from '../../../_gen/gql'

const ContactDataLine = ({ children }: { children: React.ReactNode }) => (
  <span className="inline-flex items-center gap-x-1.5">{children}</span>
)

const ContactList = ({
  contacts,
  onPreferredChanged,
}: {
  contacts: VendorContact[]
  onPreferredChanged: (vendorContactId: string, preferred: boolean) => void
}) => (
  <ul className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
    {(contacts || []).map((c) => (
      <li
        key={c?.id}
        className="bg-white rounded-lg border border-slate-200 shadow overflow-x-hidden"
      >
        <div className="flex h-full flex-col gap-y-1 border-l-4 border-gearflow p-2 relative">
          {c?.name && (
            <ContactDataLine>
              <UserIcon className="text-slate-400 inline h-5 flex-shrink-0" />
              <span className="font-semibold text-zinc-600">{c?.name}</span>
            </ContactDataLine>
          )}
          <ContactDataLine>
            <AtSymbolIcon className="text-slate-400 inline h-5 flex-shrink-0" />
            <A.T href={`mailto:${c?.email}`}>
              <span className="text-sm break-all">{c?.email}</span>
            </A.T>
          </ContactDataLine>
          {c?.phoneNumber && (
            <ContactDataLine>
              <PhoneIcon className="text-slate-400 inline h-5 flex-shrink-0" />
              <A.T className="text-sm text-slate-600" href={`tel:${c.phoneNumber}`}>
                {Phone.format(c.phoneNumber)}
              </A.T>
            </ContactDataLine>
          )}

          <Tooltip
            autoPosition
            className="absolute top-2 right-2"
            text="Always included in you future requests."
          >
            <Action.T onClick={() => onPreferredChanged(c.id, !c.preferred)}>
              <SolidStarIcon
                className={classNames(
                  'h-6 inline-block',
                  c.preferred ? 'text-amber-400' : 'text-slate-300 hover:text-amber-200'
                )}
              />
            </Action.T>
          </Tooltip>
        </div>
      </li>
    ))}
  </ul>
)

export default ContactList
