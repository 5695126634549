import { useState } from 'react'
import { DateTime } from 'luxon'
import { v4 as uuid } from 'uuid'

import type { Money } from '@/types'

import MoneyM from '@/gf/modules/Money'
import useMsgs from '@/gf/hooks/useMsgs'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useCreateInternalQuoteMutation, RfqFulfillFromInventoryQuery } from '@/buyers/_gen/gql'

type Rfq = RfqFulfillFromInventoryQuery['rfqs'][number]

type Part = {
  id: string
  mpn: string
  rfqPartMpn: string
  rfqPartId: string | null
  name: string
  quantity: number | null
  inStock: boolean
  availableAt: DateTime | null
}

type Fields = {
  parts: Part[]
  quoteNumber: string
  shippingCost: Money | null
  taxCost: Money | null
  pickupLocationId: string | null
}

type Errors = { parts: Record<string, string | null>[]; pickupLocationId?: string }

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const buildPart = (attrs?: Partial<Part>) => ({
  id: uuid(),
  mpn: '',
  rfqPartMpn: '',
  rfqPartId: null,
  name: '',
  quantity: 1,
  inStock: true,
  availableAt: null,
  ...(attrs || {}),
})

const useFulfillFromInventoryForm = ({
  rfq,
  initPickupLocationId,
  onOk,
}: {
  rfq: Rfq
  initPickupLocationId: string | null
  onOk: () => void
}) => {
  const [fields, setFields] = useState<Fields>(() => {
    const initParts =
      rfq.parts.length > 0
        ? rfq.parts.map((p) =>
            buildPart({
              mpn: p.mpn,
              rfqPartMpn: p.mpn,
              rfqPartId: p.id,
              name: p.description,
              quantity: p.quantity,
            })
          )
        : [buildPart()]

    return {
      parts: initParts,
      quoteNumber: '',
      shippingCost: zeroPrice,
      taxCost: zeroPrice,
      pickupLocationId: initPickupLocationId,
    }
  })

  const [errors, setErrors] = useState<Errors>({ parts: [] })
  const [submitting, setSubmitting] = useState(false)
  const [createInternalQuote] = useCreateInternalQuoteMutation({ client: useGqlClient() })
  const [_, msgr] = useMsgs()

  const updatePart = (id: string, updates: Partial<Part>) =>
    setFields((prevFields) => ({
      ...prevFields,
      parts: prevFields.parts.map((p) => (p.id === id ? { ...p, ...updates } : p)),
    }))

  const updateFields = (updates: Partial<Fields>) => setFields({ ...fields, ...updates })

  const addPart = () => setFields({ ...fields, parts: [...fields.parts, buildPart()] })

  const removePart = (id: string) =>
    setFields({ ...fields, parts: fields.parts.filter((p) => p.id !== id) })

  const submit = () => {
    setSubmitting(true)

    const parts = fields.parts.map((part) => {
      const availableAt = !part.inStock && part.availableAt ? part.availableAt.toISODate() : null

      return {
        mpn: part.mpn,
        name: part.name,
        quantity: part.quantity,
        unitPrice: zeroPrice,
        inStock: part.inStock,
        availableAt,
        rfqPartId: part.rfqPartId,
      }
    })

    createInternalQuote({
      variables: {
        rfqId: rfq.id,
        parts,
        quoteNumber: fields.quoteNumber,
        shippingCost: fields.shippingCost,
        taxCost: fields.taxCost,
        pickupLocationId: fields.pickupLocationId,
      },
    })
      .then(() => {
        onOk()
      })
      .catch((err) => {
        const gqlError = err.graphQLErrors[0]

        if (gqlError) {
          const partsErrors =
            gqlError.details.parts?.map((p) => ({
              mpn: p.mpn ? p.mpn.join(' / ') : null,
              name: p.name ? p.name.join(' / ') : null,
              quantity: p.quantity ? p.quantity.join(' / ') : null,
            })) || []

          const pickupLocationId = gqlError.details.pickup_location_id
          setErrors({ parts: partsErrors, pickupLocationId })
          msgr.add(gqlError.message, 'negative')
        } else msgr.addUnknownError()
      })
      .finally(() => setSubmitting(false))
  }

  return {
    fields,
    submitting,
    errors,
    updateFields,
    addPart,
    removePart,
    updatePart,
    submit,
  }
}

export default useFulfillFromInventoryForm
