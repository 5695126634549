import { CheckCircleIcon } from '@heroicons/react/solid'

import OfficeBuildingTwo from '@/gf/components/OfficeBuildingTwo'

const BalancePendingApproval = () => (
  <>
    <div className="-left-4 bg-blue-100 opacity-90 rounded-full w-20 h-20 absolute top-1/2 transform -translate-y-1/2">
      <OfficeBuildingTwo className="absolute top-1/2 transform -translate-y-1/2 w-12 h-12 left-1/2 -translate-x-1/2 text-blue-600 opacity-90" />
    </div>
    <div className="pl-20 flex flex-1 items-center justify-between">
      <div className="block py-2 sm:p-2">
        <div>
          <h2 className="text-base font-medium text-gray-900">Your application is incomplete</h2>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          Please link your bank account to continue with the approval process.
        </div>
        <div className="mt-4 pb-0 text-sm text-gray-700" />
      </div>
    </div>
  </>
)

const PendingApproveNetTerms = () => (
  <>
    <div className="-left-4 bg-blue-100 opacity-90 rounded-full w-20 h-20 absolute top-1/2 transform -translate-y-1/2">
      <OfficeBuildingTwo className="absolute top-1/2 transform -translate-y-1/2 w-12 h-12 left-1/2 -translate-x-1/2 text-blue-600 opacity-90" />
    </div>
    <div className="pl-20 flex flex-1 items-center justify-between">
      <div className="block py-2 sm:p-2">
        <div>
          <h2 className="text-base font-medium text-gray-900">Your application is pending</h2>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          You will be notified on your status once the approval process is complete.
        </div>
        <div className="mt-4 pb-0 text-sm text-gray-700" />
      </div>
    </div>
  </>
)

const CompleteApproveNetTerms = () => (
  <>
    <div className="-left-4 bg-green-100 opacity-90 rounded-full w-20 h-20 absolute top-1/2 transform -translate-y-1/2">
      <CheckCircleIcon className="absolute top-1/2 transform -translate-y-1/2 w-20 h-20 left-1/2 -translate-x-1/2 text-green-500 opacity-90" />
    </div>
    <div className="pl-20 flex flex-1 items-center justify-between">
      <div className="block py-2 sm:p-2">
        <div>
          <h2 className="text-base font-medium text-green-500">Approved</h2>
        </div>
        <div className="mt-2 text-sm text-gray-700">
          You have been approved for net 30 terms and will see an option to pay by invoice at
          checkout.
        </div>
        <div className="mt-4 pb-0 text-sm text-gray-700" />
      </div>
    </div>
  </>
)

export { PendingApproveNetTerms, BalancePendingApproval, CompleteApproveNetTerms }
