import Section from './Section'
import partsSpend from './parts_spend.svg'

const PartsSpent = () => (
  <Section
    title="Parts Spend"
    text={[
      'Know exactly how much your spending each week, what machines are driving up your costs, and get insights on reducing your overall spend.',
      'Also, add your parts spend budget and monitor it each month to make sure your spend is on track.',
    ]}
  >
    <img src={partsSpend} alt="" className="h-full w-full object-contain object-bottom" />
  </Section>
)

export default PartsSpent
