import { SessionQuery, useTrackUserIsActiveMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useLocalStorage from '@/gf/hooks/useLocalStorage'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useTrackUserIsActive = (session: SessionQuery['session'] | undefined) => {
  const location = useLocation()
  const client = useGqlClient()
  const [trackUserIsActive] = useTrackUserIsActiveMutation()

  const { value: storageLastActiveAt, setValue: setStoreLastActiveAt } =
    useLocalStorage<DateTime | null>(
      'lastActiveAt',
      null,
      (rawValue) => DateTime.fromISO(rawValue),
      (value) => value?.toISO() ?? null
    )

  // Update when the user was last active at
  useEffect(() => {
    // Refresh every hour
    if (
      session?.user &&
      (!storageLastActiveAt || storageLastActiveAt.diffNow('hours').hours < -1)
    ) {
      trackUserIsActive({ client }).then(() => {
        setStoreLastActiveAt(DateTime.now())
      })
    }
  }, [location, session, storageLastActiveAt])
}

export default useTrackUserIsActive
