import { PlusIcon } from '@heroicons/react/outline'

import type { Money } from '@/types'

import { useInventoryAutocompleteLazyQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import TextInput from '@/gf/components/TextInput'
import PriceV2 from '@/gf/components/inputs/PriceV2'
import QuantityInput from '@/gf/components/inputs/Quantity'
import Typeahead from '@/gf/components/next/Typeahead'

type Part = {
  id: string
  mpn: string
  rfqPartMpn: string
  rfqPartId: string | null
  name: string
  quantity: number | null
  unitPrice?: Money | null
}

const PartsForm = ({
  parts,
  errors,
  updatePart,
  removePart,
  addPart,
  autoCompleteFromInventory = false,
}: {
  parts: Part[]
  errors: Record<string, string | null>[]
  updatePart: (id: string, updates: Partial<Part>) => void
  removePart: (id: string) => void
  addPart: () => void
  autoCompleteFromInventory?: boolean
}) => {
  const [fetchInventory] = useInventoryAutocompleteLazyQuery({
    client: useGqlClient(),
  })

  const onFetchOptions = async (search: string) => {
    const { data } = await fetchInventory({ variables: { search } })
    return data?.inventory.results ?? []
  }

  return (
    <div className={parts.length > 1 ? 'divide-y' : ''}>
      {parts.map((part, index) => (
        <div className="flex flex-wrap gap-4 justify-between py-6 first:pt-0" key={part.id}>
          <div className="flex gap-4 flex-wrap grow">
            <div className="grow space-y-2">
              <div className="flex gap-4 flex-wrap grow">
                <Field label="Part Number" errors={errors[index]?.mpn} htmlFor={`${part.id}-mpn`}>
                  <div className="w-40">
                    {autoCompleteFromInventory ? (
                      <Typeahead
                        value={part.mpn}
                        onChange={(mpn) => updatePart(part.id, { mpn })}
                        onFetchOptions={onFetchOptions}
                        placeholder="Part description"
                        className="w-full"
                        renderOption={(o) => `${o.mpn} - ${o.name}`}
                        transformSelection={(o) => o.mpn || ''}
                        afterSelection={(o) => updatePart(part.id, { name: o.name || '' })}
                        id={`${part.id}-mpn`}
                      />
                    ) : (
                      <TextInput
                        value={part.mpn}
                        setValue={(mpn) => updatePart(part.id, { mpn })}
                        id={`${part.id}-mpn`}
                      />
                    )}
                  </div>
                </Field>

                <Field
                  label="Description"
                  errors={errors[index]?.name}
                  htmlFor={`${part.id}-name`}
                  className="grow"
                >
                  <div className="min-w-72">
                    {autoCompleteFromInventory ? (
                      <Typeahead
                        value={part.name}
                        onChange={(name) => updatePart(part.id, { name })}
                        onFetchOptions={onFetchOptions}
                        placeholder="Part description"
                        className="w-full"
                        renderOption={(o) => `${o.mpn} - ${o.name}`}
                        transformSelection={(o) => o.name || ''}
                        afterSelection={(o) => updatePart(part.id, { mpn: o.mpn || '' })}
                        id={`${part.id}-name`}
                      />
                    ) : (
                      <TextInput
                        value={part.name}
                        setValue={(name) => updatePart(part.id, { name })}
                        id={`${part.id}-name`}
                      />
                    )}
                  </div>
                </Field>
              </div>

              {part.rfqPartId && part.rfqPartMpn && part.mpn !== part.rfqPartMpn && (
                <div className="flex px-2 py-1 bg-gray-100 border border-gray-300 rounded-md text-sm max-w-prose">
                  <div>
                    Requested part: <span className="font-medium">{part.rfqPartMpn}</span>. If this
                    is different from the requested part, remove it and add a new part.
                  </div>
                </div>
              )}
            </div>

            <Field
              label="Quantity"
              errors={errors[index]?.quantity}
              htmlFor={`${part.id}-quantity`}
            >
              <div className="w-24">
                <QuantityInput
                  value={part.quantity}
                  setValue={(quantity) => updatePart(part.id, { quantity })}
                  min={1}
                  id={`${part.id}-quantity`}
                />
              </div>
            </Field>

            {part.unitPrice !== undefined && (
              <Field label="Unit Price" errors={errors[index]?.unitPrice}>
                <div className="w-32">
                  <PriceV2
                    price={part.unitPrice}
                    onChange={(price) => updatePart(part.id, { unitPrice: price ?? null })}
                  />
                </div>
              </Field>
            )}
          </div>

          {parts.length > 1 && (
            <div className="justify-end">
              <Action.T
                className="flex gap-1 items-center whitespace-nowrap mt-8"
                onClick={() => removePart(part.id)}
              >
                Remove
              </Action.T>
            </div>
          )}
        </div>
      ))}

      <div className={parts.length > 1 ? 'pt-6' : ''}>
        <Action.S className="flex gap-1 items-center whitespace-nowrap" onClick={() => addPart()}>
          <PlusIcon className="w-5 h-5" />
          Add Another Part
        </Action.S>
      </div>
    </div>
  )
}

export default PartsForm
