import React from 'react'
import { ApolloError } from '@apollo/client'

import useGqlClient from '../hooks/useGqlClient'

import useUppy from '@/gf/hooks/useUppy'
import useMsgs from '@/gf/hooks/useMsgs'

import useToggle from '@/gf/hooks/useToggle'

import ModalForm from '@/gf/components/ModalForm'
import UppyFileInput from '@/gf/components/UppyFileInput'
import { useUploadInventoryMutation } from '@/buyers/_gen/gql'
import InputLabel from '@/gf/components/InputLabel'

interface File {
  id: string
  name: string
  extension: string
  type?: string
  data: string
}

const allowedFileTypes = ['.csv', 'text/csv']

const UploadInventory = ({ show, close }: { show: boolean; close: () => void }) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinnerToggle] = useToggle()
  const [_uploadFile, setUploadFile] = React.useState<File>()
  const [update] = useUploadInventoryMutation({ client: useGqlClient() })

  const uppy = useUppy({
    autoProceed: false,
    onFilesAdded: ([file]) => {
      const reader = new FileReader()
      reader.onloadend = (e) => {
        if (e.target?.result && typeof e.target.result === 'string') {
          const fileWithData: File = {
            id: file.id,
            name: file.name,
            extension: file.extension,
            type: file.type,
            data: e.target.result,
          }
          setUploadFile(fileWithData)
        }
      }
      reader.readAsDataURL(file.data)
    },
    allowedFileTypes,
    onComplete: ([file]) => {
      update({ variables: { url: file.url, leadTime: null } })
        .then(() => {
          msgr.add(
            'Your file is being processed. This may take a few minutes depending on the number of parts uploaded.',
            'positive'
          )
        })
        .catch((err: ApolloError) => {
          const message = err.graphQLErrors.reduce((acc, error) => `${acc}${error.message}`, '')
          msgr.add(message, 'negative')
        })
        .finally(() => {
          spinnerToggle.off()
          close()
        })
    },
    maxNumberOfFiles: 1,
  })

  return (
    <>
      <ModalForm
        title="Upload Inventory"
        open={show}
        onClose={close}
        onSubmit={(e) => {
          e.preventDefault()
          spinnerToggle.on()
          uppy.upload()
        }}
        submitButtonShowSpinner={spinnerLive}
      >
        <div className="mt-6 flex flex-col space-y-8">
          <div className="flex flex-col gap-y-0.5">
            <p className="text-sm font-medium text-gray-700">
              Example inventory CSV file:{' '}
              <span className="font-mono text-gray-800">inventory.csv</span>
            </p>
            <blockquote className="px-2 py-1 text-sm text-gray-800 font-mono bg-gray-100 rounded-sm">
              <span className="block">mpn,name,quantity,price</span>
              <span className="block">&quot;ABC123&quot;,&quot;Seal&quot;,12,&quot;2.49&quot;</span>
              <span className="block">
                &quot;0R0781&quot;,&quot;Fuel Injector&quot;,5,&quot;99.00&quot;
              </span>
            </blockquote>
          </div>
          <InputLabel label="Inventory file">
            <UppyFileInput
              required
              uppy={uppy}
              onFileInputError={(error) => msgr.add(error, 'negative')}
              allowedFileTypes={allowedFileTypes}
            />
          </InputLabel>
        </div>
      </ModalForm>
    </>
  )
}

export default UploadInventory
