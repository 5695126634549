import { ModalSize } from '@/types'

import MoneyM from '@/gf/modules/Money'

import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Subscribe from '@/gf/components/PlanAndBilling'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'

import {
  useAddPhpFixedPlusSubscriptionMutation,
  useAllPlansQuery,
  useSettingsBillingDetailsQuery,
  useUsersQuery,
} from '@/buyers/_gen/gql'

import type { Money, Plan } from '@/types'

const FixedPlusModal = ({
  open,
  onClose,
  onComplete,
}: {
  open?: boolean
  onClose: () => void
  onComplete: () => Promise<unknown>
}) => {
  const {
    user: { id: userId, email: userEmail },
    organization: { id: orgId, freeLicenses },
  } = useSession()
  const client = useGqlClient()
  const plans = useAllPlansQuery({ client })
  const stripePlan = plans?.data?.allPlans.find(
    (p) => p.subscriptionType === 'php_fixed_plus' && p.default
  ) as Plan
  const [addFixedPlusSubscription] = useAddPhpFixedPlusSubscriptionMutation({ client })
  const queryResult = useSettingsBillingDetailsQuery({ variables: { orgId, userId }, client })
  const usersResult = useUsersQuery({ variables: { orgId }, client: useGqlClient() })

  if (!stripePlan || !queryResult.data || !usersResult.data) return null

  const { planSubscription } = queryResult.data
  const users = usersResult.data.org?.users || []

  const baseLicenses = planSubscription
    ? planSubscription.plan.baseLicenses
    : stripePlan?.baseLicenses
      ? stripePlan.baseLicenses
      : null

  const fee = planSubscription
    ? planSubscription.plan.amount
    : MoneyM.fromInt(stripePlan.amount, 'USD')

  const basePrice = planSubscription
    ? (planSubscription?.plan?.basePrice as Money)
    : MoneyM.fromInt(stripePlan?.basePrice ?? 0, 'USD')

  const qty = users.filter((u) => u.active).length - freeLicenses

  const closeModal = () => onComplete()

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.LG}>
      <div className="p-12 relative">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <Subscribe
          title="Upgrade to Parts Hub Pro"
          users={users}
          minLicenses={qty}
          baseLicenses={baseLicenses}
          basePrice={basePrice}
          email={userEmail}
          licenseUnitPrice={fee}
          createProSubscription={addFixedPlusSubscription}
          onSubscribed={closeModal}
        />
      </div>
    </Modal>
  )
}

export default FixedPlusModal
