import A from '@/gf/components/A'
import Box from '@/gf/components/Box'

const CreateRequestBanner = () => (
  <Box className="shadow-base rounded-xl px-8 py-6 flex gap-x-8 items-center">
    <hgroup className="space-y-1 flex-grow">
      <h2 className="text-2xl font-medium">Need parts right away?</h2>
      <p className="text-lg">It’s as easy as adding one machine, one location, and one vendor.</p>
    </hgroup>

    <div>
      <A.P
        size="lg"
        href={`/rfqs/create?source.path=${encodeURIComponent(
          document.location.pathname
        )}&source.button=banner-create-request`}
      >
        Create a Request
      </A.P>
    </div>
  </Box>
)

export default CreateRequestBanner
