import vendor from './vendor.png'
import useToggle from '@/gf/hooks/useToggle'
import Banner from './Banner'
import Modal from '@/gf/components/ModalNext'
import { ModalSize } from '@/types'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Youtube from '@/gf/components/Video/Youtube'

const VendorVideo = () => {
  const [videoOpen, videoToggle] = useToggle()

  return (
    <>
      <Modal size={ModalSize.XL} open={videoOpen} onClose={videoToggle.off}>
        <div className="flex justify-between pt-3 pl-6 pr-4">
          <h3 className="text-2xl font-medium py-2">Gearflow Vendor Experience</h3>
          <CloseModalButton onClick={videoToggle.off} />
        </div>

        <div className="p-6">
          <Youtube
            videoId="mqunHORknSg"
            title="Parts Hub Vendor Experience"
            className="rounded-sm overflow-hidden shadow-md border border-gray-200"
          />
        </div>
      </Modal>
      <Banner
        title="See the vendor experience"
        text="See how simple it is for your vendors to view your request and submit a quote back in minutes."
        action="Watch Video"
        onActionClick={videoToggle.on}
        performingAction={false}
        image={vendor}
        color="pink"
      />
    </>
  )
}

export default VendorVideo
