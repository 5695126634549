import React from 'react'
import { GraphQLError } from 'graphql'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useSession from '../../../hooks/useSession'
import useGqlClient from '../../../hooks/useGqlClient'
import { useRemoveUserMutation } from '../../../_gen/gql'

import ModalForm from '@/gf/components/ModalForm'

const RemoveModal = ({
  userId,
  open,
  onRemove,
  onClose,
  children,
}: {
  userId?: string
  open: boolean
  onRemove: () => void
  onClose: () => void
  children: React.ReactNode
}) => {
  const [_, msgr] = useMsgs()
  const { organization } = useSession()
  const [spinning, spinner] = useToggle()
  const [removeUser] = useRemoveUserMutation({ client: useGqlClient() })

  const remove = (event) => {
    event?.preventDefault()
    if (!userId) return

    spinner.on()

    removeUser({ variables: { orgId: organization.id, userId } })
      .then(() => {
        msgr.add('User removed', 'positive')
        onRemove()
      })
      .catch((e: GraphQLError) => {
        if (e.message) msgr.add(e.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(spinner.off)
  }

  return (
    <ModalForm
      open={open}
      onClose={onClose}
      title="Remove User"
      submitButtonDisabled={spinning}
      submitButtonShowSpinner={spinning}
      onSubmit={remove}
    >
      {children}
    </ModalForm>
  )
}

export default RemoveModal
