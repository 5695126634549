import Ghost from '@/gf/components/Ghost'
import { Maybe } from '@/types'
import VendorLogo from '@/buyers/components/Vendors/VendorLogo'
import VendorBadge, { VendorBadgeType } from '@/buyers/components/Vendors/VendorBadge'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'
import { Duration } from 'luxon'
import DurationM from '@/gf/modules/Duration'
import isNumber from 'lodash/isNumber'

const Loading = ({ className }: { className?: string }) => (
  <div className={classNames('flex w-full gap-x-3 cursor-pointer', className)}>
    <div className="flex-shrink-0">
      <Ghost className="w-12 h-12 border border-gray-300 !rounded-full block" />
    </div>
    <div className="flex-grow space-y-2">
      <div>
        <Ghost className="block w-56 h-6" />
        <p className="flex gap-x-2 items-center justify-between mt-1">
          <Ghost className="block w-1/4 h-4" />

          <Ghost className="block w-16 h-3" />
        </p>
      </div>

      <div className="text-sm text-gray-600">
        <Ghost className="block h-4 w-2/3" />
      </div>
    </div>
  </div>
)

const VendorCard = ({
  logoUrl,
  name,
  distance,
  address,
  responseTimeSec,
  numberOfQuotes,
  badges,
  className,
  onHover,
  onClick,
  actionButton,
}: {
  logoUrl: Maybe<string>
  name: string
  distance: Maybe<number>
  address: { city; state }
  responseTimeSec: Maybe<number>
  numberOfQuotes: Maybe<number>
  badges: VendorBadgeType[]
  className?: string
  onHover?: () => void
  onClick?: () => void
  actionButton: React.ReactNode
}) => {
  const responseTimeText =
    responseTimeSec && responseTimeSec > 0
      ? `${DurationM.format(Duration.fromMillis(responseTimeSec * 1000))} to quote`
      : null
  const transactionsText =
    numberOfQuotes && numberOfQuotes > 0 ? `${numberOfQuotes} transactions` : null
  return (
    <div
      className={twMerge(
        'flex w-full gap-x-3',
        (onHover || onClick) && 'cursor-pointer hover:bg-blue-50',
        className
      )}
      onMouseOver={onHover}
      onFocus={onHover}
      onClick={onClick}
    >
      <div className="flex-grow flex gap-x-4 min-w-0">
        <div className="flex-shrink-0">
          <VendorLogo url={logoUrl} />
        </div>
        <div className="flex-grow min-w-0">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900 truncate overflow-ellipsis">{name}</h3>
            {isNumber(distance) ? (
              <span className="text-gray-600 text-sm flex-shrink-0">
                {distance > 0 && distance < 1 ? '< 1' : distance.toFixed(0)} mi
              </span>
            ) : null}
          </div>

          <div className="flex justify-between">
            <div>
              <p className="text-base flex-grow text-gray-900">
                {address.city} {address.state && `, ${address.state}`}
              </p>
              {badges.length > 0 && (
                <div className="flex gap-x-2 mt-1">
                  {badges.map((b) => (
                    <VendorBadge key={b} type={b} />
                  ))}
                </div>
              )}

              {responseTimeText || transactionsText ? (
                <p className="text-sm text-gray-600 mt-2">
                  {[responseTimeText, transactionsText].filter((s) => s !== null).join(' • ')}
                </p>
              ) : null}
            </div>

            <div className="pt-2 self-end">{actionButton}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

VendorCard.Loading = Loading

export default VendorCard
