import useMicrosoftAuthUrl from '@/buyers/hooks/useMicrosoftAuthUrl'
import MicrosoftButton from '@/gf/components/MicrosoftButton'

const MicrosoftContactsButton = ({
  redirectParams,
}: {
  redirectParams?: () => URLSearchParams
}) => {
  const params = redirectParams ? redirectParams() : new URLSearchParams(window.location.search)

  let queryString = params.toString()
  queryString = queryString.length > 0 ? `?${params.toString()}` : ''

  const href = useMicrosoftAuthUrl({
    scopes: ['Contacts.Read'],
    redirectTo: window.location.pathname + queryString,
  })

  return <MicrosoftButton href={href}>Select from Microsoft Contacts</MicrosoftButton>
}

export default MicrosoftContactsButton
