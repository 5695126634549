import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useEffect, useState } from 'react'
import { useMatch } from 'react-router-dom'
import { usePendingInvitesQuery } from '../_gen/gql'
import useSession from './useSession'

type Fields = {
  inviteId: string | null
  name: string
  email: string
  phoneNumber: string
  title: string
  roleId: string | null
}

type Errors = {
  name: string | null
  email: string | null
  phoneNumber: string | null
  title: string | null
  roleId: string | null
}

// If the structure changes, bump this version
const key = 'add-user-form-v2'

const sessionStore = {
  fetch: (inviteId: string | null) => {
    const item = sessionStorage.getItem(inviteId ? `${key}/${inviteId}` : key)
    if (!item) return null

    return JSON.parse(item) as { fields: Fields; errors: Errors }
  },
  save: (form: { fields: Fields; errors: Errors }) =>
    sessionStorage.setItem(
      form.fields.inviteId ? `${key}/${form.fields.inviteId}` : key,
      JSON.stringify(form)
    ),
}

const initErrors: Errors = {
  name: null,
  email: null,
  phoneNumber: null,
  title: null,
  roleId: null,
}

const useAddUserForm = (defaultRoleId?: string) => {
  const { orgId } = useSession()
  const inviteId = useMatch('/settings/users/invites/:inviteId/*')?.params.inviteId || null

  const invite = usePendingInvitesQuery({
    variables: { orgId },
    client: useGqlClient(),
    skip: !inviteId,
  }).data?.org?.pendingInvites.find((pi) => pi.id === inviteId)

  const initFields: Fields = {
    inviteId,
    name: '',
    email: '',
    phoneNumber: '',
    title: '',
    roleId: defaultRoleId ?? null,
  }

  const sessionForm = sessionStore.fetch(inviteId)
  const [fields, setFields] = useState<Fields>(sessionForm?.fields || initFields)
  const [errors, setErrors] = useState<Errors>(sessionForm?.errors || initErrors)

  const save = (fields: Fields, errors: Errors) => {
    sessionStore.save({ fields, errors })
    setFields(fields)
    setErrors(errors)
  }

  const updateFields = (updates: Partial<Fields>) => save({ ...fields, ...updates }, errors)

  useEffect(() => {
    if (invite) {
      const updates: Partial<Fields> = { email: invite.user.email }
      if (fields.name === '') updates.name = invite.user.name || ''
      if (fields.email === '') updates.email = invite.user.email
      if (fields.phoneNumber === '') updates.phoneNumber = invite.user.phoneNumber || ''
      if (!fields.roleId) updates.roleId = invite.user.userRole?.id
      updateFields(updates)
    }
  }, [!invite])

  const reset = () => save(initFields, initErrors)

  const resetErrors = () => save(fields, initErrors)

  const validate = (context?: 'details' | 'permissions') => {
    resetErrors()

    return new Promise<void>((resolve, reject) => {
      let newErrors = initErrors

      if (!context || context === 'details') {
        if (fields.name.length === 0) newErrors = { ...newErrors, name: 'Name is required.' }
        if (fields.email.length === 0) newErrors = { ...newErrors, email: 'Email is required.' }

        if (fields.phoneNumber.length === 0)
          newErrors = { ...newErrors, phoneNumber: 'Phone number is required.' }
      }

      if (!context || context === 'permissions')
        if (!fields.roleId) newErrors = { ...newErrors, roleId: 'Must select a role.' }

      save(fields, { ...errors, ...newErrors })

      if (newErrors === initErrors) resolve()
      else reject()
    })
  }

  return { fields, errors, updateFields, reset, resetErrors, validate }
}

export type AdminUserForm = ReturnType<typeof useAddUserForm>

export default useAddUserForm
