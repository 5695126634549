import { useEffect, useState } from 'react'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const userAgent = window.navigator === undefined ? '' : navigator.userAgent
    const isMobileByUserAgent = !!userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
    setIsMobile(isMobileByUserAgent)

    const handleResize = () => {
      const isMobileByWidth = window.innerWidth <= 640
      setIsMobile(isMobileByWidth)
    }

    // Initialize
    handleResize()
    // Update when window resizes
    window.addEventListener('resize', handleResize)

    // Clean up
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}

export default useIsMobile
