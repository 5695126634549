import { CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

const SelectableButton = ({
  children,
  selected,
  onClick,
  className,
}: {
  children: ReactNode
  selected: boolean
  onClick: () => void
  className?: string
}) => (
  <button
    type="button"
    className={twMerge(
      'block shadow-base bg-white border rounded-xl relative transition duration-75 w-full',
      selected && 'border-blue-600 border-2',
      className
    )}
    onClick={onClick}
  >
    <span
      className={classNames('absolute top-1.5 right-1.5', selected ? 'opacity-100' : 'opacity-0')}
    >
      <CheckCircleIcon className="z-10 relative h-5 w-5 inline-block text-blue-600" />
      <div className="z-0 absolute inset-1 bg-white rounded-full" />
    </span>

    {children}
  </button>
)

export default SelectableButton
