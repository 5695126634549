import { useNavigate, useParams } from 'react-router-dom'

import usePage from '@/gf/hooks/usePage'
import useSession from '@/buyers/hooks/useSession'
import { FetchConversationsQueryResult } from '@/buyers/_gen/gql'
import { useContext } from '../RFQ'

import Layout from '@/gf/components/Layout'
import Spinner from '@/gf/components/Spinner'
import Inbox from './Messages/Inbox'

const Messages = ({
  paginatedConversations,
  refetch,
  error,
  internalOrg,
}: {
  paginatedConversations:
    | NonNullable<NonNullable<FetchConversationsQueryResult>['data']>['fetchConversations']
    | undefined
  refetch: () => Promise<unknown>
  error?: { message: string }
  internalOrg?: boolean
}) => {
  const { rfq, org, refetchRfq } = useContext()
  const { conversationId: selectedConversationId, storeId: selectedStoreId } = useParams()
  const navigate = useNavigate()
  const { user } = useSession()
  const [page, setPage] = usePage()

  return (
    <div className="flex grow">
      {error ? (
        <Layout.FullPageLayout>
          <p className="text-sm text-gray-700">{error.message}</p>
        </Layout.FullPageLayout>
      ) : !paginatedConversations ? (
        <Layout.FullPageLayout>
          <Spinner />
        </Layout.FullPageLayout>
      ) : (
        <Inbox
          org={org}
          conversations={paginatedConversations.conversations}
          selectedConversationId={selectedConversationId}
          selectedStoreId={selectedStoreId}
          selectedInternalOrg={internalOrg}
          onSelectedConversationIdChange={(conversationId) =>
            conversationId
              ? navigate(`/rfqs/${rfq.id}/messages/${conversationId}`, { replace: true })
              : navigate(`/rfqs/${rfq.id}/messages`)
          }
          refetchConversations={refetch}
          page={page}
          setPage={setPage}
          pagination={paginatedConversations.pagination}
          user={user}
          requestForQuote={rfq}
          refetchRequestForQuote={refetchRfq}
        />
      )}
    </div>
  )
}

export default Messages
