import { RequestForQuoteStep, useAddRfqImagesMutation } from '@/buyers/_gen/gql'
import PartRequestsTableV2 from '@/buyers/components/PartRequestsTableV2'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Banner from '@/gf/components/Banner'
import Link from '@/gf/components/Link'
import MachineDownIcon from '@/gf/components/MachineDownIcon'
import MachinesList from '@/gf/components/MachinesList'
import UppyFilesManager from '@/gf/components/inputs/UppyFilesManager'
import useMsgs from '@/gf/hooks/useMsgs'
import AttachmentM from '@/gf/modules/Attachment'
import { GlobeAltIcon, InformationCircleIcon } from '@heroicons/react/outline'
import pluralize from 'pluralize'
import { useContext } from '../RFQ'
import AwaitingQuotes from './Request/AwaitingQuotes'
import Divisor from './Request/Divisor'
import NonparticipatingVendors from './Request/NonparticipatingVendors'
import Orders from './Request/Orders'
import Section from './Request/Section'
import { MultiButtonAction, MultiButtons } from '@/gf/components/MultiButton'

const Request = ({
  refetch,
  actions,
}: {
  refetch: () => Promise<unknown>
  actions: MultiButtonAction[]
}) => {
  const { rfq, user } = useContext()
  const [_, msgr] = useMsgs()
  const gqlClient = useGqlClient()
  const [addRfqImages] = useAddRfqImagesMutation({ client: gqlClient })

  // The number of stores assigned to the RFQ, but that are not associated to an assigned
  // vendor and have not placed a quote.
  const gfNetworkAwaiting = rfq.requestForQuoteStores.filter(
    (rfqs) =>
      !rfq.requestForQuoteVendors.some((rfqv) => rfqv.vendor.store?.id === rfqs.store.id) &&
      !rfq.storeOrders.some((rfqso) => rfqso.store.id === rfqs.store.id) &&
      !rfq.nonparticipations.some((n) => n.storeId === rfqs.store.id)
  ).length

  return (
    <div className="mt-4 flex flex-row grow gap-x-2 items-start">
      <div className="bg-white rounded-lg border pt-1 w-full max-w-screen-lg">
        {rfq.step === RequestForQuoteStep.Inbound && !user.can.approveRequests && (
          <Section title="">
            <Banner
              icon={InformationCircleIcon}
              text="Waiting for approval before the Request is submitted"
              action={
                <Link.T className="pr-2 text-sm" to={`/rfqs/${rfq.id}/messages/internalOrg`}>
                  Message my team
                </Link.T>
              }
            />
          </Section>
        )}

        <Section title="Request">
          <PartRequestsTableV2 rfq={rfq} onChange={refetch} />
        </Section>

        <Section title="Images">
          <UppyFilesManager
            fileUrls={rfq.images.map((image) => image.url)}
            onAdd={(addFileUrls) =>
              addRfqImages({ variables: { id: rfq?.id, imageUrls: addFileUrls } }).then(refetch)
            }
            onError={(error) => msgr.add(error, 'negative')}
            allowedFileTypes={AttachmentM.defaultAllowedFileTypes}
          />
        </Section>

        {(rfq.partsRequest || rfq.machineInformation) && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2">
              {rfq.partsRequest && (
                <Section title="Additional details">
                  <p className="text-sm text-gray-700 whitespace-pre-wrap">{rfq.partsRequest}</p>
                </Section>
              )}

              {rfq.machineInformation && (
                <Section title="Machine Information">
                  <p className="text-sm text-gray-700">{rfq.machineInformation}</p>
                </Section>
              )}
            </div>
            <Divisor />
          </>
        )}

        {rfq.orgMachines.length !== 0 && (
          <Section
            title={
              <div className="flex flex-row items-center gap-x-2">
                {pluralize('Machines', rfq.orgMachines.length)}{' '}
                {rfq.machineDown && <MachineDownIcon />}
              </div>
            }
          >
            <MachinesList
              accountMachines={rfq.orgMachines.map((om) => ({ ...om, ...om.machine }))}
              showOwned
              linkToMachine
            />
          </Section>
        )}

        {!rfq.needsApproval && <Orders />}

        {!rfq.closedAt && !rfq.canceledAt ? (
          <>
            <AwaitingQuotes />
            <NonparticipatingVendors />
          </>
        ) : (
          <Section>
            <div className="p-4 flex gap-2 text-gray-700 bg-gray-50 border border-gray-200 rounded">
              <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />

              <div className="space-y-1 text-sm">
                <p className="font-medium">Request Closed</p>
                <p>This request was closed and can no longer receive quotes.</p>
              </div>
            </div>
          </Section>
        )}

        {gfNetworkAwaiting > 0 && rfq.enabled && !rfq.closed && (
          <div className="flex justify-center items-center gap-4 text-gray-500 pb-4">
            <GlobeAltIcon className="w-14 h-14" />

            <div className="w-96 text-xl font-medium leading-tight">
              There {gfNetworkAwaiting > 1 ? <>are</> : <>is</>}{' '}
              {pluralize('vendor', gfNetworkAwaiting, true)} from the Gearflow Network working on
              your request.
            </div>
          </div>
        )}
      </div>
      <div className="lg:w-48 xl:w-64 hidden lg:flex shrink-0">
        <MultiButtons className="!shadow-sm" items={actions} />
      </div>
    </div>
  )
}

export default Request
