import Money from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'
import { ArrowDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { floor } from 'mathjs'

export type PositiveDirection = 'up' | 'down'

const defaultFormat = (value: number) => value.toFixed(1)
export const formatMoney = (maximumFractionDigits: number) => (value: number) =>
  Money.format(Money.fromDecimal(value, 'USD'), { maximumFractionDigits })
export const formatTime = (valueInSeconds: number) => Time.secondsToString(valueInSeconds)
export const formatPercentage = (decimals: number) => (value: number) =>
  `${value === floor(value) ? value.toFixed(0) : value.toFixed(decimals)}%`

const Variation = ({
  value,
  toformat = defaultFormat,
  className = '',
  positiveDirection = 'up',
  boxed = true,
}: {
  value: number
  className?: string
  toformat?: typeof defaultFormat
  positiveDirection: PositiveDirection
  boxed?: boolean
}) => {
  const isPositive = positiveDirection === 'down' ? value <= 0 : value >= 0
  return (
    <span
      className={classNames(
        'font-medium inline-flex items-center border-opacity-10 gap-x-1 rounded-[0.25rem] self-start',
        isPositive ? 'text-green-700 ' : 'text-red-700 ',
        boxed && 'border py-1 px-2',
        boxed && (isPositive ? 'border-green-800 bg-green-50' : 'border-red-800 bg-red-50'),
        className
      )}
    >
      <ArrowDownIcon
        className={classNames('inline-block h-[0.875rem] w-[0.875rem]', value >= 0 && 'rotate-180')}
      />

      {toformat(Math.abs(value))}
    </span>
  )
}

export default Variation
