import { useBranchSelectorQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Select from '@/gf/components/next/Select'
import { Maybe } from '@/types'
import { useMemo } from 'react'

const BranchSelector = ({
  value,
  onChange,
  className,
}: {
  value: Maybe<string>
  onChange: (newValue: string) => void
  className?: string
}) => {
  const { data, loading } = useBranchSelectorQuery({ client: useGqlClient() })

  const branches = data?.allBranches ?? []

  const options = useMemo(
    () =>
      branches.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    [branches]
  )

  const selectedOption = useMemo(() => options.find((o) => o.value === value), [value, options])

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={(o) => o && onChange(o.value)}
      isLoading={loading}
      className={className}
      placeholder="Select Billing Company"
    />
  )
}

export default BranchSelector
