import Ghost from '@/gf/components/Ghost'

const RFQCardGhost = () => (
  <div className="mt-6 border rounded-lg overflow-hidden">
    <div className="flex justify-between items-center border-b p-2">
      <Ghost className="block h-8 rounded-xl w-52" />
      <Ghost className="block h-6 rounded-xl w-32" />
    </div>
    <div className="p-2">
      <Ghost className="block h-4 rounded-xl w-full mb-1" />
      <Ghost className="block h-4 rounded-xl w-full mb-1" />
      <Ghost className="block h-4 rounded-xl w-1/3 mb-1" />
    </div>

    <div className="bg-slate-50 p-2 flex justify-between">
      <Ghost className="h-4 w-33" />
      <Ghost className="h-4 w-48" />
    </div>
  </div>
)

export default RFQCardGhost
