import BadgeNext from '@/gf/components/BadgeNext'
import { ExclamationIcon } from '@heroicons/react/outline'
import { BadgeCheckIcon, HeartIcon } from '@heroicons/react/solid'

export enum VendorBadgeType {
  MyDealer,
  ActiveOnGf,
  DraftVendor,
}

const badgesConfig: Record<VendorBadgeType, JSX.Element> = {
  [VendorBadgeType.MyDealer]: (
    <BadgeNext color="green">
      <HeartIcon className="inline-block h-3 w-3" /> Your Vendor
    </BadgeNext>
  ),
  [VendorBadgeType.DraftVendor]: (
    <BadgeNext color="yellow">
      <ExclamationIcon className="inline-block h-3 w-3" /> Finish setup before sending a request
    </BadgeNext>
  ),
  [VendorBadgeType.ActiveOnGf]: (
    <BadgeNext color="blue">
      <BadgeCheckIcon className="inline-block h-3 w-3" />
      Active
    </BadgeNext>
  ),
}

const VendorBadge = ({ type }: { type: VendorBadgeType }) => badgesConfig[type]

export default VendorBadge
