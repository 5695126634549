import Link from '@/gf/components/Link'
import { UsersIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import IconZeroState from '../../../components/IconZeroState'

const ContactsZeroState = () => (
  <IconZeroState
    title="No contacts recorded"
    subtitle={
      <>
        You can add a new contact by clicking{' '}
        <em className="font-medium text-gray-700">Add Contact</em> below
      </>
    }
    icon={UsersIcon}
  >
    <Link.P to="./new">
      <PlusIcon className="inline-block h-4" /> Add Contact
    </Link.P>
  </IconZeroState>
)

export default ContactsZeroState
