import { useAttachPaymentMethodMutation, useUsersQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { UNKNOWN_ERROR } from '@/gf/providers/MsgsProvider'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import classNames from 'classnames'
import { GraphQLError } from 'graphql'
import React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import useGqlClient from '../hooks/useGqlClient'
import type { PlanPaymentMethod, PlanSubscription } from '../hooks/usePlanQuery'
import useSession from '../hooks/useSession'
import PaymentMethod from './PaymentMethod'

type CreditCardForm = {
  cardNumber: string
  cardExpiry: string
  cardCvc: string
}

const StripeErrorMessage = ({ errors }: { errors?: string }) => {
  if (!errors) return null

  return (
    <div className="flex gap-1 items-center text-red-500">
      <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />
      <span className="text-sm text-red-500">{errors}</span>
    </div>
  )
}

const UpdatePaymentMethod = ({
  planPaymentMethod,
  onUpdate,
}: {
  planPaymentMethod: PlanPaymentMethod
  onUpdate: () => void
}) => {
  const { user } = useSession()
  const stripe = useStripe()
  const elements = useElements()
  const [loading, loader] = useToggle()
  const [_msgs, msgsMgr] = useMsgs()
  const [attachPaymentMethod] = useAttachPaymentMethodMutation({ client: useGqlClient() })
  const [updateOpen, updateToggler] = useToggle()

  const paymentMethodSchema = yup.object({
    cardNumber: yup.string().required().label('Card number'),
    cardExpiry: yup.string().required().label('Expiration'),
    cardCvc: yup.string().required().label('Cvc'),
  })

  const form = useForm<CreditCardForm>({
    defaultValues: {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
    },
    resolver: yupResolver(paymentMethodSchema),
  })

  const onStripeInputChanged =
    (field) =>
    ({ complete }) => {
      form.setValue(field, complete ? '*force-validation-passed*' : '')
      form.trigger(field)
    }

  const addPayment = () => {
    if (!stripe || !elements) {
      const error = 'missing stripe or elements'
      const paymentMethod = {
        id: undefined,
        card: { last4: undefined, brand: undefined },
      }
      return Promise.resolve({ paymentMethod, error })
    }

    const cardElement = elements.getElement(CardNumberElement)

    return stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as Exclude<typeof cardElement, null>,
      billing_details: { email: user.email },
    })
  }

  const updatePaymentMethod = async () => {
    try {
      loader.on()

      const { error, paymentMethod } = await addPayment()

      if (!paymentMethod?.id) {
        loader.off()
        msgsMgr.add('Please enter a payment method to continue', 'negative')
        return
      }

      if (error) {
        loader.off()
        msgsMgr.add(`Sorry, we are having trouble with your payment method ${error}`, 'negative')
        return
      }

      const paymentMethodId = paymentMethod.id

      attachPaymentMethod({ variables: { paymentMethodId } })
        .then(() => {
          msgsMgr.add('Payment Method Updated!', 'positive')
          onUpdate()
          updateToggler.off()
          form.reset()
        })
        .catch((err: GraphQLError) => {
          msgsMgr.add(err.message || UNKNOWN_ERROR, 'negative')
        })
        .finally(loader.off)
    } catch (_err) {
      loader.off()

      const generic = 'Sorry, we are having trouble adding your payment method'
      msgsMgr.add(generic, 'negative')
    }
  }

  return (
    <form className="py-4" onSubmit={form.handleSubmit(updatePaymentMethod)}>
      <Card
        title="Payment Details"
        subtitle={<>Your payment information on file for Parts Hub Pro (if applicable)</>}
      >
        <Card.Section>
          <div className="grid grid-cols-6 gap-4">
            <div className="self-start col-span-3">
              <PaymentMethod planPaymentMethod={planPaymentMethod} />
            </div>
            <div className="self-start col-span-3">
              <div className="relative">
                <div className="relative h-56 overflow-hidden">
                  <div
                    className={classNames({
                      'absolute inset-0 items-center justify-center transition-all ease-in-out duration-400 transform':
                        true,
                      'translate-x-0 slide': !updateOpen,
                      'translate-x-full slide': updateOpen,
                    })}
                  >
                    <div className="w-full absolute block flex justify-end">
                      <Action.P onClick={updateToggler.on} performing={loading}>
                        Update
                      </Action.P>
                    </div>
                  </div>
                  <div
                    className={classNames({
                      'absolute inset-0 items-center justify-center transition-all ease-in-out duration-400 transform':
                        true,
                      'translate-x-0 slide': updateOpen,
                      'translate-x-full slide': !updateOpen,
                    })}
                  >
                    <div className="w-full absolute block">
                      <CardNumberElement
                        options={{ showIcon: true }}
                        onChange={onStripeInputChanged('cardNumber')}
                      />
                      <StripeErrorMessage errors={form.formState.errors.cardNumber?.message} />
                      <div className="grid grid-cols-6 gap-4">
                        <div className="self-start col-span-3">
                          <CardExpiryElement onChange={onStripeInputChanged('cardExpiry')} />
                          <StripeErrorMessage errors={form.formState.errors.cardExpiry?.message} />
                        </div>
                        <div className="self-start col-span-3">
                          <CardCvcElement onChange={onStripeInputChanged('cardCvc')} />
                          <StripeErrorMessage errors={form.formState.errors.cardCvc?.message} />
                        </div>
                      </div>
                      <div className="flex justify-end gap-4">
                        {!loading && <Action.S onClick={updateToggler.off}>Cancel</Action.S>}
                        <Action.P onClick={updatePaymentMethod} performing={loading}>
                          Save
                        </Action.P>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Section>
      </Card>
    </form>
  )
}

const UpdatePayment = ({
  planSubscription,
  planPaymentMethod,
  onUpdate,
}: {
  planSubscription: PlanSubscription | null
  planPaymentMethod: PlanPaymentMethod | null
  onUpdate: () => void
}) => {
  const { organization } = useSession()
  const orgId = organization.id
  const { stripePublicKey } = useConfig()
  const stripePromise = React.useMemo(() => loadStripe(stripePublicKey), [])
  const usersResult = useUsersQuery({ variables: { orgId }, client: useGqlClient() })

  if (!usersResult.data) return null

  return planPaymentMethod && planSubscription ? (
    <Elements stripe={stripePromise}>
      <UpdatePaymentMethod planPaymentMethod={planPaymentMethod} onUpdate={onUpdate} />
    </Elements>
  ) : null
}

export default UpdatePayment
