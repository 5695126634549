import { gql } from '@apollo/client'

const SEARCH_SUPPLIERS = gql`
  query SearchSuppliers($value: String) {
    searchSuppliers(value: $value) {
      id
      name
      email
    }
  }
`

export const SEARCH_BUYERS = gql`
  query SearchBuyers($value: String) {
    searchBuyers(value: $value) {
      id
      name
      email
      phoneNumber
      organization {
        id
      }
    }
  }
`

export default SEARCH_SUPPLIERS
