/**
 * Banner Component
 * -----------------
 * Displays a banner with an optional button.
 * (similar to the Card component, but for small horizontal messages - the card is more of a vertical component)
 *
 * props
 * -----------------
 * text: text content of the banner
 * buttonText?: text for the action button - no button if this prop is undefined
 * onClick?: action event for clicking the banner button
 * closeButton?: whether to show the close button or not (defaults to false)
 * onClose?: action event for clicking the close button
 */

import { HeroIcon } from '@/types'
import { ExclamationIcon, SpeakerphoneIcon, XIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import { ReactNode } from 'react'
import Button from './ButtonOld'

const Banner = ({
  className,
  text,
  buttonText,
  action,
  onClick,
  closeButton = false,
  onClose,
  error = false,
  children,
  icon,
}: {
  className?: string
  text?: string
  buttonText?: string
  action?: ReactNode
  onClick?: () => void
  closeButton?: boolean
  onClose?: () => void
  error?: boolean
  children?: ReactNode
  icon?: HeroIcon
}) => {
  const Icon = icon || (error ? ExclamationIcon : SpeakerphoneIcon)
  return (
    <div
      className={classNames('flex flex-wrap bg-white shadow-sm border rounded-md p-2', className)}
    >
      <div className="flex-1 flex items-center">
        <span
          className={classNames(
            'flex rounded-lg',
            error ? 'border border-2 p-1 ml-0.5 border-red-400' : 'p-2 bg-gray-200'
          )}
        >
          <Icon className={classNames('h-6 w-6', error ? 'text-red-400' : 'text-gray-600')} />
        </span>
        <div className="ml-3">{children || <p className="flex text-gray-700">{text}</p>}</div>
      </div>
      {(buttonText || action) && (
        <div className="order-3 mt-2 flex flex-shrink-0 items-center w-full sm:order-2 sm:mt-0 sm:w-auto">
          {buttonText ? (
            <Button
              className="w-full justify-center"
              type="button"
              primary={false}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          ) : (
            action
          )}
        </div>
      )}
      {closeButton && (
        <div className="order-2 flex flex-shrink-0 sm:order-3 sm:ml-2">
          <button
            type="button"
            className="-mr-1 flex p-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-yellow-500"
            onClick={onClose}
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  )
}

export default Banner
