import Button from '@/gf/components/ButtonOld'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useEditMachineOrgEngineSpecsMutation } from '../../../_gen/gql'
import useGqlClient from '../../../hooks/useGqlClient'

interface Props {
  machineDetails: OrgMachineDetails
  onAfterSave: () => void
  onCancel: () => void
}

interface EngineSpecsForm {
  make: string | null
  model: string | null
  serialNumber: string | null
}

const engineSpecsSchema = yup.object({
  make: yup.string().required().label('Make'),
  model: yup.string().required().label('Model'),
  serialNumber: yup.string().required().label('Serial Number'),
})

const EngineSpecsForm: React.FC<Props> = ({ machineDetails, onAfterSave, onCancel }) => {
  const orgMachine = machineDetails.data?.orgMachine

  const [_, messages] = useMsgs()
  const [editMachineSpecs] = useEditMachineOrgEngineSpecsMutation({ client: useGqlClient() })

  const form = useForm<EngineSpecsForm>({
    shouldUnregister: true,
    defaultValues: {
      make: orgMachine?.engineMake || '',
      model: orgMachine?.engineModel || '',
      serialNumber: orgMachine?.engineSerialNumber || '',
    },
    resolver: yupResolver(engineSpecsSchema),
  })

  const onSubmit = async (values: EngineSpecsForm) => {
    try {
      await editMachineSpecs({
        variables: {
          id: orgMachine?.id as string,
          make: values.make as string,
          model: values.model as string,
          serialNumber: values.serialNumber as string,
        },
      })

      machineDetails.refetch()

      onAfterSave()
    } catch (err) {
      console.error(err)
      messages.add('Oops! Looks like something went wrong.', 'negative')
    }
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-y-2 max-w-xs">
      <TextField
        label="Engine Make"
        errors={form.formState.errors.make?.message}
        {...form.register('make')}
      />

      <TextField
        label="Engine Model"
        errors={form.formState.errors.model?.message}
        {...form.register('model')}
      />

      <TextField
        label="Engine Serial Number"
        errors={form.formState.errors.serialNumber?.message}
        containerClassName="w-1/2"
        {...form.register('serialNumber')}
      />

      <div className="flex gap-x-4 mt-2">
        <Button
          type="button"
          className="font-semibold justify-center tracking-wide border-slate-500 text-slate-500 bg-transparent hover:bg-slate-50 w-1/3"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button type="submit" className="font-semibold justify-center tracking-wide w-2/3">
          Save
        </Button>
      </div>
    </form>
  )
}

export default EngineSpecsForm
