import { useInternalInventoryLookupQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Link from '@/gf/components/Link'
import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { memo } from 'react'
import { Part } from './PartDetailsStep'

const InventoryFulfillmentMessage = ({ rfqId, parts }: { rfqId: string; parts: Part[] }) => {
  const { data } = useInternalInventoryLookupQuery({
    variables: {
      partNumbers: parts.map((p) => p.partNumber),
    },
    client: useGqlClient(),
    skip: parts.length === 0,
  })

  const matchedProducts = data?.inventory.results ?? []
  if (!data || matchedProducts.length === 0) {
    return null
  }

  const fullMatch = matchedProducts.length === parts.length

  return (
    <div className="mb-4 p-2 border rounded-lg text-orange-500 border-orange-200 bg-orange-100">
      <p className="leading-5 text-sm">
        {fullMatch ? 'You have these parts in stock' : 'You have some of these parts in stock'}.
        Would you like to fulfill this request internally?
      </p>
      <ul className="mt-1 flex gap-1 text-sm">
        {matchedProducts.map((p) => (
          <li className="ml-4">- {p.mpn}</li>
        ))}
      </ul>
      <div className="mt-3">
        <Link.S to={`/rfqs/${rfqId}/fulfill-from-inventory`} size="sm">
          Fulfill from Inventory <ArrowNarrowRightIcon className="inline-block w-4 h-4" />
        </Link.S>
      </div>
    </div>
  )
}

export default memo(InventoryFulfillmentMessage)
