import Action from '@/gf/components/Action'
import Switch from '@/gf/components/Switch'
import TextAreaField from '@/gf/components/TextAreaField'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useEditMachineMutation } from '../../_gen/gql'
import useGqlClient from '../../hooks/useGqlClient'
import CloseModalButton from '@/gf/components/CloseModalButton'

interface Props {
  machineDetails: OrgMachineDetails
  onAfterSave: () => void
  onCancel: () => void
}

interface OrgMachineForm {
  machineYear: number | null
  machineMake: string | null
  machineModel: string | null
  serialNumber: string | null
  name: string | null
  description: string | null
  enabled: boolean
  owned: boolean
}

const orgMachineSchema = yup.object({
  machineYear: yup
    .number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .typeError('Must be a number')
    .nullable()
    .label('Year'),
  machineMake: yup.string().required().label('Make'),
  machineModel: yup.string().required().label('Model'),
  name: yup.string().required('Please fill this in').label('Unit Number'),
  serialNumber: yup.string().required().label('Serial Number'),
  description: yup.string().notRequired().label('Description'),
  enabled: yup.boolean().required(),
  owned: yup.boolean().required(),
})

const OrgMachineForm: React.FC<Props> = ({ machineDetails, onAfterSave, onCancel }) => {
  const orgMachine = machineDetails.data?.orgMachine
  const [_, messages] = useMsgs()
  const [editMachine] = useEditMachineMutation({ client: useGqlClient() })

  const form = useForm<OrgMachineForm>({
    shouldUnregister: true,
    defaultValues: {
      machineYear: orgMachine?.machine?.year,
      machineMake: orgMachine?.machine?.make || '',
      machineModel: orgMachine?.machine?.model || '',
      name: orgMachine?.name || '',
      serialNumber: orgMachine?.serialNumber || '',
      description: orgMachine?.description || '',
      enabled: orgMachine?.enabled === true,
      owned: orgMachine?.owned === true,
    },
    resolver: yupResolver(orgMachineSchema),
  })

  const onSubmit = async (values: OrgMachineForm) => {
    try {
      await editMachine({
        variables: {
          id: orgMachine?.id as string,
          orgMachine: {
            description: values.description,
            serialNumber: values.serialNumber,
            enabled: values.enabled,
            owned: values.owned,
            machine: {
              make: values.machineMake,
              model: values.machineModel,
              year: values.machineYear,
            },
            name: values.name as string,
          },
        },
      })

      messages.add('Machine information updated!', 'positive')

      onAfterSave()
    } catch (err) {
      console.error(err)
      messages.add('Oops! Looks like something went wrong.', 'negative')
    }
  }

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className="p-6 flex flex-col gap-y-2 w-full relative"
    >
      <h3 className="text-xl font-medium pb-2">Edit Machine Information</h3>
      <CloseModalButton className="absolute top-2 right-2" onClick={onCancel} />

      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        <TextField
          label="Unit #"
          errors={form.formState.errors.name?.message}
          {...form.register('name')}
        />

        <TextField
          label="Year"
          errors={form.formState.errors.machineYear?.message}
          {...form.register('machineYear')}
        />

        <TextField
          label="Make"
          errors={form.formState.errors.machineMake?.message}
          {...form.register('machineMake')}
        />

        <TextField
          label="Model"
          errors={form.formState.errors.machineModel?.message}
          {...form.register('machineModel')}
        />
      </div>

      <TextField
        label="Serial Number"
        errors={form.formState.errors.serialNumber?.message}
        {...form.register('serialNumber')}
      />

      <TextAreaField
        label="Description"
        errors={form.formState.errors.description?.message}
        placeholder="Type any additional info related to this machine"
        {...form.register('description')}
      />

      <Controller
        name="enabled"
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <label className="flex items-center gap-x-2 mt-2">
              <Switch checked={field.value} onChange={(v) => field.onChange(v)} />
              <span className="text-sm flex-grow">
                This machine is currently active in the fleet.
              </span>
            </label>
          </>
        )}
      />

      <Controller
        name="owned"
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => (
          <>
            <label className="flex items-center gap-x-2 mt-2">
              <Switch checked={field.value} onChange={(v) => field.onChange(v)} />
              <span className="text-sm flex-grow">My organization owns this machine.</span>
            </label>
          </>
        )}
      />

      <div className="flex gap-x-4 mt-4">
        <Action.S className="w-1/2" onClick={onCancel}>
          Cancel
        </Action.S>
        <Action.P color="blue" type="submit" className="w-1/2">
          Save
        </Action.P>
      </div>
    </form>
  )
}

export default OrgMachineForm
