import { FilterField, Option } from '../../types'

const notificationFields = (userOptions: Option[]): FilterField[] => [
  {
    id: 'inserted_at',
    display: 'Date',
    filterTypeIds: ['date_time_in_range'],
  },
  {
    id: 'user_id',
    display: 'User',
    filterTypeIds: ['is'],
    options: userOptions,
  },
  {
    id: 'store_order_event_type',
    display: 'Order Event',
    filterTypeIds: ['is'],
    options: [
      { id: 'created', display: 'Created on gearflow.com' },
      { id: 'created_on_dashboard', display: 'Created manually' },
      { id: 'edited', display: 'Edited' },
      { id: 'quote_approved', display: 'Quote approved' },
      { id: 'quote_denied', display: 'Quote denied' },
      { id: 'approved', display: 'Approved' },
      { id: 'refund_created', display: 'Refund created' },
      { id: 'additional_charge_created', display: 'Additional charge created' },
      { id: 'additional_charge_approved', display: 'Additional charge approved' },
      { id: 'additional_charge_denied', display: 'Additional charge denied' },
      { id: 'ready_for_pickup', display: 'Ready' },
      { id: 'shipped', display: 'Shipped' },
      { id: 'cancelled', display: 'Cancelled' },
      { id: 'note', display: 'Note' },
      { id: 'edited_email_resent', display: 'Edited email resent' },
      { id: 'confirmation_email_resent', display: 'Confirmation email resent' },
      { id: 'shipment_configured', display: 'Shipment configured' },
      { id: 'created_from_rfq', display: 'Created from RFQ' },
    ],
  },
  {
    id: 'request_for_quote_event_type',
    display: 'RFQ Event',
    filterTypeIds: ['is'],
    options: [
      { id: 'created_on_website', display: 'Created on website' },
      { id: 'created_on_dashboard', display: 'Created manually' },
      { id: 'created_on_buyer', display: 'Created on dashboard' },
      { id: 'created_on_mobile', display: 'Created on mobile' },
      { id: 'note', display: 'Note' },
      { id: 'assigned', display: 'Assigned' },
      { id: 'assigned_deleted', display: 'Assigned supplier deleted' },
      { id: 'closed', display: 'Closed' },
      { id: 'cancelled', display: 'Cancelled' },
      { id: 'quote_created', display: 'Quote created' },
      { id: 'owner_added', display: 'Owner added' },
      { id: 'owner_deleted', display: 'Owner removed' },
      { id: 'fulfilled', display: 'Fulfilled' },
    ],
  },
  {
    id: 'domain',
    display: 'Domain',
    filterTypeIds: ['is'],
    options: [
      { id: 'store_order', display: 'Order' },
      { id: 'request_for_quote', display: 'RFQ' },
    ],
  },
]

export default notificationFields
