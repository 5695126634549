import { Maybe } from '@/types'
import classNames from 'classnames'

type Size = 'sm' | 'md'

const sizes: Record<Size, string> = {
  sm: 'w-[3.125rem] h-[3.125rem]',
  md: 'w-20 h-20',
}

const VendorLogo = ({ url, size = 'sm' }: { url: Maybe<string>; size?: Size }) =>
  url ? (
    <img
      src={url}
      loading="lazy"
      className={classNames(
        'border border-gray-300 rounded-full object-contain bg-white flex-shrink-0',
        sizes[size]
      )}
      alt=""
    />
  ) : (
    <div
      className={classNames(
        ' border border-gray-300 rounded-full bg-gradient-to-tr from-slate-50 to-slate-200 flex-shrink-0',
        sizes[size]
      )}
    />
  )

export default VendorLogo
