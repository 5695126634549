import { useClaimAccountMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import ClaimAccountModal from '@/gf/components/ClaimAccount/Modal'
import useToggle from '@/gf/hooks/useToggle'
import { App } from '@/retail/_gen/gql'
import { ArrowRightIcon } from '@heroicons/react/solid'
import Content from './Content'

const ClaimAccountBanner = () => {
  const { user } = useSession()
  const reloadSession = useReloadSession()
  const [claimAccountMutation] = useClaimAccountMutation({ client: useGqlClient() })
  const [claimModalOpen, claimModalToggle] = useToggle()

  const claimAccount = (fields: {
    password: string
    passwordConfirmation: string
    emailOptIn: boolean
  }) =>
    claimAccountMutation({ variables: { userId: user.id, ...fields } }).then(() => {
      reloadSession()
    })

  return (
    <>
      <div className="bg-orange-300">
        <Content className="flex items-center justify-between gap-x-12 py-2 text-orange-900 font-medium">
          <p className="text-base">
            Claim your free Gearflow account in 1 click to easily track and manage your orders
            across your team.
          </p>

          <button
            type="button"
            onClick={claimModalToggle.on}
            className="shrink-0 flex items-center gap-x-2 rounded-md hover:bg-orange-900 hover:bg-opacity-10 px-3 py-2"
          >
            <span>Claim Account</span> <ArrowRightIcon className="h-4 w-4" />
          </button>
        </Content>
      </div>

      <ClaimAccountModal
        open={claimModalOpen}
        onClose={claimModalToggle.off}
        email={user.email}
        claimAccount={claimAccount}
        onSuccess={() => undefined}
        app={App.PartsHub}
      />
    </>
  )
}

export default ClaimAccountBanner
