import {
  HomeNextDocument,
  HomeNextQuery,
  useDismissAccountBuilderMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import { Maybe } from '@/types'
import { CheckIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useMemo } from 'react'

const Dot = ({ complete }) => (
  <span
    className={classNames(
      'bg-white w-5 h-5 border border-blue-600 rounded-full inline-flex justify-center items-center relative',
      complete && 'bg-blue-600'
    )}
  >
    {complete && <CheckIcon className="h-4 w-4 inline-block text-white" />}
  </span>
)

const OnboardingProgress = ({
  isInitialSetupComplete,
  checkmarks,
}: {
  isInitialSetupComplete: boolean
  checkmarks: NonNullable<HomeNextQuery['org']>['onboardingCheckmarks']
}) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [dismissMutation, { loading }] = useDismissAccountBuilderMutation({
    client,
    variables: { orgId },
    refetchQueries: [HomeNextDocument],
  })
  const steps: [string, Maybe<DateTime>][] = useMemo(
    () => [
      ['Add Locations', checkmarks.locationsCompletedAt],
      ['Add Brands', checkmarks.brandsCompletedAt],
      ['Add Vendors', checkmarks.vendorsCompletedAt],
      ['Invite Team', checkmarks.usersCompletedAt],
    ],
    [checkmarks]
  )

  const allComplete = useMemo(
    () =>
      !!(
        checkmarks.brandsCompletedAt &&
        checkmarks.locationsCompletedAt &&
        checkmarks.vendorsCompletedAt &&
        checkmarks.usersCompletedAt
      ),
    [checkmarks]
  )

  return (
    <section>
      {isInitialSetupComplete ? (
        <Box className="shadow-base flex flex-col-reverse sm:flex-row  gap-4 sm:gap-6 p-6 sm:p-8">
          <div className="space-y-3">
            <h3 className="text-2xl font-medium">Continue building your account</h3>
            <div className="flex gap-x-4">
              {allComplete && (
                <Action.S onClick={() => dismissMutation()} performing={loading}>
                  Dismiss
                </Action.S>
              )}

              <Link.P to="/account-builder" color="blue">
                Continue building
              </Link.P>
            </div>
          </div>
          <div className="flex-grow space-y-2">
            <div className="bg-blue-100 py-2 px-[10%] rounded-full ">
              <div className="flex justify-between relative">
                <hr className="absolute border-blue-600 inset-y-1/2 inset-x-0" />
                {steps.map(([key, completedAt]) => (
                  <Dot key={key} complete={!!completedAt} />
                ))}
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4">
              {steps.map(([key, completedAt]) => (
                <span
                  key={key}
                  className={classNames(
                    'text-base font-medium text-center',
                    completedAt ? 'text-gray-400' : 'text-blue-600'
                  )}
                >
                  {key}
                </span>
              ))}
            </div>
          </div>
        </Box>
      ) : (
        <Box className="shadow-base bg-gray-50 flex gap-4 sm:gap-6 py-6 px-8 items-center">
          <hgroup className="space-y-1 flex-grow">
            <h2 className="text-xl font-medium">Start building your account</h2>
            <p className="text-base">
              You’ll add your first shop location and some of the vendors that support it. It’s
              quick to get started.
            </p>
          </hgroup>
          <div className="flex-shrink-0">
            <Link.P to="/setup" color="blue" className="font-medium">
              Start Building
            </Link.P>
          </div>
        </Box>
      )}
    </section>
  )
}
export default OnboardingProgress
