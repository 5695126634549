import type { PlanPaymentMethod } from '../hooks/usePlanQuery'

import arrowbg from '../images/arrowbg.jpg'

const MaskedDigit = () => (
  <i
    className="inline-block p-0 my-0 w-2 h-2 italic box-border bg-slate-900"
    style={{
      display: 'inline-block',
      borderRadius: '50%',
      width: '9px',
      height: '9px',
      backgroundColor: '#141c31',
      marginRight: '3px',
    }}
  />
)

const MaskedDigits = () => (
  <span className="inline-block box-border">
    <MaskedDigit />
    <MaskedDigit />
    <MaskedDigit />
    <MaskedDigit />
  </span>
)

const PaymentMethod = ({ planPaymentMethod }: { planPaymentMethod: PlanPaymentMethod }) => (
  <div className="max-w-sm">
    <div className="relative overflow-hidden bg-indigo-50 py-8 px-6 rounded-md -ml-4 sm:mx-0 sm:px-6">
      <img
        alt=""
        src={arrowbg}
        width="919"
        height="1351"
        decoding="async"
        data-nimg="1"
        className="absolute left-1/2 top-0 translate-x-[-10%] translate-y-[-45%] lg:translate-x-[-32%]"
        loading="lazy"
        style={{ color: 'transparent' }}
      />
      <div className="relative">
        <p className="flex gap-4 truncate mb-6 text-lg pt-16">
          <MaskedDigits />
          <MaskedDigits />
          <MaskedDigits />
          <span className="inline-block box-border">{planPaymentMethod.lastFour}</span>
        </p>
      </div>
      <div className="relative flex justify-between items-center p-0box-border">
        <p className="flex w-full justify-between my-0 text-lg leading-4 box-border text-zinc-500">
          <span className="flex items-center box-border capitalize">{planPaymentMethod.name}</span>
          {planPaymentMethod.brand === 'visa' || planPaymentMethod.brand === 'mastercard' ? (
            <span className="box-border">
              <img
                className="w-12 h-12"
                src={`/images/${planPaymentMethod.brand}.svg`}
                alt={planPaymentMethod.brand}
              />
            </span>
          ) : (
            <span className="box-border" />
          )}
        </p>
        <div className="flex-shrink-0 w-4 box-border" />
      </div>
    </div>
  </div>
)

export default PaymentMethod
