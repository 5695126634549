import { useMutation } from '@apollo/client'
import classNames from 'classnames'
import omit from 'lodash/omit'
import React from 'react'
import { Address, ShippingLocation } from '../../types'
import useMsgs from '../hooks/useMsgs'
import GraphQL from '../modules/GraphQL'
import Action from './Action'
import AddressInput from './AddressInput'
import Checkbox from './Checkbox'
import PhoneInput from './PhoneInput'
import TextField from './TextField'

import EDIT_SHIPPING_LOCATION from '../mutations/shippingLocation'
import PointSelection from './PointSelection'

const ShippingLocationForm = ({
  shippingLocation,
  className,
  onAfterSave = () => undefined,
  onCancel,
  allowPointSelection = false,
}: {
  shippingLocation: Pick<
    ShippingLocation,
    'id' | 'name' | 'code' | 'phoneNumber' | 'address' | 'defaultLocation'
  >
  className?: string
  onAfterSave?: () => void
  onCancel?: () => void
  allowPointSelection?: boolean
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [name, setName] = React.useState<string>(shippingLocation.name)
  const [code, setCode] = React.useState<string>(shippingLocation.code || '')
  const [defaultLocation, setDefaultLocation] = React.useState<boolean>(
    shippingLocation.defaultLocation
  )
  const [phoneNumber, setPhoneNumber] = React.useState<string>(shippingLocation.phoneNumber)
  const [address, setAddress] = React.useState<Address | null>(shippingLocation.address)
  const [editShippingLocation] = useMutation(EDIT_SHIPPING_LOCATION)

  return (
    <form
      className={classNames('bg-white p-4 flex flex-col', className)}
      onSubmit={(e) => {
        e?.preventDefault()

        if (!address) {
          msgsMgr.add('Address is required', 'negative')
          return
        }
        const addressInput = {
          ...omit(GraphQL.cleanGraphQlInput(address), ['latitude', 'longitude']),
          point: address.point ? GraphQL.cleanGraphQlInput(address.point) : null,
        }

        const formData = {
          name,
          code,
          phoneNumber,
          defaultLocation,
          address: addressInput,
        }

        editShippingLocation({ variables: { ...formData, id: shippingLocation.id } })
          .then(() => {
            onAfterSave()

            msgsMgr.add('Updated Shipping Location', 'positive')
          })
          .catch(() => {
            msgsMgr.add('Failed Updating Shipping Location', 'negative')
          })
      }}
    >
      <div className="pb-2">
        <Checkbox
          label="Default Location"
          checked={defaultLocation}
          value="Default Location"
          onChange={() => setDefaultLocation(!defaultLocation)}
        />
      </div>
      <div className="pt-2 pb-2">
        <TextField
          label="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
          noErrorText
        />
      </div>
      <div className="pt-2 pb-2">
        <PhoneInput
          label="Phone number"
          required
          value={phoneNumber}
          onChange={(newPhoneNumber) => setPhoneNumber(newPhoneNumber)}
        />
      </div>
      <div className="pt-2 pb-4">
        <TextField
          label="Code (optional)"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          noErrorText
        />
      </div>
      <div className="flex flex-col gap-4 py-6 border-t-1">
        <p className="text-xs font-bold text-gray-900 uppercase tracking-wider">Shipping address</p>
        {allowPointSelection && (
          <PointSelection
            point={address?.point}
            onChange={(point) => setAddress({ ...address, point } as Address)}
            className="text-left"
          />
        )}
        <AddressInput
          address={address || undefined}
          onChange={(newAddress) => setAddress(newAddress)}
        />
      </div>
      <div className="flex gap-x-6 justify-end">
        {onCancel && <Action.S onClick={onCancel}>Cancel</Action.S>}
        <Action.P className="self-end flex-grow max-w-56" type="submit">
          Submit
        </Action.P>
      </div>
    </form>
  )
}

export default ShippingLocationForm
