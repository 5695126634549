import classNames from 'classnames'

import { FilterTab } from '../../types'

const NotificationBadge = ({ count }: { count: number }) => (
  <span className="absolute -mt-1.5 ml-0.5 h-5 w-5 inline-flex items-center justify-center bg-gradient-to-t from-blue-400 to-blue-200 text-blue-900 font-medium text-xs rounded-full">
    {count > 99 ? '99+' : count}
  </span>
)

const FilterTabs = ({
  tabs,
  currentTabName,
  onTabSelect,
}: {
  tabs: FilterTab[]
  currentTabName?: string
  onTabSelect: (filterTab: FilterTab) => void
}) => (
  <div className="px-4 sm:px-8 border-b border-gray-200 bg-gray-50">
    <ul className="-mb-px flex space-x-4" aria-label="Tabs">
      {tabs.map((tab, index) => {
        const current = currentTabName ? currentTabName === tab.name : index === 0
        return (
          <li key={tab.name} aria-current={current ? 'true' : undefined}>
            <button
              className={classNames(
                current
                  ? 'text-gray-700 font-semibold border-gearflow'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'group inline-flex items-center py-4 pb-3 px-3 border-b-2 font-medium text-sm'
              )}
              type="button"
              onClick={() => onTabSelect(tab)}
            >
              <div>
                <span>{tab.name}</span>

                {tab.notification !== undefined && tab.notification !== 0 && (
                  <NotificationBadge count={tab.notification} />
                )}
              </div>
            </button>
          </li>
        )
      })}
    </ul>
  </div>
)

export default FilterTabs
