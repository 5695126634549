import { useState, useMemo } from 'react'
import uniqBy from 'lodash/uniqBy'
import { Navigate } from 'react-router-dom'

import { NewConversationStore, Pagination, User, DetailsForConversationProps } from '@/types'

import ConversationM from '@/buyers/modules/Conversation'
import useSession from '@/buyers/hooks/useSession'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import {
  ConversationMembersDocument,
  FetchConversationsDocument,
  FetchMessagesForConversationDocument,
  useCreateConversationMutation,
  useCreateMessageMutation,
  useFetchMessagesForConversationQuery,
  useReadReceiptsForConversationQuery,
  RequestForQuoteStep,
  RequestQuery,
} from '@/buyers/_gen/gql'
import useAbsoluteMessagesSendToInput, {
  noUsersErrorText,
  threadContainerClassName,
} from '@/buyers/hooks/useAbsoluteMessagesSendToInput'

import InboxCommon, { Conversation } from '@/gf/components/Inbox'
import { RequestForQuoteInboxDetails } from '@/buyers/components/InboxDetails'

type Rfq = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]
type Org = RequestQuery['org']

const useRequestForQuote = (requestForQuote: Rfq) =>
  useMemo(
    () => (props: DetailsForConversationProps) => (
      <RequestForQuoteInboxDetails {...props} requestForQuote={requestForQuote} />
    ),
    [requestForQuote]
  )

const RequestForQuoteInbox = ({
  org,
  conversations,
  selectedConversationId,
  selectedStoreId,
  selectedInternalOrg,
  onSelectedConversationIdChange,
  refetchConversations,
  page,
  setPage,
  pagination,
  user,
  requestForQuote,
  refetchRequestForQuote,
}: {
  org: Org
  conversations: Conversation[]
  selectedConversationId: string | undefined
  selectedStoreId: string | undefined
  selectedInternalOrg?: boolean
  onSelectedConversationIdChange: (conversationId: string | undefined) => void
  refetchConversations: () => Promise<unknown>
  page: number
  setPage: (page: number) => void
  pagination: Pagination
  user: Pick<User, 'id' | 'role'>
  requestForQuote: Rfq
  refetchRequestForQuote: () => Promise<unknown>
}) => {
  const gqlClient = useGqlClient()
  const { featureFlags } = useSession()
  const [sendToUserIds, setSendToUserIds] = useState<string[]>([])
  const [sendToErrorText, setSendToErrorText] = useState<string>()
  const DetailsForConversation = useRequestForQuote(requestForQuote)

  const [createMessage] = useCreateMessageMutation({
    client: gqlClient,
    refetchQueries: [
      FetchMessagesForConversationDocument,
      FetchConversationsDocument,
      ConversationMembersDocument,
    ],
  })

  const [createConversation] = useCreateConversationMutation({
    client: gqlClient,
    refetchQueries: [FetchConversationsDocument],
  })

  const absoluteThreadContent = useAbsoluteMessagesSendToInput({
    errorText: sendToErrorText,
    onChangeErrorText: setSendToErrorText,
    onChangeSendToUserIds: setSendToUserIds,
  })

  if (!org) return null

  // Buyer can start a conversation with any Store they've received a quote from
  const quoteStores = requestForQuote.storeOrders.map((storeOrder) => storeOrder.store)

  // Also include known Vendors (Stores that have a Vendor with this Organization)
  const vendorStores: NewConversationStore[] = featureFlags.canMessageVendorDealerBeforeQuote
    ? (requestForQuote.requestForQuoteVendors?.reduce(
        (acc: NewConversationStore[], rfqVendor) =>
          rfqVendor.vendor.store ? [...acc, rfqVendor.vendor.store] : acc,
        []
      ) ?? [])
    : []

  const isExternalOrder = (requestForQuote.storeOrders ?? []).some((so) => so.externalOrder)

  const nonAdminConvos = (() => {
    if (requestForQuote.step === RequestForQuoteStep.Inbound) return []

    const internalVendorStoreId = org.vendors.find((v) => v.internal)?.store?.id

    return uniqBy([...quoteStores, ...vendorStores], (s) => s.id)
      .filter(({ id }) => id !== internalVendorStoreId && !isExternalOrder)
      .map((s) => ConversationM.newNonAdminConversationRfq(requestForQuote, s))
  })()

  const newConversationsUnfiltered = [
    ...nonAdminConvos,
    // Admin conversations
    ConversationM.newAdminConversationRfq(undefined, requestForQuote),
    // Internal Organization conversation
    ConversationM.newInternalOrgConversationRfq(undefined, requestForQuote, org),
  ]

  // Filter out existing conversations
  const newConversations = ConversationM.filterNewConversations(
    newConversationsUnfiltered,
    conversations
  )

  // If there's a selected Store, find the conversation id from the conversations and newConversations
  const selectedStoreConversationId = selectedStoreId
    ? (conversations.find((conversation) => conversation.store?.id === selectedStoreId)?.id ??
      newConversations.find((newConversation) => newConversation.store?.id === selectedStoreId)?.id)
    : undefined

  const selConvoId = selectedConversationId ?? selectedStoreConversationId

  const selectedConversation =
    conversations && [...conversations, ...newConversations].find((c) => c.id === selConvoId)

  const { error: messagesError, ...messagesQueryResult } = useFetchMessagesForConversationQuery({
    client: gqlClient,
    variables: {
      conversationId: selectedConversation?.id as string,
    },
    pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
    // Only refetch if the conversation is unread, so we can fetch the correct "read" status
    onCompleted: selectedConversation?.unreadMessages ? refetchConversations : undefined,
    skip: !selConvoId || !selectedConversation || 'newConversation' in selectedConversation,
  })

  const { fetchMessagesForConversation: messages } = messagesQueryResult.data || {}

  const { readReceiptsForConversation } =
    useReadReceiptsForConversationQuery({
      client: gqlClient,
      variables: { conversationId: selectedConversation?.id as string },
      pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
      skip: !selConvoId || !selectedConversation || 'newConversation' in selectedConversation,
    }).data || {}

  if (!selectedConversationId && selectedInternalOrg) {
    const internalConversation =
      conversations.find(ConversationM.isInternalOrgConversation) ??
      newConversations.find(ConversationM.isInternalOrgConversation)

    if (internalConversation)
      return (
        <Navigate to={`/rfqs/${requestForQuote.id}/messages/${internalConversation.id}`} replace />
      )
  }

  return (
    <InboxCommon
      className="mt-4"
      user={user}
      userOrganizationId={org.id}
      conversations={conversations}
      selectedConversation={selectedConversation}
      selectedConversationId={selConvoId}
      messages={messages}
      messagesError={messagesError}
      readReceiptsForConversation={readReceiptsForConversation}
      onSelectedConversationIdChange={onSelectedConversationIdChange}
      page={page}
      setPage={setPage}
      pagination={pagination}
      newConversations={newConversations}
      DetailsForConversation={DetailsForConversation}
      conversationRowContent={ConversationM.defaultConversationRowContent}
      createMessage={(variables) =>
        createMessage({ variables: { ...variables, to: sendToUserIds } }).then(() => {
          refetchRequestForQuote()
        })
      }
      createConversation={(variables) => {
        if (
          ConversationM.isInternalOrgConversation({
            admin: variables.conversationInput.admin,
            store: variables.conversationInput.storeId
              ? { id: variables.conversationInput.storeId }
              : null,
          }) &&
          sendToUserIds.length === 0
        ) {
          setSendToErrorText(noUsersErrorText)
          return Promise.resolve(undefined)
        }
        return createConversation({ variables: { ...variables, to: sendToUserIds } }).then(
          (resp) => {
            refetchRequestForQuote()
            return resp.data?.createConversation
          }
        )
      }}
      absoluteThreadContent={absoluteThreadContent}
      threadContainerClassName={threadContainerClassName}
    />
  )
}

export default RequestForQuoteInbox
