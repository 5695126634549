const Section = ({
  children,
  title,
  actions = null,
}: {
  children: React.ReactNode
  title?: React.ReactNode
  actions?: React.ReactNode
}) => (
  <section>
    {(title || actions) && (
      <header className="flex justify-between px-4 xl:px-6 pt-2 xl:pt-3">
        {title && <h3 className="text-lg font-medium">{title}</h3>}
        {actions && <div>{actions}</div>}
      </header>
    )}

    <div className="px-4 xl:px-6 pt-2 xl:pt-3 pb-4 xl:pb-6">{children}</div>
  </section>
)

export default Section
