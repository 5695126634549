import { ReactNode } from 'react'
import classNames from 'classnames'

const Pill = ({ className, children }: { className?: string; children: ReactNode }) => (
  <div
    className={classNames(
      'px-3 py-1 inline-flex flex-row items-center space-x-2 rounded-full whitespace-nowrap',
      className
    )}
  >
    {children}
  </div>
)

export default Pill
