import Action from '@/gf/components/Action'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { useNavigate } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import { useOrgBrandsQuery } from '../_gen/gql'
import FullScreenLayout from '../components/FullScreenLayout'
import SelectShippingLocationModal from '../components/SelectShippingLocationModal'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import Brands from './Setup/Brands'
import useBrandsForm from './Setup/Brands/useForm'

const OrgBrands = () => {
  const [_, msgs] = useMsgs()
  const navigate = useNavigate()
  const client = useGqlClient()
  const [returnTo, _setReturnTo] = useQueryParam('returnTo', StringParam)
  const [locationSelectionOpen, locationModalToggle] = useToggle()
  const { organization: org } = useSession()

  const form = useBrandsForm([])

  const { loading } = useOrgBrandsQuery({
    variables: { id: org.id },
    client,
    onCompleted(data) {
      form.updateFields({
        previouslyAddedBrands: data.org?.brandStats.map(({ brand }) => brand) ?? [],
      })
    },
  })

  const backToSource = () => returnTo && navigate(returnTo)

  const onSave = () =>
    form.save(() => {
      msgs.add('Brands saved to your account', 'positive')

      if (returnTo) {
        backToSource()
      } else {
        locationModalToggle.on()
      }
    })

  const onCancelClick = () => {
    if (returnTo) {
      backToSource()
    } else {
      navigate('/account-builder')
    }
  }

  if (loading) {
    return null
  }

  return (
    <FullScreenLayout
      footer={[
        <Action.S onClick={onCancelClick}>Cancel</Action.S>,
        <Action.P color="blue" onClick={onSave} disabled={form.submitDisabled}>
          Done Adding
        </Action.P>,
      ]}
    >
      <SelectShippingLocationModal
        open={locationSelectionOpen}
        onClose={() => {
          locationModalToggle.off()

          navigate('/account-builder')
        }}
        onSaveAndExit={() => navigate('/account-builder')}
        title="Find vendors for the brands you just added."
        subtitle="Which location do you want to add vendors for?"
      />

      <Brands
        form={form}
        title="Add Fleet Brands"
        subtitle={null}
        goBackAction={returnTo ? { title: 'Add Vendors', onClick: backToSource } : undefined}
      />
    </FullScreenLayout>
  )
}

export default OrgBrands
