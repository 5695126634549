import {
  SearchAccountMachinesQuery,
  useSearchAccountMachinesQuery,
  useSelectedMachineQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Select from '@/gf/components/next/Select'
import { Maybe } from '@/types'
import { useMemo, useState } from 'react'
import { GroupBase, OptionProps, components } from 'react-select'

type ShallowMachine = Pick<
  SearchAccountMachinesQuery['machinesSearch'][number],
  'name' | 'serialNumber'
> & {
  machine: {
    make: string | null
    model: string | null
    year: number | null
  }
}

type MachineOption = {
  label: string
  value: string
  machine: ShallowMachine
}

function CustomOption<
  Option extends MachineOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: OptionProps<Option, IsMulti, Group>) {
  const orgMachine = props.data.machine
  return (
    <components.Option {...props}>
      <span className="flex flex-col">
        <span className="font-medium">{orgMachine.name}</span>
        <span className="font-normal">
          {orgMachine.machine.make} {orgMachine.machine.model} {orgMachine.machine.year}
        </span>
        <span className="text-sm">
          <span className="italic text-gray-600">Serial:</span> {orgMachine.serialNumber}
        </span>
      </span>
    </components.Option>
  )
}

const MachineSelect = ({
  value,
  onChange,
  id,
}: {
  value: Maybe<string>
  onChange: (newValue: string) => void
  id?: string
}) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [search, setSearch] = useState('')
  const { data, loading } = useSearchAccountMachinesQuery({
    variables: { orgId, value: search },
    client,
  })
  const { data: selectedMachineData, loading: loadingSelected } = useSelectedMachineQuery({
    variables: { id: value as string },
    client,
    skip: !value,
  })

  const options = useMemo(
    () => (data?.machinesSearch ?? []).map((m) => ({ label: m.name, value: m.id, machine: m })),
    [data?.machinesSearch]
  )

  const selectedOption = useMemo(
    () =>
      selectedMachineData?.orgMachine
        ? {
            label: selectedMachineData?.orgMachine.name,
            value: selectedMachineData?.orgMachine.id,
            machine: selectedMachineData.orgMachine as ShallowMachine,
          }
        : null,
    [selectedMachineData]
  )
  return (
    <Select
      value={selectedOption}
      onChange={(o) => o && onChange(o?.value)}
      options={options}
      isLoading={loading || loadingSelected}
      placeholder="Select machine"
      className="text-sm w-full"
      onInputChange={setSearch}
      filterOption={() => true}
      id={id}
      components={{
        Option: CustomOption<MachineOption>,
      }}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? `No machines found for "${inputValue}"` : 'No machines found'
      }
    />
  )
}

export default MachineSelect
