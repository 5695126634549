import { ReactNode, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import logo from '@/retail/components/Layout/logo.png'
import uniqueId from 'lodash/uniqueId'
import Messages from '@/gf/components/RocketLayout/Messages'

const FullScreenLayout = ({
  children,
  topCenter,
  topRight,
  footer = [],
}: {
  children: ReactNode
  topCenter?: ReactNode
  topRight?: ReactNode
  footer?: ReactNode[]
}) => {
  const keyedFooterActions = useMemo(
    () => (footer.length > 0 ? footer.map((action) => ({ key: uniqueId(), action })) : []),
    [footer]
  )
  return (
    <div className="min-h-screen bg-white border-t-8 border-gearflow flex flex-col">
      <header className="flex px-8 py-6">
        <div className="basis-1/2 md:basis-1/4">
          <RouterLink to="/">
            <img src={logo} alt="Gearflow" className="w-36" />
          </RouterLink>
        </div>

        <div className="basis-0 hidden md:basis-1/2 md:block">{topCenter}</div>

        <div className="basis-1/2 flex justify-end md:basis-1/4">{topRight}</div>
      </header>

      <div className="md:hidden px-8">{topCenter}</div>

      <div className="flex-1">
        {children}
        <Messages />
      </div>

      {footer && footer.length > 0 ? (
        <div className="sticky bottom-0 bg-white border-t-2 border-gray-100 px-8 py-2">
          <div className="max-w-xl mx-auto flex justify-between">
            {keyedFooterActions.map(({ key, action }) => (
              <div key={key}>{action}</div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default FullScreenLayout
