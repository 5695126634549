import { DeliveryMethod } from '@/buyers/_gen/gql'
import { CubeIcon, OfficeBuildingIcon, TruckIcon } from '@heroicons/react/outline'
import keyBy from 'lodash/keyBy'
import { DateTime } from 'luxon'
import { useContext } from '../../RFQ'
import Section from './Section'
import VendorDetails from './VendorDetails'

const formatReceivedAt = (dateTime: DateTime) => {
  const format = dateTime.year < DateTime.now().year ? 'DD, t' : 'LLL d, t'
  return dateTime.toFormat(format)
}

const NonparticipatingVendors = () => {
  const { rfq } = useContext()

  const nonparticipationByStoreId = keyBy(rfq.nonparticipations, (n) => n.storeId)

  const nonparticipatingRfqVendors = rfq.requestForQuoteVendors.filter((rfqv) =>
    rfq.nonparticipations.some((n) => n.storeId === rfqv.vendor?.store?.id)
  )

  if (nonparticipatingRfqVendors.length === 0) return null

  return (
    <Section
      title={<div className="flex flex-row items-center gap-x-4">Can&apos;t Participate</div>}
    >
      <div className="flex flex-col gap-y-4">
        <p className="text-sm">
          {nonparticipatingRfqVendors.length > 1 ? 'These vendors have' : 'This vendor has'} decided
          to not participate in the request.
        </p>

        {nonparticipatingRfqVendors.map((rfqVendor) => (
          <div className="border-2 rounded-md">
            <div className="relative flex flex-col md:flex-row gap-y-2 p-4">
              <div className="flex-grow">
                {rfqVendor.vendor.store && (
                  <VendorDetails
                    name={rfqVendor.vendor.store.name}
                    address={rfqVendor.vendor.store.address}
                    distance={rfqVendor.distanceFromShippingAddress}
                    contactNames={rfqVendor.vendorContacts
                      .filter((c) => !!c.name)
                      .map((c) => c.name as string)}
                  >
                    <div className="mt-3 text-sm">
                      Reason:{' '}
                      {nonparticipationByStoreId[rfqVendor.vendor.store.id]?.reason === 'Other'
                        ? nonparticipationByStoreId[rfqVendor.vendor.store.id].details
                        : nonparticipationByStoreId[rfqVendor.vendor.store.id]?.reason}
                    </div>
                  </VendorDetails>
                )}
              </div>

              <div className="flex flex-col items-end space-y-2">
                <div className="text-sm">
                  Requested on {formatReceivedAt(rfqVendor.addedAt || rfq.insertedAt)}
                </div>

                <div className="flex items-center gap-1 text-sm">
                  {rfqVendor.deliveryMethod === DeliveryMethod.Pickup ? (
                    <>
                      <OfficeBuildingIcon className="w-4 h-4" />
                      <span>Will Call</span>
                    </>
                  ) : rfqVendor.deliveryMethod === DeliveryMethod.Shipping ? (
                    <>
                      <CubeIcon className="w-4 h-4" />
                      <span>Ship</span>
                    </>
                  ) : rfqVendor.deliveryMethod === DeliveryMethod.VendorDelivery ? (
                    <>
                      <TruckIcon className="w-4 h-4" />
                      <span>Delivery</span>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Section>
  )
}

export default NonparticipatingVendors
