// https://tailwindui.com/components/application-ui/data-display/description-lists

const List = ({ children }: { children: React.ReactNode }) => (
  <dl className="space-y-4 sm:divide-y sm:divide-gray-200 sm:space-y-0">{children}</dl>
)

const Row = ({
  className = '',
  title,
  children,
}: {
  className?: string
  title: React.ReactNode
  children: React.ReactNode
}) => (
  <div className={`px-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 ${className}`}>
    <dt className="text-sm leading-6 font-semibold text-gray-600">{title}</dt>
    <dd className="sm:col-span-2 text-base">{children}</dd>
  </div>
)

export default { List, Row }
