import type { Money } from '@/types'

import MoneyM from '@/gf/modules/Money'

import Label from '@/gf/components/Label'
import PriceInput from '@/gf/components/inputs/PriceV2'

const zeroPrice = MoneyM.fromDecimal(0, 'USD')

const PricesForm = ({
  subtotal,
  shipping,
  tax,
  pickup,
  setTax,
  setShipping,
}: {
  subtotal: Money
  shipping: Money | null
  tax: Money | null
  pickup: boolean
  setTax: (price: Money | null) => void
  setShipping: (price: Money | null) => void
}) => {
  const total = [subtotal, shipping, tax].map((m) => m || zeroPrice).reduce(MoneyM.add)

  return (
    <div className="grid grid-cols-2 gap-y-2 gap-x-8 text-base items-center">
      <Label>Subtotal</Label>
      <div className="text-right">{MoneyM.format(subtotal)}</div>

      <Label htmlFor="shipping">Shipping</Label>

      <div className="flex justify-end">
        {pickup ? (
          <div className="flex gap-2 items-center">
            <span className="text-sm text-gray-500 italic">(In-store Pickup)</span>
            <span>{MoneyM.format(zeroPrice)}</span>
          </div>
        ) : (
          <div className="w-32">
            <PriceInput
              price={shipping}
              onChange={(price) => setShipping(price || null)}
              min={0}
              id="shipping"
            />
          </div>
        )}
      </div>

      <Label htmlFor="tax">Tax</Label>

      <div className="flex justify-end">
        <div className="w-32">
          <PriceInput price={tax} onChange={(price) => setTax(price || null)} min={0} id="tax" />
        </div>
      </div>

      <Label>Total</Label>
      <div className="text-right">{MoneyM.format(total)}</div>
    </div>
  )
}

export default PricesForm
