import { useAddUserMutation } from '@/buyers/_gen/gql'
import useAddUserForm from '@/buyers/hooks/useAddUserForm'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import TextInput from '@/gf/components/inputs/Text'
import { commonContext, register } from '@/gf/modules/GrammarEvents'
import { useEffect, useRef, useState } from 'react'
import { useRfqNotificationsContext } from './context'
import SubscriptionChanges from './SubscriptionChanges'

const NewUser = ({
  onAdded,
  cancel,
}: {
  onAdded: (userId: string) => void
  cancel: () => void
}) => {
  const session = useSession()
  const { org, rfq } = useRfqNotificationsContext()
  const userEmailRef = useRef<HTMLInputElement>(null)
  const [newUserIds, setNewUserIds] = useState<string[]>([])
  const [adding, setAdding] = useState(false)
  const { fields, updateFields, reset } = useAddUserForm()
  const [addUserMutation] = useAddUserMutation({ client: useGqlClient() })

  const addUser = async () => {
    if (!org || !rfq) return
    setAdding(true)

    const roleId = org.roles.filter((r) => r.name === 'Purchaser')[0].id

    const { data } = await addUserMutation({
      variables: {
        orgId: org.id,
        email: fields.email,
        name: '',
        paymentMethodId: null,
        inviteId: null,
        phoneNumber: '',
        title: null,
        roleIds: [roleId],
      },
    })

    if (!data) return

    // [user] creates [user] for [request_for_quote] notifications
    register({
      subject: { type: 'user', id: session.user.id },
      verb: 'creates',
      directObject: { type: 'user', id: data.addUser },
      prepositionalObject: { type: 'request_for_quote', id: rfq.id },
      sentence: '[S] creates [DO] for [PO] notifications',
      context: commonContext(),
    })

    reset()
    setAdding(false)
    setNewUserIds([...newUserIds, data.addUser])
    onAdded(data.addUser)
  }

  useEffect(() => {
    userEmailRef.current?.focus()
  }, [])

  return (
    <div className="space-y-4">
      <Form onSubmit={addUser} className="flex gap-2 items-center">
        <TextInput
          value={fields.email}
          setValue={(email) => updateFields({ email })}
          placeholder="New User Email"
          ref={userEmailRef}
        />

        <Action.P type="submit" performing={adding} className="whitespace-nowrap">
          Add
        </Action.P>

        <Action.S onClick={cancel}>Cancel</Action.S>
      </Form>

      <SubscriptionChanges />
    </div>
  )
}

export default NewUser
