import { RfqNotificationsQuery } from '@/buyers/_gen/gql'
import { createContext, useContext } from 'react'

export type Org = Exclude<RfqNotificationsQuery['org'], null>
type Rfq = RfqNotificationsQuery['rfqs'][number]
type PlanSubscription = RfqNotificationsQuery['planSubscription']
type PlanPaymentMethod = RfqNotificationsQuery['planPaymentMethod']

export const RfqNotificationsContext = createContext<
  | {
      org: Org | undefined
      rfq: Rfq | undefined
      planSubscription: PlanSubscription | undefined
      planPaymentMethod: PlanPaymentMethod | undefined
    }
  | undefined
>(undefined)

export const useRfqNotificationsContext = () => {
  const context = useContext(RfqNotificationsContext)
  if (context === undefined) throw new Error('must be used within provider')
  return context
}
