import { useState } from 'react'
import ModalForm from '@/gf/components/ModalForm'
import TextInput from '@/gf/components/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useGqlClient from '../../hooks/useGqlClient'
import { useEditWorkOrderMutation } from '../../_gen/gql'

const EditWorkOrderModal = ({ open, onClose, woNumber, requestId, refetch }) => {
  const [_, messages] = useMsgs()
  const [workOrderNumber, setWorkOrderNumber] = useState(woNumber || null)
  const [editWorkOrder] = useEditWorkOrderMutation({ client: useGqlClient() })

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    editWorkOrder({
      variables: {
        id: requestId,
        workOrderNumber,
      },
    })
      .then(() => {
        refetch()
        messages.add('Work Order Number Updated!', 'positive')
      })
      .catch(() => {
        messages.add('Error updating work order number', 'negative')
      })
      .finally(onClose)
  }

  return (
    <ModalForm
      title="Edit Work Order Number"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Save"
    >
      <div className="flex flex-col gap-y-2">
        <TextInput value={workOrderNumber} setValue={setWorkOrderNumber} />
      </div>
    </ModalForm>
  )
}

export default EditWorkOrderModal
