import React from 'react'

import FilterSet from '../modules/FilterSet'

import { Filter, FiltersApi, FilterTypeId, FilterField } from '../../types'

const useFilters = (initialFilters: Filter[]) => {
  const [filters, setFilters] = React.useState(initialFilters)

  const add = (filter: Filter) => setFilters([...filters, filter])

  const remove = (id: string) => setFilters(FilterSet.remove(filters, id))

  const updateField = (filter: Filter, field: FilterField) =>
    setFilters(FilterSet.updateField(filters, filter.id, field))

  const updateTypeId = (filter: Filter, type: FilterTypeId) =>
    setFilters(FilterSet.updateTypeId(filters, filter.id, type))

  const updateText = (filter: Filter, text: string) =>
    setFilters(FilterSet.updateText(filters, filter.id, text))

  const updateFrom = (filter: Filter, from: string | null) =>
    setFilters(FilterSet.updateFrom(filters, filter.id, from))

  const updateTo = (filter: Filter, to: string | null) =>
    setFilters(FilterSet.updateTo(filters, filter.id, to))

  const updateOption = (filter: Filter, optionId: string) =>
    setFilters(FilterSet.updateOption(filters, filter.id, optionId))

  const api: FiltersApi = {
    add,
    remove,
    updateField,
    updateTypeId,
    updateText,
    updateFrom,
    updateTo,
    updateOption,
  }

  return [filters, api] as const
}

export default useFilters
