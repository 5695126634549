import Page from '@/gf/components/Page'
import Frame from './Frame'
import NoAccess from './NoAccess'
import PartHubProPitch from './PartHubProPitch'
import { Breadcrumbs } from '@/types'

const FullPagePaywall = ({ breadcrumbs, title }: { breadcrumbs?: Breadcrumbs; title?: string }) => (
  <Frame breadcrumbs={breadcrumbs}>
    <Page title={title}>
      <div className="flex my-4">
        <NoAccess />
      </div>
      <PartHubProPitch />
    </Page>
  </Frame>
)

export default FullPagePaywall
