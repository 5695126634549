import { useVendorContactsQuery, VendorContact } from '../_gen/gql'
import useGqlClient from './useGqlClient'

const useVendorContacts = (vendorId: string) => {
  const { refetch, loading, error, data } = useVendorContactsQuery({
    variables: { id: vendorId },
    client: useGqlClient(),
  })

  return { refetch, loading, error, contacts: (data?.vendor?.contacts || []) as VendorContact[] }
}

export default useVendorContacts
