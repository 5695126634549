import { gql } from '@apollo/client'

const EDIT_SHIPPING_LOCATION = gql`
  mutation EditShippingLocation(
    $id: String
    $name: String!
    $code: String
    $defaultLocation: Boolean
    $phoneNumber: String!
    $address: AddressInput!
  ) {
    editShippingLocation(
      id: $id
      name: $name
      code: $code
      defaultLocation: $defaultLocation
      phoneNumber: $phoneNumber
      address: $address
    ) {
      id
    }
  }
`

export default EDIT_SHIPPING_LOCATION
