import { DeliveryMethod } from '@/buyers/_gen/gql'
import {
  SimpleTooltip,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/gf/components/next/Tooltip'
import RadioInput from '@/gf/components/next/forms/RadioInput'
import { Maybe } from '@/types'
import { ExclamationIcon } from '@heroicons/react/solid'
import { ReactNode } from 'react'

const DeliveryMethodSelector = ({
  dealerLocationId,
  value,
  onChange,
  showWillCallDistanceWarning,
  showAddressNotDeliverableWarning,
  selectionAction,
  tutorial = false,
}: {
  dealerLocationId: string
  value: Maybe<DeliveryMethod>
  onChange: (newValue: DeliveryMethod) => void
  showWillCallDistanceWarning: boolean
  showAddressNotDeliverableWarning: boolean
  selectionAction?: ReactNode
  tutorial?: boolean
}) => (
  <>
    <label className="py-2 flex gap-x-2 text-sm" htmlFor={`will-call-${dealerLocationId}`}>
      <RadioInput
        name={`delivery-method-${dealerLocationId}`}
        checked={value === DeliveryMethod.Pickup}
        onChange={(e) => e.target.checked && onChange(DeliveryMethod.Pickup)}
        id={`will-call-${dealerLocationId}`}
      />
      <span>
        <span className="flex items-center gap-x-1">
          Will Call{' '}
          {showWillCallDistanceWarning && (
            <SimpleTooltip
              placement="bottom-start"
              text="May be out of range"
              className="lg:max-w-56"
            >
              <ExclamationIcon className="h-5 w-5 inline-block text-yellow-600" />
            </SimpleTooltip>
          )}
          {value === DeliveryMethod.Pickup && selectionAction}
        </span>
        {showWillCallDistanceWarning && (
          <span className="text-xs text-yellow-600 hidden xl:block">May be out of range</span>
        )}
      </span>
    </label>
    <Tooltip>
      <TooltipTrigger>
        <label
          className="py-2 flex items-center gap-x-2 text-sm"
          htmlFor={`shipping-${dealerLocationId}`}
        >
          <RadioInput
            name={`delivery-method-${dealerLocationId}`}
            checked={value === DeliveryMethod.Shipping}
            onChange={(e) => e.target.checked && onChange(DeliveryMethod.Shipping)}
            id={`shipping-${dealerLocationId}`}
            disabled={tutorial}
          />
          <span>
            <span className="flex items-center gap-x-1">
              Shipping{' '}
              {showAddressNotDeliverableWarning && (
                <ExclamationIcon className="h-5 w-5 inline-block text-yellow-600" />
              )}
              {value === DeliveryMethod.Shipping && selectionAction}
            </span>
            {showAddressNotDeliverableWarning && (
              <span className="text-xs text-yellow-600">Address not deliverable</span>
            )}

            {tutorial ? (
              <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                Shipping is disabled for this tutorial
              </TooltipContent>
            ) : showAddressNotDeliverableWarning ? (
              <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                Our system flagged this drop-off location as not deliverable. Please verify that
                this address is correct before proceeding.
              </TooltipContent>
            ) : null}
          </span>
        </label>
      </TooltipTrigger>
    </Tooltip>
  </>
)

export default DeliveryMethodSelector
