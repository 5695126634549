import Ghost from '@/gf/components/Ghost'

const ItemGhost = () => (
  <div className="rounded-lg border overflow-x-hidden">
    <div className="flex flex-col gap-y-2 h-full border-l-4 border-slate-100 p-2">
      <Ghost className="block h-5 w-48 rounded-xl" />
      <Ghost className="block h-4 w-full rounded-xl" />
      <Ghost className="block h-4 w-32 rounded-xl" />
    </div>
  </div>
)

const ContactsListGhost = () => (
  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 mt-4">
    <ItemGhost />
    <ItemGhost />
  </div>
)

export default ContactsListGhost
