import { useSettingsQuery } from '@/buyers/_gen/gql'
import Frame from '@/buyers/components/Frame'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Page from '@/gf/components/Page'
import Tabs from '@/gf/components/Tabs'
import nth from 'lodash/nth'
import { Navigate, Route, Routes, matchPath, useLocation, useNavigate } from 'react-router-dom'
import Billing from './Settings/Billing'
import Integrations from './Settings/Integrations'
import NotificationSettings from './Settings/Notifications'
import Org from './Settings/Org'
import ProfileSettings from './Settings/Profile'
import Qualify from './Settings/Qualify/Qualify'
import UsersSettings from './Settings/Users'
import AddUser from './Settings/Users/Add'

const BuyerSettings = () => {
  const {
    user: { id: userId },
  } = useSession()

  const location = useLocation()
  const navigate = useNavigate()
  const user = useSettingsQuery({ variables: { userId }, client: useGqlClient() }).data?.user

  const tabs = (() => {
    if (!user) return undefined

    let result = [
      { name: 'Profile', path: '/settings/profile' },
      { name: 'Notifications', path: '/settings/notifications' },
    ]

    if (user.can.updateAccountSettings)
      result = [
        ...result,
        { name: 'Organization', path: '/settings/organization' },
        { name: 'Plan & Billing', path: '/settings/billing' },
      ]

    if (user.can.manageUsers) result.push({ name: 'Users & Permissions', path: '/settings/users' })

    if (user.can.updateAccountSettings)
      result.push({ name: 'Integrations', path: '/settings/integrations' })

    return result
  })()

  const currentTab =
    tabs?.find((t) => !!matchPath({ path: `${t.path}/*` }, location.pathname)) || nth(tabs, 0)

  const onTabSelect = (selectedTab: string) => {
    const path = tabs?.find((t) => t.name === selectedTab)?.path as string
    navigate(path)
  }

  return (
    <Frame>
      <Page className="mt-4" title="Settings">
        <Tabs tabs={tabs} currentTab={currentTab} onTabSelect={onTabSelect} className="mt-4" />

        <div className="mt-4">
          <Routes>
            <Route path="" element={<Navigate to="profile" replace />} />
            <Route path="profile" element={<ProfileSettings />} />
            <Route path="users/add/*" element={<AddUser />} />
            <Route path="users/invites/:inviteId/add/*" element={<AddUser />} />
            <Route path="users/*" element={<UsersSettings />} />
            <Route path="general" element={<Navigate to="/settings/organization" replace />} />
            <Route path="organization" element={<Org />} />
            <Route path="integrations/*" element={<Integrations />} />
            <Route path="qualify/*" element={<Qualify />} />
            <Route path="billing/*" element={<Billing />} />
            <Route path="notifications" element={<NotificationSettings />} />
          </Routes>
        </div>
      </Page>
    </Frame>
  )
}

export default BuyerSettings
