import { GraphQLError } from 'graphql'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import { useDeactivateUserMutation } from '@/buyers/_gen/gql'

import ModalForm from '@/gf/components/ModalForm'

import type { User } from '../Users'

const DeactivateModal = ({
  user,
  open,
  onClose,
  onDeactivated,
}: {
  user: User | null
  open: boolean
  onClose: () => void
  onDeactivated: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [deactivateUser] = useDeactivateUserMutation({ client: useGqlClient() })

  const onSubmit = () => {
    if (!user) return

    spinner.on()
    deactivateUser({ variables: { userId: user.id } })
      .then(() => {
        msgr.add('User deactivated.', 'positive')
        onDeactivated()
      })
      .catch((err: GraphQLError) => {
        if (err.message) msgr.add(err.message, 'negative')
        else msgr.addUnknownError()
      })
      .finally(spinner.off)
  }

  return (
    <ModalForm
      title="Deactivate User"
      open={open}
      onClose={onClose}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
      submitButtonText="Yes"
      submitButtonShowSpinner={spinnerLive}
      submitButtonDisabled={spinnerLive}
    >
      <div className="space-y-2">
        <p className="prose">
          Are you sure you want to deactivate <span className="font-bold">{user?.email}</span>?
        </p>
        <p className="prose">
          They will not be able to access the organization. They can be reactivated in the future.
        </p>
      </div>
    </ModalForm>
  )
}

export default DeactivateModal
