import { ApolloError, gql, useMutation } from '@apollo/client'
import { PhotographIcon } from '@heroicons/react/outline'
import { PencilAltIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'

import {
  OrgSettingsQuery,
  useOrgSettingsQuery,
  useSetOrganizationLogoMutation,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUppy from '@/gf/hooks/useUppy'
import Attachment from '@/gf/modules/Attachment'

import ApprovalSettings from '@/buyers/components/ApprovalSettings'
import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import ModalForm from '@/gf/components/ModalForm'
import Spinner from '@/gf/components/Spinner'
import SpinnerSmall from '@/gf/components/SpinnerSmall'
import TextField from '@/gf/components/TextField'
import UppyFileInput from '@/gf/components/UppyFileInput'

interface Vars {
  id: string
  name: string
  taxExemptCertUrl: string | null
}

interface Data {
  updateAccount: boolean
}

const mutation = gql`
  mutation UpdateAccount($id: String!, $name: String!, $taxExemptCertUrl: String) {
    updateAccount(id: $id, name: $name, taxExemptCertUrl: $taxExemptCertUrl)
  }
`

const useUpdateAccount = () => {
  const [mutate] = useMutation<Data, Vars>(mutation)

  return (variables: Vars) => mutate({ variables })
}

const ExemptionCertificate = ({ org }: { org: Exclude<OrgSettingsQuery['org'], null> }) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [uploading, setUploading] = useState(false)
  const [uploadingSpinnerLive, uploadingSpinnerToggle] = useToggle()
  const updateAccount = useUpdateAccount()
  const reloadSession = useReloadSession()

  const allowedFileTypes = ['.pdf']
  const uppy = useUppy({
    onFilesAdded: () => uploadingSpinnerToggle.on(),
    onComplete: ([file]) => {
      updateAccount({ id: org.id, taxExemptCertUrl: file.url, name: org.name })
        .then(() => {
          msgsMgr.add('Account saved.', 'positive')
          setUploading(false)
          reloadSession()
        })
        .finally(() => uploadingSpinnerToggle.off())
    },
    allowedFileTypes,
    maxNumberOfFiles: 1,
  })

  return (
    <Field label="Exemption Certificate">
      <div className="space-y-4">
        <div className="flex gap-16 items-center text-sm">
          {org.taxExemptCertUrl ? (
            <A.T href={org.taxExemptCertUrl} target="_blank">
              {Attachment.nameFromUrl(org.taxExemptCertUrl)}
            </A.T>
          ) : (
            <span className="text-gray-400 italic">Not uploaded.</span>
          )}
          <Action.S size="sm" onClick={() => setUploading(!uploading)}>
            {uploading ? 'cancel' : org.taxExemptCertUrl ? 'replace' : 'upload'}
          </Action.S>
        </div>
        {uploading &&
          (uploadingSpinnerLive ? (
            <SpinnerSmall />
          ) : (
            <UppyFileInput
              uppy={uppy}
              allowedFileTypes={allowedFileTypes}
              onFileInputError={(error) => msgsMgr.add(error, 'negative')}
            />
          ))}
      </div>
    </Field>
  )
}

const Org = () => {
  const {
    organization: { id: orgId },
    featureFlags,
  } = useSession()

  const [_msgs, msgsMgr] = useMsgs()
  const client = useGqlClient()
  const org = useOrgSettingsQuery({ variables: { orgId }, client }).data?.org
  const [spinnerLive, spinnerToggler] = useToggle()
  const [form, setForm] = useState<{ name: string }>({ name: '' })
  const updateAccount = useUpdateAccount()
  const reloadSession = useReloadSession()
  const [setOrganizationLogo] = useSetOrganizationLogoMutation({ client })
  const [editLogoVisible, editLogoToggle] = useToggle()
  const allowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png']
  const [uploadingLogoSpinnerLive, uploadingLogoSpinnerToggle] = useToggle()
  const [previewLogoUrl, setPreviewLogoUrl] = useState<string>()

  useEffect(() => {
    if (org) setForm({ name: org.name })
  }, [!org])

  const uppy = useUppy({
    allowedFileTypes,
    maxNumberOfFiles: 1,
    onFilesAdded: () => uploadingLogoSpinnerToggle.on(),
    onComplete: ([file]) => {
      setPreviewLogoUrl(file.url)
      uploadingLogoSpinnerToggle.off()
    },
  })

  if (!org) return null

  const saveAccount = () => {
    spinnerToggler.on()

    updateAccount({
      id: org.id,
      name: form.name,
      taxExemptCertUrl: org.taxExemptCertUrl,
    })
      .then(() => {
        msgsMgr.add('Account saved.', 'positive')
        reloadSession()
      })
      .catch((e: ApolloError) => {
        msgsMgr.add(e.message, 'negative')
      })
      .finally(() => {
        spinnerToggler.off()
      })
  }

  return (
    <div className="space-y-4">
      <Form onSubmit={saveAccount} id="account">
        <Card
          title="About"
          primaryFooterAction={{
            id: 1,
            element: <Button title="Save" type="submit" performing={spinnerLive} form="account" />,
          }}
        >
          <Card.Section>
            <div className="flex flex-row space-x-4">
              <div className="flex grow flex-col space-y-4">
                <TextField
                  label="Company Name"
                  value={form.name}
                  setValue={(name) => setForm({ ...form, name })}
                />
              </div>
              <div className="relative group">
                {org.logoUrl ? (
                  <img src={org.logoUrl} alt="Profile" className="max-h-32 max-w-64 object-cover" />
                ) : (
                  <div className="px-6 py-4 bg-white flex flex-col shrink-0 justify-center items-center border rounded-md">
                    <PhotographIcon className="min-h-12 h-12 min-w-12 w-12 text-gray-400" />
                    <span className="text-gray-400 whitespace-nowrap">Add your logo</span>
                  </div>
                )}
                <button
                  className="absolute -top-3 -right-3 p-2 rounded-full bg-white drop-shadow-md "
                  type="button"
                  onClick={() => editLogoToggle.on()}
                >
                  <PencilAltIcon className="block w-5 h-5 text-gearflow hover:text-orange-700" />
                </button>
                <ModalForm
                  title="Update Logo"
                  open={editLogoVisible}
                  onClose={editLogoToggle.off}
                  onSubmit={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!previewLogoUrl) {
                      msgsMgr.add('Error, no logo uploaded', 'negative')
                      return
                    }
                    spinnerToggler.on()
                    setOrganizationLogo({
                      variables: { id: org.id, logoUrl: previewLogoUrl },
                    })
                      .then(() => reloadSession())
                      .catch(() => msgsMgr.add('Error uploading image', 'negative'))
                      .finally(() => {
                        setPreviewLogoUrl(undefined)
                        spinnerToggler.off()
                        editLogoToggle.off()
                      })
                  }}
                  submitButtonShowSpinner={spinnerLive}
                  submitButtonDisabled={!previewLogoUrl || spinnerLive}
                >
                  <div className="mt-2 flex flex-col space-y-4">
                    <UppyFileInput
                      uppy={uppy}
                      onFileInputError={(error) => msgsMgr.add(error, 'negative')}
                      allowedFileTypes={allowedFileTypes}
                    />
                    {uploadingLogoSpinnerLive ? (
                      <Spinner />
                    ) : (
                      previewLogoUrl && (
                        <div className="flex flex-col space-y-2 items-center">
                          <p className="text-base text-gray-700">Preview</p>
                          <img
                            className="max-h-32 max-w-64 object-contain"
                            src={previewLogoUrl}
                            alt="Preview logo"
                          />
                        </div>
                      )
                    )}
                  </div>
                </ModalForm>
              </div>
            </div>
          </Card.Section>
        </Card>
      </Form>

      <ApprovalSettings org={org} />

      {featureFlags.taxExemptCert && (
        <Card title="Taxes">
          <Card.Section>
            <ExemptionCertificate org={org} />
          </Card.Section>
        </Card>
      )}
    </div>
  )
}

export default Org
