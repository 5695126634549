import AddressM from '@/gf/modules/Address'
import { useState } from 'react'
import { Address } from '../../types'

interface BusinessQualifyForm {
  fields: {
    businessName: string
    businessType: string
    website: string
    annualSales: string
    ein: string
    address: Address
  }
  errors: {
    businessName: string | null
    businessType: string | null
    website: string | null
    annualSales: string | null
    ein: string | null
    address: string | null
  }
}

// If the BusinessQualifyForm type changes, bump this version
const key = 'business-qualify-form-v2'

const sessionStore = {
  fetch: () => {
    const item = sessionStorage.getItem(key)
    if (!item) return null

    return JSON.parse(item) as BusinessQualifyForm
  },
  save: (form: BusinessQualifyForm) => sessionStorage.setItem(key, JSON.stringify(form)),
}

const initForm: BusinessQualifyForm = {
  fields: {
    businessType: '',
    website: '',
    annualSales: '',
    businessName: '',
    ein: '',
    address: AddressM.init(),
  },
  errors: {
    businessType: null,
    website: null,
    annualSales: null,
    businessName: null,
    ein: null,
    address: null,
  },
}

const useBillingQualifyForm = () => {
  const [form, setForm] = useState(sessionStore.fetch() || initForm)

  const save = (updatedForm: BusinessQualifyForm) => {
    sessionStore.save(updatedForm)
    setForm(updatedForm)
  }

  const updateFields = (fields: Partial<BusinessQualifyForm['fields']>) =>
    save({ ...form, fields: { ...form.fields, ...fields } })

  const reset = () => save(initForm)

  const resetErrors = () => save({ ...form, errors: initForm.errors })

  const validate = (context?: 'business' | 'payment') => {
    resetErrors()

    return new Promise<void>((resolve, reject) => {
      let { errors } = initForm
      const { fields } = form

      if (!context || context === 'business') {
        if (fields.businessName.length === 0)
          errors = { ...errors, businessName: 'Business Name is required.' }
        if (fields.businessType.length === 0)
          errors = { ...errors, businessType: 'Business Type is required.' }
        if (fields.website.length === 0) errors = { ...errors, website: 'Website is required.' }
        if (fields.annualSales.length === 0)
          errors = { ...errors, annualSales: 'Annual sales is required.' }
        if (fields.ein.length === 0) errors = { ...errors, ein: 'EIN is required (numbers only)' }
      }

      save({ ...form, errors: { ...form.errors, ...errors } })

      if (errors === initForm.errors) resolve()
      else reject()
    })
  }

  return { form, updateFields, reset, resetErrors, validate }
}

export type AdminBillingQualifyFormReducer = ReturnType<typeof useBillingQualifyForm>

export default useBillingQualifyForm
