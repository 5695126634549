import { useState } from 'react'
import AddressInput from '@/gf/components/AddressInput'
import ModalForm from '@/gf/components/ModalForm'
import useMsgs from '@/gf/hooks/useMsgs'
import AddressM from '@/gf/modules/Address'
import useGqlClient from '../../hooks/useGqlClient'
import { useUpdateVendorAddressMutation } from '../../_gen/gql'
import { Address } from '../../../types'
import { useContext } from '../Vendor'

interface AddressFormModalProps {
  open: boolean
  onClose: () => void
  onAfterSave: () => void
}

const AddressFormModal = ({ open, onClose, onAfterSave }: AddressFormModalProps) => {
  const [_, messages] = useMsgs()
  const { vendor } = useContext()
  const [address, setAddress] = useState<Address>(vendor.address || AddressM.init())
  const [updateAddress] = useUpdateVendorAddressMutation({ client: useGqlClient() })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      await updateAddress({
        variables: {
          vendorId: vendor.id,
          address: {
            firstName: address.firstName || '',
            lastName: address.lastName || '',
            companyName: address.companyName || '',
            lineOne: address.lineOne || '',
            lineTwo: address.lineTwo || '',
            city: address.city || '',
            state: address.state || '',
            country: address.country || '',
            postalCode: address.postalCode || '',
            deliverable: false,
            rdi: '',
            point: address.point ?? null,
          },
        },
      })

      onClose()
      onAfterSave()
    } catch (err) {
      console.error(err)
      messages.add('Oops, looks like something went wrong', 'negative')
    }
  }

  return (
    <ModalForm
      title="Vendor Address"
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      submitButtonText="Save Address"
    >
      <div className="flex flex-col gap-y-2">
        <AddressInput
          address={address}
          requireFirstLastName={false}
          onChange={(newAddress: Address) => setAddress(newAddress)}
          hideCompany
          hideFirstLastName
        />
      </div>
    </ModalForm>
  )
}

export default AddressFormModal
