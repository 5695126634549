import { useStoreOrderSectionQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Money from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'

import StoreOrderStepBadge from '@/buyers/components/StoreOrderStepBadge'
import Link from '@/gf/components/LinkOld'

const StoreOrderSection = ({
  storeOrderId,
  showStoreName,
}: {
  storeOrderId: string
  showStoreName?: boolean
}) => {
  const storeOrder = useStoreOrderSectionQuery({
    variables: { id: storeOrderId },
    client: useGqlClient(),
  }).data?.storeOrder

  if (!storeOrder) return null

  return (
    <div>
      <p className="mb-2 text-base font-medium text-gray-900">
        {StoreOrderM.isQuote({ state: storeOrder.state }) ? 'Quote' : 'Order'}
      </p>
      <div className="flex items-center gap-x-3 gap-y-2 flex-wrap text-sm whitespace-nowrap">
        <Link to={`/orders/${storeOrder.id}`}>Order {storeOrder.shortId}</Link>
        <StoreOrderStepBadge step={storeOrder.step} />
      </div>
      <div className="text-sm text-gray-700">{Money.format(storeOrder.total)}</div>
      {showStoreName && <div className="text-sm text-gray-700">{storeOrder.store.name}</div>}
    </div>
  )
}

export default StoreOrderSection
