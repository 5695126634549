import { useStoreOrdersSearchLiteQuery, useVendorActivityQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Link from '@/gf/components/Link'
import LocationPlaceholder from '@/gf/components/Location/LocationPlaceholder'
import SingleMarkerMap from '@/gf/components/SingleMarkerMap'
import { InformationCircleIcon } from '@heroicons/react/solid'
import nth from 'lodash/nth'
import OrderCard from '../../components/OrderCard/OrderCard'
import RFQCard from '../../components/RFQCard/RFQCardNext'
import { useContext } from '../Vendor'
import ActivityZeroState from './ActivityZeroState'

const Activity = () => {
  const { orgId } = useSession()
  const { vendor } = useContext()
  const client = useGqlClient()

  const { data: storeOrderData, loading: storeOrderLoading } = useStoreOrdersSearchLiteQuery({
    variables: { orgId, page: 1, filters: [['equals', 'vendor_id', vendor.id]] },
    client,
  })

  const { data: requestsData, loading: requestsLoading } = useVendorActivityQuery({
    variables: { orgId, filter: JSON.stringify(['vendor_id_eq', vendor.id]) },
    client,
  })

  const storeOrders = storeOrderData?.org?.paginatedStoreOrders.storeOrders || []
  const lastStoreOrder = storeOrders && (nth(storeOrders, 0) || null)

  const rfqs = requestsData?.searchRequests.requests || []
  const lastRfq = rfqs && (nth(rfqs, 0) || null)

  const noActivity = !lastStoreOrder && !lastRfq && !storeOrderLoading && !requestsLoading

  return (
    <div className="p-4">
      {vendor.location ? (
        <SingleMarkerMap lat={vendor.location.lat} lng={vendor.location.lng} />
      ) : (
        <LocationPlaceholder text="Shipping Address not available for this vendor" />
      )}

      {noActivity ? (
        <ActivityZeroState />
      ) : (
        <>
          <OrderCard loading={storeOrderLoading} storeOrder={lastStoreOrder} className="mt-6" />
          <RFQCard loading={requestsLoading} requestForQuote={lastRfq} className="mt-6" />
        </>
      )}

      <section className="border-t border-slate-100 mt-6 pt-4">
        <h3 className="text-xl text-slate-600 flex items-center">Vendor History</h3>
        <p className="text-slate-500 mt-1 prose leading-5">
          <InformationCircleIcon className="inline-block h-4 text-gearflow opacity-75" /> You can
          view a complete history of all <strong>Orders</strong> and <strong>Requests</strong>{' '}
          created for this vendor using the links below.
        </p>
        <div className="flex gap-x-4 mt-4 mb-2">
          <Link.T
            to={`/rfqs?search=${encodeURIComponent(vendor.name)}`}
            title="See all requests for this vendor"
          >
            All Requests
          </Link.T>

          <Link.T
            to={`/orders?search=${encodeURIComponent(vendor.name)}`}
            title="See all orders for this vendor"
          >
            All Orders
          </Link.T>
        </div>
      </section>
    </div>
  )
}

export default Activity
