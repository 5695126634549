import type { Request } from '../Requests'

import { RequestForQuoteStep, StoreOrderStep } from '@/buyers/_gen/gql'

import GfPartsCell from '@/gf/components/PartsCell'

const rfqSteps = [
  RequestForQuoteStep.PoSent,
  RequestForQuoteStep.Fulfilling,
  RequestForQuoteStep.Fulfilled,
]

const storeOrderSteps = [StoreOrderStep.PoSent, StoreOrderStep.Fulfilling, StoreOrderStep.Fulfilled]

const PartsCell = ({ rfq }: { rfq: Request }) => {
  const areLineItems = rfqSteps.includes(rfq.step)

  const parts = areLineItems
    ? rfq.storeOrders
        .filter((so) => storeOrderSteps.includes(so.step))
        .flatMap((so) => so.lineItems)
        .map((li) => ({
          id: li.id,
          backordered: !li.inStock,
          availableAt: li.availableAt,
          description: li.product.name || '',
          mpn: li.product.mpn || '',
          quantity: li.quantity,
        }))
    : rfq.parts.map((p) => ({
        id: p.id,
        backordered: false,
        availableAt: null,
        description: p.description,
        mpn: p.mpn,
        quantity: p.quantity,
      }))

  return <GfPartsCell parts={parts} areLineItems={areLineItems} children={null} />
}

export default PartsCell
