import React from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import useConfig from '@/gf/hooks/useConfig'

const GqlClientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const config = useConfig()

  const client = new ApolloClient({
    uri: `${config.buyersUrl}/gql`,
    cache: new InMemoryCache(),
    credentials: 'include',
    defaultOptions: { query: { fetchPolicy: 'no-cache' } },
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GqlClientProvider
