import { JsonParam, encodeQueryParams } from 'use-query-params'
import { SelectedValue } from './useSelectedValues'
import queryString from 'query-string'

type InsightQueryParam = {
  tab: string
  selectedValues: SelectedValue[]
}
export const encodeInsightQueryParamString = (insight: InsightQueryParam) =>
  queryString.stringify(encodeQueryParams({ insight: JsonParam }, { insight }))
