import LabeledText from '@/gf/components/LabeledText'
import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'

interface Props {
  machineDetails: OrgMachineDetails
}

const EngineSpecsView: React.FC<Props> = ({ machineDetails }) => {
  const orgMachine = machineDetails.data?.orgMachine

  return (
    <>
      <LabeledText label="Engine Make" value={orgMachine?.engineMake} />
      <LabeledText label="Engine Model" value={orgMachine?.engineModel} />
      <LabeledText label="Engine Serial Number" value={orgMachine?.engineSerialNumber} />
    </>
  )
}

export default EngineSpecsView
