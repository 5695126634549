import { gql } from '@apollo/client'

export const ADD_TIMELINE_NOTE = gql`
  mutation AddTimelineNote(
    $note: String
    $privacy: String
    $requestForQuoteId: String
    $storeId: String
  ) {
    addTimelineNote(
      note: $note
      privacy: $privacy
      requestForQuoteId: $requestForQuoteId
      storeId: $storeId
    ) {
      id
    }
  }
`
