import { CheckIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import {
  Route,
  Routes,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import {
  SelectedPaymentMethod,
  StoreOrderStep,
  useApproveQuoteMutation,
  useMobileCheckoutOrderQuery,
  useSetPurchaseOrderStoreOrderMutation,
} from '../_gen/gql'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import useBalanceModal from './Order/YourOrder/useBalanceModal'

import StoreOrderM from '@/gf/modules/StoreOrder'

import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import RedAlert from '@/gf/components/RedAlert'
import Spinner from '@/gf/components/Spinner'
import StoreOrderStepBadge from '../components/StoreOrderStepBadge'

// Only let Balance payment methods checkout on this page
const mobileCheckoutPaymentMethods: SelectedPaymentMethod[] = [SelectedPaymentMethod.Balance]

const getSuccessPath = (
  storeOrderId: string,
  paymentMethod: SelectedPaymentMethod,
  purchaseOrder: string | null
) => {
  const baseSuccessPath = `/mobile-checkout/${storeOrderId}/method/${paymentMethod}/success`
  if (!purchaseOrder) return baseSuccessPath
  const searchParams = createSearchParams()
  searchParams.set('po', purchaseOrder)
  return `${baseSuccessPath}?${searchParams.toString()}`
}

const appUrl = (storeOrderId: string) =>
  `gearflow-parts-hub://authenticated/requests/order/${storeOrderId}?accepted=true`

const Success = ({
  storeOrderId,
  paymentMethod,
  purchaseOrder,
}: {
  storeOrderId: string
  paymentMethod: SelectedPaymentMethod
  purchaseOrder: string | null
}) => {
  const gqlClient = useGqlClient()
  const [isSettingPoNumber, setIsSettingPoNumber] = useState(false)
  const [errorSettingPoNumber, setErrorSettingPoNumber] = useState(true)
  const [setPurchaseOrderStoreOrder] = useSetPurchaseOrderStoreOrderMutation({ client: gqlClient })
  const navigateToApp = () => window.open(appUrl(storeOrderId))

  const updatePoNumber = (po: string) => {
    setIsSettingPoNumber(true)
    setErrorSettingPoNumber(false)
    setPurchaseOrderStoreOrder({ variables: { storeOrderId, purchaseOrder: po } })
      .then(() => {
        // Navigate back to the app
        navigateToApp()
      })
      .catch(() => {
        setErrorSettingPoNumber(true)
      })
      .finally(() => {
        setIsSettingPoNumber(false)
      })
  }

  useEffect(() => {
    if (storeOrderId) {
      // Stripe still needs to set the purchase order
      if (purchaseOrder && paymentMethod === SelectedPaymentMethod.Stripe) {
        updatePoNumber(purchaseOrder)
      } else {
        // All other payment methods can navigate directly back to the app
        navigateToApp()
      }
    }
  }, [storeOrderId, purchaseOrder, paymentMethod])

  return (
    <div className="p-4 flex flex-col gap-y-4 grow justify-center items-center">
      {purchaseOrder && errorSettingPoNumber ? (
        <div className="p-4 flex flex-col justify-center items-center gap-y-4">
          <RedAlert
            className="border border-red-200"
            title="Error saving the purchase order number."
          />
          <Action.S onClick={() => updatePoNumber(purchaseOrder)}>Retry</Action.S>
        </div>
      ) : purchaseOrder && isSettingPoNumber ? (
        <Spinner />
      ) : (
        <div className="p-4 flex flex-col gap-y-4 items-center rounded-md bg-gray-50 border border-gray-200">
          <div className="flex flex-row gap-x-2 items-center">
            <div className="p-1 rounded-full bg-green-500">
              <CheckIcon className="w-5 h-5 flex shrink-0 text-white" />
            </div>
            <p className="text-base text-gray-900 font-medium">Quote approved</p>
          </div>
          <p className="text-sm text-gray-900">You can close this page</p>
          <div className="flex-col gap-y-2">
            <Spinner size="sm" />
            <p className="text-sm text-gray-900">Redirecting you back to the app...</p>
          </div>
        </div>
      )}
      <A.P href={appUrl(storeOrderId)}>Open the app</A.P>
    </div>
  )
}

const Checkout = ({
  storeOrderId,
  paymentMethod,
  purchaseOrder,
}: {
  storeOrderId: string
  paymentMethod: SelectedPaymentMethod
  purchaseOrder: string | null
}) => {
  const { user } = useSession()
  const gqlClient = useGqlClient()
  const { data, error } = useMobileCheckoutOrderQuery({
    client: gqlClient,
    variables: {
      filter: JSON.stringify(['id_eq', storeOrderId]),
    },
  })
  const [approveQuote] = useApproveQuoteMutation({ client: gqlClient })
  const [isApproving, setIsApproving] = useState(false)
  const [errorApproving, setErrorApproving] = useState(false)

  const navigate = useNavigate()

  const orderUrl = `/orders/${storeOrderId}`
  const successPath = getSuccessPath(storeOrderId, paymentMethod, purchaseOrder)

  const { open: openBalanceModal } = useBalanceModal({
    approve: () => {
      setIsApproving(true)
      setErrorApproving(false)
      approveQuote({
        variables: {
          storeOrderId,
          useTerms: false,
          autoClose: !user.claimed,
          purchaseOrder,
          paymentMethod: SelectedPaymentMethod.Balance,
        },
      })
        .then(() => {
          // Order approved
          navigate(successPath)
          window.open(appUrl(storeOrderId))
        })
        .catch(() => {
          setErrorApproving(true)
        })
        .finally(() => {
          setIsApproving(false)
        })
    },
    balanceTransactions: data?.storeOrderV2?.balanceTransactions,
  })

  useEffect(() => {
    // Open the balance modal
    if (
      paymentMethod === SelectedPaymentMethod.Balance &&
      !error &&
      data?.storeOrderV2?.step === StoreOrderStep.Quoted &&
      StoreOrderM.isPaymentMethodAllowed(paymentMethod, data.storeOrderV2.paymentOption)
    ) {
      openBalanceModal()
    }
  }, [openBalanceModal])

  return error ? (
    <div className="p-4 flex flex-col grow justify-center items-center">
      <RedAlert className="border border-red-200" title="Error, please contact support" />
    </div>
  ) : !data ? (
    <Spinner />
  ) : !data.storeOrderV2 ? (
    <div className="p-4 flex flex-col grow justify-center items-center">
      <RedAlert className="border border-red-200" title="Order not found" />
    </div>
  ) : // Quote was approved
  data.storeOrderV2.step === StoreOrderStep.PoSent ? (
    <div className="p-4 flex flex-col gap-y-4 grow justify-center items-center">
      <div className="p-4 flex flex-col gap-y-4 items-center rounded-md bg-gray-50 border border-gray-200">
        <div className="flex flex-row gap-x-2 items-center">
          <div className="p-1 rounded-full bg-green-500">
            <CheckIcon className="w-5 h-5 flex shrink-0 text-white" />
          </div>
          <p className="text-base text-gray-900 font-medium">Quote approved</p>
        </div>
      </div>
      <A.P href={appUrl(storeOrderId)}>Open the app</A.P>
    </div>
  ) : // Order is not a quote
  data.storeOrderV2.step !== StoreOrderStep.Quoted ? (
    <div className="p-4 flex flex-col grow justify-center items-center gap-y-4">
      <div className="p-4 flex flex-col items-start gap-y-1 rounded-md bg-gray-50 border border-gray-200">
        <StoreOrderStepBadge step={data.storeOrderV2.step} />
        <p className="text-sm text-gray-900">
          Cannot check out for this order, because it is not a quote.
        </p>
      </div>
      <A.P href={appUrl(storeOrderId)}>Open the app</A.P>
      <Link.S to={orderUrl}>Open the order page</Link.S>
    </div>
  ) : // Check payment method is allowed for the Order
  !StoreOrderM.isPaymentMethodAllowed(paymentMethod, data.storeOrderV2.paymentOption) ? (
    <div className="p-4 flex flex-col grow justify-center items-center gap-y-4">
      <RedAlert
        className="border border-red-200"
        title="The selected payment method is not available for this order."
      />
      <A.P href={appUrl(storeOrderId)}>Check out from the app</A.P>
      <Link.S to={orderUrl}>Check out from the order page</Link.S>
    </div>
  ) : // Check we can process the payment method on this page
  !mobileCheckoutPaymentMethods.includes(paymentMethod) ? (
    <div className="p-4 flex flex-col grow justify-center items-center gap-y-4">
      <RedAlert
        className="border border-red-200"
        title="The selected payment method is not available from this page."
      />
      <A.P href={appUrl(storeOrderId)}>Check out from the app</A.P>
      <Link.S to={orderUrl}>Check out from the order page</Link.S>
    </div>
  ) : // Balance checkout
  paymentMethod === SelectedPaymentMethod.Balance ? (
    errorApproving ? (
      <RedAlert className="border border-red-200" title="Please contact support">
        Error accepting quote.
      </RedAlert>
    ) : isApproving ? (
      <Spinner />
    ) : (
      <div className="p-4 flex flex-col gap-y-4 grow justify-center items-center">
        <div className="p-4 flex flex-col gap-y-4 items-center rounded-md bg-gray-50 border border-gray-200">
          <div className="flex-col gap-y-2">
            <Spinner size="sm" />
            <p className="text-sm text-gray-900">Opening checkout page...</p>
          </div>
          <p className="text-sm text-gray-500 text-center">
            If the checkout page doesn&apos;t open after a few seconds, tap the button below to open
            it.
          </p>
        </div>
        <Action.P onClick={openBalanceModal}>Open checkout page</Action.P>
        <A.S href={appUrl(storeOrderId)}>Back to the app</A.S>
      </div>
    )
  ) : (
    // Catch last error
    <div className="p-4 flex flex-col grow justify-center items-center">
      <RedAlert className="border border-red-200" title="Error, please contact support" />
      <A.S href={appUrl(storeOrderId)}>Open the app</A.S>
      <Link.S to={orderUrl}>Open the order page</Link.S>
    </div>
  )
}

const MobileCheckoutOrder = () => {
  const { storeOrderId, paymentMethod } = useParams<{
    storeOrderId: string
    paymentMethod: SelectedPaymentMethod
  }>() as { storeOrderId: string; paymentMethod: SelectedPaymentMethod }
  const [searchParams, _setSearchParams] = useSearchParams()
  const po = searchParams.get('po')

  return (
    <div className="absolute inset-0 flex">
      <div className="flex flex-col grow justify-center items-center gap-y-4">
        <Routes>
          <Route
            path="*"
            element={
              <Checkout
                storeOrderId={storeOrderId}
                paymentMethod={paymentMethod}
                purchaseOrder={po}
              />
            }
          />
          <Route
            path="success"
            element={
              <Success
                storeOrderId={storeOrderId}
                paymentMethod={paymentMethod}
                purchaseOrder={po}
              />
            }
          />
        </Routes>
      </div>
    </div>
  )
}

export default MobileCheckoutOrder
