// Returns the select options for any list of items given an idKey and nameKey
const getSelectOptions = <Item extends object>({
  items,
  idKey = 'id',
  nameKey = 'name',
  showEmptyOption = false,
  emptyOptionText = 'N/A',
  showDefaultOption = false,
  defaultOptionText = '--- All ---',
}: {
  items?: Item[]
  idKey?: string
  nameKey?: string
  showEmptyOption?: boolean
  emptyOptionText?: string
  showDefaultOption?: boolean
  defaultOptionText?: string
}) => {
  if (!items) return [{ id: '', display: 'loading...' }]

  if (items.length === 0)
    return [...(showEmptyOption ? [{ id: '', display: emptyOptionText }] : [])]

  return [
    ...(showDefaultOption ? [{ id: '', display: defaultOptionText }] : []),
    ...items.map((item) => ({ id: item[idKey], display: item[nameKey] })),
  ]
}

export default { getSelectOptions }
