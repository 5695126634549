import Ghost from '@/gf/components/Ghost'

const Loading = () => (
  <>
    {[1, 2, 3, 4, 5].map((n) => (
      <li key={n} className="w-full flex gap-x-4 p-4 border rounded-xl shadow-base">
        <Ghost className="block h-12 w-12 rounded-full flex-shrink-0" />
        <span className="block flex-grow">
          <Ghost className="block w-2/3 h-5" />
          <Ghost className="block w-3/5 h-4 mt-2" />
        </span>
      </li>
    ))}
  </>
)

export default Loading
