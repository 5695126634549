import PerformanceSnippet from '@/buyers/pages/Home/ReportsSection/PerformanceSnippet'
import Section from './Section'

const Performance = () => (
  <Section
    title="Performance"
    text={[
      'Get insight into how often you’re sending urgent requests, on time requests, accuracy of requests, and internal fulfillment.',
    ]}
  >
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-3 w-full">
      <PerformanceSnippet
        name="Urgent Requests"
        value={14.3}
        variation={1.2}
        positiveDirection="down"
      />
      <PerformanceSnippet name="Accuracy" value={99.6} variation={-3.4} positiveDirection="up" />
      <PerformanceSnippet name="On Time" value={86.7} variation={5.6} positiveDirection="up" />
      <PerformanceSnippet
        name="Internal Fulfillment"
        value={65.2}
        variation={-7.8}
        positiveDirection="up"
      />
    </div>
  </Section>
)

export default Performance
