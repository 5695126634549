import Ghost from '@/gf/components/Ghost'

const EngineSpecsGhost: React.FC = () => (
  <div className="flex flex-col gap-y-2">
    <Ghost className="w-56 h-7 mb-2" />

    <Ghost className="w-20 h-4" />
    <Ghost className="w-36 h-4 mb-2" />

    <Ghost className="w-20 h-4" />
    <Ghost className="w-40 h-4 mb-2" />

    <Ghost className="w-20 h-4" />
    <Ghost className="w-36 h-4" />
  </div>
)

export default EngineSpecsGhost
