import { useCallback, useRef } from 'react'
import { useSuggestMachinesLazyQuery } from '../_gen/gql'
import useGqlClient from './useGqlClient'
import debouncePromise from '@/gf/modules/debouncePromise'
import { Maybe } from '@/types'

const useMachineSuggestion = () => {
  const client = useGqlClient()
  const [suggestMachines] = useSuggestMachinesLazyQuery({ client })
  const debouncedSuggestMachines = useRef(debouncePromise(suggestMachines, 300)).current

  const onSuggestMachines = useCallback(
    async (
      prefix: string
    ): Promise<{ id: string; make: string; model: string; year: Maybe<number> }[]> => {
      const result = await debouncedSuggestMachines({ variables: { search: prefix } })

      if (result === 'skipped') {
        return []
      }

      return (
        result.data?.autocompleteMachines.map((m) => ({
          id: m.id ?? '',
          make: m.make ?? '',
          model: m.model ?? '',
          year: m.year,
        })) ?? []
      )
    },
    [debouncedSuggestMachines]
  )

  return onSuggestMachines
}

export default useMachineSuggestion
