import { LineSvgProps } from '@nivo/line'

import Chart from '@/gf/modules/Chart'

const getBaseGraph = ({
  days,
  pointSymbol,
}: {
  days: number
  pointSymbol: LineSvgProps['pointSymbol']
}): Partial<LineSvgProps> => ({
  xScale: {
    type: 'time',
    format: '%Y-%m-%d',
    useUTC: false,
    precision: 'day',
  },
  yScale: {
    type: 'linear',
  },
  xFormat: 'time:%Y-%m-%d',
  margin: {
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
  },
  pointSymbol,
  pointSize: days > 30 ? 4 : days > 10 ? 5 : 8,
  pointBorderWidth: days > 30 ? 1.5 : days > 10 ? 2 : 3,
  pointColor: { from: 'color' },
  colors: { datum: 'color' },
  pointBorderColor: Chart.BACKGROUND_COLOR,
  pointLabelYOffset: -12,
  enableGridX: false,
  enableGridY: false,
  axisLeft: null,
  axisBottom: null,
  theme: {
    background: Chart.BACKGROUND_COLOR,
    fontSize: 16,
    textColor: '#6B7280',
  },
  useMesh: true,
})

export default {
  SECONDARY_LINE_COLOR: Chart.SECONDARY_LINE_COLOR,
  getBaseGraph,
  getDataColor: Chart.getDataColor,
}
