import { useQuery, QueryHookOptions } from '@apollo/client'

import FETCH_ALL_STORES from '@/gf/queries/suppliers'

import { Store } from '../../types'

interface FetchStoresData {
  allStores: Store[]
}

const useStores = (options?: QueryHookOptions<FetchStoresData>) =>
  useQuery<FetchStoresData>(FETCH_ALL_STORES, { ...options, fetchPolicy: 'no-cache' })

export default useStores
