import classNames from 'classnames'

const Content = ({ className, ...props }: JSX.IntrinsicElements['div']) => (
  <div
    className={classNames('max-w-[87.5rem] w-full mx-auto px-4 lg:px-8', className)}
    {...props}
  />
)

export default Content
