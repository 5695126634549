import { useNavigate } from 'react-router-dom'

import FreeProTrialSplashContent from '@/buyers/components/FreeProTrialSplashContent'

const FreeProTrial = ({ endPath }: { endPath: string }) => {
  const navigate = useNavigate()

  return (
    <FreeProTrialSplashContent
      onClaimed={() => {
        navigate(endPath)
        return Promise.resolve()
      }}
      onClose={() => navigate(endPath)}
    />
  )
}

export default FreeProTrial
