import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import type { Pagination, User } from '@/types'

import useGqlClient from '@/buyers/hooks/useGqlClient'
import ConversationM from '@/buyers/modules/Conversation'
import useAbsoluteMessagesSendToInput, {
  noUsersErrorText,
  threadContainerClassName,
} from '@/buyers/hooks/useAbsoluteMessagesSendToInput'
import {
  ConversationMembersDocument,
  FetchConversationsDocument,
  FetchMessagesForConversationDocument,
  useCreateConversationMutation,
  useCreateMessageMutation,
  useFetchMessagesForConversationQuery,
  useReadReceiptsForConversationQuery,
  StoreOrderQuery,
} from '@/buyers/_gen/gql'

import InboxCommon, { Conversation } from '@/gf/components/Inbox'
import InboxDetails from '@/buyers/components/InboxDetails'

import type { StoreOrder } from '../Messages'

type Org = Exclude<StoreOrderQuery['org'], null>

const StoreOrderInbox = ({
  org,
  conversations,
  refetchConversations,
  page,
  setPage,
  pagination,
  user,
  storeOrder,
  selectedConversationId,
  selectedStoreId,
  selectedInternalOrg,
  onSelectedConversationIdChange,
  refetchStoreOrder,
  marginTop = true,
}: {
  org: Org
  conversations: Conversation[]
  refetchConversations: () => Promise<unknown>
  page: number
  setPage: (page: number) => void
  pagination: Pagination
  user: Pick<User, 'id' | 'role'>
  storeOrder: StoreOrder
  selectedConversationId: string | undefined
  selectedStoreId?: string
  selectedInternalOrg?: boolean
  onSelectedConversationIdChange: (conversationId: string | undefined) => void
  refetchStoreOrder: () => Promise<unknown>
  marginTop?: boolean
}) => {
  const client = useGqlClient()
  const [sendToUserIds, setSendToUserIds] = useState<string[]>([])
  const [sendToErrorText, setSendToErrorText] = useState<string>()
  const DetailsForConversation = InboxDetails.useStoreOrder(storeOrder)

  const [createMessage] = useCreateMessageMutation({
    client,
    refetchQueries: [
      FetchMessagesForConversationDocument,
      FetchConversationsDocument,
      ConversationMembersDocument,
    ],
  })

  const [createConversation] = useCreateConversationMutation({
    client,
    refetchQueries: [FetchConversationsDocument],
  })

  const absoluteThreadContent = useAbsoluteMessagesSendToInput({
    errorText: sendToErrorText,
    onChangeErrorText: setSendToErrorText,
    onChangeSendToUserIds: setSendToUserIds,
  })

  const nonAdminConvos =
    storeOrder.store.id !== org.vendors.find((v) => v.internal)?.store?.id &&
    !storeOrder.externalOrder
      ? [
          storeOrder.order.requestForQuote
            ? ConversationM.newNonAdminConversationRfq(
                storeOrder.order.requestForQuote,
                storeOrder.store
              )
            : ConversationM.newNonAdminConversationStoreOrder(storeOrder),
        ]
      : []

  const newConversationsUnfiltered = [
    ...nonAdminConvos,
    // Admin conversations
    storeOrder.order.requestForQuote
      ? ConversationM.newAdminConversationRfq(
          storeOrder.order.requestForQuote.user?.organization?.id,
          storeOrder.order.requestForQuote
        )
      : ConversationM.newAdminConversationStoreOrder(storeOrder),
    // Internal Organization conversation
    storeOrder.order.requestForQuote
      ? ConversationM.newInternalOrgConversationRfq(
          storeOrder.order.requestForQuote.user?.organization?.id,
          storeOrder.order.requestForQuote,
          org
        )
      : ConversationM.newInternalOrgConversationStoreOrder(storeOrder, org),
  ]

  // Filter out existing conversations
  const newConversations = ConversationM.filterNewConversations(
    newConversationsUnfiltered,
    conversations
  )

  const selectedConversation =
    conversations &&
    [...conversations, ...newConversations].find((c) => c.id === selectedConversationId)

  const { error: messagesError, ...messagesQueryResult } = useFetchMessagesForConversationQuery({
    client,
    variables: {
      conversationId: selectedConversation?.id as string,
    },
    pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
    // Only refetch if the conversation is unread, so we can fetch the correct "read" status
    onCompleted: selectedConversation?.unreadMessages ? refetchConversations : undefined,
    skip:
      !selectedConversationId || !selectedConversation || 'newConversation' in selectedConversation,
  })

  const { fetchMessagesForConversation: messages } = messagesQueryResult.data || {}

  const { readReceiptsForConversation } =
    useReadReceiptsForConversationQuery({
      client,
      variables: { conversationId: selectedConversation?.id as string },
      pollInterval: ConversationM.MESSAGES_POLL_INTERVAL,
      skip:
        !selectedConversationId ||
        !selectedConversation ||
        'newConversation' in selectedConversation,
    }).data || {}

  if (!selectedConversationId && selectedStoreId) {
    const storeConversation =
      conversations.find((c) => c.store?.id === selectedStoreId) ??
      newConversations.find((c) => c.store?.id === selectedStoreId)

    if (storeConversation)
      return <Navigate to={`/orders/${storeOrder.id}/messages/${storeConversation.id}`} replace />
  }

  if (!selectedConversationId && selectedInternalOrg) {
    const internalConversation =
      conversations.find(ConversationM.isInternalOrgConversation) ??
      newConversations.find(ConversationM.isInternalOrgConversation)

    if (internalConversation)
      return (
        <Navigate to={`/orders/${storeOrder.id}/messages/${internalConversation.id}`} replace />
      )
  }

  return (
    <InboxCommon
      user={user}
      userOrganizationId={org.id}
      conversations={conversations}
      selectedConversation={selectedConversation}
      selectedConversationId={selectedConversationId}
      messages={messages}
      messagesError={messagesError}
      readReceiptsForConversation={readReceiptsForConversation}
      onSelectedConversationIdChange={onSelectedConversationIdChange}
      page={page}
      setPage={setPage}
      pagination={pagination}
      newConversations={newConversations}
      DetailsForConversation={DetailsForConversation}
      conversationRowContent={ConversationM.defaultConversationRowContent}
      createMessage={(variables) =>
        createMessage({ variables: { ...variables, to: sendToUserIds } }).then(() => {
          refetchStoreOrder()
        })
      }
      createConversation={(variables) => {
        if (
          ConversationM.isInternalOrgConversation({
            admin: variables.conversationInput.admin,
            store: variables.conversationInput.storeId
              ? { id: variables.conversationInput.storeId }
              : null,
          }) &&
          sendToUserIds.length === 0
        ) {
          setSendToErrorText(noUsersErrorText)
          return Promise.resolve(undefined)
        }
        return createConversation({ variables: { ...variables, to: sendToUserIds } }).then(
          (resp) => {
            refetchStoreOrder()
            return resp.data?.createConversation
          }
        )
      }}
      absoluteThreadContent={absoluteThreadContent}
      threadContainerClassName={threadContainerClassName}
      marginTop={marginTop}
    />
  )
}

export default StoreOrderInbox
