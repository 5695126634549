import { Routes, Route, useNavigate, useLocation, useMatch, Navigate } from 'react-router-dom'
import useBillingQualifyForm from '../../../hooks/useBillingQualifyForm'
import ProgressNav from '../../../components/ProgressNav'
import Business from './Business'
import Payment from './Payment'
import Review from './Review'

const progressNav = {
  steps: [
    { id: '1', name: 'Business Info' },
    { id: '2', name: 'Review' },
    { id: '3', name: 'Payment Info' },
  ],
  theme: {
    textPrimary: 'text-gearflow',
    bgPrimary: 'bg-gearflow',
    textMedDark: 'text-orange-700',
    textDark: 'text-orange-900',
    hoverBgDark: 'hover:bg-orange-700',
    groupHoverBgDark: 'group-hover:bg-orange-700',
    bgLight: 'bg-orange-50',
    borderPrimary: 'border-gearflow',
    borderTopPrimary: 'border-t-gearflow',
    borderLight: 'border-orange-200',
    ringPrimary: 'ring-orange-500',
    focusRingPrimary: 'focus:ring-orange-500',
  },
}

const stepPaths = [
  '/settings/qualify/business',
  '/settings/qualify/review',
  '/settings/qualify/payment',
]

const Qualify = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const formReducer = useBillingQualifyForm()
  const successMatch = useMatch('/settings/qualify/success')

  const cancel = () => {
    formReducer.reset()
    navigate('/settings/billing')
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl">Net 30 Application</h2>
      <ProgressNav
        stepIndex={successMatch ? 4 : stepPaths.indexOf(location.pathname)}
        setStepIndex={(index) => navigate(stepPaths[index])}
        navLinksDisabled
        {...progressNav}
      />
      <Routes>
        <Route path="" element={<Navigate to="business" replace />} />
        <Route path="business" element={<Business {...formReducer} cancel={cancel} />} />
        <Route path="review" element={<Review />} />
        <Route path="payment" element={<Payment />} />
      </Routes>
    </div>
  )
}

export default Qualify
