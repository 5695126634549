import { useContext } from 'react'

import Context from '@/buyers/contexts/ApolloWsClient'

const useApolloWsClient = () => {
  const client = useContext(Context)
  if (client === undefined) throw new Error('must be used within provider')
  return client
}

export default useApolloWsClient
