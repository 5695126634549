import { ExternalOrderDocument, useCreateExternalVendorMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { FormEvent, useState } from 'react'

const CreateVendorModal = ({
  open,
  onClose,
  onVendorAdded,
}: {
  open: boolean
  onClose: () => void
  onVendorAdded: (vendorId: string) => void
}) => {
  const [_, msgs] = useMsgs()

  const [createExternalVendor] = useCreateExternalVendorMutation({
    client: useGqlClient(),
    refetchQueries: [ExternalOrderDocument],
  })

  const [name, setName] = useState('')

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const { data } = await createExternalVendor({ variables: { name } })
      msgs.add('External Vendor Added', 'positive')

      onVendorAdded(data?.createExternalVendor as string)
      onClose()
    } catch (err) {
      console.error(err)
      msgs.addUnknownError()
    } finally {
      setName('')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={onSubmit} className="relative p-6 space-y-2">
        <CloseModalButton onClick={onClose} className="absolute top-3 right-3" />
        <h2 className="font-medium text-2xl">Create Vendor</h2>

        <p className="text-lg">This vendor is only available for Logged Orders</p>

        <Field label="Vendor Name" className="pt-4">
          <TextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="Vendor name here"
          />
        </Field>

        <div className="flex pt-6 gap-x-3">
          <Action.S onClick={onClose} className="w-2/5">
            Cancel
          </Action.S>
          <Action.P type="submit" className="w-3/5">
            Create Vendor
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default CreateVendorModal
