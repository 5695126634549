import { Conversation } from '@/gf/components/Inbox'
import { NewConversation } from '@/types'

import MessagesSendToInput from '../components/MessagesSendToInput'
import ConversationM from '../modules/Conversation'

const useAbsoluteMessagesSendToInput = ({
  errorText,
  onChangeErrorText,
  onChangeSendToUserIds,
}) => {
  const absoluteThreadContent = (conversation: Conversation | NewConversation) =>
    ConversationM.isInternalOrgConversation(conversation) ? (
      <div className="absolute top-0 right-0 left-0 py-3 px-6 flex grow justify-center items-start">
        <MessagesSendToInput
          conversationId={
            ConversationM.isNewConversation(conversation) ? undefined : conversation.id
          }
          conversationRfqId={conversation.requestForQuote?.id}
          conversationStoreOrderId={conversation.storeOrder?.id}
          onChangeSendToUserIds={(newUserIds) => {
            if (newUserIds.length > 0 && errorText) onChangeErrorText(undefined)
            onChangeSendToUserIds(newUserIds)
          }}
          errors={errorText}
        />
      </div>
    ) : undefined

  return absoluteThreadContent
}

export const threadContainerClassName = (conversation: Conversation | NewConversation) =>
  ConversationM.isInternalOrgConversation(conversation) ? 'pt-20' : undefined

export const noUsersErrorText = 'Select someone to notify'

export default useAbsoluteMessagesSendToInput
