import {
  OrderDocExtractionQuery,
  OrderDocExtractionStatus as Status,
  useExtractOrderDocMutation,
  useOrderDocExtractionQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import { useEffect, useState } from 'react'

const key = 'order-doc-extraction-id'

type OrderDoc = NonNullable<NonNullable<OrderDocExtractionQuery['orderDocExtraction']>['orderDoc']>

const useUpload = (onExtracted: (orderDoc: OrderDoc) => void) => {
  const msgr = useMsgs()[1]
  const client = useGqlClient()
  const [extract] = useExtractOrderDocMutation({ client })
  const [uploading, setUploading] = useState(false)
  const [extractionId, extractionIdDispatch] = useState(() => localStorage.getItem(key))

  const { data } = useOrderDocExtractionQuery({
    variables: { id: extractionId! },
    client,
    pollInterval: 2000,
    skip: !extractionId,
  })

  const setExtractionId = (id: string | null) => {
    if (id === null) localStorage.removeItem(key)
    else localStorage.setItem(key, id)

    extractionIdDispatch(id)
  }

  const resetUpload = () => {
    setExtractionId(null)
    setUploading(false)
  }

  useEffect(() => {
    if (!data) return

    const extraction = data.orderDocExtraction

    if (!extraction) {
      resetUpload()
      return
    }

    if (extraction.status === Status.Succeeded || extraction.status === Status.PartialSuccess) {
      onExtracted(extraction.orderDoc!)
      resetUpload()
    }

    if (extraction.status === Status.Failed) {
      msgr.add('No order details were found in the file.', 'neutral')
      resetUpload()
    }
  }, [data])

  const extractOrderDoc = async (url: string) => {
    const { data } = await extract({ variables: { url } })
    setUploading(false)
    setExtractionId(data!.extractOrderDoc)
  }

  return {
    uploadProcessing: uploading || extractionId,
    uploadStarted: () => setUploading(true),
    resetUpload,
    extractOrderDoc,
  }
}

export default useUpload
