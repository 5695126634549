import classNames from 'classnames'

const Youtube = ({ videoId, title, className = '' }) => (
  <div className="relative">
    <iframe
      className={classNames('w-full aspect-video', className)}
      // width="560"
      // height="315"
      width="900"
      height="506"
      src={`https://www.youtube-nocookie.com/embed/${videoId}?si=XELYdaHqUVOdsKoy`}
      title={title}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  </div>
)

export default Youtube
