import { useMachineActivityQuery, useStoreOrdersSearchLiteQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Link from '@/gf/components/LinkOld'
import { InformationCircleIcon } from '@heroicons/react/solid'
import nth from 'lodash/nth'
import qs from 'query-string'
import OrderCard from '../../components/OrderCard/OrderCard'
import RFQCard from '../../components/RFQCard/RFQCardNext'
import ActivityZeroState from './Activity/ActivityZeroState'

interface Props {
  machineId: string
}

const Activity: React.FC<Props> = ({ machineId }) => {
  const { orgId } = useSession()
  const client = useGqlClient()

  const { data: storeOrderData, loading: storeOrderLoading } = useStoreOrdersSearchLiteQuery({
    variables: { orgId, page: 1, filters: [['equals', 'account_machine_id', machineId]] },
    client,
  })

  const { data: requestsData, loading: requestsLoading } = useMachineActivityQuery({
    variables: { orgId, filter: JSON.stringify(['machine_id_eq', machineId]) },
    client,
  })

  const storeOrders = storeOrderData?.org?.paginatedStoreOrders.storeOrders || []
  const lastStoreOrder = storeOrders && (nth(storeOrders, 0) || null)

  const rfqs = requestsData?.searchRequests.requests || []
  const latestRfq = rfqs && (nth(rfqs, 0) || null)

  const noActivity = !lastStoreOrder && !latestRfq && !storeOrderLoading && !requestsLoading

  const rfqsPath = `/rfqs?${qs.stringify({ search: machineId })}`

  const ordersPath = `/orders?search=${machineId}`

  return noActivity ? (
    <ActivityZeroState />
  ) : (
    <>
      <OrderCard storeOrder={lastStoreOrder} loading={storeOrderLoading} className="mt-4" />
      <RFQCard requestForQuote={latestRfq} loading={requestsLoading} className="mt-6" />

      <section className="border-t border-slate-100 mt-6  pt-4">
        <h3 className="text-xl text-slate-600 flex items-center">Machine History</h3>
        <p className="text-slate-500 mt-1 prose leading-5">
          <InformationCircleIcon className="inline-block h-4 text-gearflow opacity-75" /> You can
          view a complete history of all <strong>Orders</strong> and <strong>Requests</strong>{' '}
          created for this machine using the links below.
        </p>
        <div className="flex gap-x-4 mt-4 mb-2">
          <Link to={rfqsPath} title="See all requests for this machine">
            All Requests
          </Link>

          <Link to={ordersPath} title="See all orders for this machine">
            All Orders
          </Link>
        </div>
      </section>
    </>
  )
}

export default Activity
