import requestDemo from './demo.png'

import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import usePersistedState from '../../CreateRequest/usePersistedState'
import useToggle from '@/gf/hooks/useToggle'
import { useStartCreateRequestTutorialMutation } from '@/buyers/_gen/gql'
import Banner from './Banner'

const Demo = () => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const { update, emptyState } = usePersistedState('new-request-data')
  const [loading, loadingToggle] = useToggle()
  const [createVendors] = useStartCreateRequestTutorialMutation({
    client,
    variables: { orgId },
  })

  const startTutorial = async () => {
    try {
      loadingToggle.on()
      await createVendors()

      update({ ...emptyState, tutorial: true })

      // use this hard redirect to make sure the request page picks up the new state
      document.location.href = `/rfqs/create?source.path=${encodeURIComponent(
        document.location.pathname
      )}&source.button=test-requ  est`
    } catch (err) {
      console.error(err)
    } finally {
      loadingToggle.off()
    }
  }

  return (
    <Banner
      title="Demo the request process"
      text="See how easy it is to submit your request, track and accept quotes, and get your order fulfilled."
      action="Demo a Request"
      onActionClick={startTutorial}
      performingAction={loading}
      image={requestDemo}
      color="blue"
    />
  )
}

export default Demo
