import { gql, InternalRefetchQueriesInclude, useMutation } from '@apollo/client'
import { StoreOrderPayload } from '../../types'

const doc = gql`
  mutation UpdateStoreOrderBuyerNotificationUsers($storeOrderId: String!, $userIds: [String]!) {
    updateStoreOrderBuyerNotificationUsers(storeOrderId: $storeOrderId, userIds: $userIds)
  }
`

interface UpdateStoreOrderBuyerNotificationUsersVars {
  storeOrderId: string
  userIds: string[]
}
interface UpdateStoreOrderBuyerNotificationUsersData {
  updateStoreOrderBuyerNotificationUsers: StoreOrderPayload
}
const useUpdateStoreOrderBuyerNotificationUsers = (
  refetchQueries?: InternalRefetchQueriesInclude
) =>
  useMutation<
    UpdateStoreOrderBuyerNotificationUsersData,
    UpdateStoreOrderBuyerNotificationUsersVars
  >(doc, { refetchQueries })

useUpdateStoreOrderBuyerNotificationUsers.Mutation = doc
export default useUpdateStoreOrderBuyerNotificationUsers
