import { useAllBranchesQuery, useLocationFieldQuery } from '@/buyers/_gen/gql'
import LocationModal from '@/buyers/components/LocationModal'
import UserLocationsSelector, {
  Query as UserLocationsQuery,
} from '@/buyers/components/UserLocationsSelector'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import AddressM from '@/buyers/modules/Address'
import Action from '@/gf/components/Action'
import FieldError from '@/gf/components/next/forms/FieldError'
import { useState } from 'react'

const LocationField = ({
  label,
  desc,
  locationId,
  onLocationIdChange,
  errors,
}: {
  label: string
  desc?: string
  locationId: string | null
  onLocationIdChange: (id: string | null) => void
  errors?: { locationId?: string }
}) => {
  const [addingLocation, setAddingLocation] = useState(false)
  const client = useGqlClient()
  const branches = useAllBranchesQuery({ variables: { value: '' }, client }).data?.allBranches

  const {
    organization: { id: orgId, requireBillingCompany },
  } = useSession()

  const selectedLocation = useLocationFieldQuery({
    variables: { orgId, filter: JSON.stringify(['id_eq', locationId]) },
    client,
    skip: !locationId,
  }).data?.org?.locations.at(0)

  return (
    <div className="flex items-start space-x-2 max-w-md">
      <LocationModal
        open={addingLocation}
        refetchQueries={[UserLocationsQuery]}
        onClose={() => setAddingLocation(false)}
        onComplete={(locId) => onLocationIdChange(locId)}
        branches={(requireBillingCompany ? branches : []) ?? []}
        buyers={[]}
        initialAddress={AddressM.init()}
        showPersist
      />

      <div className="space-y-2 flex-grow">
        <h5 className="leading-6">{label}</h5>
        {desc && <p className="prose text-sm">{desc}</p>}

        {selectedLocation ? (
          <p className="text-gray-600 text-sm leading-5">
            {selectedLocation.address?.lineOne}
            <br />
            {selectedLocation.address?.city}, {selectedLocation.address?.state}{' '}
            {selectedLocation.address?.postalCode} <br />
            {selectedLocation.address?.country}
          </p>
        ) : (
          <div className="flex flex-grow gap-x-4">
            <UserLocationsSelector
              value={null}
              onChange={onLocationIdChange}
              className="flex-grow"
            />

            <Action.S className="flex-shrink-0" onClick={() => setAddingLocation(true)}>
              Add Location
            </Action.S>
          </div>
        )}

        <FieldError error={errors?.locationId} />
      </div>

      {selectedLocation && (
        <Action.T className="font-medium text-sm" onClick={() => onLocationIdChange(null)}>
          Change
        </Action.T>
      )}
    </div>
  )
}

export default LocationField
