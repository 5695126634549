import Desc from '@/gf/components/Desc'
import Money from '@/gf/modules/Money'
import { CreditCard } from 'lucide-react'
import { calcPlanTotal } from '../../Settings/Billing/Plan'
import { useRfqNotificationsContext } from './context'

const SubscriptionChanges = () => {
  const { org, planSubscription, planPaymentMethod } = useRfqNotificationsContext()

  if (!org || !planSubscription) return null

  const addlQty = 1
  const licenses = planSubscription.quantity + org.freeLicenses
  const activeUserCount = org.users.filter((u) => u.active).length

  if (licenses > activeUserCount) return null

  const newLicenses = licenses + addlQty
  const newQty = planSubscription.quantity + addlQty
  const currTotal = calcPlanTotal(planSubscription.plan, planSubscription.quantity)
  const newTotal = calcPlanTotal(planSubscription.plan, newQty)

  return (
    <div className="space-y-2">
      <div className="font-medium">Subscription changes</div>

      <Desc.List>
        <Desc.Row title="Licenses">
          <div className="flex items-center gap-8">
            <div className="flex gap-4">
              <span className="text-gray-500 line-through">{licenses}</span>
              <span>{newLicenses}</span>
            </div>
          </div>
        </Desc.Row>

        <Desc.Row
          title={
            <>
              <span className="capitalize">{planSubscription.plan.frequency}</span> Total
            </>
          }
        >
          <div className="flex gap-4">
            <span className="text-gray-500 line-through">{Money.format(currTotal)}</span>
            <span>{Money.format(newTotal)}</span>

            {org.freeLicenses > 0 && (
              <span className="text-gray-500 italic">
                includes {org.freeLicenses} free licenses
              </span>
            )}
          </div>
        </Desc.Row>

        {planPaymentMethod && (
          <Desc.Row title="Payment">
            <div className="flex items-center gap-2">
              <CreditCard className="w-5 h-5" />

              <div>
                {planPaymentMethod.brand} ending in {planPaymentMethod.lastFour}
              </div>
            </div>
          </Desc.Row>
        )}
      </Desc.List>
    </div>
  )
}

export default SubscriptionChanges
