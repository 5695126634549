import {
  LocationsSelectDocument,
  LocationsSelectQuery,
  LocationsSelectQueryVariables,
  NotificationsQuery,
  useAddUserToLocationsMutation,
  useUnsubscribeFromLocationsMutation,
} from '@/buyers/_gen/gql'
import AutocompleteMultiInputNext from '@/buyers/components/AutocompleteMultiInputNext'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import useToggle from '@/gf/hooks/useToggle'
import ShippingLocationM from '@/gf/modules/ShippingLocation'
import { PlusIcon, XIcon } from '@heroicons/react/solid'
import { useState } from 'react'

type User = NonNullable<NotificationsQuery['user']>

const Location = ({
  user,
  location,
  onUnsubscribe,
}: {
  user: User
  location: User['locations'][number]
  onUnsubscribe: () => void
}) => {
  const [unsubscribeFromLocations] = useUnsubscribeFromLocationsMutation({ client: useGqlClient() })

  const unsubscribe = () =>
    unsubscribeFromLocations({ variables: { userId: user.id, locationIds: [location.id] } }).then(
      () => {
        onUnsubscribe()
      }
    )

  return (
    <>
      <div>
        <Link.T to={`/locations/${location.id}`}>{location.name}</Link.T>
      </div>
      <button onClick={() => unsubscribe()} type="button">
        <XIcon className="w-5 h-5 text-gray-400 hover:text-red-700" />
      </button>
    </>
  )
}

const Locations = ({ user, onChange }: { user: User; onChange: () => void }) => {
  const { orgId } = useSession()
  const [subscribing, subscribingToggle] = useToggle(false)
  const [locationIds, setLocationIds] = useState<string[]>([])
  const client = useGqlClient()
  const [subscribeToLocations] = useAddUserToLocationsMutation({ client })

  const subscribe = () =>
    subscribeToLocations({ variables: { userId: user.id, locationIds } }).then(() => {
      subscribingToggle.off()
      onChange()
    })

  const cancel = () => {
    subscribingToggle.off()
    setLocationIds([])
  }

  return (
    <div className="space-y-4">
      <div className="text-sm font-semibold">My Locations</div>
      <div className="prose">Select the locations you want to be notified for.</div>
      {user.locations.length > 0 ? (
        <div className="grid grid-cols-2 gap-2">
          {user.locations.map((l) => (
            <Location user={user} location={l} onUnsubscribe={onChange} key={l.id} />
          ))}
        </div>
      ) : (
        !subscribing && <div className="text-gray-500 italic">No locations added.</div>
      )}
      {subscribing && (
        <div className="flex gap-2 p-4 bg-gray-50">
          <div className="grow">
            <AutocompleteMultiInputNext<
              LocationsSelectQuery,
              LocationsSelectQueryVariables,
              NonNullable<LocationsSelectQuery['org']>['locations'][number]
            >
              gqlClient={client}
              query={LocationsSelectDocument}
              queryVars={(search) => ({ orgId, value: search })}
              onChange={(shippingLocations) =>
                setLocationIds(shippingLocations.map((shippingLocation) => shippingLocation.id))
              }
              transformQueryToData={(queryData) => queryData.org?.locations || []}
              transformDatumToOption={(shippingLocation) => ({
                value: shippingLocation.id,
                label: ShippingLocationM.label(shippingLocation),
              })}
              // Filter out the locations the user is already subscribed to
              filterOption={(option) =>
                !user.locations.find((location) => location.id === option.value)
              }
            />
          </div>
          <Action.P onClick={subscribe}>Add</Action.P>
          <Action.T onClick={cancel} className="text-sm">
            Cancel
          </Action.T>
        </div>
      )}
      <Action.S
        className="flex flex-row gap-x-1 items-center"
        onClick={() => subscribingToggle.on()}
      >
        <PlusIcon className="w-4 h-4 flex shrink-0 text-gray-700" />
        Add Location
      </Action.S>
    </div>
  )
}

export default Locations
