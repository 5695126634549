import useSession from '@/buyers/hooks/useSession'

const WelcomeMessage = () => {
  const { user, organization } = useSession()

  return (
    <h2 className="text-xl lg:text-2xl xl:text-3xl font-medium">
      <>Hi {user.displayName}!</> Here’s what is going on at {organization.name}.
    </h2>
  )
}

export default WelcomeMessage
