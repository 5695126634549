type Branch = {
  name: string
  code: string | null
}

const label = (branch: Branch | null) => {
  if (branch) {
    return `${branch.name}${branch.code ? ` ${branch.code}` : ''}`
  }
  return ''
}

export default label
