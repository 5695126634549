import { get } from 'lodash'
import { SortByInput, SortOrder } from '@/buyers/_gen/gql'

// field is the dot notation of the given metric T
// for example 'x.y.z'
const sortFieldToLodash =
  <T>(field: SortByInput['field']) =>
  (metric: T) =>
    get<T, string>(metric, field)

const sortOrderToLodash = (sortOrder: SortOrder) => (sortOrder === SortOrder.Desc ? 'desc' : 'asc')

export default {
  sortFieldToLodash,
  sortOrderToLodash,
}
