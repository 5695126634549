import { ApolloError, gql, useMutation } from '@apollo/client'
import classNames from 'classnames'
import React from 'react'

import { HomeNextQuery, useOrgUsersQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'

import Button from '@/gf/components/ButtonOld'
import Modal from '@/gf/components/Modal'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

const orgName = (pendingInvite) => pendingInvite?.organization.name || ''
const firstLetter = (pendingInvite) => orgName(pendingInvite).substr(0, 1)

const JOIN_REQUEST = gql`
  mutation JoinRequest($id: String!) {
    joinRequest(id: $id) {
      id
    }
  }
`

const REJECT_REQUEST = gql`
  mutation RejectRequests {
    rejectRequests {
      id
    }
  }
`

type PendingInvite = HomeNextQuery['user']['pendingInvites'][number]

const InviteModal = ({
  pendingInvites,
  open,
  onClose,
}: {
  pendingInvites: PendingInvite[]
  open: boolean
  onClose: () => void
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [show, setShow] = React.useState(false)
  const [sendJoinRequest] = useMutation(JOIN_REQUEST)
  const [sendRejectRequest] = useMutation(REJECT_REQUEST)
  const [joinSpinnerLive, joinSpinner] = useToggle()
  const [rejectSpinnerLive, rejectSpinner] = useToggle()

  const orgIds = pendingInvites.map((pi) => pi.organization.id).filter((id) => !!id)

  const usersResult = useOrgUsersQuery({
    variables: { orgIds },
    client: useGqlClient(),
  })

  if (!usersResult.data || !pendingInvites) return null

  const activeUserCount = (orgId: string) => {
    const resultOrg = usersResult?.data?.orgUsers.find((o) => o.id === orgId) || { count: 0 }
    return resultOrg.count
  }

  const joinRequest = (pendingInvite: PendingInvite) => {
    joinSpinner.on()

    sendJoinRequest({
      variables: { id: pendingInvite.id },
    })
      .then(() => msgsMgr.add('Request sent!', 'positive'))
      .catch((error: ApolloError) => msgsMgr.add(error.message, 'negative'))
      .finally(() => {
        joinSpinner.off()
        onClose()
      })
  }

  const rejectRequests = () => {
    rejectSpinner.on()

    sendRejectRequest()
      .catch((error: ApolloError) => msgsMgr.add(error.message, 'negative'))
      .finally(() => {
        rejectSpinner.off()
        onClose()
      })
  }

  const pendingAction = joinSpinnerLive || rejectSpinnerLive

  return (
    <Modal open={open} onClose={onClose} title="Join your colleagues" cancelText="Maybe later">
      <p className="text-sm text-gray-500">
        It appears that one of your colleagues has already created an account. Join them on Gearflow
        to access shared fleet information, part requests, and order information.
      </p>
      <p className="text-sm text-gray-600 font-bold py-4">
        Teams in your organization
        {pendingInvites?.map((pendingInvite) => (
          <div
            key={pendingInvite?.id}
            className="flex w-full border-indigo-400 border-2 p-6 rounded mt-2"
          >
            <div className="flex items-center">
              <div className="capitalize mr-4 w-10 h-10 text-white rounded-full bg-gray-400 flex items-center justify-center text-xl font-light">
                {firstLetter(pendingInvite)}
              </div>
              <div className="flex">
                <div className="w-full block">
                  <div className="text-lg capitalize">{orgName(pendingInvite)}</div>
                  <div className="-mt-1 text-sm font-light text-gray-600">
                    {activeUserCount(pendingInvite?.organization.id)} members
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center ml-auto">
              <Button
                title="Request"
                type="button"
                className="border border-indigo-700 bg-indigo-500 hover:bg-indigo-500 hover:bg-opacity-90 font-normal focus:outline-none focus:ring-indigo-300 focus:ring-offset-2 focus:ring-2"
                onClick={() => joinRequest(pendingInvite)}
                disabled={pendingAction}
                performing={joinSpinnerLive}
              />
            </div>
          </div>
        ))}
        <div
          onClick={() => setShow(true)}
          onMouseEnter={() => setShow(true)}
          className={classNames(
            'flex w-full p-6 rounded mt-2 border-2',
            show ? 'border-indigo-400' : 'border-gray-300'
          )}
        >
          <div className="flex items-center">
            <div className="mr-4 w-10 h-10 text-white rounded-full bg-gray-400 flex items-center justify-center text-xl font-light">
              P
            </div>
            <div className="text-lg">Personal</div>
          </div>
          <div className="flex items-center ml-auto">
            <Button
              title="Accept"
              type="button"
              className={classNames(
                'border border-indigo-700 bg-indigo-500 hover:bg-indigo-500 hover:bg-opacity-90 font-normal focus:outline-none focus:ring-indigo-300 focus:ring-offset-2 focus:ring-2',
                show ? '' : 'hidden'
              )}
              onClick={rejectRequests}
              disabled={pendingAction}
              performing={joinSpinnerLive}
            />
          </div>
        </div>
      </p>
    </Modal>
  )
}

export default InviteModal
