import Section from './Section'
import useSession from '@/buyers/hooks/useSession'
import FulfillmentValue from '@/buyers/pages/Home/ReportsSection/RequestFulfillment/FulfillmentValue'
import Timeline from '@/buyers/pages/Home/ReportsSection/RequestFulfillment/Timeline'

const hoursToSeconds = (hours: number) => hours * 60 * 60

const RequestFulfillmentTime = () => {
  const { organization } = useSession()

  return (
    <Section
      title="Request Time"
      text={[
        'Know exactly how long each part of the process is taking - from time to review requests through to order fulfillment.',
        'Also learn which vendors are negatively impacting your request fulfillment time and receive insights on how to reduce your overall time. ',
      ]}
    >
      <div className="space-y-6">
        <FulfillmentValue
          link={false}
          value={hoursToSeconds(4.5)}
          variation={hoursToSeconds(1.25)}
        />

        <Timeline
          showReviewStep={organization.requestApproval}
          reviewRequest={{
            data: {
              value: hoursToSeconds(2.3),
              variation: hoursToSeconds(-1.2),
            },
          }}
          receiveQuote={{
            data: {
              value: hoursToSeconds(1.2),
              variation: hoursToSeconds(0.6),
            },
          }}
          reviewQuote={{
            data: {
              value: hoursToSeconds(6.7),
              variation: hoursToSeconds(1.2),
            },
          }}
          orderProcessing={{
            data: {
              value: hoursToSeconds(12.3),
              variation: -hoursToSeconds(3.2),
            },
          }}
        />
      </div>
    </Section>
  )
}

export default RequestFulfillmentTime
