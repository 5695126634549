import Link from '@/gf/components/Link'
import { OfficeBuildingIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import IconZeroState from '../../components/IconZeroState'

const ActivityZeroState = () => (
  <IconZeroState
    title="No vendor activity so far"
    subtitle="Get started by requesting a quote for parts."
    icon={OfficeBuildingIcon}
  >
    <Link.P
      to={`/rfqs/create?source.path=${encodeURIComponent(
        document.location.pathname
      )}&source.button=create-request`}
    >
      <PlusIcon className="inline-block h-4" /> Create Request
    </Link.P>
  </IconZeroState>
)

export default ActivityZeroState
