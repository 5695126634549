import { useSetNotifyRfqStuckInboundMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import useMsgs from '@/gf/hooks/useMsgs'
import { useState } from 'react'

const StuckRfqNotification = ({ initialValue }: { initialValue: boolean }) => {
  const [_, msgr] = useMsgs()
  const [setNotifyRfqStuckInbound] = useSetNotifyRfqStuckInboundMutation({ client: useGqlClient() })
  const [value, setValue] = useState(initialValue)

  const updateSetting = (newValue: boolean) => {
    setValue(newValue)
    setNotifyRfqStuckInbound({ variables: { value: newValue } })
      .then(() => msgr.add('Notification setting updated!', 'positive'))
      .catch(() => msgr.addUnknownError())
  }

  return (
    <div className="space-y-4">
      <h4 className="text-sm font-semibold">Inbound Requests</h4>

      <label className="flex gap-x-3 items-center mt-2">
        <Checkbox checked={value} onChange={(e) => updateSetting(e.target.checked)} />
        <span>
          Get notified when a Request is not Approved after{' '}
          <span className="font-medium">10 minutes</span>
        </span>
      </label>
    </div>
  )
}

export default StuckRfqNotification
