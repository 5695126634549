import { OrgMachineDetails } from '@/gf/hooks/useOrgMachineDetails'
import OrgMachineForm from './OrgMachineForm'
import OrgMachineView from './OrgMachineView'
import OrgMachineViewGhost from './OrgMachineViewGhost'
import Box from '@/gf/components/Box'
import Modal from '@/gf/components/ModalNext'
import useToggle from '@/gf/hooks/useToggle'
import { ModalSize } from '@/types'

const OrgMachine = ({ machineDetails }: { machineDetails: OrgMachineDetails }) => {
  const [editing, editToggle] = useToggle()

  return (
    <Box className="max-w-[73.875rem] shadow-base rounded-xl">
      {machineDetails.loading ? (
        <OrgMachineViewGhost />
      ) : (
        <>
          <Modal open={editing} onClose={editToggle.off} size={ModalSize.SM}>
            <OrgMachineForm
              machineDetails={machineDetails}
              onAfterSave={editToggle.off}
              onCancel={editToggle.off}
            />
          </Modal>

          <OrgMachineView machineDetails={machineDetails} onEditClick={editToggle.on} />
        </>
      )}
    </Box>
  )
}

export default OrgMachine
