import { CreateRequestPageDocument } from '@/buyers/_gen/gql'
import AddAccountMachineModal from '@/buyers/components/AddAccountMachineModal'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import useToggle from '@/gf/hooks/useToggle'
import MachineIcon from '@/buyers/components/Frame/Icons/MachineIcon'

const AddMachineButton = ({
  onMachineAdded,
  orgHasMachines,
}: {
  onMachineAdded: (orgMachineId: string) => void
  orgHasMachines: boolean
}) => {
  const { organization } = useSession()
  const [open, toggle] = useToggle()

  return (
    <>
      <AddAccountMachineModal
        open={open}
        onClose={toggle.off}
        onAdd={(accountMachine) => {
          if (accountMachine) {
            onMachineAdded(accountMachine.id)
          }
        }}
        accountId={organization.id}
        refetchQueries={[CreateRequestPageDocument]}
      />

      {orgHasMachines ? (
        <Action.S className="flex-shrink-0" onClick={toggle.on}>
          Add Machine
        </Action.S>
      ) : (
        <div className="p-6 w-full flex flex-col items-center gap-y-4 bg-gray-100 rounded-lg border border-gray-200">
          <div className="flex flex-col items-center">
            <MachineIcon className="-m-1 w-8 h-8 flex shrink-0 text-gray-500" />
            <p className="text-base font-medium text-gray-500">No machines saved. Add a new one.</p>
          </div>
          <Action.P className="px-12 flex-shrink-0" onClick={toggle.on} color="blue">
            Add Machine
          </Action.P>
        </div>
      )}
    </>
  )
}

export default AddMachineButton
