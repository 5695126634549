import Box from '@/gf/components/Box'
import DelayedSearchInput from '@/gf/components/inputs/DelayedSearch'
import Layout from '@/gf/components/Layout'
import Link from '@/gf/components/Link'
import Dropdown from '@/gf/components/next/Dropdown'
import TextTag from '@/gf/components/next/TextTag'
import Page from '@/gf/components/Page'
import PaginationC from '@/gf/components/Pagination'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import usePage from '@/gf/hooks/usePage'
import useSearchQueryParam from '@/gf/hooks/useSearchQueryParam'
import Time from '@/gf/modules/Time'
import { Route, Routes } from 'react-router-dom'
import { VendorsQuery, useVendorsQuery } from '../_gen/gql'
import BrandsMissingVendors from '../components/BrandsMissingVendors'
import Frame from '../components/Frame'
import FullPagePaywall from '../components/FullPagePaywall'
import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import ContactDropdown from './Setup/Vendors/ContactDropdown'
import FinishSetup from './Vendors/FinishSetup'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [{ name: 'Vendors', href: '/vendors' }],
}

type Vendor = Exclude<VendorsQuery['org'], null>['paginatedVendors']['vendors'][number]

const Row = ({ vendor }: { vendor: Vendor }) => (
  <Tr>
    <Td>
      {vendor.draft ? (
        vendor.name
      ) : (
        <Link.T to={`/vendors/${vendor.id}`} className="text-base">
          {vendor.name}
        </Link.T>
      )}

      <p className="text-xs text-slate-400 italic mt-1">
        {!vendor.draft && (
          <>
            Account{' '}
            <span className="text-slate-500 font-medium">#{vendor.accountNumbers.join(', ')}</span>
            {' - '}
          </>
        )}
        Created {Time.toString(vendor.insertedAt)}
      </p>
    </Td>

    <Td>
      <Dropdown
        placement="bottom-end"
        trigger={
          <p className="text-sm flex gap-1">
            {vendor.brands.slice(0, 3).map((b) => (
              <TextTag key={b.id}>{b.name}</TextTag>
            ))}

            {vendor.brands.length > 3 && (
              <TextTag color="gray">+{vendor.brands.length - 3}</TextTag>
            )}
          </p>
        }
      >
        <Box className="flex gap-2 flex-wrap p-2 mt-2 shadow-lg">
          {vendor.brands.slice(3).map((b) => (
            <span className="inline-block" key={b.id}>
              <TextTag>{b.name}</TextTag>
            </span>
          ))}
        </Box>
      </Dropdown>
    </Td>
    <Td>
      {vendor.draft ? (
        <Link.P to={`finish-setup/${vendor.id}`}>Finish Setup</Link.P>
      ) : (
        vendor.contacts.length > 0 && <ContactDropdown contacts={vendor.contacts} />
      )}
    </Td>
  </Tr>
)

const Vendors = () => {
  const { orgId } = useSession()
  const { search, updateSearch } = useSearchQueryParam()
  const [page, setPage] = usePage()

  const { data, previousData } = useVendorsQuery({
    variables: { orgId, page, search },
    client: useGqlClient(),
  })

  const { org } = data || previousData || {}
  const vendors = (data ?? previousData)?.org?.paginatedVendors.vendors ?? []
  const pagination = (data ?? previousData)?.org?.paginatedVendors.pagination

  if (org && !org.features.viewDealers)
    return <FullPagePaywall breadcrumbs={breadcrumbs} title="Vendors" />

  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page
        title={
          <div className="inline-flex items-center gap-x-4">
            Vendors
            <BrandsMissingVendors />
          </div>
        }
        titleRightContent={<Link.P to="/vendors/new?selectLocation=1">Add Vendor</Link.P>}
      >
        <div className="space-y-4 mt-4">
          <DelayedSearchInput
            value={search}
            autoFocus
            setValue={(term) => {
              updateSearch(term)
              setPage(1)
            }}
          />

          <Layout.Section type="table">
            <Table>
              <Thead>
                <Tr>
                  <Th>Vendor</Th>
                  <Th>Brands</Th>
                  <Th>Contact</Th>
                </Tr>
              </Thead>
              <Tbody>
                {vendors.length === 0 ? (
                  <Tr>
                    <Td colSpan={3}>No vendors found.</Td>
                  </Tr>
                ) : (
                  vendors.map((vendor) => <Row key={vendor.id} vendor={vendor} />)
                )}
              </Tbody>
            </Table>
          </Layout.Section>
          <Layout.Section type="full">
            <PaginationC pagination={pagination} page={page} updatePage={setPage} />
          </Layout.Section>
        </div>
      </Page>

      <Routes>
        <Route path="*" element={<FinishSetup />} />
      </Routes>
    </Frame>
  )
}

export default Vendors
