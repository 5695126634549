import { ReactNode } from 'react'

const Section = ({
  title,
  text,
  children,
}: {
  title: string
  text: string[]
  children: ReactNode
}) => (
  <section className="rounded-xl border border-gray-200 bg-gray-50 shadow-base p-4 lg:p-6 xl:p-8 flex flex-col lg:flex-row gap-8">
    <div className="flex flex-col gap-y-2 w-full lg:max-w-[17.5rem] flex-shrink-0">
      <h3 className="text-2xl font-medium">{title}</h3>
      <div className="flex-grow text-base space-y-6">
        {text.map((p) => (
          <p key={p}>{p}</p>
        ))}
      </div>
    </div>
    <div className="flex-grow">{children}</div>
  </section>
)

export default Section
