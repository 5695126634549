import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import LoadingCard from '@/gf/components/LoadingCard'
import { useNavigate } from 'react-router-dom'
import { useFetchQualificationQuery } from '../../../_gen/gql'
import useGqlClient from '../../../hooks/useGqlClient'

const Review = () => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const navigate = useNavigate()
  const next = () => navigate('/settings/qualify/payment')

  const qualificationQuery = useFetchQualificationQuery({
    variables: { orgId },
    client,
    fetchPolicy: 'no-cache',
  })

  return qualificationQuery?.loading ? (
    <LoadingCard />
  ) : (
    <Card>
      <Card.Section title="Review">
        <div className="mb-2 space-y-4">
          <div className="prose">Summary of terms pending approval:</div>
        </div>
        <div className="space-y-4 text-sm">
          <ul className="max-w-2xl space-y-1 text-gray-600 list-disc list-inside">
            <li>
              Balance does not conduct hard credit checks and this information will never be shared
              with Gearflow
            </li>
            <li>
              You agree to automatically pay outstanding invoices at 37 days from invoice date via
              ACH if payment has not been received
            </li>
            <li>All payments will be made to our payment processing partner below:</li>
          </ul>
        </div>
        <div className="pt-6 prose text-sm leading-4">
          <div>Balance Payments Inc</div>
          <div>2261 Market Street #4149</div>
          <div>San Francisco, CA 94114</div>
        </div>
        <div className="flex gap-6 justify-end">
          <Action.P onClick={next}>Next</Action.P>
        </div>
      </Card.Section>
    </Card>
  )
}

export default Review
