import SentToNetworkBadge from '@/gf/components/SentToNetworkBadge'
import pluralize from 'pluralize'
import { useContext } from '../../RFQ'
import AwaitingQuote from './AwaitingQuote'
import Section from './Section'

const AwaitingQuotes = () => {
  const { rfq } = useContext()

  const vendorsWithoutQuote = rfq.requestForQuoteVendors.filter(
    (rfqVendor) =>
      !rfq.nonparticipations.some((n) => n.storeId === rfqVendor.vendor?.store?.id) &&
      !rfq.storeOrders.find((so) => so.store.id === rfqVendor.vendor.store?.id)
  )

  if (vendorsWithoutQuote.length === 0) return null

  const title = rfq.needsApproval
    ? pluralize('Vendor', vendorsWithoutQuote.length)
    : 'Awaiting Quotes'

  return (
    <Section
      title={
        <div className="flex flex-row items-center gap-x-4">
          {title}
          {rfq.broadcast && !rfq.needsApproval && <SentToNetworkBadge />}
        </div>
      }
    >
      <div className="flex flex-col gap-y-4">
        {vendorsWithoutQuote.map((rfqVendor) => (
          <AwaitingQuote rfqVendor={rfqVendor} key={rfqVendor.vendor.id} />
        ))}
      </div>
    </Section>
  )
}

export default AwaitingQuotes
