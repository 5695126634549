import { useSetUserRoleMutation } from '@/buyers/_gen/gql'
import { sortRoles } from '@/buyers/components/RolePicker'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import Radio from '@/gf/components/inputs/Radio'
import { Td, Tr } from '@/gf/components/Table'
import { Pencil, X } from 'lucide-react'
import { useState } from 'react'
import { Org, useRfqNotificationsContext } from './context'
import UserDisplay from './UserDisplay'

const User = ({
  user,
  showEditRole,
  removeUser,
  onRoleChanged,
}: {
  user: Org['users'][number]
  showEditRole: boolean
  removeUser: (userId: string) => void
  onRoleChanged: () => void
}) => {
  const { org } = useRfqNotificationsContext()
  const [editingRole, setEditingRole] = useState(false)
  const [newRoleName, setNewRoleName] = useState<string>()
  const client = useGqlClient()
  const [setUserRole] = useSetUserRoleMutation({ client })

  const changeRole = async (role: { id: string; name: string }) => {
    setNewRoleName(role.name)
    setEditingRole(false)
    await setUserRole({ variables: { userId: user.id, roleId: role.id } })
    onRoleChanged()
    await client.reFetchObservableQueries()
    setNewRoleName(undefined)
  }

  return (
    <Tr className="group">
      <Td className="py-2 px-4">
        <UserDisplay user={user} />
      </Td>

      <Td className="capitalize py-2 px-4">
        <div className="flex gap-4 items-center justify-between">
          {!editingRole ? (
            <>
              {newRoleName || user.userRole?.name}

              {showEditRole && (
                <button
                  onClick={() => setEditingRole(true)}
                  className="text-gray-400 hover:text-blue-500 flex gap-1 items-center"
                >
                  <Pencil className="h-4 w-4" /> <span>edit</span>
                </button>
              )}
            </>
          ) : (
            <div className="space-y-2">
              <div className="space-y-1">
                {org &&
                  org.roles.sort(sortRoles).map((role) => (
                    <div className="flex items-center gap-2" key={role.id}>
                      <Radio
                        id={role.id}
                        checked={user.userRole?.id === role.id}
                        onChange={({ target }) => {
                          if (target.checked) changeRole(role)
                        }}
                      />

                      <label htmlFor={role.id} className="block">
                        {role.name}
                      </label>
                    </div>
                  ))}
              </div>

              <Action.T onClick={() => setEditingRole(false)} className="text-sm">
                cancel
              </Action.T>
            </div>
          )}
        </div>
      </Td>

      <Td className="py-2 px-4">
        {!editingRole && (
          <button
            onClick={() => removeUser(user.id)}
            className="invisible text-gray-400 flex gap-1 items-center group-hover:visible hover:text-red-500"
          >
            <X className="h-5 w-5" /> <span>remove</span>
          </button>
        )}
      </Td>
    </Tr>
  )
}

export default User
