import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useInstallFleetioAppMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'

import A from '@/gf/components/A'
import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import TextInput from '@/gf/components/inputs/Text'
import Modal from '@/gf/components/ModalNext'

const InstallFleetio = ({ onInstalled }: { onInstalled: () => void }) => {
  const navigate = useNavigate()
  const { orgId } = useSession()
  const [_, msgr] = useMsgs()
  const close = () => navigate('/settings/integrations')
  const [fields, setFields] = useState({ apiKey: '', accountToken: '' })
  const [installFleetioApp] = useInstallFleetioAppMutation({ client: useGqlClient() })

  const installFleetio = () => {
    installFleetioApp({ variables: { orgId, ...fields } }).then(() => {
      close()
      msgr.add('Fleetio app installed.')
      onInstalled()
    })
  }

  return (
    <Modal open onClose={close}>
      <Form onSubmit={installFleetio} className="p-6 space-y-4">
        <div className="text-xl">Install Fleetio</div>

        <div className="prose">
          You can find this information at the{' '}
          <A.T href="https://secure.fleetio.com/api_keys" target="_blank">
            API Keys page in Fleetio
          </A.T>
          .
        </div>

        <Field label="API Key">
          <TextInput
            value={fields.apiKey}
            setValue={(apiKey) => setFields({ ...fields, apiKey })}
            required
            className="font-mono"
          />
        </Field>

        <Field label="Account Token">
          <TextInput
            value={fields.accountToken}
            setValue={(accountToken) => setFields({ ...fields, accountToken })}
            required
            className="font-mono"
          />
        </Field>

        <div className="flex gap-2">
          <Action.P type="submit">Install</Action.P>
          <Action.S onClick={close}>Cancel</Action.S>
        </div>
      </Form>
    </Modal>
  )
}

export default InstallFleetio
