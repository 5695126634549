import classNames from 'classnames'
import { NavLink, useLocation } from 'react-router-dom'

import { StoreOrderStep } from '@/buyers/_gen/gql'
import { useOrderContext } from './context'

import NotificationDot from '@/gf/components/NotificationDot'

const Nav = ({ unreadMessages }: { unreadMessages: boolean }) => {
  const { storeOrder } = useOrderContext()
  const location = useLocation()

  const tabs = [
    {
      to: '',
      name: `Your ${storeOrder.step === StoreOrderStep.Quoted ? 'Quote' : 'Order'}`,
    },
    { to: 'vendor', name: 'Vendor Details' },
    { to: 'details', name: 'Timeline' },
    { to: 'messages', name: 'Messages', showNotification: unreadMessages },
  ]

  const className = ({ isActive }: { isActive: boolean }) =>
    classNames(
      'relative md:text-lg whitespace-nowrap pb-3',
      isActive ? 'font-medium text-blue-600 border-b-[0.1875rem] border-blue-600' : 'text-gray-500'
    )

  return (
    <nav className="pl-2 md:pl-4 lg:pl-6 flex gap-x-4 lg:gap-x-6">
      {tabs.map((tab) => (
        <NavLink
          to={tab.to}
          end={
            tab.to === '' &&
            (location.pathname.includes('/details') ||
              location.pathname.includes('/messages') ||
              location.pathname.includes('/vendor'))
          }
          className={className}
          key={tab.to}
        >
          {tab.name}
          {tab.showNotification && <NotificationDot className="absolute -mt-1 ml-1" />}
        </NavLink>
      ))}
    </nav>
  )
}

export default Nav
