import { ShippingLocationsSearchQuery, useFetchOrganizationUsersQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Action from '@/gf/components/Action'
import Address from '@/gf/components/Address'
import LabeledText from '@/gf/components/LabeledText'
import SidebarProfileView from '@/gf/components/Layout/SidebarProfileView'
import SelectUsersModal from '@/gf/components/SelectUsersModal'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import Phone from '@/gf/modules/Phone'
import { ApolloError, gql, useMutation } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/outline'

const ASSIGN_SHIPPING_LOCATION = gql`
  mutation AssignShippingLocationUsers($userIds: [String!]!, $shippingLocationId: String!) {
    assignShippingLocationUsers(userIds: $userIds, shippingLocationId: $shippingLocationId) {
      id
    }
  }
`

interface SidebarLocationViewProps {
  shippingLocation: ShippingLocationsSearchQuery['shippingLocationsSearch']['shippingLocations'][number]
  onEditClick: () => void
  onDataChanged: () => void
}

const SidebarLocationView = ({
  shippingLocation,
  onEditClick,
  onDataChanged,
}: SidebarLocationViewProps) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [assignShippingLocationMutation] = useMutation(ASSIGN_SHIPPING_LOCATION)
  const gqlClient = useGqlClient()
  const {
    data: organizationUsers,
    loading: organizationUsersLoading,
    error: organizationUsersError,
  } = useFetchOrganizationUsersQuery({ client: gqlClient })
  const [assignModalOpen, assignModalToggler] = useToggle()
  const [spinnerLive, spinnerLiveToggle] = useToggle()

  const assignUsers = async (userIds) => {
    spinnerLiveToggle.on()

    try {
      await assignShippingLocationMutation({
        variables: { userIds, shippingLocationId: shippingLocation.id },
      })

      onDataChanged()
    } catch (err) {
      const message =
        err instanceof ApolloError
          ? err.message
          : 'Whoops, something went wrong. Please contact support.'
      msgsMgr.add(message, 'negative')
    } finally {
      spinnerLiveToggle.off()
      assignModalToggler.off()
    }
  }

  return (
    <SidebarProfileView
      title={shippingLocation.name}
      detail={shippingLocation.code ? `Code: ${shippingLocation.code}` : null}
      showImagePlaceholder={false}
      onEditClick={onEditClick}
      className="w-full md:w-64"
    >
      <SelectUsersModal
        titleText="Assign users to this location"
        detailsText="Select the people who should receive notifications"
        open={assignModalOpen}
        onClose={assignModalToggler.off}
        onSubmit={assignUsers}
        spinnerLive={spinnerLive}
        users={organizationUsers?.fetchOrganizationUsers}
        usersLoading={organizationUsersLoading}
        usersError={!!organizationUsersError}
        initialSelectedUsers={shippingLocation.shippingLocationUsers}
      />

      {shippingLocation.address && (
        <LabeledText
          label="Address"
          value={
            <Address
              className="text-slate-700 font-semibold text-sm"
              address={shippingLocation.address}
            />
          }
        />
      )}

      {shippingLocation.phoneNumber && (
        <LabeledText label="Phone" value={Phone.format(shippingLocation.phoneNumber)} />
      )}

      <LabeledText label="Use as Default" value={shippingLocation.defaultLocation ? 'Yes' : 'No'} />

      <LabeledText
        label="Notifications"
        labelAction={
          <Action.T onClick={assignModalToggler.on} className="p-2 rounded-full hover:bg-slate-50">
            <PencilIcon className="h-5 text-zinc-400" />
          </Action.T>
        }
        value={
          <div>
            {shippingLocation.shippingLocationUsers.length === 0 ? (
              <p className="text-slate-500 italic font-normal text-medium">No users assigned</p>
            ) : (
              <p>{shippingLocation.shippingLocationUsers.map((u) => u.displayName).join(', ')}</p>
            )}
          </div>
        }
      />
    </SidebarProfileView>
  )
}

export default SidebarLocationView
