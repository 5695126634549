import { VendorsDocument } from '@/buyers/_gen/gql'
import CompleteVendorModal from '@/buyers/components/Vendors/CompleteVendorModal'
import { useMatch, useNavigate } from 'react-router-dom'

const FinishSetup = () => {
  const navigate = useNavigate()
  const vendorId = useMatch('/vendors/finish-setup/:vendorId')?.params.vendorId

  if (!vendorId) return null

  return (
    <CompleteVendorModal
      open
      onClose={() => navigate('/vendors')}
      vendorId={vendorId}
      refetchQueries={[VendorsDocument]}
    />
  )
}

export default FinishSetup
