import { useEffect, useState } from 'react'
import { SessionQuery } from '../_gen/gql'

type Orgs = Exclude<SessionQuery['session']['user'], null>['orgs']

const useOrg = (orgs: Orgs | undefined) => {
  const [id, setId] = useState<string | null | undefined>(
    () => localStorage.getItem('org-id') || undefined
  )

  const org = (() => {
    if (!orgs || id === undefined) return undefined
    if (id === null) return null
    return orgs.find((o) => o.id === id)
  })()

  const switchOrg = (orgId: string) => {
    setId(orgId)
    localStorage.setItem('org-id', orgId)
  }

  useEffect(() => {
    if (!orgs) return

    // If you sign in as a different user, it's possible the org id in local storage doesn't
    // match any of your orgs. In that case, clear the org id.
    if (!org) {
      const firstOrgId = orgs.at(0)?.id || null

      if (firstOrgId) {
        switchOrg(firstOrgId)
      } else {
        setId(null)
        localStorage.removeItem('org-id')
      }
    }
  }, [!orgs, id])

  return { org, switchOrg }
}

export default useOrg
