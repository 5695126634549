import Action from '@/gf/components/Action'
import { ComponentProps } from 'react'

type Props = Omit<Parameters<typeof Action.S>[0], 'size' | 'className' | 'children'> & {
  Icon: (props: ComponentProps<'svg'>) => JSX.Element
}

const Pager = ({ Icon, disabled, ...props }: Props) => (
  <Action.S className="px-2" size="sm" disabled={disabled} {...props}>
    <Icon className="w-5 h-5 text-gray-500" />
  </Action.S>
)

export default Pager
