import { CheckIcon, XIcon } from '@heroicons/react/solid'

import type { Role, PermissionSummary } from '@/buyers/_gen/gql'

const RolePermissions = ({
  role,
  permissionSummaries,
}: {
  role: Pick<Role, 'id' | 'name'>
  permissionSummaries: Pick<PermissionSummary, 'title' | 'roles'>[] | undefined
}) => (
  <ul>
    {permissionSummaries?.map((ps) => (
      <li className="flex gap-2 items-center" key={ps.title}>
        {ps.roles.includes(role.name) ? (
          <CheckIcon className="w-5 h-5 inline-block text-green-500" />
        ) : (
          <XIcon className="w-5 h-5 inline-block text-red-500" />
        )}

        <span>{ps.title}</span>
      </li>
    ))}
  </ul>
)

export default RolePermissions
