import { TruckIcon } from '@heroicons/react/outline'
import { PlusIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

const ActivityZeroState: React.FC = () => (
  <section className="py-10">
    <div className="flex flex-col justify-center items-center">
      <TruckIcon className="h-16 text-slate-300" />
      <h4 className="font-medium text-xl text-slate-800 -mt-1">No activity</h4>
    </div>
    <p className="text-center max-w-md mx-auto text-gray-500 mt-2">
      Get started by requesting a quote for parts.
    </p>

    <div>
      <Link
        to={`/rfqs/create?source.path=${encodeURIComponent(
          document.location.pathname
        )}&source.button=create-request`}
        className="bg-gearflow hover:bg-gearflow-dark rounded-lg py-3 px-6 text-white flex items-center justify-center w-56 mx-auto mt-4"
      >
        <PlusIcon className="h-6 mr-2" /> Create Request
      </Link>
    </div>
  </section>
)

export default ActivityZeroState
