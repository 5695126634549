import { Dialog, DialogContent } from '@/gf/components/ui/dialog'
import { useNavigate } from 'react-router-dom'
import { useContext } from '../../RFQ'
import Notifications from '../Notifications'

const DetailsNotifications = () => {
  const { rfq } = useContext()
  const navigate = useNavigate()

  return (
    <Dialog open={true} onOpenChange={() => navigate(`/rfqs/${rfq.id}/details`)}>
      <DialogContent className="sm:max-w-2xl overflow-hidden">
        <Notifications />
      </DialogContent>
    </Dialog>
  )
}

export default DetailsNotifications
