import { Link } from 'react-router-dom'
import useMetrics from '../Reporting/useMetrics'
import Variation, { formatMoney, formatTime } from './ReportsSection/Variation'
import Money from '@/gf/modules/Money'
import { Maybe } from '@/types'
import { timeComparison } from './ReportsSection/RequestFulfillment'
import Ghost from '@/gf/components/Ghost'
import Time from '@/gf/modules/Time'
import { NO_DATA_VALUE } from '../Reporting/Scorecards'

const Metric = ({
  name,
  value,
  variation,
  to,
}: {
  name: string
  // undefined is loading, null is no value
  value: Maybe<string> | undefined
  variation: Maybe<JSX.Element>
  to: string
}) => (
  <Link
    to={to}
    className="shadow-base rounded-xl bg-white border py-6 px-4 transition duration-300 hover:border-blue-600 w-48"
  >
    <span className="block text-sm text-gray-500 font-medium">{name}</span>
    <span className="block text-3xl font-medium mt-3">
      {typeof value === 'undefined' ? (
        <Ghost className="inline-block h-6 w-40" />
      ) : (
        (value ?? NO_DATA_VALUE)
      )}
    </span>
    <span className="block">{variation}</span>
  </Link>
)

const QuickMetrics = ({ metrics }: { metrics: ReturnType<typeof useMetrics> }) => {
  const { aggregatedRequestMetrics, prevAggregatedRequestMetrics } = metrics
  const costSavingsComparison =
    typeof aggregatedRequestMetrics === 'undefined' ||
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : aggregatedRequestMetrics.costSavings === null ||
          prevAggregatedRequestMetrics.costSavings === null
        ? null
        : aggregatedRequestMetrics.costSavings.sum - prevAggregatedRequestMetrics.costSavings.sum

  const timeSavingsComparison =
    typeof aggregatedRequestMetrics === 'undefined' ||
    typeof prevAggregatedRequestMetrics === 'undefined'
      ? undefined
      : aggregatedRequestMetrics.timeSavings === null ||
          prevAggregatedRequestMetrics.timeSavings === null
        ? null
        : timeComparison(
            aggregatedRequestMetrics.timeSavings.sum,
            prevAggregatedRequestMetrics.timeSavings.sum
          )

  return (
    <section>
      <h3 className="text-2xl font-medium">Last 30 Days</h3>
      <div className="mt-3 flex gap-x-3">
        <Metric
          name="Lead Time Saved"
          value={
            typeof aggregatedRequestMetrics === 'undefined'
              ? undefined
              : aggregatedRequestMetrics.timeSavings === null
                ? null
                : Time.secondsToString(aggregatedRequestMetrics.timeSavings.sum)
          }
          variation={
            timeSavingsComparison?.variation ? (
              <Variation
                value={timeSavingsComparison.variation}
                toformat={formatTime}
                positiveDirection="up"
                boxed={false}
                className="text-lg"
              />
            ) : null
          }
          to="/reporting/time?metric=time savings"
        />
        <Metric
          name="Parts Cost Saved"
          value={
            typeof aggregatedRequestMetrics === 'undefined'
              ? undefined
              : aggregatedRequestMetrics.costSavings === null
                ? null
                : Money.format(Money.fromInt(aggregatedRequestMetrics.costSavings.sum, 'USD'), {
                    maximumFractionDigits: 0,
                  })
          }
          variation={
            costSavingsComparison ? (
              <Variation
                value={Money.toDecimal(Money.fromInt(costSavingsComparison, 'USD'))}
                positiveDirection="up"
                boxed={false}
                className="text-lg"
                toformat={formatMoney(0)}
              />
            ) : null
          }
          to="/reporting/cost?metric=savings"
        />
      </div>
    </section>
  )
}

export default QuickMetrics
