/**
 * StoreOrderEvents Component
 * -----------------
 * Timeline events for store order
 * Styling from: https://tailwindui.com/components/application-ui/lists/feeds
 *
 * props
 * -----------------
 * events - data for displaying store order events
 * state - store order state
 */

import type { StoreOrderState } from '@/buyers/_gen/gql'
import Time from '@/gf/modules/Time'
import { EventDisplayData, EventType } from '@/types'
import { Disclosure } from '@headlessui/react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'
import A from './A'
import Button from './ButtonOld'
import EventIcon from './EventIcon'

const StoreOrderEvents = ({
  events,
  state,
}: {
  events?: EventDisplayData[]
  state: StoreOrderState
}) => {
  const { hash } = useLocation()
  const highlightedEventId = hash.replace('#', '')

  if (!events) return <p className="text-sm text-gray-500">Loading ...</p>
  if (events.length === 0) return <p className="text-sm text-gray-500">No events yet.</p>

  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {events.map((event, index) => (
          <li
            id={event.id}
            key={event.id}
            className={classNames('rounded-md pr-1', {
              'bg-gray-100 bg-opacity-75': event.id === highlightedEventId,
            })}
          >
            <div className="relative pb-8">
              {index !== events.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <EventIcon eventDisplayDataIcon={event} />
                </div>
                <div className="flex flex-col w-full items-start">
                  <div className="min-w-0 w-full pt-1.5 flex justify-between space-x-4">
                    <p className="text-sm text-gray-500">{event.content}</p>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={event.datetime.toISO() || undefined}>
                        {Time.formatSimpleDateTime(event.datetime)}
                      </time>
                    </div>
                  </div>

                  {event.details && (
                    <div className="text-sm text-gray-500 mt-1">{event.details}</div>
                  )}
                  {event.sourceLink && event.sourceLinkMessage && event.type === EventType.RFQ && (
                    <div className="text-sm">
                      <A.T href={event.sourceLink}>{event.sourceLinkMessage}</A.T>
                    </div>
                  )}
                  {event.hiddenDetails && (
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Panel className="text-sm text-gray-500 mt-1">
                            {event.hiddenDetails}
                          </Disclosure.Panel>
                          <Disclosure.Button className="text-sm text-blue-500 underline">
                            {open ? 'Show less' : 'Show more'}
                          </Disclosure.Button>
                        </>
                      )}
                    </Disclosure>
                  )}
                  {event.action && !event.action.hiddenForStates.includes(state) && (
                    <Button
                      className="mt-2 w-fit"
                      title={event.action.title}
                      onClick={event.action.onClick}
                      primary={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default StoreOrderEvents
