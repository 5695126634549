import { InformationCircleIcon } from '@heroicons/react/solid'
import { Fragment } from 'react'

import useSession from '@/buyers/hooks/useSession'
import type { EventNotificationSetting } from '@/buyers/pages/Settings/Notifications/useForm'
import useForm from '@/buyers/pages/Settings/Notifications/useForm'
import Checkbox from '@/gf/components/Checkbox'
import Select from '@/gf/components/Select'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'

type Form = ReturnType<typeof useForm>

const defaultEventNotificationSetting = {
  email: true,
  sms: true,
  pushNotification: true,
  scope: 'subscribed',
}

const notificationSections = [
  { display: 'Messages', notificationEvents: [{ id: 'message_created', display: 'New Message' }] },
  {
    display: 'Request Updates',
    notificationEvents: [
      { id: 'rfq_created', display: 'Request Created', smsDisabled: true },
      {
        id: 'rfq_viewed_receipt',
        display: 'Request Viewed by Vendor',
        smsDisabled: false,
        emailDisabled: true,
        tooltip: 'When a Vendor first views your Request.',
      },
      { id: 'rfq_cancelled', display: 'Request Canceled', smsDisabled: true },
      { id: 'rfq_assigned', display: 'Request Assigned', smsDisabled: true },
      {
        id: 'vendor_unparticipated_in_rfq',
        display: <>Vendor Can&apos;t Participate</>,
        smsDisabled: true,
      },
    ],
  },
  {
    display: 'Order Updates',
    notificationEvents: [
      {
        id: 'store_order_created_confirmation',
        display: 'Order Created Confirmation',
        smsDisabled: true,
      },
      {
        id: 'store_order_quote_created',
        display: 'Order Quote Created',
        tooltip:
          'When a Vendor creates a quote for you to review. For example, when a quote is created for your Request.',
      },
      {
        id: 'store_order_quote_above_cost_threshold',
        display: 'Order Quote Above Threshold',
        tooltip:
          'When a quote is created that is above the cost threshold set by your organization and requires approval.',
        smsDisabled: true,
      },
      { id: 'store_order_edited', display: 'Order Edited', smsDisabled: true },
      { id: 'store_order_cancelled', display: 'Order Canceled', smsDisabled: true },
      { id: 'store_order_processing', display: 'Order Processing', smsDisabled: true },
      { id: 'store_order_shipped', display: 'Order Shipped', smsDisabled: true },
      {
        id: 'store_order_ready_for_pickup',
        display: 'Order is Ready',
        smsDisabled: true,
      },
      {
        id: 'store_order_additional_charge_created',
        display: 'Order Additional Charge Created',
        smsDisabled: true,
      },
      { id: 'store_order_refund_created', display: 'Order Refund Created', smsDisabled: true },
      {
        id: 'store_order_problem_reported',
        display: 'Parts Received Accuracy Problem',
        smsDisabled: true,
        tooltip:
          'When you receive parts, there is an option to report any problems with the parts you received.',
      },
      {
        id: 'store_order_shipment_delivered',
        display: 'Shipment Delivered',
        smsDisabled: true,
        tooltip: 'When a drop ship package is marked as delivered by the carrier.',
      },
    ],
  },
  {
    display: 'Accounts Payable',
    notificationEvents: [
      {
        id: 'invoice_created',
        display: 'Receive Invoice Copy',
        smsDisabled: true,
        tooltip: 'Receive a copy of the invoice for an order.',
      },
    ],
  },
]

const NotificationsTable = ({
  form: { eventNotificationSettings, setEventNotificationSettings },
}: {
  form: Form
}) => {
  const {
    organization: { approvalThresholdEnabled },
    user: { userRole },
  } = useSession()

  const disabledNotifications = {
    store_order_quote_above_cost_threshold: !(
      approvalThresholdEnabled && userRole?.name === 'admin'
    ),
  }

  const setEventNotificationSetting = (
    id: string,
    newEventNotificationSetting: EventNotificationSetting
  ) => {
    setEventNotificationSettings((prevState) => ({
      ...prevState,
      [id]: newEventNotificationSetting,
    }))
  }

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Activity</Th>
          <Th>Email</Th>
          <Th>SMS</Th>
          <Th>Preference</Th>
        </Tr>
      </Thead>
      <Tbody>
        {notificationSections.map((notificationSection) => (
          <Fragment key={notificationSection.display}>
            <Tr>
              <Td className="font-semibold text-gray-900" colSpan={4}>
                {notificationSection.display}
              </Td>
            </Tr>
            {notificationSection.notificationEvents.map((notificationEvent) => {
              const eventNotificationSetting =
                eventNotificationSettings[notificationEvent.id] || defaultEventNotificationSetting
              const isNotificationDisabled = disabledNotifications[notificationEvent.id]

              return isNotificationDisabled ? null : (
                <Tr key={notificationEvent.id}>
                  <Td className="text-gray-700">
                    <div className="flex align-center">
                      {notificationEvent.display}
                      {notificationEvent.tooltip && (
                        <Tooltip>
                          <TooltipTrigger>
                            <InformationCircleIcon className="w-5 h-5 shrink-0 ml-1.5 inline-block text-gray-500" />
                          </TooltipTrigger>
                          <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                            {notificationEvent.tooltip}
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                  </Td>
                  <Td>
                    {!notificationEvent.emailDisabled ? (
                      <Checkbox
                        checked={eventNotificationSetting.email}
                        onChange={(e) =>
                          setEventNotificationSetting(notificationEvent.id, {
                            ...eventNotificationSetting,
                            email: e.target.checked,
                          })
                        }
                      />
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )}
                  </Td>
                  <Td>
                    {!notificationEvent.smsDisabled ? (
                      <Checkbox
                        checked={eventNotificationSetting.sms}
                        onChange={(e) =>
                          setEventNotificationSetting(notificationEvent.id, {
                            ...eventNotificationSetting,
                            sms: e.target.checked,
                          })
                        }
                      />
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )}
                  </Td>
                  <Td>
                    <Select
                      className="min-w-[8rem]"
                      currentId={eventNotificationSetting.scope}
                      options={[
                        { display: 'All requests', id: 'all_activity' },
                        { display: 'My requests', id: 'subscribed' },
                      ]}
                      onChange={(selectedId) =>
                        setEventNotificationSetting(notificationEvent.id, {
                          ...eventNotificationSetting,
                          scope: selectedId === 'all_activity' ? 'all_activity' : 'subscribed',
                        })
                      }
                    />
                  </Td>
                </Tr>
              )
            })}
          </Fragment>
        ))}
      </Tbody>
    </Table>
  )
}

export default NotificationsTable
