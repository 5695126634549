import { useSetSubscriptionQuantityMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import type { PlanPaymentMethod, PlanSubscription } from '@/buyers/hooks/usePlanQuery'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Desc from '@/gf/components/Desc'
import Select from '@/gf/components/Select'
import SlideOver from '@/gf/components/SlideOver'
import useMsgs from '@/gf/hooks/useMsgs'
import MoneyM from '@/gf/modules/Money'
import { CreditCardIcon, TicketIcon } from '@heroicons/react/outline'
import pluralize from 'pluralize'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { calcPlanTotal } from './Plan'

type Props = {
  planSubscription: PlanSubscription
  planPaymentMethod: PlanPaymentMethod | null | undefined
  onAdded: () => void
}

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 40, 80]
  .map((n) => n.toString())
  .map((n) => ({ id: n, display: n }))

const AddLicenses = ({ planSubscription, planPaymentMethod, onAdded }: Props) => {
  const { organization, orgId } = useSession()
  const [_, msgr] = useMsgs()
  const [adding, setAdding] = useState(false)
  const [addlQty, setAddlQty] = useState(5)
  const [setSubscriptionQuantity] = useSetSubscriptionQuantityMutation({ client: useGqlClient() })
  const navigate = useNavigate()

  const close = () => navigate('..')
  const licenses = planSubscription.quantity + organization.freeLicenses
  const newLicenses = licenses + addlQty
  const newQty = planSubscription.quantity + addlQty
  const currTotal = calcPlanTotal(planSubscription.plan, planSubscription.quantity)
  const newTotal = calcPlanTotal(planSubscription.plan, newQty)

  const onSubmit = async () => {
    setAdding(true)
    await setSubscriptionQuantity({ variables: { orgId, quantity: newQty } })
    msgr.add(`${pluralize('license', addlQty, true)} added.`, 'positive')
    onAdded()
    close()
  }

  return (
    <SlideOver title="Add Licenses" open={true} onClose={close}>
      <div className="p-8 space-y-12">
        <div className="space-y-2">
          <div className="text-lg">How many additional licenses?</div>

          <div className="flex items-center gap-2">
            <TicketIcon className="w-8 h-8" />

            <div className="flex-initial">
              <Select
                onChange={(value) => setAddlQty(parseInt(value, 10))}
                currentId={addlQty.toString()}
                options={options}
              />
            </div>
          </div>
        </div>

        <div className="space-y-2">
          <div className="text-lg">Subscription Changes</div>

          <Desc.List>
            <Desc.Row title="Licenses">
              <div className="flex items-center gap-8">
                <div className="flex gap-4">
                  <span className="text-gray-500 line-through">{licenses}</span>
                  <span>{newLicenses}</span>
                </div>
              </div>
            </Desc.Row>

            <Desc.Row
              title={
                <>
                  <span className="capitalize">{planSubscription.plan.frequency}</span> Total
                </>
              }
            >
              <div className="flex gap-4">
                <span className="text-gray-500 line-through">{MoneyM.format(currTotal)}</span>
                <span>{MoneyM.format(newTotal)}</span>

                {organization.freeLicenses > 0 && (
                  <span className="text-gray-500 italic">
                    includes {organization.freeLicenses} free licenses
                  </span>
                )}
              </div>
            </Desc.Row>

            {planPaymentMethod && (
              <Desc.Row title="Payment">
                <div className="flex items-center gap-2">
                  <CreditCardIcon className="w-5 h-5" />

                  <div>
                    {planPaymentMethod.brand} ending in {planPaymentMethod.lastFour}
                  </div>
                </div>
              </Desc.Row>
            )}
          </Desc.List>
        </div>
      </div>

      <div className="bg-gray-50 px-8 py-4 flex gap-6 items-center">
        <Action.P onClick={onSubmit} performing={adding}>
          Add
        </Action.P>

        <Action.T onClick={close}>
          <span className="text-sm">Cancel</span>
        </Action.T>
      </div>
    </SlideOver>
  )
}

export default AddLicenses
