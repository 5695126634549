import { useMemo, useRef, useState } from 'react'
import { useSearchBrandsQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Ghost from '@/gf/components/Ghost'
import DropdownAction from '@/gf/components/next/DropdownAction'
import Select from '@/gf/components/next/Select'
import useToggle from '@/gf/hooks/useToggle'
import useClickOutside from '@/gf/hooks/useClickOutside'
import classNames from 'classnames'

const BrandsFilter = ({
  value,
  onChange,
  dropdownPlacement = 'bottom-start',
  className,
}: {
  value: string[]
  onChange: (newValue: string[]) => void
  dropdownPlacement?: 'bottom-end' | 'bottom-start'
  className?: string
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [open, menu] = useToggle()
  const menuRef = useRef<HTMLDivElement>(null)
  useClickOutside(menuRef, menu.off)

  const { data, loading } = useSearchBrandsQuery({
    client: useGqlClient(),
    variables: { searchTerm, ids: null },
  })

  const { data: selectedBrandsData, loading: loadingSelected } = useSearchBrandsQuery({
    client: useGqlClient(),
    variables: { searchTerm: null, ids: value },
    skip: value.length === 0,
  })

  const options = useMemo(
    () => (data?.brands ?? []).map((b) => ({ label: b.name, value: b.id })),
    [data?.brands]
  )

  const selectedOptions = useMemo(
    () => (selectedBrandsData?.brands ?? []).map((b) => ({ label: b.name, value: b.id })),
    [selectedBrandsData]
  )

  return (
    <>
      <div className={classNames('relative z-10', className)} ref={menuRef}>
        <DropdownAction onClick={menu.toggle}>
          Makes:{' '}
          {loadingSelected ? (
            <Ghost className="inline-block h-4 w-20" />
          ) : selectedOptions.length === 0 ? (
            'All'
          ) : (
            `${selectedOptions[0].label} ${
              selectedOptions.length > 1 ? `+${selectedOptions.length - 1}` : ''
            }`
          )}
        </DropdownAction>

        {open && (
          <div
            className={classNames(
              'absolute w-60 bg-white',
              dropdownPlacement === 'bottom-end' ? 'right-0' : 'left-0'
            )}
          >
            <Select
              isMulti
              isLoading={loading || loadingSelected}
              options={options}
              autoFocus
              defaultValue={selectedOptions}
              backspaceRemovesValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              tabSelectsValue={false}
              onInputChange={(input) => setSearchTerm(input)}
              onChange={(opts) => onChange(opts.map((o) => o.value))}
              defaultMenuIsOpen
              classNames={{
                menuPortal: () => '!relative !inset-0',
              }}
              menuPortalTarget={undefined}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default BrandsFilter
