import { pick } from 'lodash'
import { DateTime } from 'luxon'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { BooleanParam, DateParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

const useReportingFormQueryParams = <Tab extends string, Metric extends string>({
  defaultDurationId,
  defaultDurationDates,
  defaultTab,
  defaultMetric,
}: {
  defaultDurationId: string
  defaultDurationDates: { start: DateTime; end: DateTime }
  defaultTab?: Tab
  defaultMetric?: Metric
}) => {
  const [query, updateQuery] = useQueryParams({
    durationId: withDefault(StringParam, defaultDurationId),
    durationStart: withDefault(DateParam, defaultDurationDates.start.toJSDate()),
    durationEnd: withDefault(DateParam, defaultDurationDates.end.toJSDate()),
    urgentRequestsOnly: withDefault(BooleanParam, false),
    tab: withDefault(StringParam, defaultTab),
    metric: withDefault(StringParam, defaultMetric),
  })

  const durationStart = DateTime.fromJSDate(query.durationStart) as DateTime<true>
  const durationEnd = DateTime.fromJSDate(query.durationEnd) as DateTime<true>

  const form = {
    durationStart,
    durationEnd,
    durationId: query.durationId,
    urgentRequestsOnly: query.urgentRequestsOnly,
    tab: (query.tab ?? '') as Tab,
    metric: (query.metric ?? '') as Metric,
  }

  const updateForm = (formChanges: Partial<typeof form>) => {
    const queryChanges: Partial<typeof query> = {}
    if (formChanges.durationStart) queryChanges.durationStart = formChanges.durationStart.toJSDate()
    if (formChanges.durationEnd) queryChanges.durationEnd = formChanges.durationEnd.toJSDate()
    if (formChanges.durationId) queryChanges.durationId = formChanges.durationId
    if (typeof formChanges.urgentRequestsOnly !== 'undefined')
      queryChanges.urgentRequestsOnly = formChanges.urgentRequestsOnly
    if (formChanges.tab) queryChanges.tab = formChanges.tab
    if (formChanges.metric) queryChanges.metric = formChanges.metric

    updateQuery(queryChanges)
  }

  return { form, updateForm }
}

// These are the query params shared between reporting pages
export const useCommonReportingQueryParamString = () => {
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const commonQueryParams = pick(queryParams, ['durationId', 'durationStart', 'durationEnd'])
  return queryString.stringify(commonQueryParams)
}

export default useReportingFormQueryParams
