import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon, CheckIcon } from '@heroicons/react/solid'

import { Option } from '../../types'

const FilterSelect = <T extends Option>({
  options,
  current,
  onChange,
}: {
  options: T[]
  current: T
  onChange: (option: T) => void
}) => (
  <Listbox value={current} onChange={onChange}>
    {({ open }) => (
      <div className="relative">
        <Listbox.Button className="bg-white relative border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
          <span className="block truncate text-gray-700 text-sm">{current.display}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {options.map((option) => (
              <Listbox.Option
                key={option.id}
                className="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-600 hover:text-white group"
                value={option}
              >
                <span
                  className={`
                        ${current.id === option.id ? 'font-bold' : ''}
                        block truncate
                      `}
                >
                  {option.display}
                </span>
                {current.id === option.id ? (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-500">
                    <CheckIcon className="h-4 w-4 group-hover:text-white" aria-hidden="true" />
                  </span>
                ) : null}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    )}
  </Listbox>
)

export default FilterSelect
