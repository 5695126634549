import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  InternalInventoryLookupQuery,
  RfqFulfillFromInventoryQuery,
  useInternalInventoryLookupQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useForm from './useForm'

import LocationField from '@/buyers/components/LocationField'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import Form from '@/gf/components/Form'
import PartsForm from '../PartsForm'

type Rfq = RfqFulfillFromInventoryQuery['rfqs'][number]

const FulfillFromInventoryForm = ({
  rfq,
  initPickupLocationId,
}: {
  rfq: Rfq
  initPickupLocationId: string | null
}) => {
  const [ok, setOk] = useState(false)
  const navigate = useNavigate()
  const [_, msgr] = useMsgs()

  const { fields, submitting, errors, updateFields, addPart, removePart, updatePart, submit } =
    useForm({
      rfq,
      initPickupLocationId,
      onOk: () => {
        msgr.add('Quote created.', 'positive')
        setOk(true)
      },
    })

  useEffect(() => {
    if (ok) navigate(rfq.closedAt ? `/rfqs/${rfq.id}` : 'close-request')
  }, [ok])

  const updateFormWithInternalInventory = (
    productsFromInventory: InternalInventoryLookupQuery['inventory']['results']
  ) => {
    const newParts = fields.parts.map((pr) => {
      const inInventory = productsFromInventory.find((p) => p.mpn === pr.mpn)
      return inInventory
        ? {
            ...pr,
            mpn: inInventory.mpn || '',
            name: inInventory.name || '',
          }
        : pr
    })

    updateFields({ parts: newParts })
  }

  useInternalInventoryLookupQuery({
    client: useGqlClient(),
    variables: { partNumbers: rfq.parts.map((pr) => pr.mpn) },
    onCompleted(data) {
      updateFormWithInternalInventory(data.inventory.results)
    },
  })

  return (
    <Form onSubmit={submit} className="max-w-5xl mt-4">
      <Card>
        <Card.Section>
          <div className="space-y-8">
            <PartsForm
              parts={fields.parts}
              errors={errors.parts}
              updatePart={updatePart}
              removePart={removePart}
              addPart={addPart}
              autoCompleteFromInventory
            />

            <LocationField
              label="Pickup Address"
              desc="This is the address where the parts listed above are located, i.e. your parts room."
              locationId={fields.pickupLocationId}
              onLocationIdChange={(pickupLocationId) => updateFields({ pickupLocationId })}
              errors={{ locationId: errors.pickupLocationId }}
            />

            <Action.P type="submit" performing={submitting}>
              Submit
            </Action.P>
          </div>
        </Card.Section>
      </Card>
    </Form>
  )
}

export default FulfillFromInventoryForm
