import { useState } from 'react'

export type SelectedValue = {
  value: string
  display: string
}
// Store a selected value for different keys
const useSelectedValues = (key: string, defaultValue?: SelectedValue) => {
  const [selectedValueMap, setSelectedValueMap] = useState<
    Record<string, SelectedValue | undefined>
  >({})

  const defaultSelectedValue = defaultValue ?? undefined

  const toggleSelectedValue = (selectedValue: SelectedValue) =>
    setSelectedValueMap((prev) => {
      const prevSelected = key in prev ? prev[key] : defaultSelectedValue
      return {
        ...prev,
        [key]:
          prevSelected && prevSelected.value === selectedValue.value ? undefined : selectedValue,
      }
    })

  const clearSelectedValue = () => setSelectedValueMap((prev) => ({ ...prev, [key]: undefined }))

  return {
    selectedValue: key in selectedValueMap ? selectedValueMap[key] : defaultSelectedValue,
    toggleSelectedValue,
    clearSelectedValue,
  }
}

export default useSelectedValues
