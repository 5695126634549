import { useMemo } from 'react'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { ApolloClient, InMemoryCache, DocumentNode } from '@apollo/client'

import { AutocompleteOption } from '@/types'

import useConfig from '@/gf/hooks/useConfig'

type NewOptions = AutocompleteOption[]
type AsyncSelectProps = Parameters<typeof AsyncSelect>[0]

// TODO: To support the move to codegen, `client` needs to be refactored out (see BuyerAutocompleteNext), or this component should be removed.
const BuyerAutocomplete = ({
  name,
  label,
  query,
  defaultValue,
  defaultOptions,
  allData,
  selected,
  onChange,
  transformation,
  isMulti,
  variables = {},
  placeholder,
}: {
  name?: string
  label?: string
  query: DocumentNode
  allData: any[]
  selected: any[]
  transformation: (response) => NewOptions
  variables?: any
} & Pick<
  AsyncSelectProps,
  'defaultOptions' | 'isMulti' | 'placeholder' | 'onChange' | 'defaultValue'
>) => {
  const config = useConfig()

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: `${config.buyersUrl}/gql`,
        cache: new InMemoryCache(),
        credentials: 'include',
        defaultOptions: { query: { fetchPolicy: 'no-cache' } },
      }),
    [config.buyersUrl]
  )

  const runQuery = async (search: string) => {
    const response = await client.query({ query, variables: { ...variables, value: search } })
    if (!response.loading && search !== '') return transformation(response)
    return []
  }

  const debouncedOptions = debounce(runQuery, 300, { leading: true })
  const selectedIds = selected.map((selection) => selection.value)
  const value = allData.find((o) => o.id === selectedIds)
  const defaultedValue = value || defaultValue

  return (
    <div className="w-full text-sm space-y-1">
      {label && (
        <label className="block text-sm text-gray-700" htmlFor={name}>
          {label}
        </label>
      )}

      <AsyncSelect
        className="shadow-sm"
        onChange={onChange}
        defaultValue={defaultedValue}
        defaultOptions={defaultOptions}
        loadOptions={debouncedOptions}
        isMulti={isMulti}
        placeholder={placeholder}
      />
    </div>
  )
}

export default BuyerAutocomplete
