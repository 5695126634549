import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { useIntegrationsQuery, IntegrationsQuery } from '@/buyers/_gen/gql'

import Apps from './Integrations/Apps'
import ApiKeys from './Integrations/ApiKeys'
import Webhooks from './Integrations/Webhooks'

export type Org = Exclude<IntegrationsQuery['org'], null>
export type Key = Org['keys'][number]

const Integrations = () => {
  const { orgId } = useSession()
  const queryResult = useIntegrationsQuery({ variables: { orgId }, client: useGqlClient() })
  const org = queryResult.data?.org

  if (!org) return null

  return (
    <div className="space-y-4">
      <Apps org={org} onInstalled={queryResult.refetch} onUninstalled={queryResult.refetch} />
      <ApiKeys org={org} onGenerated={queryResult.refetch} onDeleted={queryResult.refetch} />

      {org.features.manageWebhooks && (
        <Webhooks org={org} onAdded={queryResult.refetch} onDeleted={queryResult.refetch} />
      )}
    </div>
  )
}

export default Integrations
