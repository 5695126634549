import React from 'react'
import classNames from 'classnames'

const SlideParentCard: React.FC<{
  children?: React.ReactNode
  className?: string
  slideOpen: boolean
}> = ({ children, slideOpen, className = '' }) => (
  <div
    className={classNames(
      `transform bg-white w-full border border-gray-200 flex items-center justify-center rounded-md transition-all ease-out duration-700 ${className}`,
      slideOpen ? 'shadow' : ''
    )}
  >
    <div className="w-full relative px-2 py-2">{children}</div>
  </div>
)

const SlideChildCard: React.FC<{
  children?: React.ReactNode
  slideOpen: boolean
}> = ({ children, slideOpen }) => (
  <div className="-mt-4 w-full px-1 sm:px-4">
    <div className="pt-4 w-full border border-gray-100 rounded-md">
      <div className={classNames('w-full block', slideOpen ? 'mt-3 pb-3' : '')}>
        <div
          className={`w-full flex items-center overflow-hidden transition-all ease-in-out duration-400 ${
            slideOpen ? 'translate-y-0' : 'translate-y-full h-0'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
)

const SlideDownCard: React.FC<{ children?: React.ReactNode }> = ({ children }) => <>{children}</>

export { SlideDownCard, SlideChildCard, SlideParentCard }
