import {
  SetupDealerLocationsQuery,
  useDealerLocationsByIdsLazyQuery,
  useOrgBrandsQuery,
  useSavedDealerLocationsQuery,
  useSetupDealerLocationsQuery,
  useVendorsSelectedLocationQuery,
} from '@/buyers/_gen/gql'
import AddVendorModal from '@/buyers/components/AddVendorModal'
import BrandsMissingVendors from '@/buyers/components/BrandsMissingVendors'
import FreeProTrialModal from '@/buyers/components/FreeProTrialModal'
import SelectShippingLocationModal from '@/buyers/components/SelectShippingLocationModal'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Link from '@/gf/components/Link'
import Modal from '@/gf/components/ModalNext'
import Dropdown from '@/gf/components/next/Dropdown'
import DropdownAction from '@/gf/components/next/DropdownAction'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import TextTag from '@/gf/components/next/TextTag'
import {
  SimpleTooltip,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/gf/components/next/Tooltip'
import SearchInput from '@/gf/components/SearchInput'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe, ModalSize } from '@/types'
import { ExclamationIcon, RefreshIcon } from '@heroicons/react/outline'
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import pick from 'lodash/pick'
import pluralize from 'pluralize'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import SelectableButton from './SelectableButton'
import Loading from './Vendors/Loading'
import useForm from './Vendors/useForm'

type Form = ReturnType<typeof useForm>

const CantFindVendor = ({
  title = "Can't find your vendor?",
  locationId,
  onLocationIdChanged,
  fields,
  onSearchAllBrands,
  onSearchAllLocations,
  onAddVendorManually,
  className,
}: {
  title?: Maybe<string>
  locationId: Maybe<string>
  onLocationIdChanged?: (locationId: Maybe<string>) => void
  fields: Form['fields']
  onSearchAllBrands: () => void
  onSearchAllLocations: () => void
  onAddVendorManually: () => void
  className?: string
}) => (
  <Box className={classNames('p-6 space-y-4 bg-gray-50', className)}>
    <hgroup className="space-y-2">
      {title && <h4 className="text-lg font-medium text-center">{title}</h4>}
      <p className="text-base text-center">
        {locationId || fields.brandIds.length > 0
          ? 'Expand your search or add a new vendor.'
          : "If your vendor isn't showing up in the list, you can add them manually."}
      </p>
    </hgroup>
    <div className="flex flex-col gap-y-2">
      {fields.brandIds.length > 0 && (
        <Action.S
          onClick={() => {
            onSearchAllBrands()
            GE.clicksButtonOnAccountSetup('search-all-brands')
          }}
          className="block shadow-base w-full"
        >
          Search All Brands
        </Action.S>
      )}
      {locationId && onLocationIdChanged && (
        <Action.S
          onClick={() => {
            onSearchAllLocations()
            GE.clicksButtonOnAccountSetup('search-all-locations')
          }}
          className="block shadow-base w-full"
        >
          Search All Locations
        </Action.S>
      )}
      <Action.S onClick={onAddVendorManually} className="block shadow-base w-full">
        Add New Vendor
      </Action.S>
    </div>
  </Box>
)

const Vendors = ({
  form: {
    fields,
    updateFields,
    toggleLocation,
    putManuallyAddedVendor,
    isLocationSelected,
    toggleBrand,
    isBrandSelected,
  },
  locationId,
  onLocationIdChanged,
  title = 'Do you work with any of these vendors?',
  locationSelectionEnabled = false,
  showBrandsFilter = false,
  showAddNewVendor = false,
  showMissingVendorAlerts = false,
  addBrandsLink = false,
}: {
  form: Form
  locationId: Maybe<string>
  onLocationIdChanged?: (locationId: Maybe<string>) => void
  title?: string
  locationSelectionEnabled?: boolean
  showBrandsFilter?: boolean
  showAddNewVendor?: boolean
  showMissingVendorAlerts?: boolean
  addBrandsLink?: boolean
}) => {
  const location = useLocation()
  const [_, msgs] = useMsgs()
  const { orgId, organization } = useSession()
  const client = useGqlClient()
  const [addVendorOpen, addVendorToggle] = useToggle()
  const [dealerLocationsByIds] = useDealerLocationsByIdsLazyQuery({ client })
  const [locationSelectionOpen, setLocationSelectionOpen] = useQueryParam(
    'selectLocation',
    withDefault(BooleanParam, false)
  )
  const [trialModalOpen, setTrialModalOpen] = useQueryParam(
    'trial',
    withDefault(BooleanParam, false)
  )

  const [locations, setLocations] = useState<
    SetupDealerLocationsQuery['dealerLocations']['entries']
  >([])
  const [page, setPage] = useState(1)
  const { data: locationData } = useVendorsSelectedLocationQuery({
    client,
    variables: { id: locationId as string },
    skip: !locationId,
  })

  const { data: brandsData } = useOrgBrandsQuery({
    client,
    variables: { id: organization.id },
    onCompleted(data) {
      updateFields({ brandIds: data.org?.brandStats.map(({ brand }) => brand.id) ?? [] })
    },
  })

  const selectedLocation = useMemo(
    () => (locationId ? (locationData?.shippingLocation ?? null) : null),
    [locationData, locationId]
  )

  const { data, loading } = useSetupDealerLocationsQuery({
    client,
    variables: {
      orgId,
      brandIds: fields.brandIds,
      nearPoint: selectedLocation?.address.point
        ? {
            location: pick(selectedLocation.address.point, ['lat', 'lng']),
            // Don't limit the distance
            distance: null,
          }
        : null,
      search: fields.search,
      page,
    },
    onCompleted(d) {
      setLocations((prev) =>
        page === 1 ? d.dealerLocations.entries : [...prev, ...d.dealerLocations.entries]
      )
    },
  })

  const { data: savedDealerLocationsData, refetch: refetchSavedLocations } =
    useSavedDealerLocationsQuery({ variables: { orgId }, client })
  const savedDealerLocations = useMemo(
    () => savedDealerLocationsData?.dealerLocations.entries ?? [],
    [savedDealerLocationsData]
  )

  const currentUrl = useMemo(
    () => `${location.pathname}${location.search}`,
    [location.pathname, location.search]
  )

  useEffect(() => {
    setPage(1)
  }, [locationId])

  const showPagination = data ? data.dealerLocations.pagination.totalPages > page : false

  const onVendorCreated = async (id: string | null | undefined) => {
    msgs.add('Vendor added to your account.', 'positive')

    if (id) {
      GE.manuallyAddsVendorOnAccountSetup(id)

      const r = await dealerLocationsByIds({ variables: { orgId, ids: [id] } })

      if (r.data?.dealerLocations.entries && r.data.dealerLocations.entries.length > 0) {
        const [addedLocation] = r.data.dealerLocations.entries

        putManuallyAddedVendor(addedLocation)
      }

      refetchSavedLocations()
    }
  }

  // Truncate the brands we show after this number of brands
  const truncateBrandsCount = 6

  const searchAllBrands = () => {
    setPage(1)
    updateFields({
      brandIds: [],
    })
  }
  const searchAllLocations = () => onLocationIdChanged && onLocationIdChanged(null)

  const addVendorManually = addVendorToggle.on

  return (
    <>
      <SelectShippingLocationModal
        open={locationSelectionOpen}
        onClose={() => setLocationSelectionOpen(undefined, 'replaceIn')}
        title="Which location do you want to add vendors for?"
      />
      <Modal open={addVendorOpen} onClose={addVendorToggle.off} size={ModalSize.SM}>
        <AddVendorModal onClose={addVendorToggle.off} onVendorCreated={onVendorCreated} draftMode />
      </Modal>
      <FreeProTrialModal
        open={trialModalOpen}
        onClose={() => {
          setTrialModalOpen(false)
        }}
        onClaimed={() => {
          setTrialModalOpen(false)
          return Promise.resolve()
        }}
      />
      <div className="px-4 lg:px-8 xl:px-12 flex gap-x-4 lg:gap-x-12 xl:gap-x-20 justify-center">
        <div className="flex-grow basis-1/5 max-w-[15.75rem] pt-12">
          {showBrandsFilter && (
            <div className="sticky top-0 space-y-3 pt-4">
              <h3 className="text-base">Your Brands</h3>
              <div className="flex gap-x-4">
                <Action.T
                  onClick={() => {
                    setPage(1)

                    updateFields({
                      brandIds: brandsData?.org?.brandStats.map(({ brand }) => brand.id) ?? [],
                    })
                  }}
                  disabled={fields.brandIds.length === brandsData?.org?.brandStats.length}
                  className={classNames('no-underline text-sm', {
                    'hover:underline': !(
                      fields.brandIds.length === brandsData?.org?.brandStats.length
                    ),
                  })}
                >
                  Select All
                </Action.T>

                <Action.T
                  onClick={() => {
                    setPage(1)

                    updateFields({
                      brandIds: [],
                    })
                  }}
                  disabled={fields.brandIds.length === 0}
                  className={classNames('no-underline text-sm', {
                    'hover:underline': !(fields.brandIds.length === 0),
                  })}
                >
                  Clear All
                </Action.T>
              </div>

              <ul className="space-y-2 max-h-103 overflow-y-auto fancy-scroll scroll-shadows transition duration-300 p-1">
                {brandsData?.org?.brandStats.map(({ brand, vendorCount }) => (
                  <li key={brand.id} className="block">
                    <label className="flex gap-x-2 items-center text-sm group">
                      <Checkbox
                        checked={isBrandSelected(brand.id)}
                        onChange={() => {
                          setPage(1)

                          toggleBrand(brand.id)
                        }}
                      />
                      <div className="block relative flex-grow">
                        <div className="flex items-center justify-between w-full">
                          <div className="flex items-center justify-start gap-x-3 w-full">
                            {brand.name}
                            <Action.T
                              onClick={() => {
                                setPage(1)

                                updateFields({ brandIds: [brand.id] })
                              }}
                              className="no-underline hover:underline hidden group-hover:block"
                            >
                              Only
                            </Action.T>
                          </div>

                          {showMissingVendorAlerts && vendorCount === 0 && (
                            <Tooltip>
                              <TooltipTrigger>
                                <span className="flex shrink-0 items-center justify-center w-6 h-6 border border-red-200 bg-red-100 text-red-700 rounded-full">
                                  <ExclamationIcon className="inline-block h-4 w-4" />
                                </span>
                              </TooltipTrigger>
                              <TooltipContent className="p-2 bg-gray-700 border border-gray-900 text-gray-100 text-sm rounded-md">
                                Missing vendor
                              </TooltipContent>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </label>
                  </li>
                ))}
              </ul>

              <Link.S
                to={
                  addBrandsLink
                    ? `/brands?returnTo=${encodeURIComponent(currentUrl)}`
                    : '/setup/brands'
                }
                className="inline-block"
              >
                <PlusIcon className="inline-block h-4 w-4" /> Add Brands
              </Link.S>
            </div>
          )}
        </div>

        <div className="w-[30rem] flex-shrink-0 space-y-6 pb-8">
          <hgroup className="space-y-2">
            <h2 className="text-2xl font-medium">{title}</h2>
            {locationSelectionEnabled ? (
              <div className="flex justify-between">
                <DropdownAction onClick={() => setLocationSelectionOpen(true, 'replaceIn')}>
                  Location: {selectedLocation?.name ?? 'All Locations'}
                </DropdownAction>

                {showMissingVendorAlerts && <BrandsMissingVendors />}
              </div>
            ) : (
              <p className="text-lg">
                If you aren’t seeing the vendors for this location, you can skip and add them after
                setup.
              </p>
            )}
          </hgroup>

          <SearchInput
            placeholder="Search for your vendor name"
            value={fields.search}
            onChange={(search) => {
              if (search === '' && fields.search.length > 0) {
                setPage(1)
              }

              updateFields({ search })
            }}
          />

          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row justify-between text-sm text-gray-600 leading-none">
              <span>
                {data?.dealerLocations.pagination.totalResults ?? 'Loading'}{' '}
                {pluralize('result', data?.dealerLocations.pagination.totalResults ?? 2)}
              </span>
              {locationId && <span>Sorting by distance</span>}
            </div>

            <ul className="space-y-4">
              {!loading && locations.length === 0 && (
                <li className="flex flex-col gap-y-4">
                  No vendors found for your filters
                  <CantFindVendor
                    className="max-w-xs"
                    title={null}
                    locationId={locationId}
                    onLocationIdChanged={onLocationIdChanged}
                    fields={fields}
                    onSearchAllBrands={searchAllBrands}
                    onSearchAllLocations={searchAllLocations}
                    onAddVendorManually={addVendorManually}
                  />
                </li>
              )}
              {locations.map((l, i) => (
                <li key={l.id} className="block">
                  <SelectableButton
                    selected={isLocationSelected(l)}
                    onClick={() => toggleLocation(l, i)}
                    className="p-4 text-left flex gap-4 hover:bg-gray-50"
                  >
                    <span className="block w-[3.125rem] h-[3.125rem] border rounded-full overflow-hidden flex-shrink-0">
                      {l.logoUrl && (
                        <img src={l.logoUrl} alt="" className="w-full h-full object-contain" />
                      )}
                    </span>

                    <span className="w-full block">
                      <span className="block text-lg font-medium leading-tight">{l.name}</span>

                      <span className="mt-1 w-full flex flex-row gap-x-2 justify-between">
                        <span className="flex flex-col gap-y-2">
                          {l.address && (
                            <span className="block min-w-48">
                              {l.address.city}, {l.address.state}
                            </span>
                          )}
                          <span className="flex gap-2 flex-wrap">
                            {/* Never truncate when there's "1 more" only truncate with at least "2 more" */}
                            {(l.brands.length > truncateBrandsCount + 1
                              ? l.brands.slice(0, truncateBrandsCount)
                              : l.brands
                            ).map((b) => (
                              <TextTag key={b.id}>{b.name}</TextTag>
                            ))}
                            {l.brands.length > truncateBrandsCount + 1 && (
                              <Tooltip placement="bottom">
                                <TooltipTrigger>
                                  <TextTag>
                                    <InformationCircleIcon className="-ml-0.5 w-4 h-4 inline-flex shrink-0" />
                                    {l.brands.length - truncateBrandsCount} more
                                  </TextTag>
                                </TooltipTrigger>
                                <TooltipContent className="p-3 bg-gray-700 border border-gray-900 text-gray-100 text-sm rounded-md max-w-96 space-y-2">
                                  <p>
                                    {l.brands
                                      .slice(truncateBrandsCount)
                                      .map((b) => b.name)
                                      .join(', ')}
                                  </p>
                                </TooltipContent>
                              </Tooltip>
                            )}
                          </span>
                        </span>
                        <span className="shrink-0 text-sm text-gray-600">
                          {l.distance && `${l.distance.toFixed(0)} mi`}
                        </span>
                      </span>
                    </span>
                  </SelectableButton>
                </li>
              ))}
              {loading && <Loading />}
            </ul>
          </div>

          {showPagination && (
            <div className="flex justify-center">
              <Action.S className="flex items-center" onClick={() => setPage((p) => p + 1)}>
                <RefreshIcon className="h-4 w-4 mr-2" /> Find more
              </Action.S>
            </div>
          )}
        </div>
        <div className="basis-1/5 max-w-[15.75rem] pt-12">
          <div className="space-y-6 sticky top-0 pt-4">
            {fields.dealerLocations.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-base">Adding Vendors</h3>
                <div className="flex gap-2 flex-wrap">
                  {fields.dealerLocations.map((selected, i) => (
                    <TextTag
                      key={selected.id}
                      onRemoveClick={
                        !selected.manuallyAdded ? () => toggleLocation(selected, i) : undefined
                      }
                    >
                      {selected.name}{' '}
                      {selected.manuallyAdded && (
                        <SimpleTooltip text="Manually Added">
                          <InformationCircleIcon className="inline-block h-4 w-4" />
                        </SimpleTooltip>
                      )}
                    </TextTag>
                  ))}
                </div>
              </div>
            )}
            {savedDealerLocations.length > 0 && (
              <Dropdown
                trigger={
                  <DropdownAction>Your Vendors ({savedDealerLocations.length})</DropdownAction>
                }
              >
                <Box className="max-w-72 p-2 space-y-2">
                  <h4 className="font-medium text-gray-500 text-sm">Previously Added</h4>
                  <div className="flex flex-wrap gap-2 max-h-64 overflow-y-auto fancy-scroll scroll-shadows">
                    {savedDealerLocations.map((b) => (
                      <TextTag key={b.id}>{b.name}</TextTag>
                    ))}
                  </div>
                </Box>
              </Dropdown>
            )}

            {showAddNewVendor && (
              <CantFindVendor
                locationId={locationId}
                onLocationIdChanged={onLocationIdChanged}
                fields={fields}
                onSearchAllBrands={searchAllBrands}
                onSearchAllLocations={searchAllLocations}
                onAddVendorManually={addVendorManually}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Vendors
