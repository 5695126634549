import React from 'react'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { UNKNOWN_ERROR } from '@/gf/providers/MsgsProvider'
import useAddPartRequest from '../hooks/useAddPartRequest'

import Action from '@/gf/components/Action'
import Field from '@/gf/components/Field'
import Form from '@/gf/components/Form'
import QuantityInput from '@/gf/components/inputs/Quantity'
import TextInput from '@/gf/components/inputs/Text'
import Modal from '@/gf/components/Modal'
import TextArea from '@/gf/components/TextArea'

interface Form {
  mpn: string
  description: string
  quantity: number | null
}

interface Errors {
  mpn?: string
  description?: string
  quantity?: string
}

interface GqlError {
  message: string
}

const initForm = {
  mpn: '',
  description: '',
  quantity: 1,
}

const Errors = (() => {
  const fromGqlErrors = (gqlErrors: GqlError[]): Errors =>
    gqlErrors.reduce((acc, gqlError) => {
      if (gqlError.message === 'mpn_or_description_required')
        return { ...acc, mpn: 'Part Number or Description is required.' }
      return acc
    }, {})

  return { fromGqlErrors }
})()

const AddPartModal = ({
  rfqId,
  open,
  onClose,
  onAdded,
}: {
  rfqId: string
  open: boolean
  onClose: () => void
  onAdded: () => void
}) => {
  const [_, msgr] = useMsgs()
  const [adding, addingToggler] = useToggle(false)
  const [errors, setErrors] = React.useState<Errors>({})
  const [form, setForm] = React.useState<Form>(initForm)
  const addPartRequest = useAddPartRequest()

  const onSubmit = () => {
    if (adding) return
    addingToggler.on()

    addPartRequest({ rfqId, ...form })
      .then(() => {
        onAdded()
        setForm(initForm)
        setErrors({})
        msgr.add('Part added.', 'positive')
      })
      .catch((err) => {
        const { graphQLErrors } = err
        const newErrors = Errors.fromGqlErrors(graphQLErrors)
        setErrors(newErrors)

        if (Object.keys(newErrors).length === 0) {
          msgr.add(err.message || UNKNOWN_ERROR, 'negative')
        }
      })
      .finally(() => {
        addingToggler.off()
      })
  }

  return (
    <Modal
      title="Add Part"
      open={open}
      onClose={onClose}
      footerAction={
        <Action.P type="submit" form="add-part" disabled={adding}>
          {adding ? <>Adding&hellip;</> : 'Add'}
        </Action.P>
      }
    >
      <Form onSubmit={onSubmit} id="add-part">
        <div className="space-y-4">
          <Field label="Part Number" errors={errors.mpn}>
            <TextInput value={form.mpn} setValue={(mpn) => setForm({ ...form, mpn })} />
          </Field>
          <Field label="Description" errors={errors.description}>
            <TextArea
              rows={4}
              value={form.description}
              setValue={(description) => setForm({ ...form, description })}
            />
          </Field>
          <Field label="Quantity" errors={errors.quantity}>
            <div className="w-48">
              <QuantityInput
                min={1}
                value={form.quantity}
                onBlur={() => {
                  if (form.quantity === null) setForm({ ...form, quantity: 1 })
                }}
                setValue={(quantity) => setForm({ ...form, quantity })}
              />
            </div>
          </Field>
        </div>
      </Form>
    </Modal>
  )
}

export default AddPartModal
