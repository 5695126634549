import { GraphQLError } from 'graphql'
import { SubmitHandler, useForm } from 'react-hook-form'

import Button from '@/gf/components/ButtonOld'
import Card from '@/gf/components/Card'
import TextField from '@/gf/components/TextField'

import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import useUpdatePassword from '../hooks/useUpdatePassword'

interface UpdatePasswordData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

const ChangePassword = () => {
  const [loading, loader] = useToggle()
  const [_msgs, msgsMgr] = useMsgs()
  const updatePassword = useUpdatePassword()

  const updatePasswordForm = useForm<UpdatePasswordData>({
    shouldUnregister: true,
    defaultValues: { oldPassword: '', newPassword: '', confirmPassword: '' },
  })

  const onUpdatePasswordSubmit: SubmitHandler<UpdatePasswordData> = (updatePasswordData) => {
    loader.on()
    updatePassword({ variables: updatePasswordData })
      .then(() => {
        msgsMgr.add('Updated password.', 'positive')
        updatePasswordForm.reset()
      })
      .catch((e: GraphQLError) => {
        let { message } = e
        if (message.includes('current_password'))
          message = message.replaceAll('current_password', "'old password'")
        else if (message.includes('password_confirmation'))
          message.replaceAll('password_confirmation', "'confirm password'")
        // Check for 'password' last, since it's a substring of the previous two
        else if (message.includes('password')) message.replaceAll('password', "'new password'")
        msgsMgr.add(message, 'negative')
      })
      .finally(() => loader.off())
  }

  return (
    <form onSubmit={updatePasswordForm.handleSubmit(onUpdatePasswordSubmit)}>
      <Card
        title="Change Password"
        subtitle={<>Updates the password associated with your account.</>}
        primaryFooterAction={{
          id: 1,
          element: <Button title="Save" type="submit" performing={loading} />,
        }}
      >
        <Card.Section>
          <div className="space-y-4">
            <TextField
              label="Current Password"
              type="password"
              {...updatePasswordForm.register('oldPassword', { required: true })}
            />
            <TextField
              label="New Password"
              type="password"
              {...updatePasswordForm.register('newPassword', { required: true })}
            />
            <TextField
              label="Confirm Password"
              type="password"
              {...updatePasswordForm.register('confirmPassword', { required: true })}
            />
          </div>
        </Card.Section>
      </Card>
    </form>
  )
}

export default ChangePassword
