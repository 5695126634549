import { PointInput, useDealerLocationsQuery, useMyVendorsForBrandQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import { VendorTypeFilter } from '@/buyers/types'
import { Maybe, Point } from '@/types'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Filters, VendorSelectionType } from '../../types'

const createNearPoint = (filters) => {
  if (filters.location) {
    return { location: filters.location, distance: null }
  }

  return null
}

const emptyFilters: Filters = {
  search: '',
  location: null,
  brands: [],
}

const createFilters = (location: Maybe<Point>, machineBrandId: Maybe<string>): Filters => ({
  search: '',
  location: location ?? null,
  brands: machineBrandId ? [machineBrandId] : [],
})

const useVendorsQuery = (
  nearbyReferencePoint: Maybe<Point>,
  machineBrandId: Maybe<string>,
  vendorSelectionType: VendorSelectionType,
  excludeVendorIds: string[],
  disabledContactIds: string[],
  listDrafts: boolean,
  tutorial: boolean
) => {
  const { orgId } = useSession()
  const client = useGqlClient()
  const [initialized, setInitialized] = useState(false)
  const [filters, setFilters] = useState<Filters>(emptyFilters)

  const { data: defaultFiltersData, loading: loadingFilters } = useMyVendorsForBrandQuery({
    client,
    variables: {
      orgId,
      location: nearbyReferencePoint as PointInput,
      machineBrandId: machineBrandId as string,
      excludedVendorIds: excludeVendorIds,
    },
    skip: !nearbyReferencePoint || !machineBrandId,
  })

  const initialFilters = useMemo(
    () => ({
      ...emptyFilters,
      location: nearbyReferencePoint,
    }),
    [nearbyReferencePoint]
  )

  useEffect(() => {
    if (initialized) {
      return
    }

    if (vendorSelectionType === 'limited') {
      setFilters({ ...initialFilters })
      setInitialized(true)
      return
    }

    if (defaultFiltersData) {
      // User has vendors for machine brand
      const savedVendorsForMachine = defaultFiltersData.dealerLocations.pagination.totalResults > 0

      const newFilters = savedVendorsForMachine
        ? createFilters(nearbyReferencePoint, machineBrandId)
        : initialFilters

      setFilters(newFilters)
      setInitialized(true)
      return
    }

    if (!loadingFilters && nearbyReferencePoint) {
      setFilters(initialFilters)
      setInitialized(true)
    }
  }, [
    loadingFilters,
    defaultFiltersData,
    initialFilters,
    nearbyReferencePoint,
    machineBrandId,
    initialized,
    vendorSelectionType,
  ])

  const filtered = useMemo(() => !isEqual(initialFilters, filters), [initialFilters, filters])

  const {
    refetch: refetchDealerLocations,
    data,
    loading: loadingVendors,
  } = useDealerLocationsQuery({
    client,
    variables: {
      orgId,
      excludedVendorIds: excludeVendorIds,
      vendorIds: null,
      searchTerm: filters.search,
      nearPoint: createNearPoint(filters),
      brandIds: filters.brands,
      saved: true,
      drafts: listDrafts ? true : null,
      tutorial,
      preferredSettings: null,
      active: null,
    },
    skip: !initialized,
  })

  const dealerLocations = (data?.dealerLocations.entries ?? []).filter((dl) =>
    dl.vendor
      ? disabledContactIds.length > 0
        ? dl.vendor.contacts.filter((c) => !disabledContactIds.includes(c.id)).length > 0
        : true
      : true
  )

  const updateFilters = (changes: Partial<Filters>) =>
    setFilters((prev) => ({ ...prev, ...changes }))

  const onSearchChanged = useCallback(
    (search) =>
      setFilters((prev) =>
        !prev.search && search
          ? {
              ...initialFilters,
              type: VendorTypeFilter.MyDealers,
              search,
            }
          : { ...prev, search }
      ),
    [initialFilters]
  )

  const loading = useMemo(
    () => !initialized || loadingFilters || loadingVendors,
    [loadingFilters, loadingVendors, initialized]
  )

  return {
    refetchDealerLocations,
    dealerLocations,
    totalResults: data?.dealerLocations.pagination.totalResults,
    loading,
    filtered,
    filters,
    updateFilters,
    onSearchChanged,
  }
}

export default useVendorsQuery
