import { GraphQLError } from 'graphql'
import { useState } from 'react'

import { useCancelRfqMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'

import Action from '@/gf/components/Action'
import Modal from '@/gf/components/Modal'
import SelectField from '@/gf/components/SelectField'
import TextAreaField from '@/gf/components/TextAreaField'

type Reason = 'locatedElsewhere' | 'duplicateRequest' | 'noLongerNeeded' | 'other'

const cancelReasons = {
  locatedElsewhere: 'Located Elsewhere',
  duplicateRequest: 'Duplicate Request',
  noLongerNeeded: 'No Longer Needed',
  other: 'Other',
}

const CancelRfqModal = ({
  open,
  onClose,
  requestForQuoteId,
}: {
  open: boolean
  onClose: ({ didCancelRfq }) => void
  requestForQuoteId: string
}) => {
  const [selectedReasonId, setSelectedReasonId] = useState<Reason>('locatedElsewhere')
  const [additionalDetails, setAddtionalDetails] = useState<string>('')
  const [_msgs, msgsMgr] = useMsgs()
  const [spinnerLive, spinner] = useToggle()
  const [cancelRequest] = useCancelRfqMutation({ client: useGqlClient() })
  const submitDisabled = !selectedReasonId || (selectedReasonId === 'other' && !additionalDetails)

  const reasonOptions = Object.keys(cancelReasons).map((key) => ({
    id: key,
    display: cancelReasons[key],
  }))

  const onSubmit = () => {
    spinner.on()

    cancelRequest({
      variables: {
        requestForQuoteId,
        reason: selectedReasonId === 'other' ? additionalDetails : cancelReasons[selectedReasonId],
      },
    })
      .then(() => onClose({ didCancelRfq: true }))
      .catch((e: GraphQLError) => {
        if (e.message) msgsMgr.add(e.message, 'negative')
        else msgsMgr.addUnknownError()
      })
      .finally(spinner.off)
  }

  return (
    <Modal
      title="Cancel Request"
      open={open}
      onClose={() => onClose({ didCancelRfq: false })}
      footerAction={
        <Action.P onClick={onSubmit} performing={spinnerLive} disabled={submitDisabled}>
          Submit
        </Action.P>
      }
    >
      <div className="space-y-4">
        <p className="prose">This will deny all open quotes.</p>

        <SelectField
          label="Reason"
          options={reasonOptions}
          currentId={selectedReasonId}
          onChange={(id) => setSelectedReasonId(id as Reason)}
        />

        {selectedReasonId === 'other' && (
          <TextAreaField
            label="What is the reason for canceling this request?"
            value={additionalDetails}
            onChange={(e) => setAddtionalDetails(e?.target.value)}
          />
        )}
      </div>
    </Modal>
  )
}

export default CancelRfqModal
