import classNames from 'classnames'

const OwnedBadge = ({ owned }: { owned: boolean }) => (
  <span
    className={classNames(
      'flex-shrink-0 rounded-full text-xs px-2 py-1 leading-3 inline-flex items-center gap-x-1 justify-center leading-3',
      owned ? 'text-sky-500 bg-sky-50' : 'bg-amber-50 text-amber-600'
    )}
  >
    <span
      className={classNames(
        'h-1.5 w-1.5 rounded-full inline-block',
        owned ? 'bg-sky-500' : 'bg-amber-600'
      )}
    />
    {owned ? 'Machine Owner' : 'Not Owner'}
  </span>
)

export default OwnedBadge
