import { gql, useMutation } from '@apollo/client'

interface Vars {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface Data {
  updatePassword: { id: string }
}

const query = gql`
  mutation UpdatePassword($oldPassword: String, $newPassword: String, $confirmPassword: String) {
    updatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      id
    }
  }
`

const useUpdatePassword = () => {
  const [updatePassword, _result] = useMutation<Data, Vars>(query)

  return updatePassword
}

export default useUpdatePassword
