import Action from '@/gf/components/Action'
import classNames from 'classnames'
import { ReactNode } from 'react'

const Banner = ({
  image,
  actionIcon,
  action,
  performingAction,
  onActionClick,
  title,
  text,
  color,
}: {
  title: string
  text: string
  action: string
  image: string
  performingAction: boolean
  onActionClick: () => void
  actionIcon?: ReactNode
  color: 'blue' | 'pink'
}) => (
  <section
    className={classNames(
      'flex items-center  shadow-base rounded-xl',
      color === 'blue' ? 'bg-blue-300' : 'bg-pink-300'
    )}
  >
    <div className="space-y-3 basis-full sm:basis-1/2 px-8 py-6">
      <h3 className="text-xl lg:text-3xl font-medium">{title}</h3>
      <p className="text-sm lg:text-lg">{text}</p>

      <div className="pt-4 lg:pt-8">
        <Action.P
          onClick={onActionClick}
          performing={performingAction}
          color={color}
          size="lg"
          className="font-medium items-center"
        >
          {actionIcon} {action}
        </Action.P>
      </div>
    </div>
    <div className="basis-1/2 pt-8 pr-2 hidden sm:block self-end">
      <img src={image} alt={action} />
    </div>
  </section>
)

export default Banner
