import { useQuery, gql } from '@apollo/client'

import { Source } from '../../types'

interface Data {
  sources: Source[]
}

interface Variables {
  storeId?: string
}

const query = gql`
  query FetchSources($storeId: String) {
    sources(storeId: $storeId) {
      id
      name
    }
  }
`

const useFetchSources = (variables: Variables) => useQuery<Data, Variables>(query, { variables })

export default useFetchSources
