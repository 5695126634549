import Box from '@/gf/components/Box'
import classNames from 'classnames'

type Props = Parameters<typeof Box>[0] & { disabled?: boolean; contentClassName?: string }

const FormSection = ({ children, className, contentClassName, disabled }: Props) => (
  <Box className={classNames('rounded-xl p-8 shadow-base', disabled && 'opacity-50', className)}>
    <div className={contentClassName}>{children}</div>
  </Box>
)

export default FormSection
