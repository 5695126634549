import { useUpdateUserPhoneMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useReloadSession from '@/buyers/hooks/useReloadSession'
import useSession from '@/buyers/hooks/useSession'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Modal from '@/gf/components/ModalNext'
import Field from '@/gf/components/next/forms/Field'
import TextInput from '@/gf/components/next/forms/TextInput'
import useMsgs from '@/gf/hooks/useMsgs'
import { useState } from 'react'

const UpdatePhoneModal = ({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) => {
  const client = useGqlClient()
  const { user } = useSession()
  const reloadSession = useReloadSession()
  const [_msgs, msgsMgr] = useMsgs()
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(user.phoneNumber || undefined)
  const [setUserPhone] = useUpdateUserPhoneMutation({ client })

  const onSubmit = async () => {
    try {
      const pattern = /^(\d{10}|\(\d{3}\)\s?\d{3}-\d{4}|\d{3}-\d{3}-\d{4}|\d{3}\s\d{3}\s\d{4})$/

      if (!phoneNumber) return
      if (!pattern.test(phoneNumber)) return

      await setUserPhone({ variables: { phoneNumber } })

      reloadSession()
      onSuccess()

      onClose()
    } catch (err) {
      msgsMgr.add((err as Error)?.message ?? 'Something went wrong')
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={(e) => e.preventDefault()} className="px-6 py-4 relative space-y-4">
        <h2 className="text-lg leading-7">Add Phone Number</h2>
        <CloseModalButton onClick={onClose} className="absolute -top-2 right-3" />

        <Field label="Phone Number">
          <TextInput
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </Field>

        <div className="flex justify-end">
          <Action.P onClick={onSubmit}>Save</Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default UpdatePhoneModal
