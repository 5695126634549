import { useState, useCallback, forwardRef } from 'react'
import debounce from 'lodash/debounce'
import { SearchIcon, XIcon } from '@heroicons/react/solid'

import TextInput from './Text'

type TextInputProps = Parameters<typeof TextInput>[0]

type Props = Omit<TextInputProps, 'onChange' | 'setValue'> & {
  delay?: number
  setValue: Exclude<TextInputProps['setValue'], undefined>
}

const DelayedSearchInput = forwardRef<HTMLInputElement, Props>(({ delay = 300, ...props }, ref) => {
  const [value, setValue] = useState(props.value)
  const debouncedOnChange = useCallback(debounce(props.setValue, delay), [])

  const update = (newValue: string) => {
    setValue(newValue)
    debouncedOnChange(newValue)
  }

  return (
    <label className="relative flex items-center">
      <TextInput {...props} value={value} setValue={update} ref={ref} className="pl-8" />
      <SearchIcon className="absolute left-2 h-5 text-gray-500" />

      {!!value && (
        <button
          onClick={() => update('')}
          className="absolute right-2 text-slate-500"
          type="button"
        >
          <XIcon className="h-5" />
        </button>
      )}
    </label>
  )
})

export default DelayedSearchInput
