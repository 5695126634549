import A from '@/gf/components/A'
import useConfig from '@/gf/hooks/useConfig'
import request from './request.png'

const RequestFlowCallout = () => {
  const config = useConfig()
  return (
    <section className="border border-gray-200 shadow-base rounded-xl bg-gray-50 h-auto lg:h-[26.75rem] p-4 lg:p-0 flex flex-col lg:flex-row items-center overflow-hidden">
      <div className="space-y-4 max-w-full lg:max-w-[25.5rem] lg:pl-12">
        <h2 className="text-3xl font-medium">
          Explore how the request flow works for your whole team and vendors.
        </h2>
        <p className="text-lg">
          Learn how field technicians, purchasing managers, and vendors all use Gearflow to get
          parts delivered fast.
        </p>
        <A.S target="_blank" href={`${config.gfBaseUrl}/how-it-works`}>
          See How it Works
        </A.S>
      </div>
      <div className="relative flex-grow h-full pt-6 lg:pt-0 lg:pl-7">
        <img src={request} alt="Request flow" className="w-[35rem] top-0 lg:top-12 lg:absolute" />
      </div>
    </section>
  )
}

export default RequestFlowCallout
