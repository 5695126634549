import { Org, useVendorsCountQuery } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'

const useVendorSelectionType = (
  org: { features: Pick<Org['features'], 'viewDealers'> } | undefined | null
) => {
  const { orgId } = useSession()

  const { data: vendorsCountData } = useVendorsCountQuery({
    variables: { orgId },
    client: useGqlClient(),
  })

  const accountHasVendors = vendorsCountData
    ? vendorsCountData.nonDraftVendors.pagination.totalResults > 0
    : false

  return org?.features.viewDealers ? 'full' : accountHasVendors ? 'limited' : 'disabled'
}

export default useVendorSelectionType
