import { gql, useQuery } from '@apollo/client'

import { RfqEventPayload, StoreOrderEventPayload } from '@/types'

import RfqEvent from '@/gf/modules/RfqEvent'
import StoreOrderEvent from '@/gf/modules/StoreOrderEvent'

type EventsPayload = {
  storeOrderEvents: StoreOrderEventPayload[]
  rfqEvents: RfqEventPayload[]
}

type Data = { eventsSearch: EventsPayload }
type StoreOrderVars = { storeOrderId: string }
type RfqVars = { requestForQuoteId: string }
type Vars = StoreOrderVars | RfqVars

const TIMELINE_QUERY = gql`
  query EventsSearch($storeOrderId: String) {
    eventsSearch(storeOrderId: $storeOrderId) {
      storeOrderEvents {
        storeOrderId
        id
        type
        privacy
        user {
          id
          name: displayName
          role
        }
        occurredAt
        insertedAt
        transaction {
          id
          action
          customer {
            currency
            amount
          }
          gearflow {
            currency
            amount
          }
          supplier {
            currency
            amount
          }
        }
        refund {
          id
          amount {
            currency
            amount
          }
        }
        additionalCharge {
          id
          name
          price {
            currency
            amount
          }
        }
        shipment {
          id
          link
          carrier
          tracking
          shipmentItems {
            id
            quantity
            lineItem {
              id
              productName
              quantity
            }
          }
        }
        storeOrderAudit {
          id
          shippingCost {
            currency
            amount
          }
          timingDetails
          pickup
          taxCost {
            currency
            amount
          }
          lineItemAudits {
            id
            quantity
            unitPrice {
              currency
              amount
            }
            productId
            productName
          }
        }
        diffStoreOrderAudit {
          id
          shippingCost {
            currency
            amount
          }
          timingDetails
          pickup
          taxCost {
            currency
            amount
          }
          lineItemAudits {
            id
            quantity
            unitPrice {
              currency
              amount
            }
            productId
            productName
          }
        }
        problem {
          id
          type
          details
        }
        note
        emailSent
        data {
          id
          details
          payOnAccount
          payOnInvoice
          name
          previousAddress {
            firstName
            lastName
            lineOne
            lineTwo
            city
            state
            country
            postalCode
            companyName
          }
          link
          deliveryMethod
        }
      }
      rfqEvents {
        requestForQuoteId
        id
        type
        privacy
        user {
          id
          name: displayName
          role
        }
        store {
          name
        }
        occurredAt
        insertedAt
        note
        emailSent
        data {
          id
          name
          fileUrl
          vendorId
          vendorName
        }
      }
    }
  }
`
const TIMELINE_QUERY_FROM_RFQ_ID = gql`
  query EventsSearch($requestForQuoteId: String) {
    eventsSearch(requestForQuoteId: $requestForQuoteId) {
      storeOrderEvents {
        storeOrderId
        id
        type
        privacy
        user {
          id
          name: displayName
          role
        }
        occurredAt
        insertedAt
        transaction {
          id
          action
          customer {
            currency
            amount
          }
          gearflow {
            currency
            amount
          }
          supplier {
            currency
            amount
          }
        }
        refund {
          id
          amount {
            currency
            amount
          }
        }
        additionalCharge {
          id
          name
          price {
            currency
            amount
          }
        }
        shipment {
          id
          link
          carrier
          tracking
          shipmentItems {
            id
            quantity
            lineItem {
              id
              productName
              quantity
            }
          }
        }
        storeOrderAudit {
          id
          shippingCost {
            currency
            amount
          }
          timingDetails
          pickup
          taxCost {
            currency
            amount
          }
          lineItemAudits {
            id
            quantity
            unitPrice {
              currency
              amount
            }
            productId
            productName
          }
        }
        diffStoreOrderAudit {
          id
          shippingCost {
            currency
            amount
          }
          timingDetails
          pickup
          taxCost {
            currency
            amount
          }
          lineItemAudits {
            id
            quantity
            unitPrice {
              currency
              amount
            }
            productId
            productName
          }
        }
        problem {
          id
          type
          details
        }
        note
        emailSent
        data {
          id
          details
          payOnAccount
          payOnInvoice
          name
          previousAddress {
            firstName
            lastName
            lineOne
            lineTwo
            city
            state
            country
            postalCode
            companyName
          }
          deliveryMethod
        }
      }
      rfqEvents {
        requestForQuoteId
        id
        type
        privacy
        user {
          id
          name: displayName
          role
        }
        store {
          name
        }
        occurredAt
        insertedAt
        note
        emailSent
        data {
          id
          name
          fileUrl
          vendorId
          vendorName
        }
      }
    }
  }
`

const useTimelineEvents = (storeOrderId: string) => {
  const result = useQuery<Data, Vars>(TIMELINE_QUERY, {
    variables: { storeOrderId },
    fetchPolicy: 'no-cache',
  })

  const data = result.data && {
    ...result.data,
    eventsSearch: {
      ...result.data.eventsSearch,
      storeOrderEvents: result.data.eventsSearch.storeOrderEvents.map(StoreOrderEvent.fromPayload),
      rfqEvents: result.data.eventsSearch.rfqEvents.map(RfqEvent.fromPayload),
    },
  }

  return { ...result, data }
}

useTimelineEvents.Query = TIMELINE_QUERY
export default useTimelineEvents

const useTimelineEventsForRfq = (requestForQuoteId: string) => {
  const result = useQuery<Data, Vars>(TIMELINE_QUERY_FROM_RFQ_ID, {
    variables: { requestForQuoteId },
  })

  const data = result.data && {
    ...result.data,
    eventsSearch: {
      ...result.data.eventsSearch,
      rfqEvents: result.data.eventsSearch.rfqEvents.map((e) => RfqEvent.fromPayload(e)),
      storeOrderEvents: result.data.eventsSearch.storeOrderEvents.map((e) =>
        StoreOrderEvent.fromPayload(e)
      ),
    },
  }

  return { ...result, data }
}

useTimelineEventsForRfq.Query = TIMELINE_QUERY_FROM_RFQ_ID
export { TIMELINE_QUERY, TIMELINE_QUERY_FROM_RFQ_ID, useTimelineEventsForRfq }
