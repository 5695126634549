const Engine = (props: React.ComponentProps<'svg'>) => (
  <svg width="24" height="24" version="1.1" viewBox="0 0 700 700" fill="currentColor" {...props}>
    <g>
      <path
        d="m 667.52946,250.7967 h -95.86257 l -17.11597,40.22612 h -7.7015 v -14.54911 c 0,-9.41493 -7.7015,-17.11597 -17.11597,-17.11597 l -42.79375,-0.006 V 227.6859 c 0,-23.9645 -18.82925,-42.79375 -42.79375,-42.79375 h -78.74049 v -30.81147 h 78.74049 c 7.7015,0 13.69538,-5.99403 13.69538,-13.69537 V 129.2571 c 0,-7.7015 -5.99403,-13.69537 -13.69538,-13.69537 l -269.6006,0.006 c -7.7015,0 -13.69538,5.99402 -13.69538,13.69537 v 11.12821 c 0,7.7015 5.99403,13.69537 13.69538,13.69537 h 88.15511 v 30.8115 l -87.29465,-0.006 c -5.99403,0 -12.83568,1.71343 -17.97644,5.13433 l -47.07465,32.52478 H 67.560978 v 120.6799 H 40.169923 l 0.006,-100.13737 c 0,-7.7015 -5.994028,-13.69538 -13.695374,-13.69538 H 15.35231 c -7.7014994,0 -13.6953751,5.99403 -13.6953751,13.69538 V 482.7388 c 0,7.7015 5.9940285,13.69538 13.6953751,13.69538 h 11.128208 c 7.701499,0 13.695374,-5.99403 13.695374,-13.69538 V 382.59685 h 27.384942 v 118.96661 h 53.921646 l 119.81944,89.8684 h 259.33011 c 25.67777,0 46.21419,-20.54252 46.21419,-46.21419 V 523.8208 h 7.7015 l 17.11597,40.22612 h 95.85646 l 30.8115,-69.32434 0.0119,-173.74261 z"
        id="path61"
        style={{ strokeWidth: '1.52835' }}
      />
    </g>
  </svg>
)

export default Engine
