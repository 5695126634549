import { DeliveryMethod, RequestQuery } from '@/buyers/_gen/gql'
import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import Modal from '@/gf/components/Modal'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'
import useConfig from '@/gf/hooks/useConfig'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { trackCopyLink } from '@/gf/modules/Analytics'
import { copyText } from '@/gf/modules/utils'
import {
  ChatAlt2Icon,
  CubeIcon,
  DocumentTextIcon,
  LinkIcon,
  OfficeBuildingIcon,
  TruckIcon,
} from '@heroicons/react/outline'
import { DateTime } from 'luxon'
import { useState } from 'react'
import useSession from '../../../hooks/useSession'
import { useContext } from '../../RFQ'
import StoreInfoModal from './StoreInfoModal'
import VendorDetails from './VendorDetails'

const formatReceivedAt = (dateTime: DateTime) => {
  const format = dateTime.year < DateTime.now().year ? 'DD, t' : 'LLL d, t'
  return dateTime.toFormat(format)
}

type RequestForQuote = RequestQuery['requestForQuotesSearch']['requestForQuotes'][number]

const AwaitingQuote = ({
  rfqVendor,
}: {
  rfqVendor: RequestForQuote['requestForQuoteVendors'][number]
}) => {
  const { suppliersUrl } = useConfig()
  const [_msgs, msgsMgr] = useMsgs()
  const { rfq } = useContext()
  const { featureFlags } = useSession()
  const [infoOpen, { toggle: toggleInfo }] = useToggle(false)
  const [showModal, setShowModal] = useState(false)
  const { store } = rfqVendor.vendor

  const copyDealerLink = (link: string) => {
    copyText(link)
    msgsMgr.add('Link copied to clipboard', 'positive')
    setShowModal(false)
    trackCopyLink('buyers')
  }

  return (
    <>
      <Modal
        title="Vendor contact links"
        open={showModal}
        onClose={() => setShowModal(false)}
        showFooter={false}
      >
        <div className="py-6 space-y-4">
          {rfqVendor.vendorContacts.map((c) => (
            <div key={c.id}>
              <Action.S
                onClick={() =>
                  copyDealerLink(
                    `${suppliersUrl}/rfqs/${rfq.id}/contact/${c.id}/quote/${rfqVendor.vendor.id}`
                  )
                }
                className="inline-flex items-center text-sm place-self-start"
              >
                <LinkIcon className="mr-0.5 h-4 text-slate-600 inline-block capitalize" />
                {c.name} Vendor Link
              </Action.S>
            </div>
          ))}
        </div>
      </Modal>
      <div className="border-2 rounded-md">
        <div className="relative flex flex-col md:flex-row gap-y-2 p-4">
          <div className="flex-grow">
            {store && (
              <VendorDetails
                name={store.name}
                address={store.address}
                distance={rfqVendor.distanceFromShippingAddress}
                contactNames={rfqVendor.vendorContacts
                  .filter((c) => !!c.name)
                  .map((c) => c.name as string)}
              >
                <div className="mt-3 flex flex-row flex-wrap items-center self-start gap-x-4 gap-y-2">
                  {featureFlags.canMessageVendorDealerBeforeQuote && (
                    <Link.T
                      to={`/rfqs/${rfq.id}/messages/store/${store.id}`}
                      className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
                    >
                      <ChatAlt2Icon className="h-5 inline-flex shrink-0 text-gearflow" /> Send
                      Message
                    </Link.T>
                  )}

                  {!rfq.needsApproval && (
                    <Tooltip>
                      <TooltipTrigger>
                        <Link.T
                          to={`/rfqs/${rfq.id}/vendors/${rfqVendor.vendor.id}/create-quote`}
                          className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
                        >
                          <DocumentTextIcon className="h-5 inline-flex shrink-0 text-slate-600" />
                          Enter Quote
                        </Link.T>
                      </TooltipTrigger>
                      <TooltipContent className="max-w-xs p-3 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
                        Call your Vendor and enter the quote information here. Or enter your order
                        information from an OEM portal.
                      </TooltipContent>
                    </Tooltip>
                  )}

                  <Action.T
                    onClick={toggleInfo}
                    className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
                  >
                    <OfficeBuildingIcon className="h-5 inline-flex shrink-0 text-slate-600" />
                    Store Info
                  </Action.T>

                  <Action.T
                    onClick={() => setShowModal(true)}
                    className="inline-flex gap-x-0.5 items-center text-sm whitespace-nowrap"
                  >
                    <LinkIcon className="h-5 inline-flex shrink-0 text-slate-600" />
                    Copy Vendor Link
                  </Action.T>
                </div>
              </VendorDetails>
            )}
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm">
              Requested on {formatReceivedAt(rfqVendor.addedAt || rfq.insertedAt)}
            </div>

            <div className="flex items-center gap-1 text-sm">
              {rfqVendor.deliveryMethod === DeliveryMethod.Pickup ? (
                <>
                  <OfficeBuildingIcon className="w-4 h-4" />
                  <span>Will Call</span>
                </>
              ) : rfqVendor.deliveryMethod === DeliveryMethod.Shipping ? (
                <>
                  <CubeIcon className="w-4 h-4" />
                  <span>Ship</span>
                </>
              ) : rfqVendor.deliveryMethod === DeliveryMethod.VendorDelivery ? (
                <>
                  <TruckIcon className="w-4 h-4" />
                  <span>Delivery</span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        {rfqVendor.vendor.id && (
          <StoreInfoModal open={infoOpen} onClose={toggleInfo} vendorId={rfqVendor.vendor.id} />
        )}
      </div>
    </>
  )
}

export default AwaitingQuote
