import type { PermissionSummary, Role } from '@/buyers/_gen/gql'
import RolePicker from '@/buyers/components/RolePicker'
import type { AdminUserForm } from '@/buyers/hooks/useAddUserForm'
import Action from '@/gf/components/Action'
import Card from '@/gf/components/Card'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

const Permissions = ({
  fields,
  errors,
  updateFields,
  validate,
  roles,
  cancel,
  navigateToStepIndex,
  permissionSummaries,
}: AdminUserForm & {
  cancel: () => void
  navigateToStepIndex: (index: number) => void
  roles: Pick<Role, 'id' | 'name'>[] | undefined
  permissionSummaries: Pick<PermissionSummary, 'title' | 'roles'>[] | undefined
}) => (
  <Card>
    <Card.Section title="Roles">
      {errors.roleId && (
        <div className="flex gap-1 items-center">
          <ExclamationCircleIcon className="block h-5 w-5 text-red-500" aria-hidden="true" />
          <span className="text-sm text-red-500">{errors.roleId}</span>
        </div>
      )}
      <RolePicker
        roles={roles}
        permissionSummaries={permissionSummaries}
        roleId={fields.roleId}
        onChange={(roleId) => updateFields({ roleId })}
      />
    </Card.Section>
    <Card.Section>
      <div className="flex gap-6 justify-end">
        <Action.T onClick={cancel}>
          <span className="text-sm">Cancel</span>
        </Action.T>

        <div className="flex gap-2">
          <Action.S onClick={() => navigateToStepIndex(0)}>Previous</Action.S>

          <Action.P onClick={() => validate('permissions').then(() => navigateToStepIndex(2))}>
            Next
          </Action.P>
        </div>
      </div>
    </Card.Section>
  </Card>
)

export default Permissions
