import { ReactNode } from 'react'
import classNames from 'classnames'
import { abs } from 'mathjs'

import { ExclamationIcon } from '@heroicons/react/solid'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/gf/components/next/Tooltip'

type GetValueDisplay = (value: number) => ReactNode

enum OutlierLevel {
  High = 2,
  Low = 1,
}

const OutlierLevelBadge = ({
  level,
  diff,
  meanValue,
  getValueDisplay,
  className,
}: {
  level: OutlierLevel
  diff: number
  meanValue: number
  getValueDisplay?: GetValueDisplay
  className?: string
}) => (
  <Tooltip>
    <TooltipTrigger className={className}>
      <ExclamationIcon
        className={classNames(
          'w-5 h-5 flex shrink-0',
          level === OutlierLevel.High ? 'text-red-600' : 'text-yellow-600'
        )}
      />
    </TooltipTrigger>
    <TooltipContent className="max-w-52 p-3 z-50 bg-gray-50 border border-gray-300 rounded shadow-sm text-sm text-gray-900">
      <div>
        This is{' '}
        <span className="font-medium">
          {typeof getValueDisplay !== 'undefined' ? getValueDisplay(abs(diff)) : abs(diff)}
        </span>{' '}
        {diff < 0 ? 'lower' : 'higher'} than the average (
        {typeof getValueDisplay !== 'undefined' ? getValueDisplay(meanValue) : meanValue}).
      </div>
    </TooltipContent>
  </Tooltip>
)

const OutlierBadge = ({
  value,
  meanValue,
  stdValue,
  downIsGood,
  getValueDisplay,
  className,
}: {
  value: number
  meanValue: number
  stdValue: number
  downIsGood?: boolean
  getValueDisplay?: GetValueDisplay
  className?: string
}) => {
  const diff = value - meanValue
  const highOutlierDiff = 4 * stdValue
  const lowOutlierDiff = 3 * stdValue
  return (downIsGood && diff > highOutlierDiff) || (!downIsGood && diff < -highOutlierDiff) ? (
    <OutlierLevelBadge
      className={className}
      level={OutlierLevel.High}
      diff={diff}
      meanValue={meanValue}
      getValueDisplay={getValueDisplay}
    />
  ) : (downIsGood && diff > lowOutlierDiff) || (!downIsGood && diff < -lowOutlierDiff) ? (
    <OutlierLevelBadge
      className={className}
      level={OutlierLevel.Low}
      diff={diff}
      meanValue={meanValue}
      getValueDisplay={getValueDisplay}
    />
  ) : null
}

export default OutlierBadge
